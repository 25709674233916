import * as React from "react";
import { Card } from "react-bootstrap";

interface IProps {
	valueCodeId: string;
	used: Date;
	found: boolean;
	usedByEmail: string;
	usedByName: string;
}

export default function ValueCodeItem(props: IProps) {
	let name: string;
	if (props.usedByEmail) {
		name = props.usedByName;

		if (name === props.usedByEmail) {
			name = "No name";
		}
	}

	return (
			<Card className="w-75" style={{maxWidth: 'none'}}>
				<Card.Body className="d-flex column text-center">
					<Card.Title className="py-2 h1">{props.valueCodeId}</Card.Title>
					<Card.Subtitle className={`d-flex ${props.used ? "justify-content-between" : "justify-content-center"}`}>
						{props.found
							? props.used
								? (
									<>
										<div className="d-flex justify-content-between w-100">
											<span>Used on:</span>
											<span><b>{props.used.toLocaleString("no")}</b></span>
										</div>
									</>
									)
								: "Unused"
							: "Not found"}
					</Card.Subtitle>
					{props.found && props.usedByEmail && (
						<>
							<div className="d-flex justify-content-between">
								<span>Email: </span>
								<Card.Text><b>{props.usedByEmail || "Unknown"} </b></Card.Text>
							</div>
							<div className="d-flex justify-content-between">
								<span>Name: </span>
								<Card.Text><b>{name}</b></Card.Text>
							</div>
						</>
					)}
				</Card.Body>
			</Card>
	);
}

import { Types } from "@sno_oslo/shared-utils";
import React, { useCallback, useEffect, useState } from "react";
import { Stack } from "react-bootstrap";

import FetchErrorAlert from "../common/FetchErrorAlert";
import Loader from "../common/loader";
import { getAllPersonalTrainers } from "../../controllers/personal-trainers";
import useFormat from "../../hooks/useFormat";
import TrainerCard from "./TrainerCard";

const TrainersList: React.FC = () => {
	const format = useFormat();
	const [trainers, setTrainers] = useState<Array<Types.IPersonalTrainer>>();
	const [isLoading, setIsLoading] = useState(false);
	const [fetchError, setFetchError] = useState<Error | null>(null);

	const fetchTrainers = useCallback(async () => {
		try {
			setFetchError(null);
			setIsLoading(true);

			setTrainers(await getAllPersonalTrainers());
		} catch (err) {
			setFetchError(err as Error);
		} finally {
			setIsLoading(false);
		}
	}, []);

	const handleTrainerDeleted = useCallback(
		(id: Types.IPersonalTrainer["id"]) => {
			setTrainers(trainers!.filter((trainer) => trainer.id !== id));
		},
		[trainers],
	);

	useEffect(() => {
		fetchTrainers();
	}, []);

	return (
		<>
			<h1>{format("trainers:title")}</h1>
			{trainers ? (
				trainers.length > 0 ? (
					<Stack gap={3} className="max-w-md mx-auto">
						{trainers.map((trainer) => (
							<TrainerCard key={trainer.id} trainer={trainer} onDeleted={handleTrainerDeleted} />
						))}
					</Stack>
				) : (
					<p className="text-center p-3 lead">{format("common:emptyState")}</p>
				)
			) : (
				fetchError && <FetchErrorAlert message={fetchError.message} onRetry={fetchTrainers} />
			)}
			{isLoading && <Loader loadingText={format("common:loading")} />}
		</>
	);
};

export default TrainersList;

import { EContentfulContentTypeId } from "../../enums/contentful-content-type-id.enum";
import { managementClient } from "./managementClient";
import { EntryProps } from "contentful-management";

export const getSlopes = async () => {
	const { items } = await managementClient.entry.getMany({
		query: { content_type: EContentfulContentTypeId.SLOPE, order: "sys.createdAt" },
	});

	return items;
};

export const updateSlope = async (entryId: string, entry: EntryProps) => {
	const updatedEntry = await managementClient.entry.update(
		{
			entryId,
		},
		entry,
	);

	return managementClient.entry.publish(
		{
			entryId,
		},
		updatedEntry,
	);
};

import { Types } from "@sno_oslo/shared-utils";
import React, { useCallback } from "react";

import FetchErrorAlert from "../common/FetchErrorAlert";
import Loader from "../common/loader";
import useFormat from "../../hooks/useFormat";
import PromoCard from "./PromoCard";
import useFetchPromoCodes from "../../hooks/useFetchPromoCodes";

interface IProps {
	accessLevel: Types.AdminAccessLevel;
}

const PromoCodesList: React.FC<IProps> = ({ accessLevel }) => {
	const format = useFormat();
	const { promoCodes, setPromoCodes, isLoading, fetchError, fetchPromoCodes } = useFetchPromoCodes();

	const handlePromoCodeDelete = useCallback(
		(id: Types.IPromoCode["id"]) => {
			setPromoCodes(promoCodes!.filter((promocode) => promocode.id !== id));
		},
		[promoCodes],
	);

	return (
		<>
			<h1>{format("promocodes:title")}</h1>
			{promoCodes ? (
				promoCodes.length > 0 ? (
					promoCodes.map((promocode) => (
						<PromoCard key={promocode.id} promocode={promocode} onDeleted={handlePromoCodeDelete} accessLevel={accessLevel} />
					))
				) : (
					<p className="text-center p-3 lead">{format("common:emptyState")}</p>
				)
			) : (
				fetchError && <FetchErrorAlert message={fetchError.message} onRetry={fetchPromoCodes} />
			)}
			{isLoading && <Loader loadingText={format("common:loading")} />}
		</>
	);
};

export default PromoCodesList;

import { Types } from "@sno_oslo/shared-utils";
import moment from "moment";
import React, { useState } from "react";
import { validate as validateEmail } from "email-validator";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import useFormat from "../../hooks/useFormat";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import useSnackbar from "../../hooks/useSnackbar";
import { deleteUser, updateUserEmail } from "../../controllers/customers";
import { useCallback } from "react";
import ConfirmModal from "../common/ConfirmModal";
import { useNavigate } from "react-router-dom";

interface IFormValues {
	email: string;
}
interface IProps {
	user: Types.IUserData;
}

const CustomerForm: React.FC<IProps> = ({ user }) => {
	const format = useFormat();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [isDeleting, setDeleting] = useState(false);
	const { addAlert } = useSnackbar();
	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		control,
		formState: { isSubmitting, errors },
	} = useForm<any>({
		defaultValues: {
			email: user.email || "",
		},
		mode: "onBlur",
	});

	useWatch({ control, name: "email" });

	const onDelete = useCallback(async () => {
		setDeleting(true);

		try {
			await deleteUser(user.uid);

			addAlert(format('alerts:deleted'), "error");
			navigate('..');
		} catch (err) {
			console.error(err);
			addAlert((err as Error).message || format("error:default"), "danger");
			setDeleting(false);
		}
	}, [user.uid, addAlert, format]);

	const onSubmit = useCallback<SubmitHandler<IFormValues>>(
		async ({ email }) => {
			try {
				await updateUserEmail(user.uid, email);

				addAlert(format('alerts:saved'), "success");
			} catch (err) {
				console.error(err);
				addAlert((err as Error).message || format("error:default"), "danger");
			}
		},
		[user.uid, addAlert, format],
	);

	return (
		<>
			<Form noValidate onSubmit={handleSubmit(onSubmit)}>
				<Stack gap={3}>
					<Row>
						<Form.Group as={Col}>
							<Form.Label>{format("common:id")}</Form.Label>
							<Form.Control value={user.uid} disabled />
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>{format("common:name")}</Form.Label>
							<Form.Control value={user.name} disabled />
						</Form.Group>
					</Row>

					<Row>
						<Form.Group as={Col}>
							<Form.Label>{format("common:email")}</Form.Label>
							<Form.Control
								type="email"
								{...register("email", { required: true, validate: { invalid: validateEmail } })}
								isInvalid={!!errors.email}
							/>
							{errors.email && (
								<Form.Control.Feedback type="invalid">
									{format(`validation:${errors.email.type}`, { field: format("common:email") })}
								</Form.Control.Feedback>
							)}
						</Form.Group>
					</Row>

					<Row>
						<Form.Group as={Col}>
							<Form.Label>{format("common:joinedAt")}</Form.Label>
							<Form.Control value={moment(user.createdAt).format("YYYY-MM-DD LT")} disabled />
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>{format("common:lastLoginAt")}</Form.Label>
							<Form.Control value={moment(user.lastSignInTime).format("YYYY-MM-DD LT")} disabled />
						</Form.Group>
					</Row>
				</Stack>

				<Stack direction="horizontal" gap={2} className="mt-3">
					<Button type="submit" disabled={isSubmitting}>
						{format(isSubmitting ? "users:form:save:loading" : "common:save")}
					</Button>
					<div className="flex-1" />

					<Button
						variant="outline-danger"
						disabled={isSubmitting}
						onClick={() => setShowDeleteModal(true)}
					>
						{format(`common:delete`)}
					</Button>
				</Stack>
			</Form>

			<ConfirmModal
				confirmVariant="danger"
				show={showDeleteModal}
				onHide={() => setShowDeleteModal(false)}
				onConfirm={onDelete}
				isLoading={isDeleting}
			>
				{format("users:list:delete:details")} <mark>{user.name}</mark>
			</ConfirmModal>
		</>
	);
}

export default CustomerForm;
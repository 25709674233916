import { useContext } from "react";

import ContentContext from "../contexts/ContentContext";

const useFormat = () => {
	const { format } = useContext(ContentContext);
	return format;
};

export default useFormat;

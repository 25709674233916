import moment from "moment";
import { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Types } from "@sno_oslo/shared-utils";
import { TVippsChargeStatus } from "@sno_oslo/shared-utils/dist/types";
import { IFullOrderModel } from "@sno_oslo/node-utils/dist/cartOrder/dynamodb-schemas";

import useFormat from "../../../hooks/useFormat";
import useSnackbar from "../../../hooks/useSnackbar";
import RefundForm, { IRefundFormValues } from "../refunds/RefundForm";
import { issueVippsChargeRefund } from "../../../controllers/memberships";
import { sendRefundToAccounting, updateOrder } from "../../../controllers/orders";
import { convertToUnixSeconds } from "../../../utils/dateUtils";

interface IVippsChargeItemProps {
	order?: IFullOrderModel;
	charge: Types.IVippsAgreementCharge;
	onRefunded: () => void;
	onCancelProduct?: () => Promise<void>;
}

const chargeStatusToColorMap: Record<TVippsChargeStatus, string> = {
	CANCELLED: "#c83e4d",
	CHARGED: "#008000",
	DUE: "#dbe3e8",
	FAILED: "#c83e4d",
	PARTIALLY_CAPTURED: "#dbe3e8",
	PARTIALLY_REFUNDED: "#008000",
	PENDING: "#dbe3e8",
	PROCESSING: "#dbe3e8",
	REFUNDED: "#ffd23f",
	RESERVED: "#dbe3e8",
};

const VippsChargeItem: FC<IVippsChargeItemProps> = ({ order, charge, onRefunded, onCancelProduct }) => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const [showRefundModal, setShowRefundModal] = useState(false);
	const refundedAmount = charge.history.reduce(
		(total, record) => total + (record.event === "REFUND" ? record.amount : 0),
		0,
	);
	const remainingAmount = charge.amount - refundedAmount;
	const giftCartToCashRate = (order.giftCardsPayment || 0) / order.cartPrice;

	const handleRefund = async ({ amount, cancelProduct, authorizedBy, reason }: IRefundFormValues) => {
		try {
			await issueVippsChargeRefund(
				{
					amount,
					description: `Refunded ${charge.description}, ${amount / 100} NOK`,
				},
				charge.agreementId,
				charge.id,
			);

			const isFirstCharge = order ? order.id === charge.id : !charge.id.startsWith("ch-");
			const chargeAmountWithoutDiscount = order
				? order.cartPriceWithoutPromoCode - (isFirstCharge ? order.giftCardsPayment || 0 : 0)
				: charge.amount;
			const discountRate = (chargeAmountWithoutDiscount - charge.amount) / chargeAmountWithoutDiscount;

			const discount = (amount / charge.amount) * chargeAmountWithoutDiscount * discountRate;

			const partialRefundRate = amount / charge.amount;

			if (isFirstCharge && order) {
				const refund: Types.IOrderRefund = {
					cash: amount,
					discount,
					partial: amount < charge.amount,
					reason,
					authorizedBy,
					products: order.orderLines.map((line) => {
						const discount = (line.productPayload.discount as number) || 0;
						const actualPrice = (line.productPayload.price as number) - discount;
						const giftCardAmount = Math.round(giftCartToCashRate * actualPrice);
						const cash = actualPrice - giftCardAmount;

						return {
							tempId: line.productPayload.tempId as string,
							tempFamilyPackId: line.productPayload.tempFamilyPackId as string,
							productId: line.productId,
							name: line.productPayload.name as string,
							cash: partialRefundRate * cash,
							discount: partialRefundRate * discount,
							addons: (
								line.productPayload.addons as Array<{ id: string; productName: string; price: number }>
							)?.map((addon) => ({
								id: addon.id,
								name: addon.productName,
								cash: partialRefundRate * (addon.price - Math.round(giftCartToCashRate * addon.price)),
							})),
						};
					}),
					vippsTransactionId: charge.transactionId,
					createdAt: new Date().toISOString(),
				};
				const refunds = order.refunds?.length ? [...order.refunds, refund] : [refund];

				await updateOrder(order.id, { refunds });
			}

			const xledgerRefundItems = [
				{
					amount: -amount,
					chargeId: charge.id,
					chargeCreated: convertToUnixSeconds(new Date(charge.history[0].occurred).getTime()),
					productId: charge.agreementId,
					number: 1,
					name: charge.description,
					partner: "snooslo",
					discount,
				},
			];

			await sendRefundToAccounting(order?.id || charge.id, {
				refundItems: xledgerRefundItems,
			});

			if (cancelProduct) {
				onCancelProduct?.();
			}

			addAlert("Refunded successfully", "success");

			onRefunded();
		} catch (err) {
			addAlert((err as Error).message || format("error:default"), "danger");
		} finally {
			setShowRefundModal(false);
		}
	};

	return (
		<>
			<div className="ms-2 me-auto">
				<div className="fw-bold">{charge.id}</div>

				<p
					className="mb-0 fw-bold"
					style={{
						color: chargeStatusToColorMap[charge.status],
					}}
				>
					Status: {charge.status}
				</p>

				<p className="mb-0">Amount: {charge.amount / 100} NOK</p>

				{refundedAmount > 0 && <p className="mb-0">Refunded: {refundedAmount / 100} NOK</p>}

				<p className="mb-0">Created on: {moment(charge.history[0].occurred).format("DD MMMM YYYY")}</p>

				{charge.amount > 0 && remainingAmount > 0 && (
					<Button onClick={() => setShowRefundModal(true)} size="sm" className="mt-1 mb-1">
						Refund
					</Button>
				)}
			</div>

			<Modal
				show={showRefundModal}
				centered
				onHide={() => setShowRefundModal(false)}
				className="modal-background"
			>
				<Modal.Header closeButton>
					<Modal.Title>Refund charge: {charge.id}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<RefundForm
						remainingAmount={remainingAmount}
						onRefund={handleRefund}
						canCancel={!!onCancelProduct}
					/>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default VippsChargeItem;

import { Types } from "@sno_oslo/shared-utils";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Calendar, CalendarProps, Event, momentLocalizer, View, Views } from "react-big-calendar";
import { useNavigate } from "react-router";
import { getAllCourses } from "../../../controllers/lessons/groupCourses";
import useFormat from "../../../hooks/useFormat";
import useSnackbar from "../../../hooks/useSnackbar";
import { getDisciplineColor } from "../../../utils/colorUtils";

import SectionLoader from "../../common/SectionLoader";

moment.locale("en", {
	week: {
		dow: 1,
		doy: 1,
	},
});
const localizer = momentLocalizer(moment);

interface IProps {}

interface IEvent extends Event {
	id: string;
	index: number;
	discipline: Types.Discipline;
}

interface IRange {
	start: Date;
	end: Date;
}

const AllCoursesCalendar: React.FC<IProps> = () => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const navigate = useNavigate();
	const views = useMemo<Array<View>>(() => ["month", "week", "day"], []);
	const [range, setRange] = useState<IRange>({
		start: moment().startOf("month").toDate(),
		end: moment().endOf("month").toDate(),
	});
	const [isFetching, setFetching] = useState(false);

	const [courses, setCourses] = useState<Array<Types.IGroupCourse>>([]);
	const courseEvents = useMemo(
		() =>
			courses
				.map(({ id, name, disciplines, startDates, endDates }, index) =>
					startDates.map(
						(start, i) =>
							({
								id,
								index,
								title: `${name} (${disciplines.join(", ")})`,
								discipline: disciplines[0],
								start: new Date(start),
								end: new Date(endDates[i]),
							} as IEvent),
					),
				)
				.flat(),
		[courses, range],
	);

	const handleSelectEvent = useCallback((event: IEvent) => {
		navigate(`/lessons/group-courses/${event.id}`);
	}, []);

	const handleRangeChange = useCallback<Required<CalendarProps>["onRangeChange"]>((newRange) => {
		setRange(
			Array.isArray(newRange)
				? {
						start: newRange[0],
						end: moment(newRange[newRange.length - 1])
							.endOf("day")
							.toDate(),
				  }
				: newRange,
		);
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			setFetching(true);

			try {
				setCourses(await getAllCourses());
			} catch (err) {
				addAlert((err as Error).message || format("error:default"), "danger");
			} finally {
				setFetching(false);
			}
		};

		fetchData();
	}, []);

	return (
		<SectionLoader className="h-100" isLoading={isFetching}>
			<Calendar
				localizer={localizer}
				views={views}
				defaultView={Views.MONTH}
				onRangeChange={handleRangeChange}
				selectable
				onSelectEvent={handleSelectEvent}
				events={courseEvents}
				startAccessor="start"
				endAccessor="end"
				step={60}
				timeslots={1}
				eventPropGetter={({ discipline }) => ({
					style: { backgroundColor: getDisciplineColor(discipline) },
				})}
			/>
		</SectionLoader>
	);
};

export default AllCoursesCalendar;

import { IFullOrderModel } from "@sno_oslo/node-utils/dist/cartOrder/dynamodb-schemas";
import { Types } from "@sno_oslo/shared-utils";
import type stripe from "stripe";

import { performRequest } from "./request";

export interface ISearchResult {
	id: string;
	name: string;
	email: string;
}

export interface IPassesResponse {
	ownerAccessProducts: Array<Types.IAccessProduct>;
	userAccessProducts: Array<Types.IAccessProduct>;
}

export interface IGuestPassesResponse {
	guestAccessProducts: Array<{ Items: Array<Types.IAccessProduct> }>;
}

export interface IGuestPassResponse {
	guestAccess: { Items: Array<Types.IAccessProduct> };
}

export const searchUser = (query: string): Promise<Types.IUserData[]> =>
	performRequest({
		path: `/users/search`,
		method: "POST",
		body: { query },
	});

export const getUser = (userId: string): Promise<{ user: Types.IUserData }> =>
	performRequest({
		path: `/users/${userId}`,
		method: "GET",
	});

export const getUserPasses = (userId: string, archived?: boolean): Promise<IPassesResponse> =>
	performRequest({
		path: `/users/${userId}/passes${archived ? "?archived=true" : ""}`,
		method: "GET",
	});

export const getGuestPassesByEmail = (userEmail: string, archived = false): Promise<IGuestPassesResponse> =>
	performRequest({
		path: `/users/guest/passes?email=${userEmail}&archived=${archived}`,
		method: "GET",
	});

export const searchGuestPassesByEmail = (userEmail: string, archived = false): Promise<Array<Types.IAccessProduct>> =>
	performRequest({
		path: `/users/search/guest?archived=${archived}`,
		method: "POST",
		body: { query: userEmail },
	});

export const getGuestOrders = (orderIds: Array<string>): Promise<Array<IFullOrderModel>> => {
	const queryString = orderIds.map((id) => `orderIds[]=${encodeURIComponent(id)}`).join("&");
	return performRequest({
		path: `/users/guest/orders?${queryString}`,
		method: "GET",
	});
};

export const getUserOrders = (userId: string): Promise<Array<IFullOrderModel>> =>
	performRequest({
		path: `/users/${userId}/orders`,
		method: "GET",
	});

export const getUserStripeSubscriptions = (userId: string): Promise<Array<stripe.Subscription>> =>
	performRequest({
		path: `/users/${userId}/stripe-subscriptions`,
		method: "GET",
	});

export const updateUserEmail = (userId: string, email: string): Promise<{ user: Types.IUserData }> =>
	performRequest({
		path: `/users/${userId}`,
		method: "PUT",
		body: { email },
	});

export const deleteUser = (userId: string): Promise<string> =>
	performRequest({
		path: `/users/${userId}`,
		method: "DELETE",
	});

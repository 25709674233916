/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useState } from "react";
import { NavLink, useMatch, useSearchParams } from "react-router-dom";
import useFormat from "../../hooks/useFormat";
import AllCoursesModal from "./group_courses/AllCoursesModal";

export default function Sidebar() {
	const format = useFormat();
	const [params] = useSearchParams();
	const isCoursesPage = useMatch({ path: "/lessons/group-courses/", caseSensitive: false, end: true });
	const isArchived = params.get("archived") === "true";
	const [isAllCoursesModalOpen, setAllCoursesModalOpen] = useState(false);

	return (
		<Fragment>
			<nav className="sidebar">
				<h2>Lessons</h2>
				<ul>
					<li>
						<a href="#">Home</a>
					</li>
					<li>Group courses</li>
					<ul>
						<li>
							<NavLink
								to="group-courses"
								className={isCoursesPage && !isArchived ? "selected" : undefined}
							>
								Active
							</NavLink>
						</li>
						<li>
							<NavLink
								to="group-courses?archived=true"
								className={isCoursesPage && isArchived ? "selected" : undefined}
							>
								Archived
							</NavLink>
						</li>
					</ul>
					<li>
						<NavLink to="add_gc" className={({ isActive }) => (isActive ? "selected" : undefined)}>
							Add group course
						</NavLink>
					</li>
					<li>
						<a
							href="#"
							onClick={(e) => {
								e.preventDefault();
								setAllCoursesModalOpen(true);
							}}
						>
							{format("lessons:courses:all")}
						</a>
					</li>
					<li>
						<NavLink
							to="search_reservation"
							className={({ isActive }) => (isActive ? "selected" : undefined)}
						>
							Search reservation
						</NavLink>
					</li>
				</ul>
			</nav>

			<AllCoursesModal
				show={isAllCoursesModalOpen}
				onHide={() => setAllCoursesModalOpen(false)}
				title={format("lessons:courses:all")}
			/>
		</Fragment>
	);
}

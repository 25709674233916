import { Types } from "@sno_oslo/shared-utils";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";

import { getAllPersonalTrainers, updatePersonalTrainerReservation } from "../../controllers/personal-trainers";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";

interface IProps {
	reservation: Types.IPersonalTrainerReservation;
	onCancel: () => void;
	onUpdated: (reservation: Types.IPersonalTrainerReservation) => void;
}

interface IFormValues {
	trainerId: string;
}

const TrainerReservationChangeTrainerForm: React.FC<IProps> = ({ reservation, onCancel, onUpdated }) => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const [personalTrainers, setPersonalTrainers] = useState<Array<Types.IPersonalTrainer> | null>(null);

	const {
		register,
		handleSubmit,
		formState: { isSubmitting },
	} = useForm<IFormValues>({
		defaultValues: {
			trainerId: reservation.personalTrainerId
		},
		mode: "onBlur",
	});

	useEffect(() => {
		(async () => {
			try {
				setPersonalTrainers(await getAllPersonalTrainers());
			} catch (err) {
				addAlert((err as Error).message || format("error:default"), "danger");
			}
		})();
	}, []);

	const onSubmit = useCallback<SubmitHandler<IFormValues>>(
		async (values) => {
			try {
				const savedReservation = await updatePersonalTrainerReservation(
					reservation.personalTrainerId,
					reservation.id,
					{
						personalTrainerId: values.trainerId,
					},
				);

				addAlert(format("alerts:saved"), "success");
				onUpdated(savedReservation);
			} catch (err) {
				addAlert((err as Error).message || format("error:default"), "danger");
			}
		},
		[reservation.id, reservation.personalTrainerId, format, addAlert],
	);

	return (
		<Form noValidate onSubmit={handleSubmit(onSubmit)}>
			<Stack gap={3}>
				<Form.Group>
					<Form.Label>{format("form:trainer")}</Form.Label>
					<Form.Select size="lg" {...register("trainerId")}>
						{personalTrainers ? (
							personalTrainers.map(({ id, name, surname }) => (
								<option key={id} value={id}>
									{name} {surname}
								</option>
							))
						) : (
							<option value="" disabled>
								{format("form:trainer:fetching")}
							</option>
						)}
					</Form.Select>
				</Form.Group>

				<Stack direction="horizontal" gap={2} className="justify-content-end mt-1">
					<Button variant="secondary" onClick={() => onCancel()}>
						{format("common:cancel")}
					</Button>
					<Button type="submit" variant="primary" disabled={isSubmitting}>
						{format("trainers:reservations:preview:changeTrainer")}
					</Button>
				</Stack>
			</Stack>
		</Form>
	);
};

export default TrainerReservationChangeTrainerForm;

import * as React from "react";
import { Container, Form, ListGroup, Stack, InputGroup, Button } from "react-bootstrap";
import { FaQrcode } from "react-icons/fa";

import LoaderButton from "../common/loader-button";
import { searchGuestPassesByEmail, searchUser } from "../../controllers/customers";
import CustomerItem from "./customerItem";
import { useState } from "react";
import { Scanner } from "@yudiel/react-qr-scanner";
import { getAccessPass } from "../../controllers/access-passes";
import { useNavigate } from "react-router-dom";
import GuestItem from "./guestItem";

export default function Customers() {
	const navigate = useNavigate();

	const [searchQuery, setSearchQuery] = React.useState<string | null>(null);
	const [isSearching, setIsSearching] = React.useState<boolean>(false);
	const [searchResults, setSearchResults] = React.useState<Array<any>>([]);
	const [searchGeustResults, setSearchGeustResults] = React.useState<Array<{ email: string; name: string }>>([]);
	const [isScannerOpened, setIsScannerOpened] = useState(false);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		setIsSearching(true);
		try {
			if (!isNaN(+searchQuery)) {
				return handleScan(searchQuery);
			}
			const [customers, guestCustomers, guestArchiveCustomers] = await Promise.all([
				searchUser(searchQuery),
				searchGuestPassesByEmail(searchQuery),
				searchGuestPassesByEmail(searchQuery, true),
			]);

			const uniquePasses = Array.from(
				new Map(
					[...guestCustomers, ...guestArchiveCustomers]
						.filter((item) => !!item.userEmail)
						.map((item) => [item.userEmail, item]),
				).values(),
			).map(({ userEmail, userNameForPass }) => ({ email: userEmail, name: userNameForPass }));

			setSearchResults(customers);
			setSearchGeustResults(uniquePasses);
			setIsSearching(false);
		} catch (error) {
			setIsSearching(false);
		}
	};

	const handleScan = async (scanResult: string) => {
		setIsScannerOpened(false);
		setIsSearching(true);

		try {
			const accessPass = await getAccessPass(+scanResult);
			setIsSearching(false);
			if (accessPass.userId) {
				navigate(`/customers/${accessPass.userId}?qrCode=${accessPass.qrCode}`);
			} else {
				navigate(
					`/customers/guest?email=${accessPass.userEmail}&qrCode=${accessPass.qrCode}&name=${accessPass.userNameForPass}`,
				);
			}
		} catch (e) {
			console.error(e);
			setIsSearching(false);
		}
	};

	return (
		<React.Fragment>
			<Container className="p-3">
				<h1>Search for customers</h1>

				<Form onSubmit={handleSubmit}>
					<Stack gap={3}>
						<Form.Group controlId="formGroupEmail">
							<Form.Label>Name or email address or QRcode</Form.Label>
							<InputGroup>
								<Form.Control
									type="search"
									name="searchQuery"
									size="lg"
									onChange={(e) => setSearchQuery(e.target.value)}
								/>
								<Button
									variant={isScannerOpened ? "danger" : "info"}
									className="rounded-end"
									onClick={() => setIsScannerOpened((prev) => !prev)}
								>
									<FaQrcode />
								</Button>
							</InputGroup>
						</Form.Group>
					</Stack>

					<LoaderButton
						type="submit"
						loadingLabel="Searching"
						isLoading={isSearching}
						disabled={!searchQuery}
						size="lg"
						className="mt-3"
					>
						Search
					</LoaderButton>
				</Form>

				{searchResults && (
					<ListGroup variant="flush" className="mt-4">
						{searchResults.map((customer) => (
							<CustomerItem
								key={customer.uid}
								userId={customer.uid}
								name={customer.name}
								email={customer.email}
							/>
						))}
					</ListGroup>
				)}

				<ListGroup variant="flush" className="mt-4">
					{searchGeustResults.map((guest) => (
						<GuestItem key={guest.email} name={guest.name} email={guest.email} />
					))}
				</ListGroup>

				{isScannerOpened && (
					<div className="mt-3">
						<Scanner onScan={(result) => handleScan(result[0]?.rawValue)} />
					</div>
				)}
			</Container>
		</React.Fragment>
	);
}

import { Helpers, Types } from "@sno_oslo/shared-utils";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, ButtonGroup, Col, Form, ListGroup, Modal, Row, Stack, ToggleButton } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";

import { getGuestOrders, getGuestPassesByEmail } from "../../controllers/customers";
import useFormat from "../../hooks/useFormat";
import PlusIcon from "../common/PlusIcon";
import Loader from "../common/loader";
import AccessPassDetails from "./accessPassDetails";
import AccessPassItem from "./accessPassItem";
import MinusIcon from "../common/MinusIcon";
import { AllProductsType, getProducts } from "../../controllers/value-codes";
import ArrowRight from "../common/arrow";
import type { IFullOrderModel } from "@sno_oslo/node-utils/dist/cartOrder/dynamodb-schemas";
import OrderDetails from "./order/OrderDetail";
import OrderItem from "./order/orderItem";

type TOrdersFilter = "confirmed" | "unconfirmed";
type TPassesFilter = "ongoing" | "archived";

const GuestAccessPasses: React.FC = () => {
	const [searchParams] = useSearchParams();
	const qrCode = searchParams.get("qrCode") ? +searchParams.get("qrCode") : null;
	const guestEmail = searchParams.get("email") ? searchParams.get("email") : null;
	const guestName = searchParams.get("name") ? searchParams.get("name") : null;
	const format = useFormat();
	const [accessPasses, setAccessPasses] = useState<(Types.IAccessProduct | Types.IAccessProductArchive)[] | null>(
		null,
	);
	const [guestInfo] = useState<{ email: string; name: string } | null>({
		email: guestEmail ?? "",
		name: guestName ?? "",
	});
	const [activePassQrCode, setActivePassQrCode] = useState<number | null>(qrCode);
	const [expandAccessPasses, setExpandAccessPasses] = useState<boolean>(false);
	const [expandOrders, setExpandOrders] = useState<boolean>(false);
	const [activeOrderId, setActiveOrderId] = useState<string | null>(null);
	const [orders, setOrders] = useState<Array<IFullOrderModel> | null>([]);
	const [orderIds, setOrderIds] = useState<Array<string>>([]);
	const [ordersFilter, setOrdersFilter] = useState<TOrdersFilter>("confirmed");
	const [passesFilter, setPassesFilter] = useState<TPassesFilter>("ongoing");
	const [loading, setLoading] = useState<boolean>(false);
	const [products, setProducts] = useState<AllProductsType[]>([]);
	const filteredPasses = useMemo(
		() =>
			accessPasses
				?.filter((pass) =>
					passesFilter === "archived"
						? !!(pass as Types.IAccessProductArchive).archivedAt
						: !(pass as Types.IAccessProductArchive).archivedAt,
				)
				.sort((a, b) => new Date(b.purchasedAt!).getTime() - new Date(a.purchasedAt!).getTime()),
		[accessPasses, passesFilter],
	);
	const activePass = useMemo(
		() => (accessPasses && activePassQrCode ? accessPasses.find((pass) => pass.qrCode === activePassQrCode) : null),
		[accessPasses, activePassQrCode],
	);

	const filteredOrders = useMemo(
		() =>
			orders
				.filter((order) => (ordersFilter === "confirmed" ? order.confirmed : !order.confirmed))
				.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
		[orders, ordersFilter],
	);
	const activeOrder = useMemo(
		() => (orders.length && activeOrderId ? orders.find((order) => order.id === activeOrderId) : null),
		[orders, activeOrderId],
	);

	useEffect(() => {
		if (guestEmail) {
			fetchPasses();
		}
	}, [guestEmail]);

	const fetchProducts = async () => {
		try {
			const allProducts = await getProducts();
			setProducts(allProducts);
		} catch (err) {
			console.error(err);
		}
	};

	const fetchPasses = async () => {
		try {
			await fetchProducts();
			const [activePasses, archivedPasses] = await Promise.all([
				getGuestPassesByEmail(guestEmail),
				getGuestPassesByEmail(guestEmail, true),
			]);
			const passes = [
				...activePasses.guestAccessProducts[0].Items,
				...archivedPasses.guestAccessProducts[0].Items,
			];
			setAccessPasses(passes);
			const orderIds = passes.map(({ orderId }) => orderId);
			const uniqueOrderIdsSet = new Set(orderIds);
			const uniqueOrderIds = [...uniqueOrderIdsSet];
			setOrderIds(uniqueOrderIds);
			await fetchAllOrders(uniqueOrderIds);
		} catch (err) {
			console.error(err);
		}
	};

	const fetchAllOrders = async (orderIds: Array<string>) => {
		try {
			setLoading(true);
			const allOrders = await getGuestOrders(orderIds);
			const allOrdersCompacted = allOrders.map((ord) => {
				return {
					...ord,
					orderLines: Helpers.compactFamilyPassesOrderlines(ord.orderLines || []),
				} as IFullOrderModel;
			});

			setOrders(allOrdersCompacted);
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	const handleUpdatedAccessPass = useCallback(
		(updatedPass: Types.IAccessProduct) => {
			setAccessPasses(accessPasses.map((pass) => (pass.id === updatedPass.id ? updatedPass : pass)));
		},
		[accessPasses],
	);

	return (
		<>
			<div className="customers-details max-w-md mx-auto">
				{accessPasses ? (
					<>
						<div className="header">
							<Link to="/customers">
								<ArrowRight />
								<Button variant="link" className="back-btn">
									Back to search
								</Button>
							</Link>
						</div>

						<h1>Guest</h1>

						<div className="panel w-100 max-w-md mx-auto">
							<Form noValidate>
								<Stack gap={3}>
									<Row>
										<Form.Group as={Col}>
											<Form.Label>{format("common:email")}</Form.Label>
											<Form.Control value={guestInfo.email} disabled />
										</Form.Group>
										<Form.Group as={Col}>
											<Form.Label>{format("common:name")}</Form.Label>
											<Form.Control value={guestInfo.name} disabled />
										</Form.Group>
									</Row>
								</Stack>
							</Form>
						</div>

						<div id="user-access-passes">
							<h1 className="mt-5 mb-3">
								Access passes{filteredPasses ? <span> ({filteredPasses.length}) </span> : " "}
								{expandAccessPasses ? (
									<span
										style={{ cursor: "pointer" }}
										onClick={() => {
											setExpandAccessPasses(false);
										}}
									>
										<MinusIcon />
									</span>
								) : (
									<span
										style={{ cursor: "pointer" }}
										onClick={() => {
											setExpandAccessPasses(true);
										}}
									>
										<PlusIcon />
									</span>
								)}
							</h1>
							<div hidden={expandAccessPasses === false}>
								<ButtonGroup className="mb-2">
									{["ongoing", "archived"].map((value) => (
										<ToggleButton
											id={value}
											key={value}
											type="radio"
											value={value}
											checked={value === passesFilter}
											onClick={() => setPassesFilter(value as TPassesFilter)}
											variant="outline-primary"
										>
											{format(`common:${value}`)}
										</ToggleButton>
									))}
								</ButtonGroup>
								<ListGroup variant="flush">
									{filteredPasses ? (
										filteredPasses.map((pass, index) => (
											<AccessPassItem
												key={index}
												pass={pass}
												onClick={() => setActivePassQrCode(pass.qrCode)}
												products={products}
											/>
										))
									) : (
										<Loader loadingText={format("common:loading")} />
									)}
								</ListGroup>
							</div>
						</div>
						<div id="user-orders">
							<h1 className="mt-5 mb-3">
								Orders{!loading ? <span> ({filteredOrders.length}) </span> : " "}
								{expandOrders ? (
									<span
										style={{ cursor: "pointer" }}
										onClick={() => {
											setExpandOrders(false);
										}}
									>
										<MinusIcon />
									</span>
								) : (
									<span
										style={{ cursor: "pointer" }}
										onClick={() => {
											setExpandOrders(true);
										}}
									>
										<PlusIcon />
									</span>
								)}
							</h1>

							<div hidden={expandOrders === false}>
								<ButtonGroup className="mb-2">
									{["confirmed", "unconfirmed"].map((value) => (
										<ToggleButton
											id={value}
											key={value}
											type="radio"
											value={value}
											checked={value === ordersFilter}
											onClick={() => setOrdersFilter(value as TOrdersFilter)}
											variant="outline-primary"
										>
											{format(`common:${value}`)}
										</ToggleButton>
									))}
								</ButtonGroup>

								<ListGroup variant="flush">
									{loading ? (
										<Loader loadingText={format("common:loading")} />
									) : filteredOrders.length ? (
										filteredOrders.map((order, index) => (
											<OrderItem
												order={order}
												key={index}
												onClick={() => setActiveOrderId(order.id)}
											/>
										))
									) : (
										<div>None</div>
									)}
								</ListGroup>
							</div>
						</div>
					</>
				) : (
					<Loader loadingText={format("common:loading")} />
				)}
			</div>

			{activePass && (
				<Modal show onHide={() => setActivePassQrCode(null)} className="modal-background">
					<Modal.Header closeButton>
						<Modal.Title>Access pass</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<AccessPassDetails
							pass={activePass}
							onUpdate={handleUpdatedAccessPass}
							onDelete={(deletedPass) => {
								setActivePassQrCode(null);
								setAccessPasses(accessPasses.filter((pass) => pass.id !== deletedPass.id));
							}}
							products={products}
							refreshPass={fetchPasses}
						/>
					</Modal.Body>
				</Modal>
			)}

			{activeOrder && (
				<Modal show onHide={() => setActiveOrderId(null)} className="modal-background">
					<Modal.Header closeButton>
						<Modal.Title>Order: {activeOrder.id}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<OrderDetails
							order={activeOrder}
							onUpdated={() => fetchAllOrders(orderIds)}
							products={products}
							loading={loading}
						/>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
};

export default GuestAccessPasses;

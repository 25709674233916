import { Types } from "@sno_oslo/shared-utils";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FetchErrorAlert from "../common/FetchErrorAlert";
import Loader from "../common/loader";
import { getPromoCode } from "../../controllers/promo-codes";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";
import PromoCodeForm from "./PromoCodeForm";

const EditPromoCode: React.FC = () => {
	const format = useFormat();
	const [promoCode, setPromoCode] = useState<Types.IPromoCode>();
	const [fetchError, setFetchError] = useState<Error | null>(null);
	const { id } = useParams<{ id: string }>();
	const { addAlert } = useSnackbar();
	const navigate = useNavigate();

	const fetchPromoCode = useCallback(async () => {
		try {
			setFetchError(null);

			const promocodeResult = await getPromoCode(id);
			setPromoCode(promocodeResult);
		} catch (err) {
			if (err.code === 404) {
				addAlert(format("promocodes:edit:notFound", { id }), "danger");
				navigate("../", { replace: true });
			} else {
				setFetchError(err as Error);
			}
			setFetchError(err as Error);
		}
	}, [id, format, addAlert, navigate]);

	const handleSaved = useCallback(
		(savedPromoCode: Types.IPromoCode) => {
			addAlert(format("alerts:saved"), "success");
			console.log("savedPromoCode", savedPromoCode);
			setPromoCode(savedPromoCode);
		},
		[format, addAlert],
	);

	useEffect(() => {
		fetchPromoCode();
	}, [id]);

	return (
		<>
			<h1>{format("promocodes:edit")}</h1>

			{fetchError ? (
				<FetchErrorAlert message={fetchError.message} onRetry={fetchPromoCode} />
			) : (
				<div className="panel">
					{promoCode ? (
						<PromoCodeForm promoCode={promoCode} onSaved={handleSaved} />
					) : (
						<Loader loadingText={format("common:loading")} />
					)}
				</div>
			)}
		</>
	);
};

export default EditPromoCode;

import { Types } from "@sno_oslo/shared-utils";
import React, { useCallback, useState } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";

import {
	addPersonalTrainerAvailability,
	deletePersonalTrainerAvailability,
	updatePersonalTrainerAvailability,
} from "../../controllers/personal-trainers";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";
import { formatDatetimeLocal } from "../../utils/dateUtils";

interface IProps {
	personalTrainerId: string;
	availability?: Types.IPersonalTrainerAvailability;
	range?: { start: Date; end: Date };
	onCancel: () => void;
	onDeleted?: (availabilityId: Types.IPersonalTrainerAvailability["id"]) => void;
	onSaved: (availability: Types.IPersonalTrainerAvailability) => void;
}

interface IFormValues extends Pick<Types.IPersonalTrainerAvailability, "disciplines"> {
	start: string;
	end: string;
}

const TrainerAvailabilityForm: React.FC<IProps> = ({
	personalTrainerId,
	availability,
	range,
	onCancel,
	onDeleted,
	onSaved,
}) => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const [isDeleting, setDeleting] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { isSubmitting, errors },
		setValue,
		control,
	} = useForm<IFormValues>({
		defaultValues: {
			disciplines:
				availability?.disciplines ||
				Types.getAllDisciplines().reduce(
					(c, discipline) => ({
						...c,
						[discipline]: Types.getAllDifficulties(),
					}),
					{},
				),
			start: availability
				? formatDatetimeLocal(new Date(availability.start))
				: range
				? formatDatetimeLocal(range.start)
				: "",
			end: availability
				? formatDatetimeLocal(new Date(availability.end))
				: range
				? formatDatetimeLocal(range.end)
				: "",
		},
		mode: "onBlur",
	});
	const start = useWatch({
		control,
		name: "start",
	});
	const disciplines = useWatch({
		control,
		name: "disciplines",
	});
	const isLoading = isDeleting || isSubmitting;

	const handleDelete = useCallback(async () => {
		setDeleting(true);
		try {
			await deletePersonalTrainerAvailability(availability.personalTrainerId, availability.id);

			addAlert(format("alerts:deleted"), "success");
			onDeleted?.(availability.id);
		} catch (err) {
			addAlert((err as Error).message || format("error:default"), "danger");
			setDeleting(false);
		}
	}, [availability?.id, personalTrainerId, onDeleted, format, addAlert]);

	const onSubmit = useCallback<SubmitHandler<IFormValues>>(
		async (values) => {
			try {
				const availabilityProps = {
					disciplines: values.disciplines,
					start: new Date(values.start).toISOString(),
					end: new Date(values.end).toISOString(),
				};
				let savedAvailability: Types.IPersonalTrainerAvailability;

				if (availability) {
					savedAvailability = await updatePersonalTrainerAvailability(
						personalTrainerId,
						availability.id,
						availabilityProps,
					);
				} else {
					savedAvailability = await addPersonalTrainerAvailability(personalTrainerId, availabilityProps);
				}

				addAlert(format("alerts:saved"), "success");
				onSaved(savedAvailability);
			} catch (err) {
				addAlert((err as Error).message || format("error:default"), "danger");
			}
		},
		[availability?.id, personalTrainerId, onSaved, format, addAlert],
	);

	return (
		<Form noValidate onSubmit={handleSubmit(onSubmit)}>
			<Stack gap={3}>
				<Form.Group controlId="start">
					<Form.Label>{format("common:start")}</Form.Label>
					<Form.Control
						type="datetime-local"
						{...register("start", { required: true })}
						isInvalid={!!errors.start}
					/>
					{errors.start && (
						<Form.Control.Feedback type="invalid">
							{format(`validation:${errors.start.type}`, { field: format("common:start") })}
						</Form.Control.Feedback>
					)}
				</Form.Group>

				<Form.Group controlId="end">
					<Form.Label>{format("common:end")}</Form.Label>
					<Form.Control
						type="datetime-local"
						{...register("end", {
							required: true,
							validate: {
								invalid: (end) => new Date(start) < new Date(end),
							},
						})}
						isInvalid={!!errors.end}
					/>
					{errors.end && (
						<Form.Control.Feedback type="invalid">
							{format(`validation:${errors.end.type}`, { field: format("common:end") })}
						</Form.Control.Feedback>
					)}
				</Form.Group>

				<Form.Group controlId="disciplines">
					{Types.getAllDisciplines().map((discipline) => {
						const levels = disciplines[discipline];

						return (
							<div key={discipline}>
								<Form.Check
									type="checkbox"
									id={discipline}
									label={format(`discipline:${discipline}`)}
									checked={discipline in disciplines}
									onChange={(e) => {
										setValue(
											"disciplines",
											(e.target as HTMLInputElement).checked
												? {
														...disciplines,
														[discipline]: Types.getAllDifficulties(),
												  }
												: (Object.keys(disciplines)
														.filter((d) => d !== discipline)
														.reduce(
															(s, d) => ({ ...s, [d]: disciplines[d] }),
															{},
														) as typeof disciplines),
										);
									}}
									className="mb-2"
								/>

								{levels && (
									<Form.Control
										as="select"
										multiple
										{...register(`disciplines.${discipline}`)}
										className="mb-3"
									>
										{Types.getAllDifficulties().map((level) => (
											<option key={level} value={level}>
												{format(`level:${level}`)}
											</option>
										))}
									</Form.Control>
								)}
							</div>
						);
					})}
				</Form.Group>

				<Stack direction="horizontal" gap={2} className="mt-1">
					{onDeleted && (
						<Button variant="danger" onClick={handleDelete} disabled={isLoading}>
							{format("common:delete")}
						</Button>
					)}
					<div className="flex-1" />
					<Button variant="secondary" onClick={() => onCancel()}>
						{format("common:cancel")}
					</Button>
					<Button type="submit" variant="primary" disabled={isLoading}>
						{format("common:save")}
					</Button>
				</Stack>
			</Stack>
		</Form>
	);
};

export default TrainerAvailabilityForm;

import { Types } from "@sno_oslo/shared-utils";
import React, { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import FetchErrorAlert from "../common/FetchErrorAlert";
import Loader from "../common/loader";
import { getAdminUser } from "../../controllers/admin-users";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";
import AdminUserForm from "./AdminUserForm";

const EditAdminUser: React.FC = () => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const [user, setUser] = useState<Types.IAdminUser>();
	const [fetchError, setFetchError] = useState<Error | null>(null);
	const { email } = useParams<{ email: string }>();
	const navigate = useNavigate();

	const fetchUser = useCallback(async () => {
		try {
			setFetchError(null);

			setUser(await getAdminUser(email!));
		} catch (err) {
			if ((err as { code?: number }).code === 404) {
				addAlert(format("users:edit:notFound", { email }), "danger");
				navigate("/admin-users", { replace: true });
			} else {
				setFetchError(err as Error);
			}
		}
	}, [email, format, addAlert, navigate]);

	const handleSaved = useCallback(
		(savedUser: Types.IAdminUser) => {
			addAlert(format("alerts:saved"), "success");
			setUser(savedUser);
		},
		[format, addAlert],
	);

	useEffect(() => {
		fetchUser();
	}, [email]);

	return (
		<Container>
			{fetchError ? (
				<FetchErrorAlert message={fetchError.message} onRetry={fetchUser} />
			) : user ? (
				<>
					<h2>{format("users:edit:title")}</h2>
					<AdminUserForm user={user} onSaved={handleSaved} />
				</>
			) : (
				<Loader loadingText={format("common:loading")} />
			)}
		</Container>
	);
};

export default EditAdminUser;

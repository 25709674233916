import React from "react";
import { Helpers, Types } from "@sno_oslo/shared-utils";
import { Col, Form, Row } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";
import DatePicker from "react-datepicker";

import useFormat from "../../../hooks/useFormat";

interface IProps {
	filters: Record<string, any>;
	onChange: (key: string, value: any) => void;
}

const GroupCoursesFilter: React.FC<IProps> = ({ filters, onChange }) => {
	const format = useFormat();
	const replaceUnderscore = (el: string) => el.replace(/_/g, " ");

	const priceClasses = Types.getAllPriceClasses();
	const disciplines = Types.getAllDisciplines();
	const levels = Types.getAllDifficulties();
	const formTypes = Types.getAllDetailsType();
	const agesRange = Types.getAllAgesRange();

	const handleChange = (e) => {
		const { name, value } = e.target;
		onChange(name, value);
	};

	return (
		<Form.Group as={Row} className="px-2 mb-5 gx-3 gy-3">
			<Col sm={12} md={6} xl={4} className="d-flex align-items-center">
				<Form.Label htmlFor="filter-name" className="mb-0">
					Name
				</Form.Label>
				<DebounceInput
					id="filter-name"
					className="form-control ms-2"
					name="name"
					placeholder="Type name.."
					value={"name" in filters ? filters.name : ""}
					debounceTimeout={300}
					onChange={handleChange}
				/>
			</Col>

			<Col sm={12} md={6} xl={4} className="d-flex align-items-center">
				<Form.Label htmlFor="filter-type-select" className="mb-0">
					Type
				</Form.Label>
				<Form.Select
					id="filter-type-select"
					className="ms-2"
					onChange={handleChange}
					name="formType"
					value={"formType" in filters ? filters.formType : "Empty"}
				>
					<option value="">Empty</option>
					{formTypes.map((formType) => {
						return (
							<option key={formType} value={formType}>
								{replaceUnderscore(formType)}
							</option>
						);
					})}
				</Form.Select>
			</Col>

			<Col sm={12} md={6} xl={4} className="d-flex align-items-center">
				<Form.Label htmlFor="filter-difficulty-select" className="mb-0">
					Difficulty
				</Form.Label>
				<Form.Select
					id="filter-difficulty-select"
					className="ms-2"
					onChange={handleChange}
					name="levels"
					value={"levels" in filters ? filters.levels : "Empty"}
				>
					<option value="">Empty</option>
					{levels.map((level) => {
						return (
							<option key={level} value={level}>
								{format(`level:${level}`)}
							</option>
						);
					})}
				</Form.Select>
			</Col>

			<Col sm={12} md={6} xl={4} className="d-flex align-items-center">
				<Form.Label htmlFor="filter-disciplines-select" className="mb-0">
					Disciplines
				</Form.Label>
				<Form.Select
					id="filter-disciplines-select"
					className="ms-2"
					onChange={handleChange}
					name="disciplines"
					value={"disciplines" in filters ? filters.disciplines : "Empty"}
				>
					<option value="">Empty</option>
					{disciplines.map((discipline) => {
						return (
							<option key={discipline} value={discipline}>
								{format(`discipline:${discipline}`)}
							</option>
						);
					})}
				</Form.Select>
			</Col>

			<Col sm={12} md={6} xl={4} className="d-flex align-items-center">
				<Form.Label htmlFor="filter-price-class-select" className="mb-0 text-nowrap">
					Price class
				</Form.Label>
				<Form.Select
					id="filter-price-class-select"
					className="ms-2"
					onChange={handleChange}
					name="priceClass"
					value={"priceClass" in filters ? filters.priceClass : "Empty"}
				>
					<option value="">Empty</option>
					{priceClasses.map((priceClass) => {
						return (
							<option key={priceClass} value={priceClass}>
								{Helpers.capitalize(priceClass)}
							</option>
						);
					})}
				</Form.Select>
			</Col>

			<Col sm={12} md={6} xl={4} className="d-flex align-items-center">
				<Form.Label htmlFor="filter-age-select" className="mb-0 text-nowrap">
					Age
				</Form.Label>
				<Form.Select
					id="filter-age-select"
					className="ms-2"
					onChange={handleChange}
					name="ageRange"
					value={"ageRange" in filters ? filters.ageRange : "Empty"}
				>
					<option value="">Empty</option>
					{agesRange.map((age) => {
						return (
							<option key={age} value={age}>
								{age}
							</option>
						);
					})}
				</Form.Select>
			</Col>

			<Col sm={12} md={6} xl={4} className="d-flex align-items-center">
				<Form.Label htmlFor="filter-age-select" className="mb-0 text-nowrap">
					Date
				</Form.Label>
				<DatePicker
					selected={filters.from}
					onChange={([from, to]) => {
						if (from !== filters.from) {
							onChange("from", from);
						}
						if (to !== filters.to) {
							onChange("to", to);
						}
					}}
					startDate={filters.from}
					endDate={filters.to}
					selectsRange
					isClearable
					className="ms-2 form-control"
				/>
			</Col>
		</Form.Group>
	);
};

export default GroupCoursesFilter;

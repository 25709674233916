import { Types } from "@sno_oslo/shared-utils";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";
import TrainerForm from "./TrainerForm";

const AddPersonalTrainer: React.FC = () => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const navigate = useNavigate();

	const handleSaved = useCallback(
		({ id }: Types.IPersonalTrainer) => {
			addAlert(format("alerts:saved"), "success");
			navigate(`../${id}/edit`);
		},
		[format, addAlert, navigate],
	);

	return (
		<>
			<h1>{format("trainers:add")}</h1>
			<div className="panel w-100 max-w-md mx-auto">
				<TrainerForm onSaved={handleSaved} />
			</div>
		</>
	);
};

export default AddPersonalTrainer;

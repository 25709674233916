import React from "react";
import { Alert, Button } from "react-bootstrap";

import useFormat from "../../hooks/useFormat";

interface IProps {
	message: string;
	onRetry: () => void;
}

const FetchErrorAlert: React.FC<IProps> = ({ message, onRetry }) => {
	const format = useFormat();

	return (
		<Alert variant="danger">
			<Alert.Heading>{format("common:error")}</Alert.Heading>
			<p>{message || format("error:default")}</p>
			<hr />
			<div className="d-flex justify-content-end">
				<Button onClick={() => onRetry()} variant="outline-danger">
					{format("common:tryAgain")}
				</Button>
			</div>
		</Alert>
	);
};

export default FetchErrorAlert;

/**
 * handles all service action to do with personal trainers
 */
import { Types } from "@sno_oslo/shared-utils";

import { performRequest } from "./request";

export const getAccessPass = (qrCode: number): Promise<Types.IAccessProduct | Types.IAccessProductArchive> =>
	performRequest({ path: `/access-passes/${qrCode}`, method: "GET" });

export const updatePass = (qrCode: number, body: Partial<Types.IAccessProduct>): Promise<Types.IAccessProduct> =>
	performRequest({ path: `/access-passes/${qrCode}`, method: "POST", body });

export const freezeAccessPass = (
	id: Types.IAccessProduct["id"],
	body: {
		freeze: boolean;
		unfreezeAt?: string;
		extendLockInEndDate?: boolean;
		hideQRCode?: boolean;
	},
): Promise<Types.IAccessProduct> =>
	performRequest({
		path: `/access-passes/${id}/freeze`,
		method: "POST",
		body,
	});

export const applyPromoCodeToAccessPass = (
	id: Types.IAccessProduct["id"],
	promoCode: string,
): Promise<Types.IAccessProduct> =>
	performRequest({
		path: `/access-passes/${id}/apply-promo`,
		method: "POST",
		body: { promoCode },
	});

export const unapplyPromoCodeToAccessPass = (id: Types.IAccessProduct["id"]): Promise<Types.IAccessProduct> =>
	performRequest({
		path: `/access-passes/${id}/unapply-promo`,
		method: "POST",
	});

export const updatePassExpireTime = (
	qrCode: Types.IAccessProduct["qrCode"],
	expireTime: number,
): Promise<Types.IAccessProduct> =>
	performRequest({
		path: `/access-passes/${qrCode}/expire-time`,
		method: "PATCH",
		body: { expireTime },
	});

export const archiveAccessPass = (id: Types.IAccessProduct["id"]) =>
	performRequest({
		path: `/access-passes/${id}/archive`,
		method: "DELETE",
	});

export const archiveAccessPassByQrCode = (qrCode: Types.IAccessProduct["qrCode"]) =>
	performRequest({
		path: `/access-passes/qr-code/${qrCode}/archive`,
		method: "DELETE",
	});

export const addAccessPass = (body: {
	qrCode?: number;
	userId: string;
	type?: Types.ProductType | string;
	zones?: Types.ZoneType[];
	daysType?: Types.DaysType;
	priceClass: Types.PriceClass;
	price?: number;
	metraSerialNumber?: string;
	expireTime?: number;
}): Promise<Types.IAccessProduct> =>
	performRequest({
		path: "/access-passes/add-pass",
		method: "POST",
		body,
	});

import { IFullOrderModel } from "@sno_oslo/node-utils/dist/cartOrder/dynamodb-schemas";
import { Types } from "@sno_oslo/shared-utils";
import { performRequest } from "./request";
import type Stripe from "stripe";

export const updateOrder = (orderId: string, props: any): Promise<IFullOrderModel> =>
	performRequest({
		path: `/orders/${orderId}/update`,
		method: "PUT",
		body: {
			orderId,
			props,
		},
	});

export const cancelOrderRefundItems = (orderId: string, orderlines: Array<Types.IOrderLine>): Promise<void> =>
	performRequest({
		path: `/orders/${orderId}/cancel-order-refund-items`,
		method: "POST",
		body: {
			orderlines,
		},
	});

export const getVippsOrderDetails = (orderId: string): Promise<Types.IVippsGetPaymentDetailsResponse> =>
	performRequest({
		path: `/orders/${orderId}/vipps-details`,
		method: "GET",
		body: {
			orderId,
		},
	});

export const issueVippsOrderRefund = (
	transaction: Types.IVippsRefundPaymentPayloadTransaction,
	orderId: string,
): Promise<Types.IVippsRefundPaymentResponse> =>
	performRequest({
		path: `/orders/${orderId}/vipps-refund`,
		method: "POST",
		body: {
			amount: transaction.amount,
			orderId,
			transactionText: transaction.transactionText,
		},
	});

export const issueStripeOrderRefund = (
	orderId: string,
	stripePaymentIntentId: string,
	amount?: number,
): Promise<Stripe.Refund> =>
	performRequest({
		path: `/orders/${orderId}/stripe-refund`,
		method: "POST",
		body: {
			stripePaymentIntentId,
			amount,
		},
	});

export const sendRefundToAccounting = (
	orderId: string,
	{ refundItems }: Types.IVippsRefundXledgerItemPayload,
): Promise<void> =>
	performRequest({
		path: `/orders/${orderId}/vipps-refund-xledger`,
		method: "POST",
		body: { refundItems },
	});

export const downloadReceipt = (orderId: string): Promise<{ data: string }> =>
	performRequest({
		path: `/orders/${orderId}/receipt`,
		method: "GET",
	});

import { Types } from "@sno_oslo/shared-utils";
import React, { useMemo, useState } from "react";
import useFormat from "../../hooks/useFormat";
import { Modal, ModalProps } from "react-bootstrap";
import { getPersonalTrainer } from "../../controllers/personal-trainers";
import moment from "moment";

interface IProps extends ModalProps {
	availability: Types.IPersonalTrainerAvailability;
	showTrainerDetails?: boolean;
}

const TrainerAvailabilityModal: React.FC<IProps> = ({
	availability,
	onReservationDeleted,
	onReservationUpdated,
	showTrainerDetails,
	...rest
}) => {
	const format = useFormat();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [trainer, setTrainer] = useState<Types.IPersonalTrainer>(null);

	useMemo(async () => {
		setIsLoading(true);
		setTrainer(await getPersonalTrainer(availability.personalTrainerId));
		setIsLoading(false);
	}, [availability.personalTrainerId]);

	return (
		<>
			<Modal show {...rest}>
				{isLoading ? (
					"Loading..."
				) : (
					<>
						<Modal.Header closeButton>
							<Modal.Title className="text-truncate">
								{trainer.name} {trainer.surname}
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div>
								<h3>{format("common:timeslot")}:</h3>
								<div className="pb-4">
									<span>
										{format("common:start")}: {moment(availability.start).format("YYYY-MM-DD LT")}
									</span>
									<br />
									<span>
										{format("common:end")}: {moment(availability.end).format("YYYY-MM-DD LT")}
									</span>
								</div>

								<h3>{format("common:disciplines")}:</h3>
								{Object.keys(availability.disciplines).map((discipline, index) => (
									<>
										<h4>
											{index + 1}. {discipline}
										</h4>
										<ul>
											{availability.disciplines[discipline].map((level) => (
												<li>{level}</li>
											))}
										</ul>
									</>
								))}
							</div>
						</Modal.Body>
					</>
				)}
			</Modal>
		</>
	);
};

export default TrainerAvailabilityModal;

/**
 * The is the main menu
 * It will display buttons for each module of the app
 * The modules shown are based on the users access type
 */
import { Types } from "@sno_oslo/shared-utils";
import React from "react";
import { Button, Container, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";

import useFormat from "../../hooks/useFormat";

interface IProps {
	accessLevel: Types.AdminAccessLevel;
}

const Menu: React.FC<IProps> = ({ accessLevel }) => {
	const format = useFormat();
	const links =
		accessLevel === Types.AdminAccessLevel.DRIFT
			? [
					{ to: "/customers", labelKey: "customers" },
					{ to: "/slopes", labelKey: "slopes" },
			  ]
			: [
					{ to: "/customers", labelKey: "customers" },
					{ to: "/value-codes-templates/all", labelKey: "codes:templates" },
					{ to: "/lessons/group-courses", labelKey: "lessons" },
					{ to: "/personal-trainers", labelKey: "trainers" },
					{ to: "/gift-cards", labelKey: "giftCards" },
					{ to: "/event-tickets", labelKey: "eventTickets" },
					{ to: "/promocodes", labelKey: "promocodes" },
					{ to: "/passes", labelKey: "passes" },
					{ to: "/slopes", labelKey: "slopes" },

					...(accessLevel === Types.AdminAccessLevel.FULL
						? [
								{ to: "/pricing-availability", labelKey: "pricing-availability" },
								{ to: "/admin-users", labelKey: "admins" },
						  ]
						: []),
			  ];

	return (
		<Container>
			<Stack gap={2}>
				{links.map(({ to, labelKey }) => {
					if (labelKey === "passes" && accessLevel !== Types.AdminAccessLevel.FULL) {
						return null;
					}

					return (
						<Link key={to} to={to}>
							<Button as="span" variant="outline-primary" size="lg" className="d-block">
								{format(`menu:${labelKey}`)}
							</Button>
						</Link>
					);
				})}
			</Stack>
		</Container>
	);
};

export default Menu;

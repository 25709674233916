import { Types } from "@sno_oslo/shared-utils";
import React, { useCallback, useState } from "react";
import { Badge, Dropdown, ListGroup, Spinner, Stack } from "react-bootstrap";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import { Link } from "react-router-dom";

import ConfirmModal from "../common/ConfirmModal";
import { deleteAdminUser, resetAdminUserPassword } from "../../controllers/admin-users";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";
import MoreIcon from "../icons/MoreIcon";

interface IProps {
	user: Types.IAdminUser;
	isLoggedUser: boolean;
	onDeleted: (uid: string) => void;
}

const AdminUsersListItem: React.FC<IProps> = ({ user: { uid, email, accessLevel }, isLoggedUser, onDeleted }) => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [isResetingPassword, setIsResetingPassword] = useState(false);
	const isLoading = isDeleting || isResetingPassword;

	const handleDelete = useCallback(async () => {
		try {
			setIsDeleting(true);
			setIsDeleteModalVisible(false);

			await deleteAdminUser(email);

			addAlert(format("alerts:deleted"), "success");
			onDeleted(uid);
		} catch (err) {
			addAlert((err as Error).message || format("error:default"), "danger");
			setIsDeleting(false);
		}
	}, [email, addAlert, format, onDeleted]);

	const handleResetPassword = useCallback(async () => {
		try {
			setIsResetingPassword(true);

			await resetAdminUserPassword(email);
			addAlert(format("users:list:item:resetPassword:success"), "success");
		} catch (err) {
			addAlert((err as Error).message || format("error:default"), "danger");
		} finally {
			setIsResetingPassword(false);
		}
	}, [email, addAlert, format]);

	return (
		<>
			<ListGroup.Item>
				<Stack direction="horizontal" gap={3} className="w-100">
					<p className="text-truncate m-0">
						{email}{" "}
						<Badge
							bg={
								accessLevel === Types.AdminAccessLevel.FULL
									? "primary"
									: accessLevel === Types.AdminAccessLevel.LIMITED
									? "warning"
									: "info"
							}
						>
							{format(`common:access:${accessLevel}`)}
						</Badge>
					</p>

					<Dropdown align="end" className="ms-auto">
						<DropdownToggle className="leading-0" disabled={isLoading} variant="secondary" size="sm">
							{isLoading ? <Spinner size="sm" animation="border" className="opacity-75" /> : <MoreIcon />}
						</DropdownToggle>

						<DropdownMenu className="mt-1">
							<Dropdown.Item as={Link} to={email}>
								{format("users:list:item:edit")}
							</Dropdown.Item>

							<Dropdown.Item as="button" onClick={handleResetPassword}>
								{format("users:list:item:resetPassword")}
							</Dropdown.Item>

							{!isLoggedUser && (
								<Dropdown.Item
									as="button"
									onClick={() => setIsDeleteModalVisible(true)}
									className="dropdown-item--danger"
								>
									{format("users:list:item:delete")}
								</Dropdown.Item>
							)}
						</DropdownMenu>
					</Dropdown>
				</Stack>
			</ListGroup.Item>

			<ConfirmModal
				confirmVariant="danger"
				show={isDeleteModalVisible}
				onHide={() => setIsDeleteModalVisible(false)}
				onConfirm={handleDelete}
			>
				{format("users:list:delete:details")} <mark>{email}</mark>
			</ConfirmModal>
		</>
	);
};

export default AdminUsersListItem;

import { Types } from "@sno_oslo/shared-utils";
import { getVippsAgreementCharges, getVippsAgreementDetails } from "../controllers/memberships";
import { IVippsMembership } from "../types/memberships";

export const getVippsMemberships = async (agreementIds: Array<string>) => {
	const vippsMembershipPromises: Array<Promise<IVippsMembership>> = agreementIds.map(async (agreementId) => {
		const vippsAgreementDetails: Types.IVippsAgreementDetailsResponse = await getVippsAgreementDetails(agreementId);
		const vippsAgreementCharges: Array<Types.IVippsAgreementCharge> = await getVippsAgreementCharges(agreementId);
		return {
			type: "vipps" as const,
			details: vippsAgreementDetails,
			charges: vippsAgreementCharges,
			linkedOrderIds: vippsAgreementCharges.map((vaCharge) => vaCharge.id),
		};
	});
	return Promise.all(vippsMembershipPromises);
};

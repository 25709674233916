import { Types } from "@sno_oslo/shared-utils";
import React, { useCallback, useState } from "react";

import ConfirmModal, { IProps as IConfirmModalProps } from "../common/ConfirmModal";
import { deletePersonalTrainerReservation } from "../../controllers/personal-trainers";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";

interface IProps extends Omit<IConfirmModalProps, "onConfirm" | "isLoading"> {
	personalTrainerId: Types.IPersonalTrainer["id"];
	reservationId: Types.IPersonalTrainerReservation["id"];
	onDeleted: (reservationId: Types.IPersonalTrainerReservation["id"]) => void;
}

const TrainerReservationDeleteModal: React.FC<IProps> = ({ personalTrainerId, reservationId, onDeleted, ...rest }) => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const [isLoading, setLoading] = useState(false);

	const handleConfirm = useCallback(async () => {
		setLoading(true);
		try {
			await deletePersonalTrainerReservation(personalTrainerId, reservationId);

			addAlert(format("trainers:reservations:preview:delete:success"), "success");
			onDeleted(reservationId);
		} catch (err) {
			setLoading(false);
			addAlert((err as Error).message || format("error:default"), "danger");
		}
	}, [personalTrainerId, reservationId, onDeleted, format, addAlert]);

	return (
		<ConfirmModal
			title={format("trainers:reservations:preview:delete")}
			confirmVariant="danger"
			onConfirm={handleConfirm}
			isLoading={isLoading}
			{...rest}
		>
			{format("trainers:reservations:preview:delete:details")}
			<mark>#{reservationId}</mark>
		</ConfirmModal>
	);
};

export default TrainerReservationDeleteModal;

import { FC, useState } from "react";
import { NavLink } from "react-router-dom";
import DeletePassModal from "./DeletePassModal";

const PassesSidebar: FC = () => {
	const [isAllCoursesModalOpen, setAllCoursesModalOpen] = useState(false);

	return (
		<>
			<nav className="sidebar">
				<h2>Access Passes</h2>
				<ul>
					<li>
						<a href="#">Home</a>
					</li>
					<li>
						<NavLink to="add-bulk" className={({ isActive }) => (isActive ? "selected" : undefined)}>
							Add bulk
						</NavLink>
					</li>
					<li>
						<a
							href="#"
							onClick={(e) => {
								e.preventDefault();
								setAllCoursesModalOpen(true);
							}}
						>
							Delete pass
						</a>
					</li>
				</ul>
			</nav>

			<DeletePassModal show={isAllCoursesModalOpen} onHide={() => setAllCoursesModalOpen(false)} />
		</>
	);
};

export default PassesSidebar;

import { Types } from "@sno_oslo/shared-utils";
import React, { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";
import ValueCodeForm from "./ValueCodeForm";

interface LocationState {
  template: Types.ITemplate
} 

const AddValueCode = () => {
	const { template } = useLocation().state as LocationState
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const navigate = useNavigate();

	const handleSaved = useCallback(
		() => {
			addAlert(format("alerts:saved"), "success");
			navigate(`..`, { replace: true, state: { template } });
		},
		[format, addAlert, navigate],
	);

	return (
		<>
			<h1>{format("promocodes:add")}</h1>
			<div className="panel max-w-md mx-auto">
				<ValueCodeForm onSaved={handleSaved} />
			</div>
		</>
	);
};

export default AddValueCode

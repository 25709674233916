import React, { PropsWithChildren, useCallback, useMemo, useState } from "react";

import SnackbarContext, { ISnackbarContextValue } from "../contexts/SnackbarContext";
import { IAlert } from "../types/alert";

const SnackbarProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [alerts, setAlerts] = useState<Array<IAlert>>([]);

	const addAlert = useCallback<ISnackbarContextValue["addAlert"]>(
		(msg, variant) => {
			setAlerts([
				...alerts,
				{
					id: Math.random().toString(),
					msg,
					variant,
				},
			]);
		},
		[alerts],
	);

	const removeAlert = useCallback<ISnackbarContextValue["removeAlert"]>(
		(id) => {
			setAlerts(alerts.filter((alert) => alert.id !== id));
		},
		[alerts],
	);

	const value = useMemo<ISnackbarContextValue>(
		() => ({
			alerts,
			addAlert,
			removeAlert,
		}),
		[alerts, addAlert, removeAlert],
	);

	return <SnackbarContext.Provider value={value}>{children}</SnackbarContext.Provider>;
};

export default SnackbarProvider;

/**
 * This component presents a login form and asks
 * the login controller to log the user in.
 * Upon a successful login and event is emitted
 * causing the parent to re-render.
 */

import * as React from "react";
import { Alert, Container, Form, Stack } from "react-bootstrap";

import LoaderButton from "../common/loader-button";
import { loginUser } from "../../controllers/auth";
import * as Emitter from "../../controllers/eventEmitter";

interface IState {
	isLoggingIn?: boolean;
	email?: string | null;
	password?: string | null;
	errorMessage?: string | null;
}

interface IProps {
	errorMessage?: string | null;
}

export default class LoginPage extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		// Initial state
		this.state = {
			isLoggingIn: false,
			email: null,
			password: null,
			errorMessage: this.props.errorMessage || null,
		};

		// Bind methods
		this.loginHandler = this.loginHandler.bind(this);
		this.inputChangeHandler = this.inputChangeHandler.bind(this);
	}

	inputChangeHandler(event: any) {
		this.setState({ [event.target.name]: event.target.value, errorMessage: null });
	}

	loginHandler(event?: any) {
		event.preventDefault();

		// Show the loader and reset any error message
		this.setState({ isLoggingIn: true, errorMessage: null });

		// Validate the inputs
		if (this.state.email && this.state.password) {
			// Valid inputs - start the login
			loginUser(this.state.email, this.state.password)
				.then(() => {
					// Login success
					// Remove the loader
					this.setState({
						isLoggingIn: false,
						errorMessage: null,
					});
					// Notify that login is complete
					// This will basically make this component disappear
					Emitter.publish(Emitter.EVENT.USER_LOGGED_IN_STATE_CHANGED);
				})
				.catch((error) => {
					// show error
					this.setState({
						isLoggingIn: false,
						errorMessage: error.message || "Something went wrong logging when you in 🤷🏼‍♂️",
					});
				});
		} else {
			this.setState({
				isLoggingIn: false,
				errorMessage: "Missing email or password",
			});
		}
	}

	render() {
		// If there is an error, then render an alert component
		let errorMessage = null;
		if (this.state.errorMessage && this.state.errorMessage.length > 0) {
			errorMessage = <Alert variant="warning">{this.state.errorMessage}</Alert>;
		}

		return (
			<Container>
				<h1>Admin Login</h1>
				{errorMessage}
				<Form onSubmit={this.loginHandler}>
					<Stack gap={3}>
						<Form.Group controlId="formBasicEmail">
							<Form.Label>Email address</Form.Label>
							<Form.Control
								type="email"
								name="email"
								placeholder="Enter email"
								size="lg"
								onChange={this.inputChangeHandler}
							/>
						</Form.Group>

						<Form.Group controlId="formBasicPassword">
							<Form.Label>Password</Form.Label>
							<Form.Control
								type="password"
								name="password"
								placeholder="Password"
								size="lg"
								onChange={this.inputChangeHandler}
							/>
						</Form.Group>
					</Stack>

					<LoaderButton
						type="submit"
						loadingLabel="Logging in"
						isLoading={this.state.isLoggingIn}
						size="lg"
						className="mt-3"
					>
						Log in
					</LoaderButton>
				</Form>
			</Container>
		);
	}
}

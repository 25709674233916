import { FC, useMemo, useState } from "react";

import AddBraceletPassesForm, { type IAddBraceletPassesFormPayload } from "./AddBraceletPassesForm";
import AddPassesProcessor from "./AddPassesProcessor";

const AddBraceletPassesTab: FC = () => {
	const [activePayload, setActivePayload] = useState<IAddBraceletPassesFormPayload | null>(null);
	const passes = useMemo(
		() =>
			activePayload
				? new Array(activePayload.to - activePayload.from + 1).fill(null).map((_, i) => ({
						qrCode: i + activePayload.from,
						userId: activePayload.userId,
						metraSerialNumber: activePayload.metraSerialNumbers[i],
						priceClass: activePayload.priceClass,
						price: activePayload.price,
				  }))
				: undefined,
		[activePayload],
	);

	return (
		<>
			<AddBraceletPassesForm isLoading={!!passes} onAddPasses={setActivePayload} />
			{passes && <AddPassesProcessor passes={passes} />}
		</>
	);
};

export default AddBraceletPassesTab;

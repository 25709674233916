import { Helpers, Types } from "@sno_oslo/shared-utils";
import { FC } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { useForm, type SubmitHandler } from "react-hook-form";
import { read, utils } from "xlsx";

interface IAddBraceletPassesFormValues {
	qrCodeRange: string;
	userId: string;
	priceClass: Types.PriceClass;
	price?: number;
	metraPrefix: string;
	metraFile: FileList;
}

export interface IAddBraceletPassesFormPayload {
	from: number;
	to: number;
	userId: string;
	priceClass: Types.PriceClass;
	price?: number;
	metraSerialNumbers: string[];
}

interface IAddBraceletPassesFormProps {
	onAddPasses: (payload: IAddBraceletPassesFormPayload) => void;
	isLoading?: boolean;
}

const AddBraceletPassesForm: FC<IAddBraceletPassesFormProps> = ({ onAddPasses, isLoading }) => {
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors, isSubmitting },
	} = useForm<IAddBraceletPassesFormValues>({
		defaultValues: {
			qrCodeRange: "",
			userId: "",
			priceClass: Types.PriceClass.ADULT,
			metraPrefix: "0009000000000000004D00000000",
		},
	});

	const onSubmit: SubmitHandler<IAddBraceletPassesFormValues> = async ({
		qrCodeRange,
		price,
		metraPrefix,
		metraFile: [metraFile],
		...values
	}) => {
		// if (metraFile.type !== "text/csv") {
		// 	setError("metraFile", { message: "Please provide CSV file" });
		// 	return;
		// }

		const fileReader = new FileReader();

		fileReader.onload = (e) => {
			const [from, to] = qrCodeRange.split("-").map((v) => parseInt(v));

			let metraRows: string[] = [];
			if (typeof e.target.result === "string") {
				metraRows = (e.target.result as string)
					.split(/\n|\r/)
					.slice(1)
					.filter((row) => !!row.trim());
			} else {
				const workbook = read(e.target.result, { type: "array" });
				const worksheet = workbook.Sheets[workbook.SheetNames[0]];
				const rows = utils.sheet_to_json(worksheet, { header: 1, defval: "" });
				metraRows = rows.slice(1).map((row) => row[0]);
			}

			if (metraRows.length !== to - from + 1) {
				setError("metraFile", { message: "Number of rows has to be the same as number of passes" });
				return;
			}

			const metraSerialNumbers = metraRows.map((row) => row.split(",").shift().trim());
			const invalidNumber = metraSerialNumbers.find((num) => num.length !== 8 && num.length !== 14);
			if (invalidNumber) {
				setError("metraFile", { message: `Found row with incorrect format: ${invalidNumber}` });
				return;
			}

			onAddPasses({
				from,
				to,
				metraSerialNumbers: metraSerialNumbers.map((num) => metraPrefix.concat(num)),
				...(typeof price === "number" && !isNaN(price) ? { price: price * 100 } : {}),
				...values,
			});
		};

		if (metraFile.type === "text/csv") {
			fileReader.readAsText(metraFile);
		} else {
			fileReader.readAsArrayBuffer(metraFile);
		}
	};

	return (
		<Form noValidate onSubmit={handleSubmit(onSubmit)}>
			<Stack gap={4}>
				<Form.Group controlId="qrCodeRange">
					<Form.Label>QR Range</Form.Label>
					<Form.Control
						isInvalid={!!errors.qrCodeRange}
						size="lg"
						pattern="\d+-\d+"
						placeholder="e.g. 1000-2000"
						{...register("qrCodeRange", {
							required: true,
							pattern: /\d+-\d+/,
							validate: (value) => {
								const [from, to] = value.split("-").map((v) => parseInt(v));
								return from < to;
							},
						})}
					/>
					{errors.qrCodeRange && (
						<Form.Control.Feedback type="invalid">
							{errors.qrCodeRange.type === "required"
								? "QR Code range is required"
								: "Invalid pattern, e.g. 1000-2000"}
						</Form.Control.Feedback>
					)}
				</Form.Group>

				<Form.Group controlId="userId">
					<Form.Label>User ID</Form.Label>
					<Form.Control
						isInvalid={!!errors.userId}
						size="lg"
						placeholder="e.g. snooslo"
						{...register("userId", {
							required: true,
							maxLength: 50,
						})}
					/>
					{errors.userId && (
						<Form.Control.Feedback type="invalid">
							{errors.userId.type === "required" ? "User ID is required" : "User ID is invalid"}
						</Form.Control.Feedback>
					)}
				</Form.Group>

				<Form.Group controlId="priceClass">
					<Form.Label>Price class</Form.Label>
					<Form.Select {...register("priceClass", { required: true })}>
						{Types.getAllPriceClasses().map((priceClass) => (
							<option key={priceClass} value={priceClass}>
								{Helpers.capitalize(priceClass)}
							</option>
						))}
					</Form.Select>
				</Form.Group>

				<Form.Group controlId="price">
					<Form.Label>Price (NOK)</Form.Label>
					<Form.Control
						size="lg"
						type="number"
						{...register("price", {
							valueAsNumber: true,
						})}
					/>
				</Form.Group>

				<Form.Group controlId="metraPrefix">
					<Form.Label>Metra prefix</Form.Label>
					<Form.Control
						size="lg"
						{...register("metraPrefix", {
							required: true,
						})}
					/>
				</Form.Group>

				<Form.Group controlId="metraFile">
					<Form.Label>Metra File (CSV or XLSX)</Form.Label>
					<Form.Control
						isInvalid={!!errors.metraFile}
						size="lg"
						type="file"
						accept=".xlsx, .xls, .csv"
						{...register("metraFile", {
							required: true,
						})}
					/>
					{errors.metraFile && (
						<Form.Control.Feedback type="invalid">
							{errors.metraFile.type === "required" ? "File is required" : errors.metraFile.message}
						</Form.Control.Feedback>
					)}
				</Form.Group>

				<Button type="submit" size="lg" disabled={isSubmitting || isLoading} className="align-self-start">
					Add
				</Button>
			</Stack>
		</Form>
	);
};

export default AddBraceletPassesForm;

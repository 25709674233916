import { Types } from "@sno_oslo/shared-utils";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { Button, Card, Col, Image, Row, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";

import ConfirmModal from "../common/ConfirmModal";
import { deletePersonalTrainer } from "../../controllers/personal-trainers";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";

interface IProps {
	trainer: Types.IPersonalTrainer;
	descriptionMaxLength?: number;
	onDeleted: (id: Types.IPersonalTrainer["id"]) => void;
}

const TrainerCard: React.FC<IProps> = ({
	trainer: { id, name, surname, description, photoUrl, age, education, disciplines, levels, ageGroup },
	descriptionMaxLength = 180,
	onDeleted,
}) => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	const dataList = useMemo(
		() => [
			{
				label: format(`trainers:details:age`),
				value: age,
			},
			{
				label: format(`trainers:details:education`),
				value: education,
			},
			{
				label: format(`trainers:details:disciplines`),
				value: disciplines.map((discipline) => format(`discipline:${discipline}`)).join(", "),
			},
			{
				label: format(`trainers:details:levels`),
				value: levels.map((level) => format(`level:${level}`)).join(", "),
			},
			{ label: "Age group", value: ageGroup ?? "-" },
		],
		[age, education, disciplines, levels],
	);

	const handleDelete = useCallback(async () => {
		try {
			setIsDeleting(true);
			setIsDeleteModalVisible(false);

			await deletePersonalTrainer(id);

			onDeleted(id);
			addAlert(format("alerts:deleted"), "success");
		} catch (err) {
			addAlert((err as Error).message || format("error:default"), "danger");
			setIsDeleting(false);
		}
	}, [id, addAlert, onDeleted]);

	return (
		<>
			<Card key={id} className="card--plain">
				<Card.Body>
					<Stack gap={3} direction="horizontal">
						{photoUrl ? (
							<Image src={photoUrl} className="avatar" roundedCircle />
						) : (
							<div className="avatar avatar--empty" />
						)}
						<div>
							<Link to={`${id}/edit`}>
								<Card.Title className="mb-1 text-dark">
									{name} {surname}
								</Card.Title>
							</Link>
							<Card.Text>
								{description.slice(0, descriptionMaxLength)}
								{description.length > descriptionMaxLength && "..."}
							</Card.Text>
						</div>
					</Stack>
					<hr />

					<Row as="dl" className="mb-0">
						{dataList.map(({ label, value }, i, arr) => (
							<Fragment key={label}>
								<Col as="dt" sm={3}>
									{label}
								</Col>
								<Col as="dd" sm={9} className={i === arr.length - 1 ? "mb-0" : undefined}>
									{value}
								</Col>
							</Fragment>
						))}
					</Row>
				</Card.Body>

				<Card.Footer className="d-flex justify-content-between">
					<Link to={`${id}/edit`}>
						<Button as="span" variant="info">
							{format("trainers:edit")}
						</Button>
					</Link>

					<Button
						variant="outline-danger"
						onClick={() => setIsDeleteModalVisible(true)}
						disabled={isDeleting}
					>
						{format(isDeleting ? "common:loading" : "trainers:delete")}
					</Button>
				</Card.Footer>
			</Card>

			<ConfirmModal
				show={isDeleteModalVisible}
				onHide={() => setIsDeleteModalVisible(false)}
				onConfirm={handleDelete}
				confirmVariant="danger"
			>
				{format("trainers:delete:details")}
				<mark>
					{name} {surname}
				</mark>
			</ConfirmModal>
		</>
	);
};

export default TrainerCard;

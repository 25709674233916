import { Types } from "@sno_oslo/shared-utils";
import qs from "query-string";

import { performRequest } from "../request";
import { IReservationDto } from "./reservations";

export interface IGroupCourseDto {
	id?: string;
	name: string; // Group course name (NO)
	nameEn: string; // Group course name (EN)
	description: string; // Group course description (EN)
	descriptionNo: string; // Group course description (NO)
	price: number; // The price of the group course in øre
	priceClass: string; // adult | youth | child
	archivedAt?: string; // Date of archiving
	ageRange?: Array<number>; // Range of age of participants
	disciplines: Array<string>; // downhill | cross-country | snowboard | ice-climbing
	levels: Array<string>; // novice | beginner | intermediate | expert
	trainer?: string; // Group course instructor
	minParticipants?: number; // Minimum number of participants
	maxParticipants?: number; // Maximum number of participants
	startDates: Array<string>; // Group course lessons start dates
	endDates: Array<string>; // Group course lessons end dates
	availableSpots?: number; // Current number of available reservations
	reservations?: Array<IReservationDto>; // Reservations to that lesson
	formType: Types.DetailsType;
	earliestStartDate?: number;
}

interface IQuery {
	filters?: Record<string, any>;
	lastKey?: Types.ILastKey | undefined;
	archived?: boolean;
}

interface IGroupCoursesQueryDto {
	lastKey?: Types.ILastKey | undefined;
	groupCourses: Array<IGroupCourseDto>;
}

export const getGroupCourses = async (query: IQuery = {}): Promise<IGroupCoursesQueryDto> => {
	const { filters, lastKey, archived } = query;
	let filterQuery = qs.stringify({
		...(lastKey
			? {
					filterLastKeyIdValue: lastKey.id,
					filterLastKeyEarliestStartDateValue: lastKey.earliestStartDate,
					filterLastKeyQueryAttributeValue: lastKey.queryAttribute,
			  }
			: {}),
		...(filters || {}),
		...(archived ? { archived } : {}),
	});

	const courses = await performRequest({
		path: `/group-courses?${filterQuery}`,
		method: "GET",
	});
	return courses;
};

export const getGroupCourse = async (groupCourseId: string, archived: string): Promise<IGroupCourseDto> => {
	return performRequest({
		path: `/group-courses/${groupCourseId}?archived=${archived}`,
		method: "GET",
	});
};

export const getSimilarGroupCourses = async (groupCourseId: string): Promise<Array<IGroupCourseDto>> => {
	return performRequest({
		path: `/group-courses/similar/${groupCourseId}`,
		method: "GET",
	});
};

export const getReservationsExport = async (groupCourseId: string, archived?: boolean): Promise<string> => {
	const reservationsData = await performRequest({
		path: `/group-courses/reservations/${groupCourseId}${archived ? "?archived=true" : ""}`,
		method: "GET",
		headers: {
			Accept: "text/csv",
		},
	});
	return reservationsData;
};

export const createGroupCourse = async (groupCourse: IGroupCourseDto): Promise<boolean | string> => {
	try {
		await performRequest({
			path: "/group-courses",
			method: "POST",
			body: groupCourse,
		});
		return true;
	} catch (error) {
		return error || "Internal error";
	}
};

export const duplicateGroupCourse = async (groupCourseId: string): Promise<Types.IGroupCourse> => {
	try {
		return await performRequest({
			path: "/group-courses/duplicate/" + groupCourseId,
			method: "POST",
		});
	} catch (error) {
		return error || "Internal error";
	}
};

export const updateGroupCourse = async (
	groupCourseId: string,
	groupCourse: IGroupCourseDto,
): Promise<boolean | string> => {
	try {
		await performRequest({
			path: `/group-courses/${groupCourseId}`,
			method: "PUT",
			body: groupCourse,
		});
		return true;
	} catch (error) {
		return error || "Internal error";
	}
};

export const rescheduleReservations = async (groupCourseId: string, reservationCodes: Array<string>): Promise<void> => {
	await performRequest({
		path: `/group-courses/${groupCourseId}`,
		method: "PATCH",
		body: reservationCodes,
	});
};

export const deleteCourse = async (groupCourseId: string, archived: boolean): Promise<boolean> => {
	await performRequest({
		path: `/group-courses/${groupCourseId}?archived=${archived}`,
		method: "DELETE",
	});

	return true;
};

export const getAllCourses = (): Promise<Array<Types.IGroupCourse>> =>
	performRequest({
		path: "/group-courses/calendar",
		method: "GET",
	});

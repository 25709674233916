import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';

const GiftCards: React.FC = () => {
    return (
        <>
            <Sidebar />
            <main className="main--with-sidebar">
                <Outlet />
            </main>
        </>
    );
}

export default GiftCards;
import { FC, useState } from "react";
import { Types } from "@sno_oslo/shared-utils";
import moment from "moment";
import { Button, Col, Form, Row, Stack, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

import useFormat from "../../../../hooks/useFormat";
import { searchReservations } from "../../../../controllers/lessons/reservations";
import useSnackbar from "../../../../hooks/useSnackbar";

const ReservationSearch: FC = () => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const [isSearching, setIsSearching] = useState<boolean>(false);
	const [reservations, setReservations] = useState<Array<Types.IReservation | Types.IReservationArchive>>(null);
	const [filters, setFilters] = useState({
		reservationCode: "",
		participantName: "",
		participantPhone: "",
		participantEmail: "",
	});
	const filtersFilled = Object.values(filters).some((val) => !!val.trim());

	const searchHandler = async () => {
		setIsSearching(true);
		try {
			setReservations(await searchReservations(filters));
		} catch (err) {
			addAlert((err as Error).message || format("error:default"), "danger");
			setReservations(null);
		} finally {
			setIsSearching(false);
		}
	};

	return (
		<div className="customers-details max-w-xl mx-auto">
			<h1>{format("reservations:search:title")}</h1>

			<Stack gap={3}>
				{[["reservationCode", "participantName"], ["participantPhone", "participantEmail"]].map((keys, i) => (
					<Row key={i}>
						{keys.map((key) => (
							<Form.Group key={key} as={Col}>
								<Form.Label>{format(`reservations:search:labels:${key}`)}</Form.Label>
								<Form.Control
									value={filters[key]}
									onChange={(e) => setFilters({ ...filters, [key]: e.target.value })}
								/>
							</Form.Group>
						))}
					</Row>
				))}
			</Stack>

			<Stack direction="horizontal" gap={2} className="mt-3">
				<Button type="submit" disabled={!filtersFilled || isSearching} onClick={searchHandler}>
					{format("common:search")}
				</Button>
			</Stack>

			<div className="mt-4">
				{reservations &&
					(reservations.length > 0 ? (
						<Table bordered striped responsive>
							<thead>
								<tr>
									<th>{format("common:code")}</th>
									<th>{format("common:confirmed")}</th>
									<th>{format("common:ownerId")}</th>
									<th>{format("common:lessonId")}</th>
									<th>{format("common:participant")}</th>
									<th>{format("common:purchasedAt")}</th>
									<th>{format("common:archivedAt")}</th>
								</tr>
							</thead>

							<tbody>
								{reservations.map((reservation) => (
									<tr key={reservation.id}>
										<td>{reservation.reservationCode!}</td>
										<td>{format(`common:${reservation.confirmed ? "yes" : "no"}`)}</td>
										<td>
											<Link to={`/customers/${reservation.ownerId}`}>{reservation.ownerId}</Link>
										</td>
										<td>
											<Link to={`../group-courses/${reservation.lessonId}`}>
												{reservation.lessonId}
											</Link>
										</td>
										<td>
											{format("common:name")}: {reservation.participantName}
											<br />
											{format("common:age")}: {reservation.participantAge ?? "-"}
											<br />
											{format("common:phone")}: {reservation.participantPhone}
										</td>
										<td>{moment(reservation.purchasedAt).format("YYYY-MM-DD LT")}</td>
										<td>
											{(reservation as Types.IReservationArchive).archivedAt
												? moment((reservation as Types.IReservationArchive).archivedAt).format(
														"YYYY-MM-DD LT",
												  )
												: "-"}
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					) : (
						<i>{format("common:emptyState")}</i>
					))}
			</div>
		</div>
	);
};

export default ReservationSearch;

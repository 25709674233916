import { Types } from "@sno_oslo/shared-utils";
import moment from "moment";
import * as React from "react";
import { Alert, Button } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";

import Loader from "../../common/loader";
import {
	createGroupCourse,
	getGroupCourse,
	IGroupCourseDto,
	updateGroupCourse,
} from "../../../controllers/lessons/groupCourses";
import DetailsForm, { getEmptyResult, IDetailsFormFields } from "./detailsForm";
import ScheduleForm, { ILessonTime } from "./scheduleForm";
import useSnackbar from "../../../hooks/useSnackbar";

enum FormSteps {
	DETAILS = 0,
	SCHEDULE = 1,
	CONFIRMATION = 2,
}

export default function AddOrEditGroupCourse() {
	const { id: groupCourseId } = useParams<{ id: string }>();
	const { addAlert } = useSnackbar();
	const [searchParams] = useSearchParams();
	const editMode = !!groupCourseId;

	const [details, setDetails] = React.useState<IDetailsFormFields>(getEmptyResult());
	const [lowerParticipantsLimit, setLowerParticipantsLimit] = React.useState<number>(1);
	const [lessonTimes, setLessonTimes] = React.useState<Array<ILessonTime>>([]);
	const [hasReservations, setHasReservations] = React.useState<boolean>(false);
	const [currentStep, setCurrentStep] = React.useState<FormSteps>(FormSteps.DETAILS);
	const [isLoading, setIsLoading] = React.useState<boolean>(editMode);
	const [saveResult, setSaveResult] = React.useState<string | null>(null);

	React.useEffect(() => {
		const loadCourse = async () => {
			const course: IGroupCourseDto = await getGroupCourse(groupCourseId, searchParams.get("archived"));
			setDetails({
				...course,
				priceClass: Types.getPriceClassFromString(course.priceClass),
				disciplines: course.disciplines.map((d) => Types.getDisciplineFromString(d)),
				levels: course.levels.map((d) => Types.getDifficultyFromString(d)),
				price: course.price / 100,
			});
			setLowerParticipantsLimit(course.maxParticipants - course.availableSpots);
			setLessonTimes(
				course.startDates.map((startDate, i) => ({
					day: moment(startDate).format("Y-MM-DD"),
					startTime: moment(startDate).format("HH:mm"),
					endTime: moment(course.endDates[i]).format("HH:mm"),
				})),
			);
			setHasReservations(!!course.reservations);
			setIsLoading(false);
		};

		if (editMode) {
			loadCourse();
		}
	}, []);

	const saveCourse = async () => {
		setIsLoading(true);
		const startDates = [];
		const endDates = [];
		lessonTimes.sort((a, b) => {
			if (a.day < b.day) {
				return -1;
			}
			return 1;
		});
		lessonTimes.forEach((dt) => {
			const [year, month, day] = dt.day.split("-").map((el, i) => (i === 1 ? +el - 1 : +el)); // [2021,09,13] => 2021,9,13
			let startTime: number | number[];
			if (dt.startTime.includes(":")) {
				startTime = dt.startTime.split(":").map((el) => +el);
			} else {
				startTime = +dt.startTime;
			}

			let endTime: number | number[];
			if (dt.endTime.includes(":")) {
				endTime = dt.endTime.split(":").map((el) => +el);
			} else {
				endTime = +dt.endTime;
			}

			if (typeof startTime === "object") {
				startDates.push(new Date(year, month, day, startTime[0], startTime[1]).toISOString());
			}

			if (typeof endTime === "object") {
				endDates.push(new Date(year, month, day, endTime[0], endTime[1]).toISOString());
			}

			if (typeof startTime === "number") {
				startDates.push(new Date(year, month, day, startTime).toISOString());
			}

			if (typeof endTime === "number") {
				endDates.push(new Date(year, month, day, endTime).toISOString());
			}
		});
		const groupCourse = { ...details, startDates, endDates };
		const result = editMode
			? await updateGroupCourse(groupCourseId, groupCourse)
			: await createGroupCourse(groupCourse);
		setIsLoading(false);
		if (result === true) {
			setSaveResult(`Course ${editMode ? "updated" : "created"} successfully !`);
		} else {
			// setSaveError(`Error: ${result}`);
			addAlert(result as string, "danger");
		}
	};

	const advanceFormStep = () => {
		setCurrentStep(currentStep + 1);
	};

	const descriptionSummary = (
		<ul>
			<li>{details.price} NOK</li>
			<li>
				{details.priceClass}
				{details.ageRange &&
					`, age range: [${
						details.ageRange.length === 1
							? details.ageRange[0]
							: `${details.ageRange[0]}-${details.ageRange[details.ageRange.length - 1]}`
					}	]`}
			</li>
			<li>{details.disciplines.map((d) => `${d}`).join(", ")}</li>
			<li>{details.levels.map((l) => `${l}`).join(", ")}</li>
			{details.trainer && <li>Trainer: {details.trainer}</li>}
			<li>{`From ${details.minParticipants} to ${details.maxParticipants} participants`}</li>
		</ul>
	);

	const scheduleSummary = (
		<>
			{lessonTimes.map((dt) => {
				return (
					<div key={dt.day}>
						{dt.day}; {dt.startTime} - {dt.endTime}
					</div>
				);
			})}
		</>
	);

	return (
		<div className="addGroupCourse">
			<h2>{editMode ? `Edit "${details.name}" course` : "Add a group course"}</h2>
			{isLoading && <Loader loadingText={editMode ? "Loading course" : "Creating new group course..."} />}
			{saveResult && <div className="result">{saveResult}</div>}
			{!isLoading && !saveResult && (
				<>
					<div className="step">
						<h3>1 - Details</h3>
						{currentStep === FormSteps.DETAILS ? (
							<DetailsForm
								details={details}
								lowerParticipantsLimit={lowerParticipantsLimit}
								onFormSuccess={advanceFormStep}
								setDetails={setDetails}
							/>
						) : (
							<div>
								<b>{details.name}</b>{" "}
								<small>
									<i>{details.description}</i>
								</small>
								<div>{descriptionSummary}</div>
								<Button variant="secondary" size="sm" onClick={() => setCurrentStep(FormSteps.DETAILS)}>
									Edit
								</Button>
							</div>
						)}
					</div>
					<div className="step">
						<h3>2 - Schedule</h3>
						{currentStep === FormSteps.SCHEDULE ? (
							<ScheduleForm
								lessonTimes={lessonTimes}
								setLessonTimes={setLessonTimes}
								onFormSuccess={advanceFormStep}
							/>
						) : (
							<div>
								{scheduleSummary}
								{lessonTimes.length > 0 && (
									<Button className="mt-2" onClick={() => setCurrentStep(FormSteps.SCHEDULE)}>
										Edit
									</Button>
								)}
							</div>
						)}
					</div>
					<div className="step">
						<h3>3 - Confirmation</h3>
						{currentStep === FormSteps.CONFIRMATION && !isLoading && (
							<div>
								<p>
									{editMode
										? "The course will be updated in the booking system"
										: "The course will be added to the booking system"}
								</p>
								{editMode && hasReservations && (
									<Alert variant="warning">
										⚠️ You&apos;re about to update a course that has reservations already. Please
										make sure the changes won&apos;t impact the participants, or please do notify
										them about the changes
									</Alert>
								)}
								<Button variant="primary" onClick={() => saveCourse()}>
									{editMode ? "Update the course" : "Create the course"}
								</Button>
							</div>
						)}
					</div>
				</>
			)}
		</div>
	);
}

import { Types } from "@sno_oslo/shared-utils";
import React, { useCallback } from "react";
import { Button, Col, Form, Image, Row, Stack } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";

import {
	createPersonalTrainer,
	updatePersonalTrainer,
	uploadPersonalTrainerPhoto,
} from "../../controllers/personal-trainers";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";
import { EAgeGroup } from "@sno_oslo/shared-utils/dist/enums";

interface IProps {
	trainer?: Types.IPersonalTrainer;
	onSaved: (trainer: Types.IPersonalTrainer) => void;
}

interface IFormValues extends Omit<Types.IPersonalTrainer, "photoUrl"> {
	photoFiles?: FileList;
}

const TrainerForm: React.FC<IProps> = ({ trainer, onSaved }) => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const {
		register,
		handleSubmit,
		formState: { isSubmitting, errors },
		reset,
	} = useForm<IFormValues>({
		defaultValues: {
			...(trainer || {}),
		},
		mode: "onBlur",
	});

	const onSubmit = useCallback<SubmitHandler<IFormValues>>(
		async ({ photoFiles, id: _, ...values }) => {
			try {
				const savedPersonalTrainer = trainer
					? await updatePersonalTrainer(trainer.id, values)
					: await createPersonalTrainer(values);

				if (photoFiles?.[0]) {
					const data = new FormData();
					data.append("photo", photoFiles[0]);
					const { photoUrl } = await uploadPersonalTrainerPhoto(savedPersonalTrainer.id, data);
					savedPersonalTrainer.photoUrl = photoUrl;
				}

				reset();
				onSaved(savedPersonalTrainer);
			} catch (err) {
				addAlert((err as Error).message || format("error:default"), "danger");
			}
		},
		[trainer?.id, reset, onSaved, format],
	);

	return (
		<Form noValidate onSubmit={handleSubmit(onSubmit)}>
			<Stack gap={3}>
				{trainer && (
					<Form.Group>
						<Form.Label>{format("common:id")}</Form.Label>
						<Form.Control value={trainer.id} disabled />
					</Form.Group>
				)}

				<Row>
					<Form.Group as={Col} controlId="name">
						<Form.Label>{format("common:name")}</Form.Label>
						<Form.Control {...register("name", { required: true })} isInvalid={!!errors.name} />
						{errors.name?.type === "required" && (
							<Form.Control.Feedback type="invalid">
								{format("validation:required", { field: format("common:name") })}
							</Form.Control.Feedback>
						)}
					</Form.Group>

					<Form.Group as={Col} controlId="surname">
						<Form.Label>{format("common:surname")}</Form.Label>
						<Form.Control {...register("surname", { required: true })} isInvalid={!!errors.surname} />
						{errors.surname?.type === "required" && (
							<Form.Control.Feedback type="invalid">
								{format("validation:required", { field: format("common:surname") })}
							</Form.Control.Feedback>
						)}
					</Form.Group>
				</Row>

				<Form.Group controlId="age">
					<Form.Label>{format("common:age")}</Form.Label>
					<Form.Control
						type="number"
						{...register("age", { required: true, min: 1, max: 99, valueAsNumber: true })}
						isInvalid={!!errors.age}
					/>
					{errors.age && (
						<Form.Control.Feedback type="invalid">
							{format(errors.age.type === "required" ? "validation:required" : "validation:invalid", {
								field: format("common:age"),
							})}
						</Form.Control.Feedback>
					)}
				</Form.Group>

				<Form.Group controlId="education">
					<Form.Label>{format("common:education")}</Form.Label>
					<Form.Control {...register("education", { required: true })} isInvalid={!!errors.education} />
					{errors.education?.type === "required" && (
						<Form.Control.Feedback type="invalid">
							{format("validation:required", { field: format("common:education") })}
						</Form.Control.Feedback>
					)}
				</Form.Group>

				<Form.Group controlId="levels">
					<Form.Label>{format("common:levels")}</Form.Label>
					<Form.Control as="select" multiple {...register("levels")}>
						{Types.getAllDifficulties().map((level) => (
							<option key={level} value={level}>
								{format(`level:${level}`)}
							</option>
						))}
					</Form.Control>
				</Form.Group>

				<Form.Group controlId="disciplines">
					<Form.Label>{format("common:disciplines")}</Form.Label>
					<Form.Control as="select" multiple {...register("disciplines")}>
						{Types.getAllDisciplines().map((discipline) => (
							<option key={discipline} value={discipline}>
								{format(`discipline:${discipline}`)}
							</option>
						))}
					</Form.Control>
				</Form.Group>

				<Form.Group controlId="ageGroup">
					<Form.Label>Age group</Form.Label>
					<Form.Control as="select" {...register("ageGroup")}>
						{Object.values(EAgeGroup).map((ageGroup) => (
							<option key={ageGroup} value={ageGroup}>
								{ageGroup}
							</option>
						))}
					</Form.Control>
				</Form.Group>

				<Form.Group controlId="description">
					<Form.Label>{format("common:description")}</Form.Label>
					<Form.Control
						as="textarea"
						rows={3}
						{...register("description", { required: true })}
						isInvalid={!!errors.description}
					/>
					{errors.description?.type === "required" && (
						<Form.Control.Feedback type="invalid">
							{format("validation:required", { field: format("common:description") })}
						</Form.Control.Feedback>
					)}
				</Form.Group>

				<Row>
					{trainer?.photoUrl && (
						<Form.Group as={Col} md="2">
							<Form.Label>{format("common:avatar")}</Form.Label>
							<div>
								<Image src={trainer.photoUrl} className="avatar" roundedCircle />
							</div>
						</Form.Group>
					)}
					<Form.Group as={Col} md={trainer?.photoUrl ? "10" : undefined}>
						<Form.Label>{format("common:avatar:select")}</Form.Label>
						<Form.Control type="file" accept="image/png, image/jpeg" {...register("photoFiles")} />
					</Form.Group>
				</Row>
			</Stack>

			<Button type="submit" disabled={isSubmitting} className="mt-3">
				{format(isSubmitting ? "common:loading" : trainer ? "common:save" : "common:submit")}
			</Button>
		</Form>
	);
};

export default TrainerForm;

import * as React from "react";
import { Card, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

import ArrowRight from "../common/arrow";

interface IProps {
	userId: string;
	email: string;
	name: string;
}

const CustomerItem: React.FC<IProps> = ({ userId, email, name }) => (
	<Link to={userId}>
		<ListGroup.Item action className="mt-3 rounded-2">
			<Card className="item-card">
				<Card.Body>
					<Card.Title>{name === email ? "No name" : name}</Card.Title>
					<Card.Subtitle>{email}</Card.Subtitle>
				</Card.Body>
			</Card>
			<ArrowRight />
		</ListGroup.Item>
	</Link>
);

export default CustomerItem;
import { Types } from "@sno_oslo/shared-utils";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Calendar, CalendarProps, Event, momentLocalizer, View, Views } from "react-big-calendar";

import SectionLoader from "../common/SectionLoader";
import { getPersonalTrainersReservations } from "../../controllers/personal-trainers";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";
import TrainerReservationModal from "./TrainerReservationModal";
import { getDisciplineColor } from "../../utils/colorUtils";

moment.locale("en", {
	week: {
		dow: 1,
		doy: 1,
	},
});
const localizer = momentLocalizer(moment);

interface IProps {}

interface IEvent extends Event {
	id: string;
	index: number;
	discipline?: Types.Discipline;
}

interface IRange {
	start: Date;
	end: Date;
}

const TrainersReservationsCalendar: React.FC<IProps> = () => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const views = useMemo<Array<View>>(() => ["month", "week", "day"], []);
	const [range, setRange] = useState<IRange>({
		start: moment().startOf("month").toDate(),
		end: moment().endOf("month").toDate(),
	});
	const [isFetching, setFetching] = useState(false);

	const [reservations, setReservations] = useState<Array<Types.IPersonalTrainerReservation>>([]);
	const reservationEvents = useMemo(
		() =>
			reservations.map(
				({ id, start, end, participants, discipline }, index) =>
					({
						id,
						index,
						title: format("trainers:calendar:reservation:label", {
							participantName: participants[0].name,
							participantPhone: participants[0].phone,
							discipline,
						}),
						discipline,
						start: new Date(start),
						end: new Date(end),
					} as IEvent),
			),
		[reservations],
	);

	const [activeReservationId, setActiveReservationId] = useState<Types.IPersonalTrainerReservation["id"] | null>(
		null,
	);
	const activeReservation = reservations.find((event) => event.id === activeReservationId);
	const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);

	const handleSelectEvent = useCallback((event: IEvent) => {
		setActiveReservationId(event.id);
		setPreviewModalOpen(true);
	}, []);

	const handleRangeChange = useCallback<Required<CalendarProps>["onRangeChange"]>((newRange) => {
		setRange(
			Array.isArray(newRange)
				? {
						start: newRange[0],
						end: moment(newRange[newRange.length - 1])
							.endOf("day")
							.toDate(),
				  }
				: newRange,
		);
	}, []);

	const handleReservationDeleted = useCallback(
		(reservationId: Types.IPersonalTrainerReservation["id"]) => {
			setReservations(reservations.filter((r) => r.id !== reservationId));
			setPreviewModalOpen(false);
		},
		[reservations],
	);

	const handleReservationUpdated = useCallback(
		(reservation: Types.IPersonalTrainerReservation) => {
			setReservations(reservations.map((r) => (r.id === reservation.id ? reservation : r)));
		},
		[reservations],
	);

	useEffect(() => {
		const fetchData = async () => {
			setFetching(true);
			setReservations([]);

			try {
				setReservations(
					await getPersonalTrainersReservations({
						from: range.start.toISOString(),
						to: range.end.toISOString(),
					}),
				);
			} catch (err) {
				addAlert((err as Error).message || format("error:default"), "danger");
			} finally {
				setFetching(false);
			}
		};

		fetchData();
	}, [range]);

	return (
		<SectionLoader className="h-100" isLoading={isFetching}>
			<Calendar
				localizer={localizer}
				views={views}
				defaultView={Views.MONTH}
				onRangeChange={handleRangeChange}
				selectable
				onSelectEvent={handleSelectEvent}
				events={reservationEvents}
				startAccessor="start"
				endAccessor="end"
				step={60}
				timeslots={1}
				eventPropGetter={({ discipline }) => ({
					style: discipline ? { backgroundColor: getDisciplineColor(discipline) } : {},
				})}
			/>

			{activeReservation && (
				<TrainerReservationModal
					show={isPreviewModalOpen}
					onHide={() => setPreviewModalOpen(false)}
					reservation={activeReservation}
					onReservationDeleted={handleReservationDeleted}
					onReservationUpdated={handleReservationUpdated}
					className="modal-background"
					showTrainerDetails
				/>
			)}
		</SectionLoader>
	);
};

export default TrainersReservationsCalendar;

import React, { useEffect, useRef } from "react";
import { Alert } from "react-bootstrap";

import { IAlert } from "../../types/alert";

interface IProps {
	alert: IAlert;
	onRemove: (id: IAlert["id"]) => void;
	duration?: number;
}

const SnackbarAlert: React.FC<IProps> = ({ alert, onRemove, duration = 5000 }) => {
	const mountedAtRef = useRef(Date.now());

	useEffect(() => {
		const timeout = setTimeout(() => {
			onRemove(alert.id);
		}, duration - (Date.now() - mountedAtRef.current));

		return () => {
			clearTimeout(timeout);
		};
	}, [alert.id, duration, onRemove]);

	return (
		<Alert variant={alert.variant || "primary"} onClose={() => onRemove(alert.id)} dismissible>
			{alert.msg}
		</Alert>
	);
};

export default SnackbarAlert;

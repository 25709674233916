/**
 * This is the main App referred to in index.tsx
 */
import { Types } from "@sno_oslo/shared-utils";
import React, { useEffect, useState } from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";

import Snackbar from "./common/Snackbar";
import PersonalTrainers from "./personal-trainers";
import AddPersonalTrainer from "./personal-trainers/AddPersonalTrainer";
import EditPersonalTrainer from "./personal-trainers/EditPersonalTrainer";
import TrainerProfile from "./personal-trainers/TrainerProfile";
import TrainersList from "./personal-trainers/TrainersList";
import AddAdminUser from "./admin-users/AddAdminUser";
import AdminUsersList from "./admin-users/AdminUsersList";
import EditAdminUser from "./admin-users/EditAdminUser";
import ChangePasswordPage from "./auth/ChangePasswordPage";
import LoginPage from "./auth/LoginPage";
import CustomerDetails from "./customers/customerDetails";
import Customers from "./customers/customers";
import Lessons from "./lessons";
import AddOrEditGroupCourse from "./lessons/group_courses/addOrEditGroupCourse";
import GroupCoursePage from "./lessons/group_courses/groupCoursePage";
import GroupCoursesList from "./lessons/group_courses/groupCoursesList";
import ReservationSearch from "./lessons/group_courses/reservation_search/reservationSearch";
import PromoCodes from "./promo-codes";
import AddPromoCode from "./promo-codes/AddPromoCode";
import EditPromoCode from "./promo-codes/EditPromoCode";
import PromoCodesList from "./promo-codes/PromoCodesList";
import GiftCards from "./gift-cards";
import GiftCardsList from "./gift-cards/GiftCardsList";
import AddGiftCard from "./gift-cards/AddGiftCard";
import PreviewGiftCard from "./gift-cards/PreviewGiftCard";
import * as Emitter from "../controllers/eventEmitter";
import { getLoggedUserTokenPayload, removeLoggedUserToken } from "../controllers/storage";
import Header from "./base/Header";
import Menu from "./base/Menu";
import ContentProvider from "../providers/ContentProvider";
import SnackbarProvider from "../providers/SnackbarProvider";
import { SearchValueCodes } from "./value-codes/searchValueCode";
import ValueCodesList from "./value-codes/valueCodesList";
import ValueCodesTemplates from "./value-codes-templates";
import ValueCodeTemplateList from "./value-codes-templates/valueCodeTemplateList";
import EditVCTemplate from "./value-codes-templates/editVCTemplate";
import AddVCTemplate from "./value-codes-templates/addVCTemplate";
import ReportValueCodes from "./value-codes/ReportValueCodes";
import ValueCodes from "./value-codes";
import AddValueCode from "./value-codes/addValueCode";
import AddPassesPage from "./add-passes/AddPassesPage";
import PassesPage from "./passes/PassesPage";
import EventTickets from "./event-tickets/event-tickets";
import SlopeStatus from "./slope-status/SlopeStatus";
import GuestAccessPasses from "./customers/guestAccessPasses";
import PricingAndAvailability from "./pricing-availability/PricingAndAvailability";

const App: React.FC = () => {
	const [tokenPayload, setTokenPayload] = useState(getLoggedUserTokenPayload());
	const [errorMessage, setErrorMessage] = useState<string | null>();

	useEffect(() => {
		Emitter.subscribe(Emitter.EVENT.USER_LOGGED_IN_STATE_CHANGED, () => {
			setTokenPayload(getLoggedUserTokenPayload());
			setErrorMessage(null);
		});

		Emitter.subscribe(Emitter.EVENT.DELETE_USER, (msg) => {
			removeLoggedUserToken();
			setErrorMessage(msg);
			setTokenPayload(null);
		});
	}, []);

	return (
		<ContentProvider>
			<SnackbarProvider>
				<HashRouter>
					<Header userIsLoggedIn={!!tokenPayload} />
					<Routes>
						<Route path="/change-password" element={<ChangePasswordPage />} />
						{tokenPayload ? (
							tokenPayload.accessLevel === Types.AdminAccessLevel.PERSONAL_TRAINER ? (
								<Route
									path="*"
									element={
										<main>
											<TrainerProfile id={tokenPayload.accessTrainerId!} />
										</main>
									}
								/>
							) : (
								<>
									<Route index element={<Menu accessLevel={tokenPayload.accessLevel} />} />
									<Route path="lessons" element={<Lessons />}>
										<Route index element={<Navigate to="group-courses" />} />
										<Route path="group-courses" element={<GroupCoursesList />} />
										<Route path="group-courses/:groupCourseId" element={<GroupCoursePage />} />
										<Route path="list_gc_archive" element={<GroupCoursesList />} />
										<Route path="add_gc" element={<AddOrEditGroupCourse />} />
										<Route path="edit_gc/:id" element={<AddOrEditGroupCourse />} />
										<Route path="search_reservation" element={<ReservationSearch />} />
									</Route>
									<Route path="personal-trainers" element={<PersonalTrainers />}>
										<Route index element={<TrainersList />} />
										<Route path="add" element={<AddPersonalTrainer />} />
										<Route path=":id/edit" element={<EditPersonalTrainer />} />
									</Route>
									<Route path="gift-cards" element={<GiftCards />}>
										<Route index element={<GiftCardsList />} />
										<Route path="archived" element={<GiftCardsList archived />} />
										<Route path="archived/:id" element={<PreviewGiftCard archived />} />
										<Route path="add" element={<AddGiftCard />} />
										<Route path=":code" element={<PreviewGiftCard />} />
									</Route>
									<Route
										path="promocodes"
										element={<PromoCodes accessLevel={tokenPayload.accessLevel} />}
									>
										<Route
											index
											element={<PromoCodesList accessLevel={tokenPayload.accessLevel} />}
										/>
										<Route path="add" element={<AddPromoCode />} />
										<Route path=":id/edit" element={<EditPromoCode />} />
									</Route>
									<Route path="customers" element={<Customers />} />
									<Route path="customers/guest" element={<GuestAccessPasses />} />
									<Route path="customers/:customerId" element={<CustomerDetails />} />
									<Route
										path="value-codes-templates"
										element={<ValueCodesTemplates accessLevel={tokenPayload.accessLevel} />}
									>
										<Route index element={<Navigate to="/all" />} />
										<Route
											path="all"
											element={<ValueCodeTemplateList accessLevel={tokenPayload.accessLevel} />}
										/>
										<Route path="add" element={<AddVCTemplate />} />
										<Route path="search" element={<SearchValueCodes />} />
										<Route path="edit_template/:id/:customer" element={<EditVCTemplate />} />
									</Route>
									<Route path="event-tickets" element={<EventTickets />} />
									<Route path="slopes" element={<SlopeStatus />} />
									<Route path="pricing-availability" element={<PricingAndAvailability />} />
									<Route path="value-codes" element={<ValueCodes />}>
										<Route index element={<ValueCodesList />} />
										<Route path="add" element={<AddValueCode />} />
										<Route path="report" element={<ReportValueCodes />} />
									</Route>
									<Route path="passes" element={<PassesPage />}>
										<Route index element={<Navigate to="add-bulk" />} />
										<Route path="add-bulk" element={<AddPassesPage />} />
									</Route>
									{tokenPayload.accessLevel === Types.AdminAccessLevel.FULL && (
										<>
											<Route
												path="admin-users"
												element={<AdminUsersList loggedUserId={tokenPayload.uid} />}
											/>
											<Route path="admin-users/:email" element={<EditAdminUser />} />
											<Route path="admin-users/create" element={<AddAdminUser />} />
										</>
									)}
									<Route path="*" element={<Navigate to="/" />} />
								</>
							)
						) : (
							<Route path="*" element={<LoginPage errorMessage={errorMessage} />} />
						)}
					</Routes>
				</HashRouter>

				<Snackbar />
			</SnackbarProvider>
		</ContentProvider>
	);
};

export default App;

import { performRequest } from "./request";
import { Types } from "@sno_oslo/shared-utils";
import type { Contentful } from "@sno_oslo/node-utils";

interface ISearchResult {
	id: string;
	used: Date;
	usedByEmail: string;
	usedByName: string;
}

export type AllProductsType = Contentful.Types.IContentfulDayPassInformation | Contentful.Types.IContentfulSubscriptionInformation

export const searchForValueCode = async (valueCodeId: string): Promise<ISearchResult> => {
	const valueCode = await performRequest({
		path: `/value-codes/search/${valueCodeId}`,
		method: "GET",
	});

	return {
		id: valueCode.id,
		used: valueCode.used && new Date(valueCode.used),
		usedByEmail: valueCode.usedByEmail,
		usedByName: valueCode.usedByName,
	};
};

export const getValueCode = async (valueCodeId: string): Promise<Types.IValueCode> => {
	const valueCode = await performRequest({
		path: `/value-codes/code/${valueCodeId}`,
		method: "GET",
	});

	return {
		...valueCode,
		templateId: valueCode.template,
	};
};

export const getValueCodesForTemplate = (
	templateId: string,
	customer: string,
	lastKey?: Types.IValueCode,
): Promise<Types.IValueCode[]> => {
	const queryParams = lastKey ? `?lastKey=${encodeURIComponent(JSON.stringify(lastKey))}` : "";

	return performRequest({
		path: `/value-codes/codes/${templateId}/${customer}${queryParams}`,
		method: "GET",
	});
};

export const getVC_CSV = (template: Types.ITemplate): Promise<any> =>
	performRequest({
		path: `/value-codes/codes/${template.id}/${template.customer}?csv=true`,
		method: "GET",
		blob: true,
	});

export const getHowManyCodesLeftFromTemplate = async (templateId: string, customer: string): Promise<number> => {
	return await performRequest({
		path: `/value-codes/templates/${encodeURIComponent(templateId)}/${encodeURIComponent(customer)}/left`,
		method: "GET",
	});
};

export const getReport = async (): Promise<any> => {
	return await performRequest({
		path: `/value-codes/admin/report`,
		method: "GET",
	});
};

export const createValueCode = async (newValueCode: ValueCodeBody): Promise<Types.IValueCode> => {
	return await performRequest({
		path: `/value-codes/code?partner=${newValueCode.customer}&csv=true`,
		method: "POST",
		body: newValueCode,
		blob: true,
	});
};

export const updateValueCode = async (newValueCode: ValueCodeBody & { id: string }): Promise<Types.IValueCode> => {
	return await performRequest({
		path: `/value-codes/code/${newValueCode.id}`,
		method: "PUT",
		body: newValueCode,
	});
};

export const deleteValueCode = async (valueCodeId: string): Promise<void> => {
	return await performRequest({
		path: `/value-codes/code/${valueCodeId}`,
		method: "DELETE",
	});
};

export type ValueCodeBody = Pick<Types.IValueCode, "customer" | "description" | "forceExpireTime"> & {
	count: number;
	templateId: Types.IValueCode["template"];
};

export const getProducts = (): Promise<
	AllProductsType[]
> =>
	performRequest({
		path: "/products",
		method: "GET",
	});

export const getTemplates = (): Promise<Array<Types.ITemplate>> =>
	performRequest({
		path: "/value-codes/templates/all",
		method: "GET",
	});

export const getTemplateById = (id: string, customer: string): Promise<Types.ITemplate> =>
	performRequest({
		path: `/value-codes/templates/one/${encodeURIComponent(id)}/${encodeURIComponent(customer)}`,
		method: "GET",
	});

export const createTemplate = async (newTemplate: Types.ITemplate): Promise<Types.ITemplate> => {
	return await performRequest({
		path: `/value-codes/admin/template`,
		method: "POST",
		body: {
			...newTemplate,
			productIds: [newTemplate.productIds],
			accessPassDuration: +newTemplate.accessPassDuration,
			maxCodes: +newTemplate.maxCodes,
			price: +newTemplate.price,
			ttlInDays: +newTemplate.ttlInDays,
		},
	});
};

export const updateTemplate = async (template: Types.ITemplate): Promise<Types.ITemplate> => {
	return await performRequest({
		path: `/value-codes/admin/template/${template.id}`,
		method: "PUT",
		body: {
			...template,
			productIds: [template.productIds],
			accessPassDuration: +template.accessPassDuration,
			maxCodes: +template.maxCodes,
			price: +template.price,
			ttlInDays: +template.ttlInDays,
		},
	});
};

export const deleteTemplate = async (id: string, customer: string): Promise<void> => {
	return await performRequest({
		path: `/value-codes/admin/template/${id}/${customer}`,
		method: "DELETE",
	});
};

import "react-day-picker/lib/style.css";

import moment from "moment";
import * as React from "react";
import { Button } from "react-bootstrap";
import DayPicker, { DateUtils } from "react-day-picker";

export interface ILessonTime {
	day: string;
	startTime: string;
	endTime: string;
}

interface IScheduleFormProps {
	lessonTimes: Array<ILessonTime>;
	setLessonTimes: (dates: Array<ILessonTime>) => void;
	onFormSuccess: () => void;
}

const areLessonsSameTime = (lessonTimes: Array<ILessonTime>): boolean =>
	new Set(lessonTimes.map((t) => t.startTime)).size === 1 && new Set(lessonTimes.map((t) => t.endTime)).size === 1;

export default function ScheduleForm(props: IScheduleFormProps) {
	const [pickerDays, setPickerDays] = React.useState<Array<Date>>(props.lessonTimes.map((dt) => new Date(dt.day)));
	const [sameTime, setSameTime] = React.useState<boolean>(
		props.lessonTimes?.length > 0 ? areLessonsSameTime(props.lessonTimes) : true,
	);
	const [sameStartTime, _setSameStartTime] = React.useState<string>(
		props.lessonTimes?.length > 0 && areLessonsSameTime(props.lessonTimes) ? props.lessonTimes[0].startTime : "",
	);
	const [sameEndTime, _setSameEndTime] = React.useState<string>(
		props.lessonTimes?.length > 0 && areLessonsSameTime(props.lessonTimes) ? props.lessonTimes[0].endTime : "",
	);
	const [dateTimeError, setDateTimeError] = React.useState<string | null>(null);

	const handleSubmit = () => {
		if (props.lessonTimes.length === 0) {
			setDateTimeError("No days selected");
			return false;
		}
		if (
			props.lessonTimes.some((dt) => {
				return !isValidDateTime(dt);
			})
		) {
			return false;
		} else {
			props.onFormSuccess();
		}
	};

	const handleDayClick: DayPicker.DayPickerProps["onDayClick"] = (day, modifiers) => {
		const { selected, disabled } = modifiers;
		if (disabled) {
			return null;
		}
		const tempDays = [...pickerDays];
		const tempDateTimes = [...props.lessonTimes];
		const dayKey = moment(day).format("Y-MM-DD");
		if (selected) {
			const selectedIndex = tempDays.findIndex((selectedDay) => DateUtils.isSameDay(selectedDay, day));
			tempDays.splice(selectedIndex, 1);
			const dateTimeIndex = tempDateTimes.findIndex((dayTimeObj) => dayTimeObj.day === dayKey);
			tempDateTimes.splice(dateTimeIndex, 1);
		} else {
			tempDays.push(day);
			tempDateTimes.push({
				day: dayKey,
				startTime: sameTime ? sameStartTime : "",
				endTime: sameTime ? sameEndTime : "",
			});
		}
		setPickerDays(tempDays);
		props.setLessonTimes(tempDateTimes);
	};

	const isValidDateTime = (dateTime: ILessonTime): boolean => {
		if (dateTime.startTime === "" || !moment(`${dateTime.day} ${dateTime.startTime}`).isValid()) {
			setDateTimeError(`Invalid time "${dateTime.startTime}"`);
			return false;
		}
		if (dateTime.endTime === "" || !moment(`${dateTime.day} ${dateTime.endTime}`).isValid()) {
			setDateTimeError(`Invalid date "${dateTime.endTime}"`);
			return false;
		}
		if (moment(`${dateTime.day} ${dateTime.endTime}`).isBefore(`${dateTime.day} ${dateTime.startTime}`)) {
			setDateTimeError(`End time is before start time`);
			return false;
		}
		return true;
	};

	const updateDateTime = (newDateTime: ILessonTime) => {
		const tempDateTimes = [...props.lessonTimes];
		const dateTimeIndex = tempDateTimes.findIndex((dayTimeObj) => dayTimeObj.day === newDateTime.day);
		tempDateTimes.splice(dateTimeIndex, 1, newDateTime);
		props.setLessonTimes(tempDateTimes);
		setDateTimeError(null);
		isValidDateTime(newDateTime);
	};

	const setSameEndTime = (time: string) => {
		_setSameEndTime(time);
		updateAllHours(time, false);
	};

	const setSameStartTime = (time: string) => {
		_setSameStartTime(time);
		updateAllHours(time, true);
	};

	const updateAllHours = (time: string, startTime = true) => {
		if (!moment(`2020-01-01 ${time}`).isValid()) {
			setDateTimeError(`Invalid time ${time}`);
			return false;
		}
		setDateTimeError(null);
		const tempDateTimes = props.lessonTimes.map((dt) => {
			const newDt = { ...dt };
			if (startTime) {
				newDt.startTime = time;
			} else {
				newDt.endTime = time;
			}
			return newDt;
		});
		props.setLessonTimes(tempDateTimes);
	};

	return (
		<div>
			<DayPicker
				selectedDays={pickerDays}
				showWeekNumbers
				showOutsideDays
				onDayClick={handleDayClick}
				disabledDays={{ before: new Date() }}
			/>
			<div className="selectedDays">
				<h4>Selected days:</h4>
				<div className="dayList">
					{pickerDays.map((date) => {
						const day = moment(date).format("Y-MM-DD");
						return (
							<div className="singleDate" key={day}>
								{day};
							</div>
						);
					})}
				</div>
			</div>
			{dateTimeError && <div className="dateTimeError">{dateTimeError}</div>}
			<div>
				<div>
					<input
						id="sameTime"
						type="checkbox"
						checked={sameTime}
						onChange={(e) => setSameTime(e.target.checked)}
					/>
					<label htmlFor="sameTime">One time slot for every date:</label>
				</div>
				<h4>Time:</h4>
				<div>
					{sameTime ? (
						<div className="timeRow">
							<div>Hours for all days:</div>
							<div>
								From:{" "}
								<input
									onChange={(e) => {
										setSameStartTime(e.target.value);
									}}
									value={sameStartTime}
									size={12}
									type="text"
								/>
							</div>
							<div>
								To:{" "}
								<input
									onChange={(e) => {
										setSameEndTime(e.target.value);
									}}
									value={sameEndTime}
									size={12}
									type="text"
								/>
							</div>
						</div>
					) : (
						<>
							{props.lessonTimes.map((dayTime) => (
								<div className="timeRow" key={dayTime.day}>
									<div>{dayTime.day}</div>
									<div>
										From:{" "}
										<input
											onChange={(e) => {
												updateDateTime({
													day: dayTime.day,
													startTime: e.target.value,
													endTime: dayTime.endTime,
												});
											}}
											value={dayTime.startTime}
											size={12}
											type="text"
										/>
									</div>
									<div>
										To:{" "}
										<input
											onChange={(e) => {
												updateDateTime({
													day: dayTime.day,
													startTime: dayTime.startTime,
													endTime: e.target.value,
												});
											}}
											value={dayTime.endTime}
											size={12}
											type="text"
										/>
									</div>
								</div>
							))}
						</>
					)}
				</div>
				<Button variant="primary" onClick={() => handleSubmit()}>
					Next
				</Button>
			</div>
		</div>
	);
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import useFormat from "../../hooks/useFormat";
import SearchGiftCardModal from "./SearchGiftCardModal";

const Sidebar: React.FC = () => {
	const { pathname, search } = useLocation();
	const format = useFormat();
	const [searchType, setSearchType] = useState<"active" | "archived">("active");
	const [isSearchModalOpen, setSearchModalOpen] = useState(false);

	useEffect(() => {
		if (isSearchModalOpen) {
			setSearchModalOpen(false);
		}
	}, [pathname + search]);

	return (
		<>
			<nav className="sidebar">
				<h2>{format("giftCards:title")}</h2>
				<ul>
					<li>
						<a href="#">{format("common:home")}</a>
					</li>
					<li>
						<NavLink to="" end className={({ isActive }) => (isActive ? "selected" : undefined)}>
							{format("giftCards:title")}
						</NavLink>
					</li>
					<li>
						<NavLink to="archived" end className={({ isActive }) => (isActive ? "selected" : undefined)}>
							{format("giftCards:archived:title")}
						</NavLink>
					</li>
					<li>
						<a
							href="#"
							onClick={(e) => {
								e.preventDefault();
								setSearchType("active");
								setSearchModalOpen(true);
							}}
						>
							{format("giftCards:search")}
						</a>
					</li>
					<li>
						<a
							href="#"
							onClick={(e) => {
								e.preventDefault();
								setSearchType("archived");
								setSearchModalOpen(true);
							}}
						>
							{format("giftCards:archived:search")}
						</a>
					</li>

					<li>
						<NavLink to="add" end className={({ isActive }) => (isActive ? "selected" : undefined)}>
							{format("giftCards:add")}
						</NavLink>
					</li>
				</ul>
			</nav>

			<SearchGiftCardModal show={isSearchModalOpen} type={searchType} onHide={() => setSearchModalOpen(false)} />
		</>
	);
};

export default Sidebar;

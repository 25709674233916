import { Types } from "@sno_oslo/shared-utils";
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import Loader from "../common/loader";
import ConfirmModal from "../common/ConfirmModal";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";
import { deleteTemplate, getHowManyCodesLeftFromTemplate } from "../../controllers/value-codes";

interface IProps {
	template: Types.ITemplate;
	onDeleted: (id: Types.ITemplate["id"]) => void;
	showModifyButtons: boolean;
}

const VCTCard: React.FC<IProps> = ({ template, onDeleted, showModifyButtons }) => {
	const [isDeleting, setIsDeleting] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [left, setLeft] = useState<number>(0);
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const navigate = useNavigate();

	const handleDelete = useCallback(async () => {
		try {
			setIsDeleting(true);
			setIsDeleteModalVisible(false);

			await deleteTemplate(template.id, template.customer);

			onDeleted(template.id);
			addAlert(format("alerts:deleted"), "success");
		} catch (err) {
			addAlert((err as Error).message || format("error:default"), "danger");
			setIsDeleting(false);
		}
	}, [addAlert]);

	const fetchHowManyLeft = async () => {
		setIsLoading(true);
		const _left = await getHowManyCodesLeftFromTemplate(template.id, template.customer);
		setLeft(_left);
		setIsLoading(false);
	};

	useEffect(() => {
		fetchHowManyLeft();
	}, []);

	return (
		<>
			<div className="d-flex flex-column justify-content-between vc-card">
				<div
					className="border-bottom text-center cursor-pointer"
					style={{ minHeight: "25px" }}
					onClick={() => navigate("../../value-codes", { state: { template, left } })}
					role="button"
				>
					<span>{template.name}</span>
				</div>
				<div
					className="d-flex text-center flex-column justify-content-between h-100"
					onClick={() => navigate("../../value-codes", { state: { template, left } })}
					role="button"
				>
					<span className="py-1 my-2">
						<i>{template.description}</i>
					</span>
					<span>
						<b>{template.customer}</b>
					</span>
				</div>
				<div className="d-flex pt-4 px-3" style={{ justifyContent: !showModifyButtons ? "center" : "space-between" }}>
					{showModifyButtons && (
						<Link
							to={`../edit_template/${encodeURIComponent(template.id)}/${encodeURIComponent(
								template.customer,
							)}`}
						>
							<Button variant="outline-primary" style={{ width: 90 }}>
								Edit
							</Button>
						</Link>
					)}
					{isLoading ? (
						<Loader className="small-loader" />
					) : (
						<span className="vc-left">
							<i>
								Left: <b>{left}</b>
							</i>
						</span>
					)}
					{showModifyButtons && (
						<Button
							variant="outline-danger"
							onClick={() => setIsDeleteModalVisible(true)}
							disabled={isDeleting}
							style={{ width: 90 }}
							>
							Delete
						</Button>
					)}
				</div>
			</div>
			<ConfirmModal
				show={isDeleteModalVisible}
				onHide={() => setIsDeleteModalVisible(false)}
				onConfirm={handleDelete}
				confirmVariant="danger"
			>
				{format("vct:remove")}
				<mark>{template.name}</mark>
			</ConfirmModal>
		</>
	);
};

export default VCTCard;

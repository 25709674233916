import { Types } from "@sno_oslo/shared-utils";
import * as React from "react";

interface IProps {
	editMode: boolean;
	guardianType: Types.GuardianType;
	reservation: Types.IReservation;
	updateReservation: (reservation: Types.IReservation) => void;
}

const GuardianDetails: React.FunctionComponent<IProps> = (props: IProps) => {
	const { guardianType } = props;
	const { details } = props.reservation;
	const nameProperty = `${guardianType}GuardianName`;
	const phoneNumberProperty = `${guardianType}GuardianPhoneNumber`;
	const emailProperty = `${guardianType}GuardianEmail`;

	const updateReservation = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.type === "number" ? parseInt(e.target.value, 10) : e.target.value;
		const reservationDetails = { ...details, [`${guardianType}Guardian${e.target.name}`]: value };
		props.updateReservation({ ...props.reservation, details: reservationDetails });
	};

	return (
		<div className="reservation-details">
			<div className="reservation-details--guardian">
				<label>
					Name
					{props.editMode ? (
						<input
							type="text"
							name="Name"
							value={props.reservation?.[nameProperty] || ""}
							onChange={updateReservation}
							placeholder="Kari Nordmann"
						/>
					) : (
						<p>{props.reservation?.[nameProperty]}</p>
					)}
				</label>
				<label>
					Phone
					{props.editMode ? (
						<input
							type="number"
							name="PhoneNumber"
							value={props.reservation?.[phoneNumberProperty] || ""}
							onChange={updateReservation}
							placeholder="55555555"
						/>
					) : (
						<p>{props.reservation?.[phoneNumberProperty]}</p>
					)}
				</label>
				{guardianType !== Types.GuardianType.PICK_UP && (
					<label>
						Email
						{props.editMode ? (
							<input
								type="email"
								name="Email"
								value={props.reservation?.[emailProperty] || ""}
								onChange={updateReservation}
								placeholder="kari.nordmann@snooslo.no"
							/>
						) : (
							<p>{props.reservation?.[emailProperty]}</p>
						)}
					</label>
				)}
			</div>
		</div>
	);
};

export default GuardianDetails;

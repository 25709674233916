import { FC, useMemo, useState } from "react";
import { Alert, Button, ListGroup, Modal } from "react-bootstrap";
import { Helpers, Types } from "@sno_oslo/shared-utils";
import type { IFullOrderModel } from "@sno_oslo/node-utils/dist/cartOrder/dynamodb-schemas";

import DownloadOrderReceiptButton from "./DownloadOrderReceiptButton";
import OrderProductLine from "./OrderProductLine";
import RefundForm, { IRefundFormValues } from "../refunds/RefundForm";
import { getOrderlinePrice, getOrderLineProductAddons, getPaymentVendor } from "../../../utils/orderUtils";
import { PAYMENT_VENDOR_COLORS } from "../../../utils/colorUtils";
import {
	cancelOrderRefundItems,
	issueStripeOrderRefund,
	sendRefundToAccounting,
	updateOrder,
} from "../../../controllers/orders";
import { convertToUnixSeconds } from "../../../utils/dateUtils";
import useFormat from "../../../hooks/useFormat";
import useSnackbar from "../../../hooks/useSnackbar";
import { AllProductsType } from "../../../controllers/value-codes";
import { vippsEPaymentRefund } from "../../../controllers/vipps/vipps.controller";

interface IOrderDetailsProps {
	order: IFullOrderModel;
	onUpdated: (order: IFullOrderModel) => void;
	products: AllProductsType[];
	loading: boolean;
}

const OrderDetails: FC<IOrderDetailsProps> = ({ order, onUpdated, products, loading }) => {
	const format = useFormat();
	const { addAlert } = useSnackbar();

	const isAgreement = order.vippsAgreementId;
	const [showRefundModal, setShowRefundModal] = useState(false);
	const refunds = order.refunds || [];

	const totalRefundedCash = refunds.reduce((total, refund) => total + refund.cash, 0) || 0;

	const paymentVendor = useMemo(() => getPaymentVendor(order.paymentVendor), [order?.paymentVendor]);

	const totalDiscount = order.cartPriceWithoutPromoCode - order.cartPrice;
	const totalCash = order.cashPayment;
	const totalRemainingCash = totalCash - totalRefundedCash;

	const giftCartToCashRate = (order.giftCardsPayment || 0) / (order.cartPrice || 1);

	const issueRefundUpdateOrder = async (refundInfo: IRefundFormValues, line?: Types.IOrderLine) => {
		try {
			let partialRefundRate = refundInfo.amount / totalCash;

			if (line) {
				const linePrice = getOrderlinePrice(line);
				const lineGiftCardAmount = Math.round(giftCartToCashRate * linePrice);
				const lineCash = linePrice - lineGiftCardAmount;

				partialRefundRate = refundInfo.amount / lineCash;
			}

			const transactionText = line
				? `Refunded ${line.productPayload.name}, ${refundInfo.amount / 100} NOK`
				: `Refunded Order ID: ${order.id}, ${refundInfo.amount / 100} NOK`;

			const products: Types.IOrderRefund["products"] = (line ? [line] : order.orderLines)
				.map((line) => {
					const discipline = line.productPayload.discipline as string;
					const discount = (line.productPayload.discount as number) || 0;
					const actualPrice = (line.productPayload.price as number) - discount;
					const giftCardAmount = giftCartToCashRate * actualPrice;
					const cash = actualPrice - giftCardAmount;
					const addons =
						getOrderLineProductAddons(line).map((addon) => ({
							id: addon.id,
							name: (addon.productTitle || addon.productName).replace(/ \(.+\)$/, ""),
							cash: partialRefundRate * (addon.price - giftCartToCashRate * addon.price),
							...(line.productType === Types.CartProductType.EVENT && addon.taxRate
								? { taxRate: addon.taxRate }
								: {}),
						})) || [];

					const refundedCash = refunds.reduce(
						(total, refund) =>
							total +
							refund.products.reduce(
								(refundTotal, product) =>
									refundTotal + (product.tempId === line.productPayload.tempId ? product.cash : 0),
								0,
							),
						0,
					);
					const remainingCash = cash - refundedCash;
					if (Math.abs(remainingCash) < 1) {
						return null;
					}

					let cashToRefund = Math.round(partialRefundRate * cash);
					if (remainingCash < cashToRefund) {
						cashToRefund = remainingCash;
					}

					// * Fix accounting name to match the one added to xledger during purchase
					let name = ((line.productPayload.name || line.productPayload.productName) as string).trim();
					if (name.startsWith("Family Pass") || name.startsWith("Familieprodukt")) {
						name = "Family Daypass";
					} else if (
						name.startsWith("Membership") ||
						name.startsWith("Medlemskap") ||
						name.startsWith("Familiemedlemskap")
					) {
						name = "12 Months Family Membership Pass";
					} else if (line.productType === Types.CartProductType.COURSE) {
						name = "course_reservation";
					} else if (line.productType === Types.CartProductType.PERSONAL_TRAININGS_PACKAGE) {
						name = "personal_trainings_package";
					} else if (line.productType === Types.CartProductType.EVENT) {
						name = "Event ticket";
					} else if (line.productType === Types.CartProductType.HOUR_PASS) {
						name = `Hourly pass ${line.productPayload.priceClass} ${line.productPayload.zone}`;
					} else if (line.productType === Types.CartProductType.SEASON_TICKET) {
						name = `Season pass ${line.productPayload.priceClass} ${line.productPayload.zone}`;
					}

					return {
						tempId: line.productPayload.tempId as string,
						tempFamilyPackId: line.productPayload.tempFamilyPackId as string,
						productId: line.productId,
						name,
						cash: cashToRefund,
						discount: Math.round(partialRefundRate * discount),
						discipline,
						addons,
						...(line.productType === Types.CartProductType.EVENT && line.productPayload.taxRate
							? { taxRate: line.productPayload.taxRate as number }
							: {}),
					};
				})
				.filter((line) => !!line);

			const refund: Types.IOrderRefund = {
				cash: products.reduce(
					(cash, product) =>
						cash +
						product.cash +
						(product.addons?.reduce((addonsCash, addon) => addonsCash + addon.cash, 0) || 0),
					0,
				),
				discount: products.reduce((discount, product) => discount + product.discount, 0),
				partial: refundInfo.amount < totalCash,
				reason: refundInfo.reason,
				authorizedBy: refundInfo.authorizedBy,
				products,
				createdAt: new Date().toISOString(),
			};

			if (
				Math.abs(refund.cash - refundInfo.amount) >
				products.reduce((length, product) => length + 1 + (product.addons?.length || 0), 0)
			) {
				throw new Error("Cash calculation error, please try partial refunds");
			}

			refund.cash = refundInfo.amount;

			let chargeId = order.id;

			if (paymentVendor === "stripe") {
				const { id, charge } = await issueStripeOrderRefund(
					order.id,
					order.stripePaymentIntentId!,
					refundInfo.amount,
				);

				chargeId = charge.toString();

				refund.stripeRefundId = id;
			} else if (paymentVendor === "vipps") {
				const { pspReference } = await vippsEPaymentRefund(order.id, refundInfo.amount);

				refund.vippsTransactionId = pspReference;
			}

			console.log({ refunds: order.refunds ? [...order.refunds, refund] : [refund] });

			await updateOrder(order.id, {
				refunds: order.refunds ? [...order.refunds, refund] : [refund],
			});

			const refundItems: Array<Types.IVippsRefundXledgerItem> = refund.products
				.map((product) => [
					{
						amount: -product.cash,
						chargeId,
						chargeCreated: convertToUnixSeconds(new Date(refund.createdAt).getTime()),
						productId: product.productId,
						number: 1,
						name: product.name,
						partner: "snooslo",
						discount: -product.discount,
						discipline: product.discipline,
						// @ts-ignore
						...(product.taxRate ? { taxRate: product.taxRate } : {}),
					},
					...(product.addons
						? product.addons.map((addon) => ({
								amount: -addon.cash,
								chargeId,
								chargeCreated: convertToUnixSeconds(new Date(refund.createdAt).getTime()),
								productId: addon.id,
								number: 1,
								name: addon.name,
								partner: "snooslo",
								discount: 0,
								// @ts-ignore
								...(addon.taxRate ? { taxRate: addon.taxRate } : {}),
								...(product.name === "Event ticket" ? { name: "Event ticket addon" } : {}),
						  }))
						: []),
				])
				.flat();

			await sendRefundToAccounting(order.id, { refundItems });

			if (refundInfo.cancelProduct) {
				await cancelOrderRefundItems(
					order.id,
					order.orderLines.filter((line) =>
						products.some((product) => product.tempId === line.productPayload.tempId),
					),
				);
			}

			addAlert("Refunded successfuly", "success");
			setShowRefundModal(false);
			onUpdated(order);
		} catch (err) {
			addAlert((err as Error).message || format("error:default"), "danger");
		}
	};

	return (
		<>
			<p className="fw-bold mb-1">Cart:</p>
			<ListGroup as="ul" className="mb-3">
				<ListGroup.Item as="li" className="d-flex">
					<div>
						<p className="mb-0 fw-bold">Price: {order.cartPrice / 100} NOK</p>
						<p className="mb-0">Purchased: {new Date(order.createdAt).toLocaleString("no")}</p>
						<div className="mb-0">
							Payment vendor:{" "}
							<span
								style={{
									color: "white",
									backgroundColor: PAYMENT_VENDOR_COLORS[paymentVendor],
									padding: "0.135rem 0.25rem",
									borderRadius: 7,
								}}
							>
								{paymentVendor}
							</span>
						</div>

						{order.stripePaymentIntentId && (
							<p className="mb-0">
								Payment intent:{" "}
								<a
									target="_blank"
									href={`https://dashboard.stripe.com/payments/${order.stripePaymentIntentId}`}
									rel="noreferrer"
								>
									{order.stripePaymentIntentId}
								</a>
							</p>
						)}

						{order.cashPayment > 0 && order.cashPayment !== order.cartPrice && (
							<p className="mb-0">Cash: {order.cashPayment / 100} NOK</p>
						)}
						{order.giftCardsPayment > 0 && (
							<p className="mb-0">Gift card: {order.giftCardsPayment / 100} NOK</p>
						)}
						{totalDiscount > 0 && <p className="mb-0">Discount: {totalDiscount / 100} NOK</p>}

						{totalRefundedCash > 0 && <p className="mb-0">Total refunded: {totalRefundedCash / 100} NOK</p>}

						<DownloadOrderReceiptButton orderId={order.id} />
					</div>
				</ListGroup.Item>
			</ListGroup>

			<p className="fw-bold mb-1">Items:</p>
			<ListGroup as="ol" numbered className="mb-3">
				{order.orderLines.map((line, i) => {
					return (
						<ListGroup.Item key={i} as="li" className="d-flex">
							<OrderProductLine
								line={line}
								giftCartToCashRate={giftCartToCashRate}
								refunds={refunds}
								onRefund={isAgreement ? undefined : issueRefundUpdateOrder}
								products={products}
								loading={loading}
							/>
						</ListGroup.Item>
					);
				})}
			</ListGroup>

			{refunds.length > 0 && (
				<>
					<p className="fw-bold mb-1">Refunds:</p>
					<ListGroup as="ol" numbered className="mb-3">
						{refunds.map((refund, i) => {
							return (
								<ListGroup.Item key={i} as="li" className="d-flex">
									<div className="ms-2 me-auto">
										<p className="mb-0">Cash amount: {refund.cash / 100} NOK</p>
										<p className="mb-0">
											Discount: {Helpers.formatPrice(refund.discount / 100)} NOK
										</p>
										<p className="mb-0">Reason: {refund.reason || "-"}</p>
										<p className="mb-0">Authorized by: {refund.authorizedBy || "-"}</p>
										<p className="mb-0">
											Created at:{" "}
											{refund.createdAt ? new Date(refund.createdAt).toLocaleString("no") : "-"}
										</p>
									</div>
								</ListGroup.Item>
							);
						})}
					</ListGroup>
				</>
			)}

			{totalRemainingCash > 0 &&
				(isAgreement ? (
					<Alert variant="warning">
						Plase go the the memberships section to refund vipps membership first charge.
					</Alert>
				) : order.orderLines.length > 1 ? (
					<>
						<Button type="submit" size="lg" onClick={() => setShowRefundModal(true)}>
							Refund order
						</Button>

						<Modal
							show={showRefundModal}
							centered
							onHide={() => setShowRefundModal(false)}
							className="modal-background"
						>
							<Modal.Header closeButton>
								<Modal.Title>Refund order: {order.id}</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<RefundForm
									remainingAmount={totalRemainingCash}
									onRefund={(refundInfo) => issueRefundUpdateOrder(refundInfo)}
									canCancel
								/>
							</Modal.Body>
						</Modal>
					</>
				) : null)}
		</>
	);
};

export default OrderDetails;

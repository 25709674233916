import { FC, useCallback, useMemo, useState } from "react";
import { Button, Card, ListGroup, ListGroupItem, Modal } from "react-bootstrap";
import { IFullOrderModel } from "@sno_oslo/node-utils/dist/cartOrder/dynamodb-schemas";
import { IVippsAgreementDetailsResponse } from "@sno_oslo/shared-utils/dist/types";
import { Types } from "@sno_oslo/shared-utils";
import moment from "moment";

import ArrowRight from "../../common/arrow";
import { IVippsMembership } from "../../../types/memberships";
import VippsChargeItem from "./VippsChargeItem";
import { cancelVippsAgreementPasses, updateVippsAgreement } from "../../../controllers/memberships";
import useFormat from "../../../hooks/useFormat";
import useSnackbar from "../../../hooks/useSnackbar";
import { PAYMENT_VENDOR_COLORS } from "../../../utils/colorUtils";
import MembershipActions from "../MembershipActions";

interface IMembershipItemProps {
	order?: IFullOrderModel;
	membership: IVippsMembership;
	passes: (Types.IAccessProduct | Types.IAccessProductArchive)[];
	onUpdatePass: (pass: Types.IAccessProduct) => void;
	onFocusAccessPass: (qrCode: number) => void;
	onCancelled: () => void;
	onRefunded: () => void;
}

interface IStatusSubtitleProps {
	status: string;
}

const StatusSubtitle = ({ status }: IStatusSubtitleProps) => {
	let color;
	switch (status) {
		case "STOPPED":
		case "EXPIRED":
			color = "#FF0000";
			break;
		case "PENDING":
			color = "#FFFF00";
			break;
		case "ACTIVE":
			color = "#008000";
			break;
		default:
			color = "#000";
	}

	return (
		<Card.Subtitle className="mb-2" style={{ color, fontWeight: "bold" }}>
			Status: {status}
		</Card.Subtitle>
	);
};

const getAmountFromVippsAgreement = ({
	campaign,
	pricing,
}: Pick<IVippsAgreementDetailsResponse, "campaign" | "pricing">) => {
	let amount = pricing.type === "LEGACY" ? pricing.amount : pricing.suggestedMaxAmount;
	if (campaign && moment().isBefore(moment(campaign.end))) {
		amount = campaign.price;
	}

	return `${amount / 100} ${pricing.currency}`;
};

const VippsMembershipItem: FC<IMembershipItemProps> = ({
	order,
	membership: { details, charges },
	passes,
	onUpdatePass,
	onFocusAccessPass,
	onCancelled,
	onRefunded,
}) => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const [showModal, setShowModal] = useState(false);
	const [action, setAction] = useState<"cancel-agreement">(null);
	const sortedCharges = useMemo(
		() =>
			charges
				.slice()
				.sort((a, b) => new Date(a.history[0].occurred).getTime() - new Date(b.history[0].occurred).getTime()),
		[charges],
	);
	const activePass = useMemo(
		() =>
			passes.find(
				(pass) =>
					!(pass as Types.IAccessProductArchive).archivedAt &&
					(!pass.expireTime || pass.expireTime * 1000 > Date.now()),
			),
		[passes],
	);

	const cancelProduct = useCallback(async () => {
		setAction("cancel-agreement");

		try {
			await cancelVippsAgreementPasses(details.id);
			await updateVippsAgreement(details.id, { status: "STOPPED" });

			onCancelled();
		} catch (err) {
			addAlert((err as Error).message || format("error:default"), "danger");
			setAction(null);
		}
	}, [details.id, onCancelled]);

	return (
		<>
			<ListGroupItem
				// onClick={onClick}
				onClick={() => setShowModal(true)}
				className="item-wrapper"
			>
				<Card className="item-card" role="button">
					<Card.Body>
						<Card.Title>{details.id}</Card.Title>
						<Card.Subtitle className="mb-2 text-muted">
							Started: {moment(details.start).format("DD MMMM YYYY")}
						</Card.Subtitle>
						<StatusSubtitle status={details.status} />

						<ListGroup as="ol" numbered className="mb-3">
							<ListGroup.Item as="li" className="d-flex">
								<div className="ms-2 me-auto">
									<div className="fw-bold">{details.productName}</div>
									<p>Description: {details.productDescription || "-"}</p>
									<p>Amount: {getAmountFromVippsAgreement(details)}</p>
								</div>
							</ListGroup.Item>
						</ListGroup>

						<Card.Subtitle className="mb-2">
							Total amount: {getAmountFromVippsAgreement(details)}
						</Card.Subtitle>

						<Card.Subtitle className="mb-2 text-muted">
							Payment vendor:{" "}
							<span
								style={{
									color: "white",
									backgroundColor: PAYMENT_VENDOR_COLORS["vipps"],
									padding: "0.25rem",
									borderRadius: 7,
								}}
							>
								Vipps
							</span>
						</Card.Subtitle>
					</Card.Body>
				</Card>
				<ArrowRight />
			</ListGroupItem>

			<Modal show={showModal} onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Agreement: {details.id}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className="fw-bold mb-1">Plans:</p>
					<ListGroup as="ol" numbered className="mb-3">
						<ListGroup.Item as="li" className="d-flex">
							<div className="ms-2 me-auto">
								<div className="fw-bold">{details.productName}</div>
								<p className="mb-0">Description: {details.productDescription || "-"}</p>
								<p className="mb-0">Amount: {getAmountFromVippsAgreement(details)}</p>
							</div>
						</ListGroup.Item>
					</ListGroup>

					<p className="fw-bold mb-1">Invoices:</p>
					<ListGroup as="ol" numbered>
						{sortedCharges.map((charge) => (
							<ListGroup.Item key={charge.id} as="li" className="d-flex">
								<VippsChargeItem
									order={order}
									charge={charge}
									onCancelProduct={details.status !== "STOPPED" ? cancelProduct : undefined}
									onRefunded={onRefunded}
								/>
							</ListGroup.Item>
						))}
					</ListGroup>

					{passes.length > 0 && (
						<>
							<p className="fw-bold mt-3 mb-1">Access passes:</p>
							<ListGroup as="ol" numbered>
								{passes.map((pass) => (
									<ListGroup.Item key={pass.qrCode} as="li" className="d-flex">
										<div className="ms-2 me-auto">
											<div className="fw-bold">{pass.qrCode}</div>
											{pass.expireTime && (
												<p className="mb-0">
													Expires: {new Date(pass.expireTime * 1000).toLocaleString("no")}
												</p>
											)}
											{(pass as Types.IAccessProductArchive).archivedAt && (
												<p className="mb-0">
													Archived at:{" "}
													{new Date(
														(pass as Types.IAccessProductArchive).archivedAt,
													).toLocaleString("no")}
												</p>
											)}
											<Button
												onClick={() => onFocusAccessPass(pass.qrCode)}
												size="sm"
												className="mt-1 mb-1"
											>
												Show more
											</Button>
										</div>
									</ListGroup.Item>
								))}
							</ListGroup>
						</>
					)}

					{activePass && <MembershipActions pass={activePass} onUpdate={onUpdatePass} />}

					<Button
						onClick={() => cancelProduct()}
						disabled={details.status === "STOPPED" || action === "cancel-agreement"}
						variant="danger"
						className="mt-3 w-full"
					>
						{details.status === "STOPPED" || details.status === "EXPIRED"
							? "Agreement already cancelled"
							: "Cancel agreement"}
					</Button>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default VippsMembershipItem;

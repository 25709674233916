import React from "react";
import { Button, ButtonProps, Modal, ModalProps } from "react-bootstrap";

import useFormat from "../../hooks/useFormat";

export interface IProps extends ModalProps {
	title?: React.ReactNode;
	confirmVariant?: ButtonProps["variant"];
	onConfirm: () => void;
	isLoading?: boolean;
}

const ConfirmModal: React.FC<IProps> = ({
	title,
	confirmVariant = "primary",
	onHide,
	onConfirm,
	isLoading,
	children,
	...rest
}) => {
	const format = useFormat();

	return (
		<Modal {...rest} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>{title ?? format("confirm:title")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{children}</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => onHide()}>
					{format("confirm:close")}
				</Button>
				<Button variant={confirmVariant} disabled={isLoading} onClick={() => onConfirm()}>
					{format("confirm:submit")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ConfirmModal;

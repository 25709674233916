import { Types } from "@sno_oslo/shared-utils";
import moment from "moment";
import React, { Fragment, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import useFormat from "../../hooks/useFormat";

interface IProps {
	reservation: Types.IPersonalTrainerReservation;
	showTrainerDetails?: boolean;
}

const TrainerReservationsDataList: React.FC<IProps> = ({
	reservation: { id, start, end, participants, discipline, level, comment, personalTrainer, ownerEmail },
	showTrainerDetails,
}) => {
	const format = useFormat();
	const items: { label: string; value: any; multiline?: boolean }[] = useMemo(
		() => [
			{
				label: format("common:id"),
				value: id,
			},
			{
				label: format("common:start"),
				value: moment(start).format("YYYY-MM-DD LT"),
			},
			{
				label: format("common:end"),
				value: moment(end).format("YYYY-MM-DD LT"),
			},
			...participants
				.map(({ name, age, phone }, i) => [
					{
						label: `${format("common:participant")} ${i}`,
						value: name,
					},
					{
						label: format("common:age"),
						value: age,
					},
					{
						label: format("common:phone"),
						value: phone,
					},
				])
				.flat(),
			{
				label: format("common:discipline"),
				value: format(`discipline:${discipline}`),
			},
			{
				label: format("common:level"),
				value: format(`level:${level}`),
			},
			{
				label: format("common:comment"),
				value: comment,
				multiline: true,
			},
			{
				label: format("common:email"),
				value: ownerEmail,
			},
			...(personalTrainer
				? [
						{
							label: format("common:trainerName"),
							value: `${personalTrainer.name} ${personalTrainer.surname}`,
						},
						{
							label: format("common:trainerId"),
							value: (
								<Link to={`/personal-trainers/${personalTrainer.id}/edit`}>{personalTrainer.id}</Link>
							),
						},
				  ]
				: []),
		],
		[format, id, start, end, participants, discipline, level, comment, showTrainerDetails, personalTrainer],
	);

	return (
		<Row as="dl">
			{items.map(({ label, value, multiline }, i) => (
				<Fragment key={i}>
					<Col as="dt" sm={3}>
						{label}
					</Col>
					<Col as="dd" className={multiline ? "text-pre-wrap" : ""} sm={9}>
						{value ?? "-"}
					</Col>
				</Fragment>
			))}
		</Row>
	);
};

export default TrainerReservationsDataList;

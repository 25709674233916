import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import type stripe from "stripe";
// import { IFullOrderModel } from "@sno_oslo/node-utils/dist/cartOrder/dynamodb-schemas";

import RefundForm, { IRefundFormValues } from "../refunds/RefundForm";
import { getStripePaymentIntentCharges, issueStripeInvoiceRefund } from "../../../controllers/memberships";
import useFormat from "../../../hooks/useFormat";
import useSnackbar from "../../../hooks/useSnackbar";

interface IStripeInvoiceItemProps {
	index: number;
	// order: IFullOrderModel;
	invoice: stripe.Invoice;
	onRefunded: (invoice: stripe.Refund) => void;
	onCancelProduct?: () => Promise<void>;
	onOpenOrderDetails: () => void;
}

const invoiceStatusToColorMap: Record<stripe.Invoice.Status, string> = {
	draft: "#dbe3e8",
	open: "#ffd23f",
	paid: "#008000",
	uncollectible: "#dbe3e8",
	void: "#dbe3e8",
};

const StripeInvoiceItem: FC<IStripeInvoiceItemProps> = ({
	index,
	// order,
	invoice,
	onRefunded,
	onCancelProduct,
	onOpenOrderDetails,
}) => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const [showRefundModal, setShowRefundModal] = useState(false);
	const [charges, setCharges] = useState<stripe.Charge[]>();

	const refundedAmount = charges
		?.map((charge) => charge.refunds.data.map((refund) => (refund.status === "succeeded" ? refund.amount : 0)))
		.flat()
		.reduce((total, refundAmount) => total + refundAmount, 0);
	const remainingAmount = invoice.total - refundedAmount;

	const handleRefund = useCallback(
		async ({ amount, cancelProduct }: IRefundFormValues) => {
			try {
				const refund = await issueStripeInvoiceRefund(
					(invoice.payment_intent as stripe.PaymentIntent).id,
					amount,
				);

				addAlert("Refunded successfully", "success");

				if (cancelProduct) {
					onCancelProduct?.();
				}

				onRefunded(refund);
			} catch (err) {
				addAlert((err as Error).message || format("error:default"), "danger");
			} finally {
				setShowRefundModal(false);
			}
		},
		[invoice, onRefunded, addAlert, format],
	);

	useEffect(() => {
		const intentId =
			typeof invoice.payment_intent === "string" ? invoice.payment_intent : invoice.payment_intent?.id;
		if (intentId) {
			setCharges(undefined);

			const fetchCharges = async () => {
				try {
					setCharges(await getStripePaymentIntentCharges(intentId));
				} catch (err) {
					console.error(err);
				}
			};
			fetchCharges();
		}
	}, [invoice.payment_intent]);

	return (
		<>
			<div className="ms-2 me-auto">
				<div className="fw-bold">
					<a target="_blank" href={`https://dashboard.stripe.com/invoices/${invoice.id}`} rel="noreferrer">
						{invoice.id}
					</a>
				</div>

				<p
					className="mb-0 fw-bold"
					style={{
						color: invoiceStatusToColorMap[invoice.status],
					}}
				>
					Status: {invoice.status.toUpperCase()}
				</p>

				<p className="mb-0">Payment intent: {(invoice.payment_intent as stripe.PaymentIntent)?.id || "-"}</p>

				<p className="mb-0">Amount: {invoice.total / 100} NOK</p>

				{refundedAmount > 0 && <p className="mb-0">Refunded: {refundedAmount / 100} NOK</p>}

				<p className="mb-0">Created on: {moment(invoice.created * 1000).format("DD MMMM YYYY")}</p>

				{!charges && index !== 0 ? (
					<Button disabled size="sm" className="mt-1 mb-1">
						Fetching charges
					</Button>
				) : (
					invoice.total > 0 &&
					invoice.payment_intent &&
					remainingAmount > 0 && (
						<Button
							onClick={() => setShowRefundModal(true)}
							size="sm"
							className="mt-1 mb-1"
							disabled={!charges}
						>
							Refund
						</Button>
					)
				)}
				{invoice.total === 0 && index === 0 && (
					<Button onClick={() => onOpenOrderDetails()} size="sm" className="mt-1 mb-1">
						Refund from order
					</Button>
				)}
			</div>

			<Modal
				show={showRefundModal}
				centered
				onHide={() => setShowRefundModal(false)}
				className="modal-background"
			>
				<Modal.Header closeButton>
					<Modal.Title>Refund invoice: {invoice.id}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<RefundForm
						remainingAmount={remainingAmount}
						onRefund={handleRefund}
						canCancel={!!onCancelProduct}
					/>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default StripeInvoiceItem;

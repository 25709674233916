import React, { useEffect, useState } from "react";
import { getReport } from "../../controllers/value-codes";
import Loader from "../common/loader";

const ReportValueCodes = () => {
  const [report, setReport] = useState<{}>();
  const [reportKeys, setReportKeys] = useState<Array<string>>([]);

  const fetchReport = async () => {
    const _report = await getReport()
    const _reportKeys = Object.keys(_report);

    setReport(_report)
    setReportKeys(_reportKeys)
  }

  useEffect(() => {
    fetchReport()
  }, [])

  return (
    <>
      {report && reportKeys.length > 0 ? (
        <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Max Codes</th>
            <th scope="col">Codes Generated</th>
            <th scope="col">Codes Used</th>
            <th scope="col">Codes Remaining</th>
          </tr>
        </thead>
        <tbody>
          {report && reportKeys.map((reportKey, i) =>  (
            <tr key={i}>
              <th>{i}</th>
              <th>{reportKey}</th>
              <th>{report[reportKey][0].maxCodes}</th>
              <th>{report[reportKey][0].numberOfCodesGenerated}</th>
              <th>{report[reportKey][0].numberOfCodesUsed}</th>
              <th>{report[reportKey][0].codesRemaining}</th>
            </tr>
          ))}
        </tbody>
      </table>
      ) : (
        <Loader loadingText="Loading..." />
      )} 
    </>
  )
}

export default ReportValueCodes
import * as React from "react";
import { Card, ListGroupItem } from "react-bootstrap";
import { IFullOrderModel } from "@sno_oslo/node-utils/dist/cartOrder/dynamodb-schemas";

import ArrowRight from "../../common/arrow";
import { PAYMENT_VENDOR_COLORS } from "../../../utils/colorUtils";
import { getPaymentVendor } from "../../../utils/orderUtils";

interface IProps {
	order: IFullOrderModel;
	onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export default function OrderItem(props: IProps) {
	const purchasedDate = new Date(props.order.createdAt);
	const paymentVendor = getPaymentVendor(props.order.paymentVendor);
	const price =
		props.order.cartPrice ||
		props.order.orderLines.reduce(
			(total, { productPayload: { price, addons } }) =>
				total +
				(price as number) +
				((addons as Array<{ price: number }>)
					? (addons as Array<{ price: number }>).reduce((addonTotal, addon) => +addonTotal + addon.price, 0)
					: 0),
			0,
		);

	return (
		<ListGroupItem onClick={props.onClick} className="item-wrapper">
			<Card className="item-card" role="button">
				<Card.Body>
					<Card.Title>{props.order.id}</Card.Title>
					<Card.Subtitle className="mb-2 text-muted">Price: {price / 100} NOK</Card.Subtitle>
					<Card.Subtitle className="mb-2 text-muted">
						Purchased: {purchasedDate.toLocaleString("no")}
					</Card.Subtitle>

					{props.order.paymentVendor && (
						<Card.Subtitle className="mb-2 text-muted">
							Payment vendor:{" "}
							<span
								style={{
									color: "white",
									backgroundColor: PAYMENT_VENDOR_COLORS[paymentVendor],
									padding: "0.25rem",
									borderRadius: 7,
								}}
							>
								{paymentVendor}
							</span>
						</Card.Subtitle>
					)}
				</Card.Body>
			</Card>
			<ArrowRight />
		</ListGroupItem>
	);
}

import React from "react";
import { useParams } from "react-router-dom";

import useFormat from "../../hooks/useFormat";
import TrainerProfile from "./TrainerProfile";

const EditPersonalTrainer: React.FC = () => {
	const format = useFormat();
	const { id } = useParams<{ id: string }>();

	return (
		<>
			<h1>{format("trainers:edit")}</h1>

			<TrainerProfile id={id!} />
		</>
	);
};

export default EditPersonalTrainer;

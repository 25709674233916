/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";

import useFormat from "../../hooks/useFormat";
import { Types } from "@sno_oslo/shared-utils";

interface IProps {
	accessLevel: Types.AdminAccessLevel;
}

const Sidebar: React.FC<IProps> = ({ accessLevel }) => {
	const format = useFormat();

	return (
		<>
			<nav className="sidebar">
				<h2>{format("vct:title")}</h2>
				<ul>
					<li>
						<a href="#">{format("common:home")}</a>
					</li>
					<li>
						<NavLink to="/value-codes-templates/all" end className={({ isActive }) => (isActive ? "selected" : undefined)}>
							{format("vct:templates")}
						</NavLink>
					</li>
					{accessLevel === Types.AdminAccessLevel.FULL ? (
						<li>
							<NavLink to="/value-codes-templates/add" end className={({ isActive }) => (isActive ? "selected" : undefined)}>
								{format("vct:add")}
							</NavLink>
						</li>
					) : null}
					<li>
            <NavLink
              to="search"
              className={({ isActive }) => (isActive ? "selected" : undefined)}
            >
              Search value code
            </NavLink>
          </li>
				</ul>
			</nav>
		</>
	);
};

export default Sidebar;

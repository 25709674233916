/**
 * Component for creating admin users
 */
import { Types } from "@sno_oslo/shared-utils";
import { validate as validateEmail } from "email-validator";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, InputGroup, Stack } from "react-bootstrap";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { Link } from "react-router-dom";

import LoaderButton from "../common/loader-button";
import { addAdminUser, updateAdminUser } from "../../controllers/admin-users";
import { getAllPersonalTrainers } from "../../controllers/personal-trainers";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";
import { getEmployee } from "../../controllers/planday";

interface IProps {
	user?: Types.IAdminUser;
	onSaved: (user: Types.IAdminUser) => void;
}

interface IFormValues {
	email: string;
	phoneNumber: string;
	plandayEmployeeId?: number;
	password: string;
	confirmPassword: string;
	accessLevel: Types.AdminAccessLevel;
	accessTrainerId?: string;
}

const AdminUserForm: React.FC<IProps> = ({ user, onSaved }) => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const [personalTrainers, setPersonalTrainers] = useState<Array<Types.IPersonalTrainer> | null>(null);
	const [isSyncing, setSyncing] = useState(false);
	const {
		register,
		handleSubmit,
		unregister,
		control,
		formState: { isSubmitting, errors },
		setValue,
	} = useForm<IFormValues>({
		defaultValues: {
			email: user?.email || "",
			phoneNumber: user?.phoneNumber || "",
			plandayEmployeeId: user?.plandayEmployeeId,
			accessLevel: user?.accessLevel || Types.AdminAccessLevel.LIMITED,
			accessTrainerId: user?.accessTrainerId,
			...(user ? {} : { password: "", confirmPassword: "" }),
		},
		mode: "onBlur",
	});
	const accessLevel = useWatch({ control, name: "accessLevel" });
	const accessTrainerId = useWatch({ control, name: "accessTrainerId" });
	const password = useWatch({ control, name: "password" });
	const email = useWatch({ control, name: "email" });
	const phoneNumber = useWatch({ control, name: "phoneNumber" });

	useEffect(() => {
		if (accessLevel !== Types.AdminAccessLevel.PERSONAL_TRAINER) {
			unregister("accessTrainerId");
		}
	}, [accessLevel]);

	useEffect(() => {
		(async () => {
			try {
				setPersonalTrainers(await getAllPersonalTrainers());
			} catch (err) {
				addAlert((err as Error).message || format("error:default"), "danger");
			}
		})();
	}, []);

	const handleSyncEmployee = useCallback(async () => {
		setSyncing(true);

		try {
			const employee = await getEmployee(email);

			setValue("plandayEmployeeId", employee.id);
			if (!phoneNumber) {
				setValue("phoneNumber", employee.phoneNumber.replace(/^\+\d{2}/, ""));
			}
		} catch (err) {
			addAlert((err as Error).message || format("error:default"), "danger");
		} finally {
			setSyncing(false);
		}
	}, [setValue, email, phoneNumber]);

	const onSubmit = useCallback<SubmitHandler<IFormValues>>(
		async (values) => {
			// @ts-ignore
			values.phoneNumber = values.phoneNumber.trim() || undefined;
			values.plandayEmployeeId =
				typeof values.plandayEmployeeId !== "number" || Number.isNaN(values.plandayEmployeeId)
					? undefined
					: values.plandayEmployeeId;

			try {
				onSaved(user ? await updateAdminUser(user.email, values) : await addAdminUser(values));
			} catch (err) {
				addAlert((err as Error).message || format("error:default"), "danger");
			}
		},
		[user?.uid, onSaved, addAlert, format],
	);

	return (
		<Form noValidate onSubmit={handleSubmit(onSubmit)}>
			<Stack gap={3}>
				{user && (
					<Form.Group>
						<Form.Label>{format("common:id")}</Form.Label>
						<Form.Control disabled defaultValue={user.uid} />
					</Form.Group>
				)}

				<Form.Group controlId="email">
					<Form.Label>{format("common:email")}</Form.Label>
					{user ? (
						<Form.Control type="email" disabled defaultValue={user.email} size="lg" />
					) : (
						<>
							<Form.Control
								type="email"
								placeholder={format("common:email:placeholder")}
								size="lg"
								isInvalid={!!errors.email}
								{...register("email", { required: true, validate: { invalid: validateEmail } })}
							/>
							{errors.email && (
								<Form.Control.Feedback type="invalid">
									{format(`validation:${errors.email.type}`, { field: format("common:email") })}
								</Form.Control.Feedback>
							)}
						</>
					)}
				</Form.Group>

				<Form.Group controlId="phoneNumber">
					<Form.Label>{format("common:phoneNumber")}</Form.Label>
					<Form.Control
						type="tel"
						placeholder={format("common:phoneNumber:placeholder")}
						size="lg"
						isInvalid={!!errors.phoneNumber}
						{...register("phoneNumber", {
							validate: {
								invalid: (phoneNumber) => !phoneNumber.trim() || /^\d{8,12}$/.test(phoneNumber),
							},
						})}
					/>
					{errors.phoneNumber && (
						<Form.Control.Feedback type="invalid">
							{format(`validation:${errors.phoneNumber.type}`, { field: format("common:phoneNumber") })}
						</Form.Control.Feedback>
					)}
				</Form.Group>

				<Form.Group controlId="plandayEmployeeId">
					<Form.Label>{format("common:plandayEmployeeId")}</Form.Label>
					<InputGroup>
						<Form.Control
							type="number"
							placeholder={format("common:plandayEmployeeId:placeholder")}
							size="lg"
							isInvalid={!!errors.plandayEmployeeId}
							{...register("plandayEmployeeId", {
								valueAsNumber: true,
								min: 0,
								validate: {
									invalid: (plandayEmployeeId) =>
										typeof plandayEmployeeId !== "number" ||
										Number.isNaN(plandayEmployeeId) ||
										Number.isInteger(plandayEmployeeId),
								},
							})}
						/>
						<Button
							onClick={handleSyncEmployee}
							disabled={isSyncing || !!errors.email}
							variant="secondary rounded-end"
						>
							{format("common:sync")}
						</Button>
					</InputGroup>
					{errors.plandayEmployeeId && (
						<Form.Control.Feedback type="invalid">
							{format(`validation:${errors.plandayEmployeeId.type}`, {
								field: format("common:plandayEmployeeId"),
							})}
						</Form.Control.Feedback>
					)}
				</Form.Group>

				{!user && (
					<>
						<Form.Group controlId="password">
							<Form.Label>{format("common:password")}</Form.Label>
							<Form.Control
								type="password"
								placeholder={format("common:password:placeholder")}
								size="lg"
								isInvalid={!!errors.password}
								{...register("password", { required: true, minLength: 8 })}
							/>
							{errors.password ? (
								<Form.Control.Feedback type="invalid">
									{errors.password.type === "minLength"
										? format("common:password:hint")
										: format(`validation:${errors.password.type}`, {
											field: format("common:password"),
										})}
								</Form.Control.Feedback>
							) : (
								<Form.Text className="text-muted">{format("common:password:hint")}</Form.Text>
							)}
						</Form.Group>

						<Form.Group controlId="confirmPassword">
							<Form.Label>{format("common:password:confirm")}</Form.Label>
							<Form.Control
								type="password"
								placeholder={format("common:password:placeholder")}
								size="lg"
								isInvalid={!!errors.confirmPassword}
								{...register("confirmPassword", {
									required: true,
									validate: {
										match: (confirmPassword) => confirmPassword === password,
									},
								})}
							/>
							{errors.confirmPassword && (
								<Form.Control.Feedback type="invalid">
									{format(`validation:${errors.confirmPassword.type}`, {
										field: format("common:password"),
									})}
								</Form.Control.Feedback>
							)}
						</Form.Group>
					</>
				)}

				<Form.Group>
					<Form.Label>{format("common:access")}</Form.Label>
					<Form.Select size="lg" {...register("accessLevel")}>
						{Object.values(Types.AdminAccessLevel).map((level) => (
							<option key={level} value={level}>
								{format(`common:access:${level}`)}
							</option>
						))}
					</Form.Select>
				</Form.Group>

				{accessLevel === Types.AdminAccessLevel.PERSONAL_TRAINER && (
					<Form.Group>
						<Form.Label>{format("form:trainer")}</Form.Label>
						<Form.Select size="lg" value={accessTrainerId} {...register("accessTrainerId")}>
							{personalTrainers ? (
								personalTrainers.map(({ id, name, surname }) => (
									<option key={id} value={id}>
										{name} {surname}
									</option>
								))
							) : (
								<option value="" disabled>
									{format("form:trainer:fetching")}
								</option>
							)}
						</Form.Select>
					</Form.Group>
				)}
			</Stack>

			<Stack direction="horizontal" gap={2} className="mt-3">
				<Link to="/admin-users">
					<Button as="span" variant="secondary" size="lg">
						{format("common:cancel")}
					</Button>
				</Link>

				<LoaderButton
					type="submit"
					size="lg"
					variant="primary"
					isLoading={isSubmitting}
					loadingLabel={format(`users:form:${user ? "save" : "create"}:loading`)}
				>
					{format(`users:form:${user ? "save" : "create"}:submit`)}
				</LoaderButton>
			</Stack>
		</Form>
	);
};

export default AdminUserForm;

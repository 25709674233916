import { Types } from "@sno_oslo/shared-utils";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FetchErrorAlert from "../common/FetchErrorAlert";
import Loader from "../common/loader";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";
import VCTemplateForm from "./VCTemplateForm";
import { getTemplateById } from "../../controllers/value-codes";

const EditVCTemplate: React.FC = () => {
	const format = useFormat();
	const [template, setTemplate] = useState<Types.ITemplate>();
	const [fetchError, setFetchError] = useState<Error | null>(null);
	const { id, customer } = useParams<{ id: string, customer: string }>();
	const { addAlert } = useSnackbar();
	const navigate = useNavigate();

	const fetchTemplate = useCallback(async () => {
		try {
			setFetchError(null);

			const templateResult = await getTemplateById(id, customer);
			setTemplate(templateResult);
		} catch (err) {
			if (err.code === 404) {
				addAlert(format("promocodes:edit:notFound", { id }), "danger");
				navigate("../", { replace: true });
			} else {
				setFetchError(err as Error);
			}
			setFetchError(err as Error);
		}
	}, [id, format, addAlert, navigate]);

	const handleSaved = useCallback(
		(savedTemplate: Types.ITemplate) => {
			addAlert(format("alerts:saved"), "success");
			setTemplate(savedTemplate);
		},
		[format, addAlert],
	);

	useEffect(() => {
		fetchTemplate();
	}, []);

	return (
		<>
			<h1>{format("vct:edit")}</h1>

			{fetchError ? (
				<FetchErrorAlert message={fetchError.message} onRetry={fetchTemplate} />
			) : (
				<div className="panel max-w-md mx-auto">
					{template ? (
						<VCTemplateForm template={template} onSaved={handleSaved} update={true}/>
					) : (
						<Loader loadingText={format("common:loading")} />
					)}
				</div>
			)}
		</>
	);
};

export default EditVCTemplate;

import React, { useEffect } from "react";
import { Modal, ModalProps } from "react-bootstrap";
import TrainersAvailabilitiesCalendar from "./TrainersAvailabilitiesCalendar";
import { useLocation } from "react-router-dom";

interface ITrainersAvailabilitiesModalProps extends ModalProps {
	title: React.ReactNode;
}

const TrainersAvailabilitiesModal: React.FC<ITrainersAvailabilitiesModalProps> = ({ title, show, onHide, ...rest }) => {
	const { pathname, search } = useLocation();

	useEffect(() => {
		if (show) {
			onHide();
		}
	}, [pathname + search]);

	return (
		<Modal show={show} onHide={onHide} fullscreen {...rest}>
			<Modal.Header closeButton>
				<Modal.Title className="text-primary">{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<TrainersAvailabilitiesCalendar />
			</Modal.Body>
		</Modal>
	);
};

export default TrainersAvailabilitiesModal;

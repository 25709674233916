/**
 * handles all service action to do with planday
 */

import { performRequest } from "./request";

export const getEmployee = (email: string): Promise<{ id: number; phoneNumber: string }> =>
	performRequest({
		path: `/planday/employees/${email}`,
		method: "GET",
	});

import { FC } from "react";
import { Button, Form, Modal, ModalProps, Stack } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import useFormat from "../../hooks/useFormat";

interface IFormValues {
	unfreezeAt?: string;
	extendLockInEndDate?: boolean;
	hideQRCode?: boolean;
}

export interface IProps extends ModalProps {
	onContinue: (values: IFormValues) => void;
}

const FreezeSubscriptionModal: FC<IProps> = ({ onContinue, onHide, ...rest }) => {
	const format = useFormat();
	const {
		register,
		control,
		handleSubmit,
		setValue,
		formState: { isSubmitting, errors },
		watch,
	} = useForm<IFormValues>({
		defaultValues: {
			hideQRCode: true,
		},
		mode: "onBlur",
	});
	const unfreezeAt = watch("unfreezeAt");

	const onSubmit = (values: IFormValues) =>
		onContinue({
			hideQRCode: values.hideQRCode,
			...(values.unfreezeAt
				? {
						unfreezeAt: new Date(values.unfreezeAt).toISOString(),
						extendLockInEndDate: values.extendLockInEndDate,
				  }
				: {}),
		});

	return (
		<Modal onHide={onHide} centered className="modal-background" {...rest}>
			<Modal.Header closeButton>
				<Modal.Title>Freeze subscription</Modal.Title>
			</Modal.Header>
			<Form noValidate onSubmit={handleSubmit(onSubmit)}>
				<Modal.Body>
					<Stack gap={3}>
						<Form.Group controlId="start">
							<Form.Label>Unfreeze at</Form.Label>
							<Form.Control
								type="datetime-local"
								{...register("unfreezeAt")}
								isInvalid={!!errors.unfreezeAt}
							/>
							{errors.unfreezeAt && (
								<Form.Control.Feedback type="invalid">Invalid date</Form.Control.Feedback>
							)}
						</Form.Group>

						<Form.Group>
							<Controller
								control={control}
								name="hideQRCode"
								render={({ field: { value, ...rest } }) => (
									<Form.Check
										label="Should the user have access to their pass during the freezing period?"
										{...rest}
										checked={!value}
										onChange={(e) => setValue("hideQRCode", !e.target.checked)}
									/>
								)}
							/>
						</Form.Group>

						{unfreezeAt && (
							<Form.Group>
								<Form.Check
									label="Do you wish to extend the membership`s binding date correspondingly to the suspended period?"
									{...register("extendLockInEndDate")}
								/>
							</Form.Group>
						)}
					</Stack>
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={() => onHide()}>
						{format("confirm:close")}
					</Button>
					<Button variant="primary" type="submit" disabled={isSubmitting}>
						{format("confirm:submit")}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default FreezeSubscriptionModal;

/**
 * Button component that can display a loader
 * It will display the loader when «isLoading» is passed
 * When loading is active the button is disabled
 * Takes the same props as the default Bootstrap button
 */

import * as React from "react";
import { Button, ButtonProps, Spinner } from "react-bootstrap";

interface IProps extends ButtonProps {
	loadingLabel?: string; // (optional) This is the label shown during loading
	isLoading?: boolean; // Pass true to flip into loading mode
	disabled?: boolean; // Disable the button
	onClick?: any;
}

export default class LoaderButton extends React.Component<IProps, {}> {
	render() {
		const { variant = "primary", loadingLabel, isLoading, ...rest } = this.props;

		return isLoading ? (
			<Button variant={variant} type="button" {...rest} disabled>
				<Spinner
					as="span"
					animation="grow"
					size="sm"
					role="status"
					aria-hidden="true"
					className="align-middle"
				/>
				&nbsp;{loadingLabel}
			</Button>
		) : (
			<Button variant={variant} type="button" {...rest} />
		);
	}
}

import React, { useCallback } from "react";
import { Form, Stack } from "react-bootstrap";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";

import LoaderButton from "../common/loader-button";
import { changePassword } from "../../controllers/auth";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";

interface IProps {
	token: string;
	onSaved: () => void;
}

interface IFormValues {
	password: string;
	confirmPassword: string;
}

const ChangePasswordForm: React.FC<IProps> = ({ token, onSaved }) => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const {
		handleSubmit,
		formState: { errors, isSubmitting },
		register,
		control,
	} = useForm<IFormValues>();
	const password = useWatch({ control, name: "password" });

	const onSubmit = useCallback<SubmitHandler<IFormValues>>(
		async (values) => {
			try {
				await changePassword(token, values.password);

				addAlert(format("changePassword:success"), "success");
				onSaved();
			} catch (err) {
				addAlert((err as Error).message || format("error:default"), "danger");
			}
		},
		[token, onSaved, addAlert, format],
	);

	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<Stack gap={3}>
				<Form.Group controlId="password">
					<Form.Label>{format("common:password")}</Form.Label>
					<Form.Control
						type="password"
						placeholder={format("common:password:placeholder")}
						size="lg"
						isInvalid={!!errors.password}
						{...register("password", { required: true, minLength: 8 })}
					/>
					{errors.password ? (
						<Form.Control.Feedback type="invalid">
							{errors.password.type === "minLength"
								? format("common:password:hint")
								: format(`validation:${errors.password.type}`, {
										field: format("common:password"),
								  })}
						</Form.Control.Feedback>
					) : (
						<Form.Text className="text-muted">{format("common:password:hint")}</Form.Text>
					)}
				</Form.Group>

				<Form.Group controlId="confirmPassword">
					<Form.Label>{format("common:password:confirm")}</Form.Label>
					<Form.Control
						type="password"
						placeholder={format("common:password:placeholder")}
						size="lg"
						isInvalid={!!errors.confirmPassword}
						{...register("confirmPassword", {
							required: true,
							validate: {
								match: (confirmPassword) => confirmPassword === password,
							},
						})}
					/>
					{errors.confirmPassword && (
						<Form.Control.Feedback type="invalid">
							{format(`validation:${errors.confirmPassword.type}`, {
								field: format("common:password"),
							})}
						</Form.Control.Feedback>
					)}
				</Form.Group>

				<div>
					<LoaderButton
						type="submit"
						size="lg"
						variant="primary"
						isLoading={isSubmitting}
						loadingLabel={format("common:saving")}
					>
						{format("common:save")}
					</LoaderButton>
				</div>
			</Stack>
		</Form>
	);
};

export default ChangePasswordForm;

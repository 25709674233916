import * as React from "react";
import { Button, Card, Container, Form, Stack } from "react-bootstrap";

import LoaderButton from "../common/loader-button";
import { activateEventTicket, searchEventTicket } from "../../controllers/event-tickets";
import { Types } from "@sno_oslo/shared-utils";
import moment from "moment";

export default function EventTickets() {
	const [searchQuery, setSearchQuery] = React.useState<string | null>(null);
	const [isSearching, setIsSearching] = React.useState<boolean>(false);
	const [currentQrCode, setCurrentQrCode] = React.useState<number>(null);
	const [searchResults, setSearchResults] = React.useState<Types.IEventTicket[]>([]);
	const [isActivating, setIsActivating] = React.useState<boolean>(false);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		setIsSearching(true);
		try {
			const eventTicket: any = await searchEventTicket(searchQuery);
			if (!eventTicket) {
				// eslint-disable-next-line no-throw-literal
				throw "No event ticket found";
			}

			setSearchResults(eventTicket);
			setIsSearching(false);
		} catch (error) {
			setIsSearching(false);
		}
	};

	const handleActivate = async (qrCode: number) => {
		setIsActivating(true);
		setCurrentQrCode(qrCode);
		try {
			const eventTicket: any = await activateEventTicket(qrCode);

			if (!eventTicket) {
				// eslint-disable-next-line no-throw-literal
				throw "Error while activating";
			}

			setSearchResults((prevSearchResults) =>
				prevSearchResults.map((searchResult) => (searchResult.qrCode === qrCode ? eventTicket : searchResult)),
			);
			setIsActivating(false);
			setCurrentQrCode(null);
		} catch (error) {
			setIsActivating(false);
			setCurrentQrCode(null);
		}
	};

	return (
		<>
			<Container>
				<h1>Search for event ticket</h1>

				<Form onSubmit={handleSubmit} style={{ maxWidth: 500 }}>
					<Stack gap={3}>
						<Form.Group controlId="formQrCode">
							<Form.Label>QR Code or Owner email</Form.Label>
							<Form.Control
								type="search"
								name="searchQuery"
								size="lg"
								onChange={(e) => setSearchQuery(e.target.value)}
							/>
						</Form.Group>
					</Stack>

					<LoaderButton
						type="submit"
						loadingLabel="Searching"
						isLoading={isSearching}
						disabled={!searchQuery}
						size="lg"
						className="mt-3"
					>
						Search
					</LoaderButton>
				</Form>

				<div style={{ display: "flex", flexDirection: "column", gap: 20, padding: 20, alignItems: "center" }}>
					{searchResults &&
						searchResults.length > 0 &&
						searchResults.map((searchResult: any) => (
							<Card
								style={{
									margin: 10,
									padding: 10,
									border: "1px solid black",
									borderRadius: 8,
									justifyContent: "center",
									width: "100%",
								}}
							>
								<Card.Body>
									<Card.Title style={{ textAlign: "center" }}>
										<h3>{searchResult.eventName}</h3>
									</Card.Title>
									<Card.Title>Code: {searchResult.qrCode}</Card.Title>
									<div style={{ fontSize: 18 }}>
										<Card.Text>
											Owner: {searchResult.eventTicketOwner.name}{" "}
											{searchResult.eventTicketOwner.surname}
										</Card.Text>
										<Card.Text>Owner email: {searchResult.eventTicketOwner.email}</Card.Text>
										<Card.Text>
											Start Date: {moment(searchResult.usageStartDate).format("DD.MM.YYYY HH:mm")}
										</Card.Text>
										<Card.Text>
											End Date: {moment(searchResult.usageEndDate).format("DD.MM.YYYY HH:mm")}
										</Card.Text>
										<Card.Text>Activated: {searchResult.activated ? "true" : "false"}</Card.Text>
										<Card.Text
											className={searchResult.cancelled ? "text-danger fw-bolder" : undefined}
										>
											Cancelled: {searchResult.cancelled ? "true" : "false"}
										</Card.Text>
									</div>
								</Card.Body>
								{!searchResult.activated && (
									<Button
										variant="outline-primary"
										size="lg"
										className="d-block"
										onClick={() => handleActivate(searchResult.qrCode)}
										disabled={isActivating}
									>
										{isActivating && searchResult.qrCode === currentQrCode
											? "Loading..."
											: "Activate"}
									</Button>
								)}
							</Card>
						))}
				</div>
			</Container>
		</>
	);
}

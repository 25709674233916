import { Types } from "@sno_oslo/shared-utils";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Calendar, CalendarProps, Event, momentLocalizer, SlotInfo, View, Views } from "react-big-calendar";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import SectionLoader from "../common/SectionLoader";
import {
	getPersonalTrainerAvailabilities,
	getPersonalTrainerGroupCourses,
	getPersonalTrainerReservations,
} from "../../controllers/personal-trainers";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";
import TrainerAvailabilityForm from "./TrainerAvailabilityForm";
import TrainerReservationModal from "./TrainerReservationModal";
import { getDisciplineColor } from "../../utils/colorUtils";

moment.locale("en", {
	week: {
		dow: 1,
		doy: 1,
	},
});
const localizer = momentLocalizer(moment);

interface IProps {
	personalTrainerId: Types.IPersonalTrainer["id"];
}

interface IEvent extends Event {
	id: string;
	index: number;
	type: "reservation" | "availability" | "group-course";
	discipline?: Types.Discipline;
	groupCourseId?: string;
}

interface IRange {
	start: Date;
	end: Date;
}

const TrainerCalendar: React.FC<IProps> = ({ personalTrainerId }) => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const navigate = useNavigate();
	const views = useMemo<Array<View>>(() => ["month", "week", "day"], []);
	const [range, setRange] = useState<IRange>({
		start: moment().startOf("month").toDate(),
		end: moment().endOf("month").toDate(),
	});
	const [isFetching, setFetching] = useState(false);
	const [selectedRange, setSelectedRange] = useState<IRange | null>(null);

	const [reservations, setReservations] = useState<Array<Types.IPersonalTrainerReservation>>([]);
	const reservationEvents = useMemo(
		() =>
			reservations.map(
				({ id, start, end, participants, discipline }, index) =>
					({
						id,
						index,
						type: "reservation",
						title: format("trainers:calendar:reservation:label", {
							participantName: participants[0].name,
							participantPhone: participants[0].phone,
							discipline,
						}),
						discipline,
						start: new Date(start),
						end: new Date(end),
					} as IEvent),
			),
		[reservations],
	);

	const [groupCourses, setGroupCourses] = useState<Array<Types.IGroupCourse>>([]);
	const groupCoursesEvents = useMemo(
		() =>
			groupCourses
				.map((course) =>
					course.startDates.map(
						(start, i) =>
							({
								id: `${course.id}_${i}`,
								groupCourseId: course.id,
								type: "group-course",
								discipline: course.disciplines[0],
								title: course.name,
								start: new Date(start),
								end: new Date(course.endDates[i]),
							} as IEvent),
					),
				)
				.flat(),
		[groupCourses],
	);

	const events = useMemo(
		() => [...reservationEvents, ...groupCoursesEvents],
		[reservationEvents, groupCoursesEvents],
	);

	const [availabilities, setAvailabilities] = useState<Array<Types.IPersonalTrainerAvailability>>([]);
	const availabilityEvents = useMemo(
		() =>
			availabilities.map(
				({ id, disciplines, start, end }) =>
					({
						id,
						type: "availability",
						title: `${format("trainers:calendar:availability:slot")} (${Object.keys(disciplines)
							.map((d) => format(`discipline:${d}`))
							.join(", ")})`,
						start: new Date(start),
						end: new Date(end),
					} as IEvent),
			),
		[availabilities, format],
	);

	const [activeAvailabilityId, setActiveAvailabilityId] = useState<Types.IPersonalTrainerAvailability["id"] | null>(
		null,
	);
	const activeAvailability = useMemo(
		() => availabilities.find(({ id }) => id === activeAvailabilityId),
		[availabilities, activeAvailabilityId],
	);

	const [activeReservationId, setActiveReservationId] = useState<Types.IPersonalTrainerReservation["id"] | null>(
		null,
	);
	const activeReservation = reservations.find((event) => event.id === activeReservationId);
	const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);

	const handleSelectEvent = useCallback(
		(event: IEvent) => {
			if (event.type === "availability") {
				setActiveAvailabilityId(event.id);
			} else if (event.type === "reservation") {
				setActiveReservationId(event.id);
				setPreviewModalOpen(true);
			} else if (event.type === "group-course") {
				navigate(`/lessons/group-courses/${event.groupCourseId}`);
			}
		},
		[navigate],
	);

	const handleSlot = useCallback(
		({ start, end }: SlotInfo) => {
			if (
				!availabilityEvents.some(
					(a) => a.start!.getTime() <= start.getTime() && a.end!.getTime() >= end.getTime(),
				)
			) {
				setSelectedRange({ start, end });
			}
		},
		[availabilityEvents],
	);

	const handleRangeChange = useCallback<Required<CalendarProps>["onRangeChange"]>((newRange) => {
		setRange(
			Array.isArray(newRange)
				? {
						start: newRange[0],
						end: moment(newRange[newRange.length - 1])
							.endOf("day")
							.toDate(),
				  }
				: newRange,
		);
	}, []);

	const handleAvailabilityCreated = useCallback(
		(createdAvailability: Types.IPersonalTrainerAvailability) => {
			setAvailabilities([...availabilities, createdAvailability]);
			setSelectedRange(null);
		},
		[availabilities],
	);

	const handleAvailabilityUpdated = useCallback(
		async (updatedAvailability: Types.IPersonalTrainerAvailability) => {
			setAvailabilities(
				availabilities.map((availability) =>
					availability.id === updatedAvailability.id ? updatedAvailability : availability,
				),
			);
			setActiveAvailabilityId(null);
		},
		[availabilities],
	);

	const handleAvailabilityDeleted = useCallback(
		async (availabilityId: Types.IPersonalTrainerAvailability["id"]) => {
			setAvailabilities(availabilities.filter(({ id }) => id !== availabilityId));
			setActiveAvailabilityId(null);
		},
		[availabilities],
	);

	const handleReservationDeleted = useCallback(
		(reservationId: Types.IPersonalTrainerReservation["id"]) => {
			setReservations(reservations.filter((r) => r.id !== reservationId));
			setPreviewModalOpen(false);
		},
		[reservations],
	);

	const handleReservationUpdated = useCallback(
		(reservation: Types.IPersonalTrainerReservation) => {
			setReservations(reservations.map((r) => (r.id === reservation.id ? reservation : r)));
		},
		[reservations],
	);

	useEffect(() => {
		const fetchData = async () => {
			setFetching(true);
			setReservations([]);
			setAvailabilities([]);
			setGroupCourses([]);

			try {
				const query = { from: range.start.toISOString(), to: range.end.toISOString() };
				const results = await Promise.all([
					getPersonalTrainerReservations(personalTrainerId, query),
					getPersonalTrainerAvailabilities(personalTrainerId, query),
					getPersonalTrainerGroupCourses(personalTrainerId, query),
				]);

				setReservations(results[0]);
				setAvailabilities(results[1]);
				setGroupCourses(results[2]);
			} catch (err) {
				addAlert((err as Error).message || format("error:default"), "danger");
			} finally {
				setFetching(false);
			}
		};

		fetchData();
	}, [personalTrainerId, range]);

	return (
		<SectionLoader className="h-100" isLoading={isFetching}>
			<Calendar
				localizer={localizer}
				views={views}
				defaultView={Views.MONTH}
				onRangeChange={handleRangeChange}
				selectable
				onSelectSlot={handleSlot}
				onSelectEvent={handleSelectEvent}
				// longPressThreshold={100}
				events={events}
				backgroundEvents={availabilityEvents}
				eventPropGetter={({ discipline }) =>
					discipline ? { style: { backgroundColor: getDisciplineColor(discipline) } } : {}
				}
				startAccessor="start"
				endAccessor="end"
				step={60}
				timeslots={1}
			/>

			<Modal show={!!selectedRange} onHide={() => setSelectedRange(null)} className="modal-background">
				<Modal.Header closeButton>
					<Modal.Title>{format("trainers:calendar:availability:add")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{selectedRange && (
						<TrainerAvailabilityForm
							personalTrainerId={personalTrainerId}
							range={selectedRange}
							onCancel={() => setSelectedRange(null)}
							onSaved={handleAvailabilityCreated}
						/>
					)}
				</Modal.Body>
			</Modal>

			<Modal
				show={!!activeAvailability}
				onHide={() => setActiveAvailabilityId(null)}
				className="modal-background"
			>
				<Modal.Header closeButton>
					<Modal.Title className="text-truncate">
						{format("trainers:availabilities:preview:title")}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{activeAvailability && (
						<TrainerAvailabilityForm
							personalTrainerId={activeAvailability.personalTrainerId}
							availability={activeAvailability}
							onCancel={() => setActiveAvailabilityId(null)}
							onDeleted={handleAvailabilityDeleted}
							onSaved={handleAvailabilityUpdated}
						/>
					)}
				</Modal.Body>
			</Modal>

			{activeReservation && (
				<TrainerReservationModal
					show={isPreviewModalOpen}
					onHide={() => setPreviewModalOpen(false)}
					reservation={activeReservation}
					onReservationDeleted={handleReservationDeleted}
					onReservationUpdated={handleReservationUpdated}
					className="modal-background"
				/>
			)}
		</SectionLoader>
	);
};

export default TrainerCalendar;

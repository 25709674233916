import * as React from "react";
import { Types } from "@sno_oslo/shared-utils";

import Loader from "../common/loader";
import { getTemplates } from "../../controllers/value-codes";
import { Stack } from "react-bootstrap";
import useFormat from "../../hooks/useFormat";
import FetchErrorAlert from "../common/FetchErrorAlert";
import { useCallback, useEffect } from "react";
import VCTCard from "./vctCard";

interface IProps {
	accessLevel: Types.AdminAccessLevel;
}

export default function ValueCodeTemplateList({ accessLevel }: IProps) {
	const format = useFormat();
	const [showModifyButtons] = React.useState<boolean>(accessLevel === Types.AdminAccessLevel.FULL);
	const [templatesList, setList] = React.useState<Array<Types.ITemplate>>();
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [fetchError, setFetchError] = React.useState<Error | null>(null);

	const fetchTemplates = React.useCallback(async () => {
		try {
			setIsLoading(true);
			setList(await getTemplates());
			setIsLoading(false);
		} catch (err) {
			setFetchError(err as Error);
		} finally {
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		fetchTemplates()
	}, []);

	const handleDelete = useCallback((id: Types.ITemplate["id"]) => {
		setList(templatesList!.filter(el => el.id !== id))
	}, [templatesList]);

	return (
		<>
			<h1>Value Code Templates</h1>
			{templatesList && !isLoading ? (
				templatesList.length > 0 ? (
					<Stack gap={3} className="mx-auto d-flex flex-row flex-wrap">
						{templatesList.map((template) => (
							<VCTCard template={template} key={template.id} onDeleted={handleDelete} showModifyButtons={showModifyButtons}/>
						))}
					</Stack>
				) : (
					<p className="text-center p-3 lead">{format("common:emptyState")}</p>
				)
			) : (
				fetchError && <FetchErrorAlert message={fetchError.message} onRetry={fetchTemplates} />
			)}
			{(isLoading) && <Loader loadingText="Loading..." />}
		</>
	);
}

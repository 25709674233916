/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";

import useFormat from "../../hooks/useFormat";
import { Types } from "@sno_oslo/shared-utils";

interface IProps {
	accessLevel: Types.AdminAccessLevel;
}

const Sidebar: React.FC<IProps> = ({ accessLevel }) => {
	const format = useFormat();

	return (
		<nav className="sidebar">
			<h2>{format("promocodes:title")}</h2>
			<ul>
				<li>
					<a href="#">{format("common:home")}</a>
				</li>
				<li>
					<NavLink to="" end className={({ isActive }) => (isActive ? "selected" : undefined)}>
						{format("promocodes:title")}
					</NavLink>
				</li>
				{accessLevel === Types.AdminAccessLevel.FULL ? (
					<li>
						<NavLink to="add" end className={({ isActive }) => (isActive ? "selected" : undefined)}>
							{format("promocodes:add")}
						</NavLink>
					</li>
				): null}
			</ul>
		</nav>
	);
};

export default Sidebar;

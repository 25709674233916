import * as React from "react";
import { Button } from "react-bootstrap";

import { IReservationDto } from "../../../../controllers/lessons/reservations";
import ReservationLine from "./reservationLine";

interface IProps {
	reservations: Array<IReservationDto>;
	selectedReservations: Array<string>;
	toggleSelectedReservations: (reservationCodes: Array<string>, select: boolean) => void;
	updateReservations: (reservations: Array<IReservationDto>) => void;
}

const ReservationList: React.FunctionComponent<IProps> = (props: IProps) => {
	const allReservationsSelected = props.reservations.length === props.selectedReservations.length;

	return (
		<>
			<div className="reservationsList">
				<div className="reservation header">
					<span>#</span>
					<span>Reservation code</span>
					<span>Participant</span>
					<span>Age</span>
					<span>Phone</span>
					<span>E-mail</span>
					<span>Purchased at</span>
					<span>Confirmed</span>
					<span>Selected</span>
				</div>
				{props.reservations.map((reservation, i, reservations) => (
					<ReservationLine
						key={reservation.reservationCode}
						reservation={reservation}
						reservationIndex={i + 1}
						selected={props.selectedReservations.includes(reservation.reservationCode)}
						toggleSelectedReservations={props.toggleSelectedReservations}
						updateReservation={(updatedReservation) =>
							props.updateReservations(
								reservations.map((r) =>
									r.reservationCode === updatedReservation.reservationCode ? updatedReservation : r,
								),
							)
						}
					/>
				))}
			</div>
			<Button
				variant="primary"
				className="float-right"
				onClick={() =>
					props.toggleSelectedReservations(
						props.reservations.map((r) => r.reservationCode),
						!allReservationsSelected,
					)
				}
			>
				{allReservationsSelected ? "Deselect all" : "Select all"}
			</Button>
		</>
	);
};

export default ReservationList;

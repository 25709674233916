import { FC, useState } from "react";
import { Card, Nav } from "react-bootstrap";

import classNames from "classnames";
import { Helpers } from "@sno_oslo/shared-utils";
import AddBraceletPassesTab from "./AddBraceletPassesTab";
import AddPassesTab from "./AddPassesTab";

const AddPassesPage: FC = () => {
	const [activeTab, setActiveTab] = useState<"ordinary" | "bracelets">("ordinary");

	return (
		<div className="py-4 container-sm">
			<Card className="card--plain max-w-md">
				<Card.Header>
					<Nav variant="pills">
						{["ordinary" as const, "bracelets" as const].map((tab) => (
							<Nav.Item key={tab}>
								<button
									className={classNames("nav-link mx-1", { active: activeTab === tab })}
									onClick={() => setActiveTab(tab)}
								>
									{Helpers.capitalize(tab)}
								</button>
							</Nav.Item>
						))}
					</Nav>
				</Card.Header>
				<Card.Body className="max-w-sm p-4">
					<Card.Title as="h4" className="mb-4">
						{activeTab === "ordinary" && "Add ordinary pass"}
						{activeTab === "bracelets" && "Add bracelet passes"}
					</Card.Title>

					{activeTab === "ordinary" && <AddPassesTab />}
					{activeTab === "bracelets" && <AddBraceletPassesTab />}
				</Card.Body>
			</Card>
		</div>
	);
};

export default AddPassesPage;

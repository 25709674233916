import { EModalMode } from "../../../enums/modal-mode.enum";
import { FC, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Enums, Types } from "@sno_oslo/shared-utils";
import { EntryProps } from "contentful-management";
import {
	createAndPublishAvailability,
	deleteAvailability,
	updateAndPublishAvailability,
} from "../../../controllers/contentful/product-settings";
import moment from "moment/moment";

interface IProps {
	modalMode: EModalMode;
	showModal: boolean;
	setShowModal: (showModal: boolean) => void;
	currentAvailability: EntryProps | null;
	setCurrentAvailability: (pricing: EntryProps | null) => void;
	zone: Types.ZoneType | null;
	isLoading: boolean;
	setIsLoading: (isLoading: boolean) => void;
	refresh: () => Promise<void>;
}

const initialFormState = {
	from: null,
	to: null,
	openDays: Object.values(Enums.EDaysOfWeek).map((dayOfWeek) => ({
		label: dayOfWeek,
		value: dayOfWeek,
		checked: true,
	})),
};

const AvailabilityModal: FC<IProps> = ({
	modalMode,
	showModal,
	setShowModal,
	currentAvailability,
	setCurrentAvailability,
	zone,
	isLoading,
	setIsLoading,
	refresh,
}) => {
	const [formData, setFormData] = useState(initialFormState);

	useEffect(() => {
		if (currentAvailability) {
			setFormData({
				from: new Date(currentAvailability.fields.from.nb),
				to: new Date(currentAvailability.fields.to.nb),
				openDays: Object.values(Enums.EDaysOfWeek).map((dayOfWeek) => ({
					label: dayOfWeek,
					value: dayOfWeek,
					checked: currentAvailability.fields.openDays?.nb.includes(dayOfWeek) ?? false,
				})),
			});
		}
	}, [currentAvailability]);

	const handleRangeChange = (dates: [Date, Date]) => {
		const [start, end] = dates;
		setFormData((prevData) => ({
			...prevData,
			from: start,
			to: end,
		}));
	};

	const handleCheckboxChange = (index: number) => {
		setFormData((prevData) => ({
			...prevData,
			openDays: prevData.openDays.map((option, i) =>
				i === index ? { ...option, checked: !option.checked } : option,
			),
		}));
	};

	const handleCloseModal = () => {
		setFormData(initialFormState);
		setShowModal(false);
		setCurrentAvailability(null);
	};

	const handleAction = async (action: (args) => Promise<void>, payload = {}) => {
		setIsLoading(true);

		await action(payload);
		await refresh();
		handleCloseModal();

		setIsLoading(false);
	};

	const handleSave = () =>
		handleAction(createAndPublishAvailability, {
			zone,
			from: moment(formData.from).format("YYYY-MM-DD"),
			to: moment(formData.to).format("YYYY-MM-DD"),
			openDays: formData.openDays.filter(({ checked }) => checked).map(({ value }) => value),
		});

	const handleUpdate = () =>
		handleAction(updateAndPublishAvailability, {
			zone,
			from: moment(formData.from).format("YYYY-MM-DD"),
			to: moment(formData.to).format("YYYY-MM-DD"),
			availability: currentAvailability,
			openDays: formData.openDays.filter(({ checked }) => checked).map(({ value }) => value),
		});

	const handleDelete = () => handleAction(deleteAvailability, currentAvailability);

	return (
		<Modal show={showModal} onHide={handleCloseModal} centered>
			<Modal.Header closeButton>
				<Modal.Title>{`${modalMode === EModalMode.CREATE ? "Create" : "Update"}`} availability</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Group className="mt-3">
							<Form.Label>Open days</Form.Label>
							{formData.openDays.map((option, index) => (
								<Form.Check
									key={index}
									type="checkbox"
									label={option.label}
									value={option.value}
									checked={option.checked}
									onChange={() => handleCheckboxChange(index)}
								/>
							))}
						</Form.Group>

						<Form.Label>Range</Form.Label>
						<br />
						<DatePicker
							onChange={handleRangeChange}
							selected={formData.from}
							startDate={formData.from}
							endDate={formData.to}
							selectsRange
							dateFormat={"dd.MM.yyyy"}
							calendarStartDay={1}
						/>
					</Form.Group>
				</Form>
			</Modal.Body>

			<Modal.Footer>
				<div style={{ flex: 1, textAlign: "left" }}>
					{modalMode === EModalMode.EDIT && (
						<Button variant="danger" onClick={handleDelete} disabled={isLoading}>
							Delete
						</Button>
					)}
				</div>
				<Button variant="secondary" onClick={handleCloseModal}>
					Close
				</Button>
				<Button
					variant="primary"
					disabled={isLoading || !formData.to || !formData.from}
					onClick={() => (modalMode === EModalMode.CREATE ? handleSave() : handleUpdate())}
				>
					{modalMode === EModalMode.CREATE ? "Create" : "Save"}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default AvailabilityModal;

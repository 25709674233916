import * as React from "react";
import { Types } from "@sno_oslo/shared-utils"

import { formatDate } from "../lessons/group_courses/dateTimeFormats";
import DeleteValueCodeModal from "./modals/DeleteValueCodeModal";

enum Modals {
  DELETE_VALUE_CODE = "DELETE_VALUE_CODE",
}

interface IProps {
  templateId: string;
  code: Types.IValueCode;
  refetch: () => void;
}

export function ValueCodesDetails({ code, templateId, refetch }: IProps) {
  const [modalShown, setModalShown] = React.useState<Modals>(null);

  const isModal = (modal: Modals): boolean => modalShown === modal;

  return (
    <div className="singleGroupCourse">
      <div className="header">
        <div>Expire time: {code.expireTime ? formatDate(new Date(code.expireTime * 1000).toString()) : "none" }</div>
        <div className="deleteLink" onClick={() => setModalShown(Modals.DELETE_VALUE_CODE)}>
          Delete
        </div>
      </div>

      <div className="details">
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around'
        }}>
          <h3>{code.id}</h3>
          <h4>Customer: {code.customer}</h4>
        </div>
        <div>
          <p className="detailsRow">
            <span className="label">Description:</span>
            <span className="value">{code.description}</span>
          </p>
          <div className="detailsRow">
            <span className="label">Addons:</span>
            <span className="value">
              {code?.addons?.join(', ')}
            </span>
          </div>

          <p className="detailsRow">
            <span className="label">Products:</span>
            <span className="value">{code?.productIds?.map((p: any) => p.title).join(', ')}</span>
          </p>
        </div>
      </div>
      {isModal(Modals.DELETE_VALUE_CODE) && (
        <DeleteValueCodeModal
          templateId={templateId}
          closeModal={() => setModalShown(null)}
          code={code.id}
          refetch={refetch}
        />
      )}
    </div>
  );
}

import { useEffect, useState } from "react";
import { getSlopes, updateSlope } from "../../controllers/contentful/slopes";
import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import * as React from "react";
import { EntryProps } from "contentful-management";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import { OSLO_TIMEZONE } from "../../constants/timezone.constant";
import "react-datepicker/dist/react-datepicker.css";

const SlopeStatus = () => {
	const [slopes, setSlopes] = useState<EntryProps[]>();
	const [activeSlope, setActiveSlope] = useState<EntryProps>();
	const [isLoading, setIsLoading] = useState(false);
	const [isOpen, setIsOpen] = useState<boolean>();
	const [lastMaintained, setLastMaintained] = useState<string>();

	const fetchSlopes = async () => {
		const res = await getSlopes();
		setSlopes(res);
	};
	useEffect(() => {
		fetchSlopes();
	}, []);

	useEffect(() => {
		if (activeSlope) {
			setIsOpen(activeSlope.fields.open["nb"]);
			setLastMaintained(activeSlope.fields.lastMaintained?.["nb"]);
		}
	}, [activeSlope]);

	useEffect(() => {
		if (activeSlope) {
			setActiveSlope(slopes.find((slope) => slope.sys.id === activeSlope.sys.id));
		}
	}, [slopes]);

	const handleSave = async () => {
		try {
			setIsLoading(true);
			activeSlope.fields.open["nb"] = isOpen;
			activeSlope.fields.lastMaintained["nb"] = lastMaintained;
			await updateSlope(activeSlope.sys.id, activeSlope);
		} catch (e) {
			console.error(e);
		} finally {
			setIsLoading(false);
			fetchSlopes();
		}
	};

	return (
		<React.Fragment>
			<Container className="p-3">
				<h1>Slope status</h1>
				<Row>
					{slopes?.map((slope, index) => {
						return (
							<Col
								key={index}
								onClick={() => setActiveSlope(slope)}
								style={{
									backgroundColor:
										activeSlope && activeSlope.sys.id === slope.sys.id ? "#3eebfa" : "transparent",
									borderRadius: 5,
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<h5 style={{ textAlign: "center" }}>{slope.fields.zone["nb"]}</h5>
							</Col>
						);
					})}
				</Row>
			</Container>
			{activeSlope && (
				<Container className="p-3">
					<Form>
						<Form.Group className="mb-3">
							<Stack direction="horizontal" gap={4}>
								<Form.Label>Slope open</Form.Label>
								<Form.Check
									type="switch"
									checked={isOpen}
									onChange={(event) => setIsOpen(event.target.checked)}
								/>
							</Stack>
						</Form.Group>

						<Stack gap={2}>
							{["nb", "en"].map((locale) => (
								<Form.Group key={locale}>
									<Form.Label>Comment ({locale})</Form.Label>
									<Form.Control
										type="textarea"
										value={activeSlope.fields.comment[locale]}
										onChange={(event) => {
											setActiveSlope((prevState) => ({
												...prevState,
												fields: {
													...prevState.fields,
													comment: {
														...prevState.fields.comment,
														[locale]: event.target.value,
													},
													open: {
														nb: isOpen,
													},
													lastMaintained: {
														nb: lastMaintained,
													},
												},
											}));
										}}
									/>
								</Form.Group>
							))}
						</Stack>

						<Stack direction={"horizontal"} gap={3} className="mt-4">
							<Button variant="primary" onClick={() => handleSave()} disabled={isLoading}>
								{isLoading ? "Saving…" : "Save"}
							</Button>
							<Button
								variant="secondary"
								onClick={() => {
									const initial = slopes.find((slope) => slope.sys.id === activeSlope.sys.id);
									setActiveSlope({ ...initial });
								}}
								disabled={isLoading}
							>
								Reset
							</Button>
						</Stack>

						<Form.Group className="mt-4">
							<Form.Label>Last maintained</Form.Label>
							<div className="d-flex ">
								<DatePicker
									selected={lastMaintained ? moment.tz(lastMaintained, OSLO_TIMEZONE).toDate() : null}
									value={lastMaintained}
									onChange={(date) =>
										setLastMaintained(moment(date).tz(OSLO_TIMEZONE, true).format())
									}
									className="form-control"
									wrapperClassName="w-full"
									showTimeSelect
								/>
								<Button variant="info" className="rounded ms-2" onClick={() => handleSave()}>
									Update
								</Button>
							</div>
						</Form.Group>
					</Form>
				</Container>
			)}
		</React.Fragment>
	);
};

export default SlopeStatus;

import { managementClient } from "./managementClient";
import { EContentfulContentTypeId } from "../../enums/contentful-content-type-id.enum";
import { Enums, Types } from "@sno_oslo/shared-utils";
import moment from "moment";
import { EntryProps } from "contentful-management";

interface IGetPricingByIdsProps {
	ids: string[];
	zone?: Types.ZoneType;
	dayType?: Types.DaysType;
	priceClass?: Types.PriceClass;
}

interface IPricingCreate {
	price?: number | null;
	from?: string | null;
	to?: string | null;
	productType?: Types.CartProductType;
	zone?: Types.ZoneType | null;
	dayType?: Types.DaysType | null;
	priceClass?: Types.PriceClass | null;
	pricingAndAvailability?: EntryProps;
	pricing?: EntryProps;
}

interface IAvailabilityCreate {
	from: string;
	to: string;
	openDays: Enums.EDaysOfWeek[];
	zone: Types.ZoneType;
}

interface IAvailabilityUpdate {
	from: string;
	to: string;
	openDays: Enums.EDaysOfWeek[];
	availability: EntryProps;
	zone: Types.ZoneType;
}

export const getProductSettings = async () => {
	const { items } = await managementClient.entry.getMany({
		query: { content_type: EContentfulContentTypeId.PRODUCT_SETTINGS },
	});

	return items[0];
};

export const getPricingByIds = async ({ ids, zone, dayType, priceClass }: IGetPricingByIdsProps) => {
	const query = {
		"sys.id[in]": ids.join(","),
		order: "fields.from",
		content_type: EContentfulContentTypeId.PRICING_AND_AVAILABILITY,
	};

	if (zone) query["fields.zone"] = zone;

	if (dayType) query["fields.dayType"] = dayType;

	if (priceClass) query["fields.priceClass"] = priceClass;

	const { items } = await managementClient.entry.getMany({
		query,
	});

	return items;
};

export const createAndPublishPricing = async ({
	price,
	from,
	to,
	productType,
	zone,
	dayType,
	priceClass,
	pricingAndAvailability,
}: IPricingCreate) => {
	let pricing = await managementClient.entry.create(
		{ contentTypeId: EContentfulContentTypeId.PRICING_AND_AVAILABILITY },
		{
			fields: {
				name: {
					nb: `${moment(from).format("DD.MM.YYYY")}-${moment(to).format("DD.MM.YYYY")} ${
						productType || "*"
					} ${zone || "*"} ${dayType || "*"} ${priceClass || "*"}`,
				},
				type: { nb: Enums.EPricingAndAvailabilityType.PRICING },
				price: { nb: price },
				from: { nb: from },
				to: { nb: to },
				zone: { nb: zone },
				dayType: { nb: dayType },
				priceClass: { nb: priceClass },
			},
		},
	);

	pricing = await managementClient.entry.publish({ entryId: pricing.sys.id }, pricing);

	pricingAndAvailability = await managementClient.entry.get({ entryId: pricingAndAvailability.sys.id });

	const existingProductPricing = pricingAndAvailability.fields[productType]?.nb || [];

	pricingAndAvailability.fields[productType] = {
		nb: [
			...existingProductPricing,
			{
				sys: {
					type: "Link",
					linkType: "Entry",
					id: pricing.sys.id,
				},
			},
		],
	};

	pricingAndAvailability = await managementClient.entry.update(
		{ entryId: pricingAndAvailability.sys.id },
		pricingAndAvailability,
	);

	await managementClient.entry.publish({ entryId: pricingAndAvailability.sys.id }, pricingAndAvailability);
};

export const updateAndPublishPricing = async ({
	price,
	from,
	to,
	pricing,
	productType,
	zone,
	dayType,
	priceClass,
}: IPricingCreate) => {
	pricing = await managementClient.entry.get({ entryId: pricing.sys.id });

	pricing.fields.name.nb = `${moment(from).format("DD.MM.YYYY")}-${moment(to).format("DD.MM.YYYY")} ${
		productType || "*"
	} ${zone || "*"} ${dayType || "*"} ${priceClass || "*"}`;
	pricing.fields.from.nb = from;
	pricing.fields.to.nb = to;
	pricing.fields.price.nb = price;

	pricing = await managementClient.entry.update({ entryId: pricing.sys.id }, pricing);

	await managementClient.entry.publish({ entryId: pricing.sys.id }, pricing);
};

export const deletePricing = async ({ pricing, pricingAndAvailability, productType }: IPricingCreate) => {
	[pricing, pricingAndAvailability] = await Promise.all([
		managementClient.entry.get({ entryId: pricing.sys.id }),
		managementClient.entry.get({ entryId: pricingAndAvailability.sys.id }),
	]);

	pricingAndAvailability.fields[productType].nb = pricingAndAvailability.fields[productType].nb.filter(
		({ sys: { id } }) => id !== pricing.sys.id,
	);

	pricingAndAvailability = await managementClient.entry.update(
		{ entryId: pricingAndAvailability.sys.id },
		pricingAndAvailability,
	);

	await managementClient.entry.publish({ entryId: pricingAndAvailability.sys.id }, pricingAndAvailability);

	if (pricing.sys.publishedVersion) await managementClient.entry.unpublish({ entryId: pricing.sys.id });

	await managementClient.entry.delete({ entryId: pricing.sys.id });
};

export const getAvailabilities = async (zone: Types.ZoneType) => {
	const { items } = await managementClient.entry.getMany({
		query: {
			content_type: EContentfulContentTypeId.PRICING_AND_AVAILABILITY,
			order: "sys.createdAt",
			"fields.type": Enums.EPricingAndAvailabilityType.AVAILABILITY,
			"fields.zone": zone,
		},
	});

	return items;
};

export const createAndPublishAvailability = async ({ openDays, from, to, zone }: IAvailabilityCreate) => {
	const availability = await managementClient.entry.create(
		{
			contentTypeId: EContentfulContentTypeId.PRICING_AND_AVAILABILITY,
		},
		{
			fields: {
				name: {
					nb: `${moment(from).format("DD.MM.YYYY")}-${moment(to).format("DD.MM.YYYY")} ${zone || "*"}`,
				},
				from: { nb: from },
				to: { nb: to },
				openDays: { nb: openDays },
				zone: { nb: zone },
				type: { nb: Enums.EPricingAndAvailabilityType.AVAILABILITY },
			},
		},
	);

	await managementClient.entry.publish({ entryId: availability.sys.id }, availability);
};

export const updateAndPublishAvailability = async ({ availability, openDays, from, to, zone }: IAvailabilityUpdate) => {
	availability = await managementClient.entry.get({ entryId: availability.sys.id });

	availability.fields.name.nb = `${moment(from).format("DD.MM.YYYY")}-${moment(to).format("DD.MM.YYYY")} ${
		zone || "*"
	}`;
	availability.fields.from.nb = from;
	availability.fields.to.nb = to;
	availability.fields.openDays = {
		nb: openDays,
	};

	availability = await managementClient.entry.update({ entryId: availability.sys.id }, availability);

	await managementClient.entry.publish({ entryId: availability.sys.id }, availability);
};

export const deleteAvailability = async (availability: EntryProps) => {
	availability = await managementClient.entry.get({ entryId: availability.sys.id });

	if (availability.sys.publishedVersion) await managementClient.entry.unpublish({ entryId: availability.sys.id });

	await managementClient.entry.delete({ entryId: availability.sys.id });
};

import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Calendar, Event, momentLocalizer, View, Views } from "react-big-calendar";

import SectionLoader from "../common/SectionLoader";
import { getPersonalTrainersAvailabilities } from "../../controllers/personal-trainers";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";
import TrainerAvailabilityModal from "./TrainerAvailabilityModal";
import { getDisciplineColor } from "../../utils/colorUtils";

moment.locale("en", {
	week: {
		dow: 1,
		doy: 1,
	},
});
const localizer = momentLocalizer(moment);

interface IProps { }

interface IEvent extends Event {
	id: string;
	index: number;
}

interface IRange {
	start: Date;
	end: Date;
}

const TrainersAvailabilitiesCalendar: React.FC<IProps> = () => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const views = useMemo<Array<View>>(() => ["month", "week", "day"], []);
	const [range, setRange] = useState<IRange>({
		start: moment().startOf("month").toDate(),
		end: moment().endOf("month").toDate(),
	});
	const [isFetching, setFetching] = useState(false);

	const [availabilities, setAvailabilities] = useState<Array<any>>([]);
	const availabilityEvents = useMemo(
		() =>
			availabilities.map(
				({ id, start, end, disciplines }, index) =>
				({
					id,
					index,
					start: new Date(start),
					end: new Date(end),
					disciplines: Object.keys(disciplines),
				} as any),
			),
		[availabilities],
	);

	const [activeEventId, setActiveEventId] = useState<string | null>(null);
	const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);
	const activeAvailability = availabilities.find((event) => event.id === activeEventId);

	const handleSelectEvent = useCallback((event: IEvent) => {
		setActiveEventId(event.id);
		setPreviewModalOpen(true);
	}, []);

	const handleRangeChange = (newRange) => {
		setRange(
			Array.isArray(newRange) ?
			{
				start: moment(newRange[0]).startOf("day").toDate(),
				end: moment(newRange[newRange.length - 1])
					.endOf("day")
					.toDate(),
			} :
			newRange
		);
	}

	useEffect(() => {
		const fetchData = async () => {
			setFetching(true);
			setAvailabilities([]);

			try {
				setAvailabilities(
					await getPersonalTrainersAvailabilities({
						from: range.start.toISOString(),
						to: range.end.toISOString(),
					})
				);

			} catch (err) {
				addAlert((err as Error).message || format("error:default"), "danger");
			} finally {
				setFetching(false);
			}
		};

		fetchData();
	}, [range]);

	return (
		<SectionLoader className="h-100" isLoading={isFetching}>
			<Calendar
				localizer={localizer}
				views={views}
				defaultView={Views.MONTH}
				onRangeChange={handleRangeChange}
				selectable
				onSelectEvent={handleSelectEvent}
				events={availabilityEvents}
				startAccessor="start"
				endAccessor="end"
				step={60}
				timeslots={1}
				eventPropGetter={({ disciplines }) => ({
					style: {
						backgroundColor: getDisciplineColor(disciplines[0]),
						border: '1px solid white'
					}
				})}
			/>

			{activeAvailability && (
				<TrainerAvailabilityModal
					show={isPreviewModalOpen}
					onHide={() => setPreviewModalOpen(false)}
					availability={activeAvailability}
					className="modal-background"
				/>
			)}
		</SectionLoader>
	);
};

export default TrainersAvailabilitiesCalendar;

import React from "react";
import moment from "moment";
import { Helpers, Types } from "@sno_oslo/shared-utils";
import useFormat from "../../hooks/useFormat";
import { Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PencilFill, TrashFill } from "react-bootstrap-icons";

interface IProps {
	giftCards: Array<Types.IGiftCard | Types.IGiftCardArchive>;
	onDelete?: (giftCard: Types.IGiftCard) => void;
}

const GiftCardsTable: React.FC<IProps> = ({ giftCards, onDelete }) => {
	const format = useFormat();

	const isArchived = (gift: Types.IGiftCard | Types.IGiftCardArchive): gift is Types.IGiftCardArchive => {
		return (gift as Types.IGiftCardArchive).archivedAt !== undefined;
	};

	return (
		<Table bordered striped responsive>
			<thead>
				<tr>
					<th>{format("common:code")}</th>
					<th>{format("common:value")} (NOK)</th>
					<th>{format("common:amountLeft")} (NOK)</th>
					<th>{format("common:id")}</th>
					<th>{format("common:payerId")}</th>
					<th>{format("common:ownerId")}</th>
					<th>{format("common:expiresAt")}</th>
					<th>{format("common:purchasedAt")}</th>
					<th />
				</tr>
			</thead>
			<tbody>
				{giftCards.map((card, i) => (
					<tr key={i}>
						<td>{card.code}</td>
						<td>{Helpers.formatPrice(card.value / 100)}</td>
						<td>{Helpers.formatPrice(card.amountLeft / 100)}</td>
						<td>{card.id}</td>
						<td>{card.payerId}</td>
						<td>{card.ownerId ? <Link to={`/customers/${card.ownerId}`}>{card.ownerId}</Link> : "-"}</td>
						<td>{moment(card.expireTime * 1000).format("YYYY-MM-DD LT")}</td>
						<td>{moment(card.purchasedAt).format("YYYY-MM-DD LT")}</td>
						<td className="td-last text-end">
							<Button
								// @ts-ignore
								as={Link}
								to={`/gift-cards/${isArchived(card) ? `archived/${card.id}` : card.code}`}
								variant="secondary"
								className="btn-icon"
							>
								<PencilFill />
							</Button>
							{onDelete && (
								<Button variant="danger" onClick={() => onDelete(card)} className="btn-icon ms-2">
									<TrashFill />
								</Button>
							)}
						</td>
					</tr>
				))}
			</tbody>
		</Table>
	);
};

export default GiftCardsTable;

import * as React from "react";
import { ListGroup } from "react-bootstrap";

import { IGroupCourseDto } from "../../../../controllers/lessons/groupCourses";
import SelectCourseItem from "./selectCourseItem";

interface IProps {
	courses: Array<IGroupCourseDto>;
	selectCourse: (courseId: string) => void;
	selectedCourse: string;
}

const SelectCourseList: React.FunctionComponent<IProps> = (props: IProps) => (
	<ListGroup>
		{props.courses.map((course) => (
			<SelectCourseItem
				course={course}
				key={course.id}
				selectCourse={props.selectCourse}
				selected={props.selectedCourse === course.id}
			/>
		))}
	</ListGroup>
);

export default SelectCourseList;

import { Types } from "@sno_oslo/shared-utils";
import React, { useCallback, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

import ConfirmModal from "../common/ConfirmModal";
import { deletePromoCode } from "../../controllers/promo-codes";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";

interface IProps {
	promocode: Types.IPromoCode;
	descriptionMaxLength?: number;
	onDeleted: (id: Types.IPromoCode["id"]) => void;
	accessLevel: Types.AdminAccessLevel;
}

const PromoCard: React.FC<IProps> = ({ promocode: { id, name }, onDeleted, accessLevel }) => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	const handleDelete = useCallback(async () => {
		try {
			setIsDeleting(true);
			setIsDeleteModalVisible(false);

			await deletePromoCode(id);

			onDeleted(id);
			addAlert(format("alerts:deleted"), "success");
		} catch (err) {
			addAlert((err as Error).message || format("error:default"), "danger");
			setIsDeleting(false);
		}
	}, [id, addAlert]);

	return (
		<>
			<Card key={id} className="mt-3 card--plain">
				<Card.Body>
					<div className="d-flex align-items-center">
						<div className="ml-3">
							{/* <Link to={`${id}/edit`}> */}
								<Card.Title className="mb-1 text-dark">{name}</Card.Title>
							{/* </Link> */}
						</div>
					</div>
				</Card.Body>

				{accessLevel === Types.AdminAccessLevel.FULL ? (
					<Card.Footer className="d-flex justify-content-between">
						<Link to={`${id}/edit`}>
							<Button as="span" variant="info">
								{format("promocodes:edit")}
							</Button>
						</Link>

						<Button
							variant="outline-danger"
							onClick={() => setIsDeleteModalVisible(true)}
							disabled={isDeleting}
							>
							{format(isDeleting ? "common:loading" : "promocodes:delete")}
						</Button>
					</Card.Footer>
				) : null}
			</Card>

			<ConfirmModal
				show={isDeleteModalVisible}
				onHide={() => setIsDeleteModalVisible(false)}
				onConfirm={handleDelete}
				confirmVariant="danger"
			>
				{format("promocodes:delete:details")}
				<mark>{name}</mark>
			</ConfirmModal>
		</>
	);
};

export default PromoCard;

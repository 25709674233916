import * as React from "react";
import { Alert, Button, Modal } from "react-bootstrap";

import LoaderButton from "../../common/loader-button";
import { deleteValueCode } from "../../../controllers/value-codes";

interface IProps {
  code: string;
  templateId: string;
  closeModal: () => void;
  refetch: () => void;
}

const DeleteReservationsModal: React.FunctionComponent<IProps> = (props: IProps) => {
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const [success, setSuccess] = React.useState<boolean>(false);

  const performDeletion = async () => {
    setIsSubmitting(true);
    try {
      await deleteValueCode(props.code)
      setSuccess(true);
      setIsSubmitting(false);
      setTimeout(() => props.closeModal(), 500)
      await props.refetch()
    } catch (err) {
      setError(err.message);
      setIsSubmitting(false);
    }
  };

  const onClose = () => {
    props.closeModal();
  };

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete value code</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this value code?</p>
        {!!error && (
          <Alert variant="danger">
            An error occurred 😱
            <br />
            <p>{error}</p>
          </Alert>
        )}
        {success && (
          <>
            <Alert variant="success">
              Value code removed
            </Alert>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <LoaderButton
          isLoading={isSubmitting}
          onClick={performDeletion}
          loadingLabel="Deleting..."
          variant="danger"
        >
          Yes, delete the value code
        </LoaderButton>

        <Button variant="secondary" onClick={onClose}>
          {success ? "OK" : "Close"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteReservationsModal;

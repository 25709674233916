import { Helpers, Types } from "@sno_oslo/shared-utils";
import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Button, ButtonGroup, ListGroup, Modal, ToggleButton } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
import type { IFullOrderModel } from "@sno_oslo/node-utils/dist/cartOrder/dynamodb-schemas";

import { getUser, getUserOrders, getUserPasses, getUserStripeSubscriptions } from "../../controllers/customers";
import useFormat from "../../hooks/useFormat";
import ArrowRight from "../common/arrow";
import PlusIcon from "../common/PlusIcon";
import Loader from "../common/loader";
import AccessPassDetails from "./accessPassDetails";
import AccessPassItem from "./accessPassItem";
import CustomerForm from "./customerForm";
import MinusIcon from "../common/MinusIcon";
import OrderItem from "./order/orderItem";
import { removeArrayDuplicates } from "../../utils/dataUtils";
import { IStripeMembership, IVippsMembership } from "../../types/memberships";
import { getVippsMemberships } from "../../utils/membershipUtils";
import VippsMembershipItem from "./membership/vippsMembershipItem";
import { getStripeSubscriptionInvoices } from "../../controllers/memberships";
import StripeMembershipItem from "./membership/stripeMembershipItem";
import OrderDetails from "./order/OrderDetail";
import useSnackbar from "../../hooks/useSnackbar";
import { AllProductsType, getProducts } from "../../controllers/value-codes";

type TPassesFilter = "ongoing" | "archived";
type TOrdersFilter = "confirmed" | "unconfirmed";

const CustomerDetails: React.FC = () => {
	const { customerId } = useParams<{ customerId: string }>();
	const [searchParams] = useSearchParams();
	const qrCode = searchParams.get("qrCode") ? +searchParams.get("qrCode") : null;
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const [accessPasses, setAccessPasses] = useState<(Types.IAccessProduct | Types.IAccessProductArchive)[] | null>(
		null,
	);
	const [activeOrderId, setActiveOrderId] = useState<string | null>(null);
	const [activePassQrCode, setActivePassQrCode] = useState<number | null>(qrCode);
	const [customer, setCustomer] = useState<Types.IUserData | null>(null);
	const [expandAccessPasses, setExpandAccessPasses] = useState<boolean>(false);
	const [expandOrders, setExpandOrders] = useState<boolean>(false);
	const [vippsMemberships, setVippsMemberships] = useState<Array<IVippsMembership>>([]);
	const [stripeMemberships, setStripeMemberships] = useState<Array<IStripeMembership>>([]);
	const [expandMemberships, setExpandMemberships] = useState<boolean>(false);
	const [passesFilter, setPassesFilter] = useState<TPassesFilter>("ongoing");
	const [ordersFilter, setOrdersFilter] = useState<TOrdersFilter>("confirmed");
	const [loading, setLoading] = useState<boolean>(false);
	const [orders, setOrders] = useState<Array<IFullOrderModel> | null>([]);
	const [products, setProducts] = useState<AllProductsType[]>([]);
	const filteredPasses = useMemo(
		() =>
			accessPasses
				?.filter((pass) =>
					passesFilter === "archived"
						? !!(pass as Types.IAccessProductArchive).archivedAt
						: !(pass as Types.IAccessProductArchive).archivedAt,
				)
				.sort((a, b) => new Date(b.purchasedAt!).getTime() - new Date(a.purchasedAt!).getTime()),
		[accessPasses, passesFilter],
	);
	const activePass = useMemo(
		() => (accessPasses && activePassQrCode ? accessPasses.find((pass) => pass.qrCode === activePassQrCode) : null),
		[accessPasses, activePassQrCode],
	);
	const filteredOrders = useMemo(
		() => orders.filter((order) => (ordersFilter === "confirmed" ? order.confirmed : !order.confirmed)),
		[orders, ordersFilter],
	);
	const activeOrder = useMemo(
		() => (orders.length && activeOrderId ? orders.find((order) => order.id === activeOrderId) : null),
		[orders, activeOrderId],
	);
	const memberships = useMemo(
		() =>
			[...vippsMemberships, ...stripeMemberships].sort(
				(a, b) =>
					(b.type === "stripe" ? b.details.created : moment(b.details.start).unix()) -
					(a.type === "stripe" ? a.details.created : moment(a.details.start).unix()),
			),
		[vippsMemberships, stripeMemberships],
	);

	useEffect(() => {
		fetchProducts();
		fetchUser();
		fetchAllOrders();
	}, [customerId]);

	useEffect(() => {
		fetchPasses();
	}, [customerId]);

	const fetchVippsMemberships = () => {
		const vippsAgrementIds = removeArrayDuplicates(
			orders.map((order) => order.vippsAgreementId),
			true,
		);
		if (vippsAgrementIds.length) {
			getVippsMemberships(vippsAgrementIds).then((vMemberships) => {
				setVippsMemberships(vMemberships);
			});
		}
	};

	useEffect(() => {
		if (!orders.length) {
			return;
		}

		fetchVippsMemberships();
	}, [orders]);

	useEffect(() => {
		if (accessPasses?.length) {
			const fetchSubscriptions = async () => {
				const subscriptions = await getUserStripeSubscriptions(customerId);
				setStripeMemberships(
					await Promise.all(
						subscriptions.map(async (details) => ({
							type: "stripe",
							details,
							invoices: await getStripeSubscriptionInvoices(details.id),
						})),
					),
				);
			};

			fetchSubscriptions();
		}
	}, [customerId, accessPasses]);

	const fetchUser = async () => {
		try {
			const { user } = await getUser(customerId!);
			setCustomer(user);
		} catch (err) {
			console.error(err);
		}
	};

	const fetchProducts = async () => {
		try {
			const allProducts = await getProducts();
			setProducts(allProducts);
		} catch (err) {
			console.error(err);
		}
	};

	const fetchPasses = async () => {
		try {
			const [activePasses, archivedPasses] = await Promise.all([
				getUserPasses(customerId),
				getUserPasses(customerId, true),
			]);
			setAccessPasses([
				...activePasses.ownerAccessProducts,
				...activePasses.userAccessProducts,
				...archivedPasses.ownerAccessProducts,
				...archivedPasses.userAccessProducts,
			]);
		} catch (err) {
			console.error(err);
		}
	};

	const fetchAllOrders = async () => {
		setLoading(true);

		try {
			const allOrders = await getUserOrders(customerId);
			const allOrdersCompacted = allOrders.map((ord) => {
				return {
					...ord,
					orderLines: Helpers.compactFamilyPassesOrderlines(ord.orderLines || []),
				} as IFullOrderModel;
			});

			setOrders(allOrdersCompacted);
		} catch (err) {
			console.error(err);
		}

		setLoading(false);
	};

	const handleUpdatedAccessPass = useCallback(
		(updatedPass: Types.IAccessProduct) => {
			setAccessPasses(accessPasses.map((pass) => (pass.id === updatedPass.id ? updatedPass : pass)));
		},
		[accessPasses],
	);

	return (
		<>
			<div className="customers-details max-w-md mx-auto">
				{customer ? (
					<>
						<div className="header">
							<Link to="/customers">
								<ArrowRight />
								<Button variant="link" className="back-btn">
									Back to search
								</Button>
							</Link>
						</div>

						<h1>Profile</h1>

						<div className="panel w-100 max-w-md mx-auto">
							<CustomerForm user={customer} />
						</div>
						<div id="user-access-passes">
							<h1 className="mt-5 mb-3">
								Access passes{filteredPasses ? <span> ({filteredPasses.length}) </span> : " "}
								{expandAccessPasses ? (
									<span
										style={{ cursor: "pointer" }}
										onClick={() => {
											setExpandAccessPasses(false);
										}}
									>
										<MinusIcon />
									</span>
								) : (
									<span
										style={{ cursor: "pointer" }}
										onClick={() => {
											setExpandAccessPasses(true);
										}}
									>
										<PlusIcon />
									</span>
								)}
							</h1>
							<div hidden={expandAccessPasses === false}>
								<ButtonGroup className="mb-2">
									{["ongoing", "archived"].map((value) => (
										<ToggleButton
											id={value}
											key={value}
											type="radio"
											value={value}
											checked={value === passesFilter}
											onClick={() => setPassesFilter(value as TPassesFilter)}
											variant="outline-primary"
										>
											{format(`common:${value}`)}
										</ToggleButton>
									))}
								</ButtonGroup>
								<ListGroup variant="flush">
									{filteredPasses ? (
										filteredPasses.map((pass) => (
											<AccessPassItem
												key={pass.qrCode}
												pass={pass}
												onClick={() => setActivePassQrCode(pass.qrCode)}
												products={products}
											/>
										))
									) : (
										<Loader loadingText={format("common:loading")} />
									)}
								</ListGroup>
							</div>
						</div>
						<div id="user-orders">
							<h1 className="mt-5 mb-3">
								Orders{!loading ? <span> ({filteredOrders.length}) </span> : " "}
								{expandOrders ? (
									<span
										style={{ cursor: "pointer" }}
										onClick={() => {
											setExpandOrders(false);
										}}
									>
										<MinusIcon />
									</span>
								) : (
									<span
										style={{ cursor: "pointer" }}
										onClick={() => {
											setExpandOrders(true);
										}}
									>
										<PlusIcon />
									</span>
								)}
							</h1>

							<div hidden={expandOrders === false}>
								<ButtonGroup className="mb-2">
									{["confirmed", "unconfirmed"].map((value) => (
										<ToggleButton
											id={value}
											key={value}
											type="radio"
											value={value}
											checked={value === ordersFilter}
											onClick={() => setOrdersFilter(value as TOrdersFilter)}
											variant="outline-primary"
										>
											{format(`common:${value}`)}
										</ToggleButton>
									))}
								</ButtonGroup>

								<ListGroup variant="flush">
									{loading ? (
										<Loader loadingText={format("common:loading")} />
									) : filteredOrders.length ? (
										filteredOrders.map((order, index) => (
											<OrderItem
												order={order}
												key={index}
												onClick={() => setActiveOrderId(order.id)}
											/>
										))
									) : (
										<div>None</div>
									)}
								</ListGroup>
							</div>
						</div>
						<div id="user-memberships">
							<h1 className="mt-5 mb-3">
								Memberships
								{!loading ? <span> ({memberships.length}) </span> : " "}
								{expandMemberships ? (
									<span
										style={{ cursor: "pointer" }}
										onClick={() => {
											setExpandMemberships(false);
										}}
									>
										<MinusIcon />
									</span>
								) : (
									<span
										style={{ cursor: "pointer" }}
										onClick={() => {
											setExpandMemberships(true);
										}}
									>
										<PlusIcon />
									</span>
								)}
							</h1>

							<div hidden={expandMemberships === false}>
								<ListGroup variant="flush">
									{loading ? (
										<Loader loadingText={format("common:loading")} />
									) : memberships.length ? (
										<>
											{memberships.map((membership) =>
												membership.type === "vipps" ? (
													<VippsMembershipItem
														key={membership.details.id}
														membership={membership}
														order={orders.find(
															(order) =>
																order.id ===
																membership.charges.find(
																	(charge) => !charge.id.startsWith("chr-"),
																)?.id,
														)}
														passes={accessPasses.filter(
															(pass) => pass.vippsAgreementId === membership.details.id,
														)}
														onUpdatePass={handleUpdatedAccessPass}
														onFocusAccessPass={setActivePassQrCode}
														onRefunded={() => {
															fetchAllOrders();
															fetchVippsMemberships();
														}}
														onCancelled={() => {
															fetchPasses();
															fetchVippsMemberships();
														}}
													/>
												) : (
													<StripeMembershipItem
														key={membership.details.id}
														membership={membership}
														onUpdate={(updatedMembership) =>
															setStripeMemberships(
																stripeMemberships.map((m) =>
																	m.details.id === updatedMembership.details.id
																		? updatedMembership
																		: m,
																),
															)
														}
														onOpenOrderDetails={() => {
															const pass = accessPasses.find(
																(pass) =>
																	pass.stripeSubscriptionId === membership.details.id,
															);
															const order =
																pass &&
																orders.find(
																	(order) =>
																		order.stripePaymentIntentId ===
																		pass.stripePaymentIntentId,
																);

															if (order) {
																setActiveOrderId(order.id);
															} else {
																addAlert(
																	"Couldn't find any order for this membership",
																	"danger",
																);
															}
														}}
														passes={accessPasses.filter(
															(pass) =>
																pass.stripeSubscriptionId === membership.details.id,
														)}
														onUpdatePass={handleUpdatedAccessPass}
														onFocusAccessPass={setActivePassQrCode}
													/>
												),
											)}
										</>
									) : (
										<div>None</div>
									)}
								</ListGroup>
							</div>
						</div>
					</>
				) : (
					<Loader loadingText={format("common:loading")} />
				)}
			</div>

			{activePass && (
				<Modal show onHide={() => setActivePassQrCode(null)} className="modal-background">
					<Modal.Header closeButton>
						<Modal.Title>Access pass</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<AccessPassDetails
							pass={activePass}
							onUpdate={handleUpdatedAccessPass}
							onDelete={(deletedPass) => {
								setActivePassQrCode(null);
								setAccessPasses(accessPasses.filter((pass) => pass.id !== deletedPass.id));
							}}
							products={products}
							refreshPass={fetchPasses}
						/>
					</Modal.Body>
				</Modal>
			)}

			{activeOrder && (
				<Modal show onHide={() => setActiveOrderId(null)} className="modal-background">
					<Modal.Header closeButton>
						<Modal.Title>Order: {activeOrder.id}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<OrderDetails
							order={activeOrder}
							onUpdated={fetchAllOrders}
							products={products}
							loading={loading}
						/>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
};

export default CustomerDetails;

import { Button, Form, Modal } from "react-bootstrap";
import { FC, useEffect, useState } from "react";
import { EModalMode } from "../../../enums/modal-mode.enum";
import { EntryProps } from "contentful-management";
import DatePicker from "react-datepicker";
import { Types } from "@sno_oslo/shared-utils";
import {
	createAndPublishPricing,
	deletePricing,
	updateAndPublishPricing,
} from "../../../controllers/contentful/product-settings";
import moment from "moment/moment";

interface IProps {
	modalMode: EModalMode;
	showModal: boolean;
	setShowModal: (showModal: boolean) => void;
	currentPricing: EntryProps | null;
	setCurrentPricing: (pricing: EntryProps | null) => void;
	productType: Types.CartProductType;
	zone: Types.ZoneType | null;
	dayType: Types.DaysType | null;
	priceClass: Types.PriceClass | null;
	refresh: () => Promise<void>;
	pricingAndAvailability: EntryProps;
	isLoading: boolean;
	setIsLoading: (isLoading: boolean) => void;
}

const initialFormState = {
	price: null,
	from: null,
	to: null,
};

const PricingModal: FC<IProps> = ({
	showModal,
	setShowModal,
	modalMode,
	currentPricing,
	setCurrentPricing,
	productType,
	zone,
	dayType,
	priceClass,
	refresh,
	pricingAndAvailability,
	isLoading,
	setIsLoading,
}) => {
	const [formData, setFormData] = useState(initialFormState);

	useEffect(() => {
		if (currentPricing) {
			setFormData({
				price: currentPricing.fields.price.nb,
				from: new Date(currentPricing.fields.from.nb),
				to: new Date(currentPricing.fields.to.nb),
			});
		}
	}, [currentPricing]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleRangeChange = (dates: [Date, Date]) => {
		const [start, end] = dates;
		setFormData((prevData) => ({
			...prevData,
			from: start,
			to: end,
		}));
	};

	const handleCloseModal = () => {
		setFormData(initialFormState);
		setShowModal(false);
		setCurrentPricing(null);
	};

	const handleAction = async (action: (args) => Promise<void>, payload = {}) => {
		setIsLoading(true);

		await action(payload);
		await refresh();
		handleCloseModal();

		setIsLoading(false);
	};

	const handleSave = async () =>
		handleAction(createAndPublishPricing, {
			price: +formData.price,
			from: moment(formData.from).format("YYYY-MM-DD"),
			to: moment(formData.to).format("YYYY-MM-DD"),
			productType,
			zone,
			dayType,
			priceClass,
			pricingAndAvailability,
		});

	const handleUpdate = async () =>
		handleAction(updateAndPublishPricing, {
			price: +formData.price,
			from: moment(formData.from).format("YYYY-MM-DD"),
			to: moment(formData.to).format("YYYY-MM-DD"),
			pricing: currentPricing,
			productType,
			zone,
			dayType,
			priceClass,
		});

	const handleDelete = async () =>
		handleAction(deletePricing, {
			pricing: currentPricing,
			pricingAndAvailability,
			productType,
		});

	return (
		<Modal show={showModal} onHide={() => handleCloseModal()} centered>
			<Modal.Header closeButton>
				<Modal.Title>{`${modalMode === EModalMode.CREATE ? "Create" : "Update"}`} pricing</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Form>
					<Form.Group style={{ width: "207px" }}>
						<Form.Label>Price</Form.Label>
						<Form.Control
							type="number"
							placeholder="0"
							name="price"
							value={formData.price}
							onChange={handleChange}
						/>
					</Form.Group>

					<Form.Group>
						<Form.Label>Range</Form.Label>
						<br />
						<DatePicker
							onChange={handleRangeChange}
							selected={formData.from}
							startDate={formData.from}
							endDate={formData.to}
							selectsRange
							dateFormat={"dd.MM.yyyy"}
							calendarStartDay={1}
						/>
					</Form.Group>
				</Form>
			</Modal.Body>

			<Modal.Footer>
				<div style={{ flex: 1, textAlign: "left" }}>
					{modalMode === EModalMode.EDIT && (
						<Button variant="danger" onClick={handleDelete} disabled={isLoading}>
							Delete
						</Button>
					)}
				</div>
				<Button variant="secondary" onClick={handleCloseModal}>
					Close
				</Button>
				<Button
					variant="primary"
					disabled={isLoading || !formData.to || !formData.from || !formData.price}
					onClick={() => (modalMode === EModalMode.CREATE ? handleSave() : handleUpdate())}
				>
					{modalMode === EModalMode.CREATE ? "Create" : "Save"}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default PricingModal;

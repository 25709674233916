import React, { PropsWithChildren, useCallback, useMemo } from "react";

import en from "../lang/en.json";
import ContentContext, { IContentContextValue } from "../contexts/ContentContext";

const ContentProvider: React.FC<PropsWithChildren> = ({ children }) => {
	// May change in future to support more languages
	const content = useMemo<Record<string, string | undefined>>(() => en, []);
	const format = useCallback<IContentContextValue["format"]>(
		(key, values) => {
			let value: string | undefined = content[key];

			if (value && values) {
				for (const [k, v] of Object.entries(values)) {
					value = value.replace(`{${k}}`, v.toString());
				}
			}

			return value ?? key;
		},
		[content],
	);

	const value = useMemo<IContentContextValue>(
		() => ({
			format,
			content,
		}),
		[format, content],
	);

	return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
};

export default ContentProvider;

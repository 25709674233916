import { performRequest } from "./request";
import { removeLoggedUserToken, saveLoggedUserToken } from "./storage";

export interface IChangePasswordTokenPayload {
	exp: number;
}

/**
 * Will make a request to the login service and set a local session for the user
 * @param email Login email
 * @param password Login password
 */
export const loginUser = async (email: string, password: string): Promise<void> => {
	// Talk to the login service
	const { token } = (await performRequest({
		path: "/auth/login",
		method: "POST",
		body: {
			email,
			password,
		},
	})) as { token: string };

	// Save access token locally
	saveLoggedUserToken(token);
};

export const logoutUser = () => removeLoggedUserToken();

export const changePassword = (token: string, password: string) =>
	performRequest({
		path: "/auth/change-password",
		method: "POST",
		body: {
			token,
			password,
		},
	});

import { Types } from "@sno_oslo/shared-utils";
import React, { useCallback } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";
import AdminUserForm from "./AdminUserForm";

const AddAdminUser: React.FC = () => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const navigate = useNavigate();

	const handleSaved = useCallback(
		({ email }: Types.IAdminUser) => {
			addAlert(format("alerts:saved"), "success");
			navigate(`/admin-users/${email}`);
		},
		[format, navigate],
	);

	return (
		<Container>
			<h2>{format("users:add:title")}</h2>
			<AdminUserForm onSaved={handleSaved} />
		</Container>
	);
};

export default AddAdminUser;

import React, { useEffect } from "react";
import { Modal, ModalProps } from "react-bootstrap";
import TrainersReservationsCalendar from "./TrainersReservationsCalendar";
import { useLocation } from "react-router-dom";

interface ITrainersReservationsModalProps extends ModalProps {
	title: React.ReactNode;
}

const TrainersReservationsModal: React.FC<ITrainersReservationsModalProps> = ({ title, show, onHide, ...rest }) => {
	const { pathname, search } = useLocation();

	useEffect(() => {
		if (show) {
			onHide();
		}
	}, [pathname + search]);

	return (
		<Modal show={show} onHide={onHide} fullscreen {...rest}>
			<Modal.Header closeButton>
				<Modal.Title className="text-primary">{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<TrainersReservationsCalendar />
			</Modal.Body>
		</Modal>
	);
};

export default TrainersReservationsModal;

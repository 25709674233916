import React, { useEffect } from "react";
import { Modal, ModalProps } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import PassDeleteForm from "./PassDeleteForm";

interface IDeletePassModalProps extends ModalProps {}

const DeletePassModal: React.FC<IDeletePassModalProps> = ({ show, onHide, ...rest }) => {
	const { pathname, search } = useLocation();

	useEffect(() => {
		if (show) {
			onHide();
		}
	}, [pathname + search]);

	return (
		<Modal show={show} onHide={onHide} {...rest}>
			<Modal.Header closeButton>
				<Modal.Title>Delete access pass</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<PassDeleteForm onCancel={onHide} onDeleted={onHide} />
			</Modal.Body>
		</Modal>
	);
};

export default DeletePassModal;

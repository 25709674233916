import React from "react";
import { Outlet } from "react-router-dom";

import Sidebar from "./Sidebar";

const PersonalTrainers: React.FC = () => (
	<>
		<Sidebar />
		<main className="main--with-sidebar">
			<Outlet />
		</main>
	</>
);

export default PersonalTrainers;

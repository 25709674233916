import React from "react";
import { Outlet } from "react-router-dom";

import Sidebar from "./Sidebar";
import { Types } from "@sno_oslo/shared-utils";

interface IProps {
	accessLevel: Types.AdminAccessLevel;
}

const ValueCodesTemplates: React.FC<IProps> = ({ accessLevel }) => (
	<>
		<Sidebar accessLevel={accessLevel} />
		<main className="main--with-sidebar">
			<Outlet />
		</main>
	</>
);

export default ValueCodesTemplates;

import { Helpers, Types } from "@sno_oslo/shared-utils";
import * as React from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";

import { getAllPersonalTrainers } from "../../../controllers/personal-trainers";
import useFormat from "../../../hooks/useFormat";
import { FormControlsId, formTypesNames, getShouldDisplayFunction } from "./forms/formTypes";

enum TextFieldsNames {
	NAME = "name",
	NAME_EN = "nameEn",
	TRAINER = "trainer",
}

interface IDetailsFormProps {
	details: IDetailsFormFields;
	lowerParticipantsLimit: number;
	setDetails: (details: IDetailsFormFields) => void;
	onFormSuccess: () => void;
}

export interface IDetailsFormFields {
	name: string;
	nameEn: string;
	description: string;
	descriptionNo: string;
	price: number;
	trainer?: string;
	personalTrainerId?: string;
	priceClass: Types.PriceClass;
	ageRange?: Array<number>;
	levels: Array<Types.Difficulty>;
	disciplines: Array<Types.Discipline>;
	maxParticipants?: number;
	minParticipants?: number;
	formType: Types.DetailsType;
	isHidden?: boolean;
	availableSpots?: number;
}

export const getEmptyResult = (): IDetailsFormFields => {
	return {
		name: "",
		nameEn: "",
		description: "",
		descriptionNo: "",
		price: 0,
		trainer: null,
		personalTrainerId: null,
		priceClass: null,
		ageRange: null,
		levels: [],
		disciplines: [],
		maxParticipants: 1,
		minParticipants: 1,
		formType: Types.DetailsType.GROUP_COURSE,
		isHidden: null,
		availableSpots: 1,
	};
};

export default function DetailsForm({ details, setDetails, lowerParticipantsLimit, onFormSuccess }: IDetailsFormProps) {
	const format = useFormat();
	const [validated, setValidated] = React.useState<boolean>(false);
	const [invalidFields, setInvalidFields] = React.useState<{}>({});
	const [personalTrainers, setPersonalTrainers] = React.useState<Array<Types.IPersonalTrainer>>(null);

	const allAgesRange = Types.getAllAgesRange();
	const ageMinValue = allAgesRange[0];
	const ageMaxValue = allAgesRange[allAgesRange.length - 1];
	const [minAge, setMinAge] = React.useState<number>(
		// Set default as first ageRange element if defined
		details.ageRange?.[0],
	);
	const [maxAge, setMaxAge] = React.useState<number>(
		// Set default as last ageRange element if defined
		details.ageRange && details.ageRange[details.ageRange.length - 1],
	);
	// Bool to make sure both or none field are defined
	const onlyOneAgeDefined = !!minAge !== !!maxAge;
	const ageRangeRequired = details.formType === Types.DetailsType.CAMP;

	React.useEffect(() => {
		(async () => {
			try {
				setPersonalTrainers(await getAllPersonalTrainers());
				// eslint-disable-next-line no-empty
			} catch {}
		})();
	}, []);

	const handleSubmit = (event) => {
		event.preventDefault();
		const form = event.currentTarget;
		console.log(event);
		if (form.checkValidity() === false) {
			event.stopPropagation();
		} else {
			onFormSuccess();
		}
		setValidated(true);
	};

	const onFormTypeChange = (type: Types.DetailsType) => {
		setDetails({
			...details,
			formType: type,
			trainer: type === Types.DetailsType.GROUP_COURSE ? details.trainer : null,
		});
	};

	const onFieldChange = (key: keyof IDetailsFormFields, value: any) => {
		setDetails({ ...details, [key]: value });
	};

	const onTextFieldChange = (key: keyof IDetailsFormFields, value: string) => {
		setInvalidFields({ ...invalidFields, [key]: value.trim() === "" });
		onFieldChange(key, value);
	};

	const onDisciplinesChange = (value: string) => {
		const selectedDiscipline = Types.getDisciplineFromString(value);
		const newDisciplineList = updateMultiSelectList(
			details.disciplines,
			selectedDiscipline,
		) as Array<Types.Discipline>;
		setDetails({
			...details,
			disciplines: [...newDisciplineList],
		});
	};

	const onLevelsChange = (value: string) => {
		const selectedDifficulty = Types.getDifficultyFromString(value);
		const newLevelsList = updateMultiSelectList(details.levels, selectedDifficulty) as Array<Types.Difficulty>;
		setDetails({
			...details,
			levels: [...newLevelsList],
		});
	};

	const onPersontalTrainerChange = (value: string) => {
		const personalTrainer = personalTrainers.find(({ id }) => id === value);

		setDetails({
			...details,
			personalTrainerId: personalTrainer?.id || null,
			trainer: personalTrainer ? `${personalTrainer.name} ${personalTrainer.surname}` : null,
		});
	};

	const updateMultiSelectList = (list: Array<string>, value: string): Array<string> => {
		let newList = [...list];
		if (list.includes(value)) {
			newList = list.filter((elem) => elem !== value);
		} else {
			newList.push(value);
		}
		return newList;
	};

	const onMinAgeChange = (value: string) => {
		setMinAge(+value);
	};

	const onMaxAgeChange = (value: string) => {
		setMaxAge(+value);
	};

	// Synchronize min/max age local state with parent's state on blur
	const handleMinMaxAgeBlur = () => {
		if (minAge && maxAge) {
			setDetails({
				...details,
				ageRange: Helpers.getRange(minAge, maxAge),
			});
		}
	};

	const shouldDisplay = getShouldDisplayFunction(details.formType);

	return (
		<Form noValidate validated={validated} onSubmit={handleSubmit} className="detailsFrom">
			<Stack gap={3}>
				<Form.Group controlId="Types.DetailsType">
					<Form.Label>Type:</Form.Label>
					<Form.Select
						value={details.formType}
						required
						onChange={(e) => {
							onFormTypeChange(e.target.value as Types.DetailsType);
						}}
					>
						{formTypesNames.map((typeName) => (
							<option key={typeName.value} value={typeName.value}>
								{typeName.name}
							</option>
						))}
					</Form.Select>
				</Form.Group>

				{shouldDisplay(FormControlsId.NAME) && (
					<Form.Group controlId={FormControlsId.NAME}>
						<Form.Label>Name(NO):</Form.Label>
						<Form.Control
							onChange={(e) => {
								onTextFieldChange(TextFieldsNames.NAME, e.target.value);
							}}
							type="text"
							required
							value={details.name}
							isInvalid={invalidFields[TextFieldsNames.NAME]}
							placeholder="Short, but descriptive"
						/>
						<Form.Control.Feedback type="invalid">Please enter name.</Form.Control.Feedback>
					</Form.Group>
				)}

				{shouldDisplay(FormControlsId.NAME_EN) && (
					<Form.Group controlId={FormControlsId.NAME_EN}>
						<Form.Label>Name(EN):</Form.Label>
						<Form.Control
							onChange={(e) => {
								onTextFieldChange(TextFieldsNames.NAME_EN, e.target.value);
							}}
							type="text"
							required
							value={details.nameEn}
							isInvalid={invalidFields[TextFieldsNames.NAME_EN]}
							placeholder="Short, but descriptive"
						/>
						<Form.Control.Feedback type="invalid">Please enter name.</Form.Control.Feedback>
					</Form.Group>
				)}

				{shouldDisplay(FormControlsId.DESCRIPTION) && (
					<Form.Group controlId={FormControlsId.DESCRIPTION}>
						<Form.Label>Description(NO):</Form.Label>
						<Form.Control
							onChange={(e) => {
								setDetails({
									...details,
									description: (e.target as HTMLTextAreaElement).value,
								});
							}}
							as="textarea"
							required
							value={details.description}
							placeholder="Some description..."
							rows={3}
						/>
						<Form.Control.Feedback type="invalid">Please enter description.</Form.Control.Feedback>
					</Form.Group>
				)}

				{shouldDisplay(FormControlsId.DESCRIPTION_NO) && (
					<Form.Group controlId={FormControlsId.DESCRIPTION_NO}>
						<Form.Label>Description(EN):</Form.Label>
						<Form.Control
							onChange={(e) => {
								setDetails({
									...details,
									descriptionNo: (e.target as HTMLTextAreaElement).value,
								});
							}}
							as="textarea"
							required
							value={details.descriptionNo}
							placeholder="Some description..."
							rows={3}
						/>
						<Form.Control.Feedback type="invalid">Please enter description.</Form.Control.Feedback>
					</Form.Group>
				)}

				{shouldDisplay(FormControlsId.PRICE) && (
					<Form.Group controlId={FormControlsId.PRICE}>
						<Form.Label>Price (NOK)</Form.Label>
						<Form.Control
							onChange={(e) => {
								setDetails({
									...details,
									price: parseInt((e.target as HTMLInputElement).value, 10) || 0,
								});
							}}
							type="number"
							required
							value={details.price.toString()}
							placeholder="0"
							min="0"
						/>
						<Form.Control.Feedback type="invalid">Please enter price.</Form.Control.Feedback>
					</Form.Group>
				)}

				{shouldDisplay(FormControlsId.TRAINER) && (
					<Form.Group controlId={FormControlsId.TRAINER}>
						<Form.Label>Personal trainer:</Form.Label>
						<Form.Select
							value={details.personalTrainerId || ""}
							required
							onChange={(e) => onPersontalTrainerChange(e.target.value)}
						>
							<option>{""}</option>
							{personalTrainers &&
								personalTrainers.map(({ id, name, surname }) => (
									<option key={id} value={id}>
										{name} {surname}
									</option>
								))}
						</Form.Select>
						<Form.Control.Feedback type="invalid">Please select personal trainer</Form.Control.Feedback>
					</Form.Group>
				)}

				{shouldDisplay(FormControlsId.PRICE_CLASS) && (
					<Form.Group controlId={FormControlsId.PRICE_CLASS}>
						<Form.Label>Participant type:</Form.Label>
						<Form.Select
							value={details.priceClass || ""}
							required
							onChange={(e) => {
								setDetails({
									...details,
									priceClass: Types.getPriceClassFromString(e.target.value),
								});
							}}
						>
							<option>{""}</option>
							{Types.getAllPriceClasses().map((pc) => (
								<option key={pc} value={pc}>
									{pc}
								</option>
							))}
						</Form.Select>
					</Form.Group>
				)}

				{shouldDisplay(FormControlsId.MIN_AGE_RANGE) && shouldDisplay(FormControlsId.MAX_AGE_RANGE) && (
					<Row>
						<Form.Group as={Col} controlId={FormControlsId.MIN_AGE_RANGE}>
							<Form.Label>Min age:</Form.Label>
							<Form.Control
								onChange={(e) => {
									onMinAgeChange(e.target.value);
								}}
								onBlur={handleMinMaxAgeBlur}
								type="number"
								step="1"
								min={ageMinValue}
								max={ageMaxValue}
								value={minAge}
								isInvalid={(ageRangeRequired && minAge === undefined) || onlyOneAgeDefined}
								placeholder="3"
								required={ageRangeRequired}
							/>
							<Form.Control.Feedback type="invalid">Please enter valid age range.</Form.Control.Feedback>
						</Form.Group>
						<Form.Group as={Col} controlId={FormControlsId.MAX_AGE_RANGE}>
							<Form.Label>Max age:</Form.Label>
							<Form.Control
								onChange={(e) => {
									onMaxAgeChange(e.target.value);
								}}
								onBlur={handleMinMaxAgeBlur}
								type="number"
								step="1"
								min={ageMinValue}
								max={ageMaxValue}
								value={maxAge}
								isInvalid={
									(ageRangeRequired && maxAge === undefined) ||
									// Make sure max age is bigger than min age
									(maxAge && minAge && maxAge < minAge) ||
									onlyOneAgeDefined
								}
								placeholder="18"
								required={ageRangeRequired}
							/>
							<Form.Control.Feedback type="invalid">Please enter valid age range.</Form.Control.Feedback>
						</Form.Group>
					</Row>
				)}

				{shouldDisplay(FormControlsId.DISCIPLINE_SINGLE) && (
					<Form.Group controlId={FormControlsId.DISCIPLINE_SINGLE}>
						<Form.Label>Discipline:</Form.Label>
						<Form.Select
							value={details.disciplines[0]}
							required
							onChange={(e) => {
								setDetails({
									...details,
									disciplines: [Types.getDisciplineFromString(e.target.value)],
								});
							}}
						>
							<option>{""}</option>
							{Types.getAllDisciplines().map((dis) => (
								<option key={dis} value={dis}>
									{dis}
								</option>
							))}
						</Form.Select>
					</Form.Group>
				)}

				{shouldDisplay(FormControlsId.DISCIPLINE_MULTI) && (
					<Form.Group controlId={FormControlsId.DISCIPLINE_MULTI}>
						<Form.Label>Discipline:</Form.Label>
						<Form.Control
							as="select"
							value={details.disciplines}
							required
							onChange={(e) => {
								onDisciplinesChange(e.target.value);
							}}
							multiple
						>
							{Types.getAllDisciplines().map((dis) => (
								<option key={dis} value={dis} className="multiOption">
									{dis}
								</option>
							))}
						</Form.Control>
					</Form.Group>
				)}

				{shouldDisplay(FormControlsId.DIFFICULTY_SINGLE) && (
					<Form.Group controlId={FormControlsId.DIFFICULTY_SINGLE}>
						<Form.Label>Level:</Form.Label>
						<Form.Select
							value={details.levels[0]}
							required
							onChange={(e) => {
								setDetails({
									...details,
									levels: [Types.getDifficultyFromString(e.target.value)],
								});
							}}
						>
							<option>{""}</option>
							{Types.getAllDifficulties().map((diff) => (
								<option key={diff} value={diff}>
									{format(`level:${diff}`)}
								</option>
							))}
						</Form.Select>
					</Form.Group>
				)}

				{shouldDisplay(FormControlsId.DIFFICULTY_MULTI) && (
					<Form.Group controlId={FormControlsId.DIFFICULTY_MULTI}>
						<Form.Label>Level:</Form.Label>
						<Form.Control
							as="select"
							value={details.levels}
							required
							onChange={(e) => {
								onLevelsChange(e.target.value);
							}}
							multiple
						>
							{Types.getAllDifficulties().map((diff) => (
								<option key={diff} value={diff} className="multiOption">
									{format(`level:${diff}`)}
								</option>
							))}
						</Form.Control>
					</Form.Group>
				)}

				{(shouldDisplay(FormControlsId.MAX_NUM_PART) || shouldDisplay(FormControlsId.MIN_NUM_PART)) && (
					<Row>
						<h5>Number of participants</h5>
						<>
							{shouldDisplay(FormControlsId.MIN_NUM_PART) && (
								<Form.Group as={Col} controlId={FormControlsId.MIN_NUM_PART}>
									<Form.Label>Minimum</Form.Label>
									<Form.Control
										onChange={(e) => {
											setDetails({
												...details,
												minParticipants:
													parseInt((e.target as HTMLInputElement).value, 10) || 0,
											});
										}}
										type="number"
										required
										min="1"
										placeholder="0"
										value={details.minParticipants.toString()}
									/>
								</Form.Group>
							)}
							{shouldDisplay(FormControlsId.MAX_NUM_PART) && (
								<Form.Group as={Col} controlId={FormControlsId.MAX_NUM_PART}>
									<Form.Label>Maximum</Form.Label>
									<Form.Control
										onChange={(e) => {
											setDetails({
												...details,
												maxParticipants:
													parseInt((e.target as HTMLInputElement).value, 10) || 0,
											});
										}}
										type="number"
										required
										min={lowerParticipantsLimit}
										placeholder="0"
										value={details.maxParticipants.toString()}
									/>
								</Form.Group>
							)}
							{shouldDisplay(FormControlsId.AVAILABLE_SPOTS) && (
								<Form.Group as={Col} controlId={FormControlsId.AVAILABLE_SPOTS}>
									<Form.Label>Available spots</Form.Label>
									<Form.Control
										onChange={(e) => {
											setDetails({
												...details,
												availableSpots: parseInt((e.target as HTMLInputElement).value, 10) || 0,
											});
										}}
										type="number"
										placeholder="0"
										value={details.availableSpots.toString()}
									/>
								</Form.Group>
							)}
						</>
					</Row>
				)}

				<Form.Group controlId={FormControlsId.IS_HIDDEN}>
					<Form.Check
						type="checkbox"
						label="Hidden on website"
						checked={details.isHidden}
						onChange={(e) => onFieldChange("isHidden", e.target.checked)}
					/>
				</Form.Group>
			</Stack>

			<Button variant="primary" type="submit" className="mt-3">
				Next
			</Button>
		</Form>
	);
}

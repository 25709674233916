import { Types } from "@sno_oslo/shared-utils";
import * as React from "react";

interface IProps {
	editMode: boolean;
	reservation: Types.IReservation;
	updateReservation: (reservation: Types.IReservation) => void;
}

const ReservationDefaultDetails: React.FunctionComponent<IProps> = (props: IProps) => (
	<div className="reservation-details">
		{props.editMode ? (
			<div>
				<div className="row mb-2">
					<div className="col-md-4">
						<label htmlFor="participantName">Participant name:</label>
					</div>
					<div className="col-md-8">
						<input
							id="participantName"
							type="text"
							name="participantName"
							value={props.reservation.participantName || ""}
							onChange={(e) =>
								props.updateReservation({ ...props.reservation, participantName: e.target.value })
							}
							placeholder="Kari Nordmann"
							className="form-control"
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4">
						<label htmlFor="participantPhone">Participant name:</label>
					</div>
					<div className="col-md-8">
						<input
							id="participantPhone"
							type="text"
							name="participantPhone"
							value={props.reservation.participantPhone || ""}
							onChange={(e) =>
								props.updateReservation({ ...props.reservation, participantPhone: e.target.value })
							}
							placeholder="+47 123 45 678"
							className="form-control"
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4">
						<label htmlFor="participantAge">Participant age:</label>
					</div>
					<div className="col-md-8">
						<input
							id="participantAge"
							type="text"
							name="participantAge"
							value={props.reservation.participantAge || ""}
							onChange={(e) =>
								props.updateReservation({ ...props.reservation, participantAge: +e.target.value })
							}
							placeholder="18"
							className="form-control"
						/>
					</div>
				</div>
			</div>
		) : (
			<>
				<h5>Participant name: {props.reservation.participantName}</h5>
				<h5>Participant phone: {props.reservation.participantPhone}</h5>
				<h5>Participant age: {props.reservation.participantAge}</h5>
			</>
		)}
	</div>
);

export default ReservationDefaultDetails;

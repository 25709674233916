import React, { useEffect, useState } from "react";
import { Button, Form, Modal, ModalProps, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useFormat from "../../hooks/useFormat";

interface IProps extends ModalProps {}

const SearchGiftCardModal: React.FC<IProps> = ({ show, type, ...props }) => {
	const navigate = useNavigate();
	const format = useFormat();
	const [code, setCode] = useState("");
	const isDisabled = !code.trim();

	const handleSearch = () => {
		navigate((type === "archived" ? "archived/" : "") + encodeURIComponent(code));
	};

	useEffect(() => {
		setCode("");
	}, [show]);

	return (
		<Modal show={show} {...props}>
			<Modal.Header>
				<Modal.Title>
					{format(type === "archived" ? "giftCards:archived:search" : "giftCards:search")}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form.Group controlId="value">
					<Form.Label>{format(type === "archived" ? "common:id" : "common:code")}</Form.Label>
					<Form.Control autoFocus value={code} onChange={(e) => setCode(e.target.value)} />
				</Form.Group>

				<Stack direction="horizontal" gap={2} className="mt-3">
					<div className="flex-1" />

					<Button variant="primary" onClick={handleSearch} disabled={isDisabled}>
						{format("common:search")}
					</Button>
				</Stack>
			</Modal.Body>
		</Modal>
	);
};

export default SearchGiftCardModal;

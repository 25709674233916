import React, { useCallback, useState } from "react";
import { Helpers, Types } from "@sno_oslo/shared-utils";
import moment from "moment";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import useFormat from "../../hooks/useFormat";
import { SubmitHandler, useForm } from "react-hook-form";
import { formatDatetimeLocal } from "../../utils/dateUtils";
import useSnackbar from "../../hooks/useSnackbar";
import { updateGiftCardExpireTime, updateGiftCardAmountLeft, archiveGiftCard } from "../../controllers/gift-cards";
import { useNavigate } from "react-router";

interface IProps {
	giftCard: Types.IGiftCard | Types.IGiftCardArchive;
	onSaved: (giftCard: Types.IGiftCard) => void;
}

interface IFormValues {
	expireTime: string;
	amountLeft?: number;
}

const isArchived = (gift: Types.IGiftCard | Types.IGiftCardArchive): gift is Types.IGiftCardArchive => {
	return (gift as Types.IGiftCardArchive).archivedAt !== undefined;
};

const GiftCardForm: React.FC<IProps> = ({ giftCard, onSaved }) => {
	const navigate = useNavigate();
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const {
		register,
		handleSubmit,
		formState: { isSubmitting, errors },
	} = useForm<IFormValues>({
		defaultValues: giftCard
			? {
					expireTime: formatDatetimeLocal(new Date(giftCard.expireTime * 1000)),
					amountLeft: giftCard.amountLeft / 100,
			  }
			: {},
		mode: "onBlur",
	});
	const [isArchiving, setArchiving] = useState(false);
	const isActionDisabled = isArchiving || isSubmitting;

	const onSubmit = useCallback<SubmitHandler<IFormValues>>(
		async ({ expireTime, amountLeft }) => {
			try {
				await Promise.all([
					updateGiftCardAmountLeft(giftCard.code, amountLeft * 100),
					updateGiftCardExpireTime(giftCard.code, new Date(expireTime).toISOString()),
				]);
				addAlert(format("alerts:saved"), "success");
			} catch (err) {
				addAlert((err as Error).message || format("error:default"), "danger");
			}
		},
		[giftCard, onSaved, format, addAlert],
	);

	const onArchive = useCallback(async () => {
		setArchiving(true);

		try {
			await archiveGiftCard(giftCard.code);
			addAlert(format("alerts:archived"), "success");
			navigate("..");
		} catch (err) {
			addAlert((err as Error).message || format("error:default"), "danger");
			setArchiving(false);
		}
	}, [giftCard.code, format, addAlert]);

	return (
		<Form noValidate onSubmit={handleSubmit(onSubmit)}>
			<Stack gap={3}>
				<Row>
					<Form.Group as={Col}>
						<Form.Label>{format("common:id")}</Form.Label>
						<Form.Control value={giftCard.id} disabled />
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>{format("common:code")}</Form.Label>
						<Form.Control value={giftCard.code} disabled />
					</Form.Group>
				</Row>

				<Row>
					<Form.Group as={Col}>
						<Form.Label>{format("common:value")} (NOK)</Form.Label>
						<Form.Control value={Helpers.formatPrice(giftCard.value / 100)} disabled />
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>{format("common:amountLeft")} (NOK)</Form.Label>
						<Form.Control {...register("amountLeft", { required: true })} type="number" />
						{errors.amountLeft && (
							<Form.Control.Feedback type="invalid">
								{format(`validation:${errors.amountLeft.type}`, { field: format("common:amountLeft") })}
							</Form.Control.Feedback>
						)}
					</Form.Group>
				</Row>

				<Row>
					<Form.Group as={Col}>
						<Form.Label>{format("common:payerId")}</Form.Label>
						<Form.Control value={giftCard.payerId} disabled />
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>{format("common:ownerId")}</Form.Label>
						<Form.Control value={giftCard.ownerId || "-"} disabled />
					</Form.Group>
				</Row>

				<Row>
					<Form.Group as={Col}>
						<Form.Label>{format("common:purchasedAt")}</Form.Label>
						<Form.Control value={moment(giftCard.purchasedAt).format("YYYY-MM-DD LT")} disabled />
					</Form.Group>
					<Form.Group as={Col} controlId="expireTime">
						<Form.Label>{format("common:expiresAt")}</Form.Label>
						<Form.Control
							type="datetime-local"
							{...register("expireTime", { required: true })}
							isInvalid={!!errors.expireTime}
						/>
						{errors.expireTime && (
							<Form.Control.Feedback type="invalid">
								{format(`validation:${errors.expireTime.type}`, { field: format("common:expiresAt") })}
							</Form.Control.Feedback>
						)}
					</Form.Group>
				</Row>
			</Stack>

			{!isArchived(giftCard) && (
				<div className="mt-3 d-flex align-items-center justify-content-between">
					<Button type="submit" disabled={isActionDisabled}>
						{format(isSubmitting ? "common:loading" : "common:save")}
					</Button>

					<Button onClick={() => onArchive()} variant="danger" disabled={isActionDisabled}>
						{format(isSubmitting ? "common:loading" : "common:archive")}
					</Button>
				</div>
			)}
		</Form>
	);
};

export default GiftCardForm;

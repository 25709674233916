import { Types } from "@sno_oslo/shared-utils";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";
import VCTemplateForm from "./VCTemplateForm";

const AddVCTemplate: React.FC = () => {
		const format = useFormat();
		const { addAlert } = useSnackbar();
		const navigate = useNavigate();
	
	const handleSaved = useCallback(
		({ id }: Types.ITemplate) => {
			addAlert(format("alerts:saved"), "success");
			navigate(`/value-codes-templates/all`, { replace: true });
		},
		[format, addAlert, navigate],
	);

	return (
		<>
			<h1>{format("vct:add")}</h1>
			<div className="panel max-w-md mx-auto">
				<VCTemplateForm onSaved={handleSaved}/>
			</div>
		</>
	)
};

export default AddVCTemplate
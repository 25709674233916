import { Types } from "@sno_oslo/shared-utils";
import * as React from "react";
import { Alert, Button, Modal } from "react-bootstrap";

import LoaderButton from "../../../../common/loader-button";
import { IReservationDto, updateReservation } from "../../../../../controllers/lessons/reservations";
import CampReservationDetails from "./campReservationDetails";
import ReservationDefaultDetails from "./reservationDefaultDetails";

interface IProps {
	closeModal: () => void;
	reservation: IReservationDto;
	show: boolean;
	updateReservation: (reservation: IReservationDto) => void;
}

const ReservationDetailsModal: React.FunctionComponent<IProps> = (props: IProps) => {
	const [editMode, setEditMode] = React.useState<boolean>(false);
	const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
	const [isSaving, setIsSaving] = React.useState<boolean>(false);
	const [reservation, setReservation] = React.useState<Types.IReservation>(props.reservation);
	const [originalReservation, setOriginalReservation] = React.useState<Types.IReservation>(props.reservation);

	const saveReservation = async () => {
		setIsSaving(true);
		setErrorMessage(null);

		try {
			await updateReservation(reservation);
			props.updateReservation({
				...props.reservation,
				...reservation,
			});
			setOriginalReservation(reservation);
			setEditMode(false);
		} catch (error) {
			setErrorMessage(error.message);
		} finally {
			setIsSaving(false);
		}
	};

	const resetReservation = () => {
		setReservation(originalReservation);
		setEditMode(false);
	};

	return (
		<Modal
			show={props.show}
			onHide={() => {
				resetReservation();
				props.closeModal();
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title>Reservation details</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{!!errorMessage && (
					<Alert variant="danger">
						An error occurred 😱
						<br />
						<p>{errorMessage}</p>
					</Alert>
				)}
				{props.reservation.formType === Types.DetailsType.CAMP ? (
					<CampReservationDetails
						editMode={editMode}
						reservation={reservation}
						updateReservation={setReservation}
					/>
				) : (
					<ReservationDefaultDetails
						editMode={editMode}
						reservation={reservation}
						updateReservation={setReservation}
					/>
				)}
			</Modal.Body>
			<Modal.Footer>
				{editMode && (
					<LoaderButton
						isLoading={isSaving}
						loadingLabel="Saving..."
						onClick={() => saveReservation()}
						variant="success"
					>
						Save
					</LoaderButton>
				)}
				{editMode ? (
					<Button variant="primary" onClick={() => resetReservation()}>
						Cancel
					</Button>
				) : (
					<Button variant="primary" onClick={() => setEditMode(true)}>
						Edit
					</Button>
				)}

				<Button
					variant="secondary"
					onClick={() => {
						resetReservation();
						props.closeModal();
					}}
				>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ReservationDetailsModal;

import React, { useCallback } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";

import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";
import { archiveAccessPassByQrCode } from "../../controllers/access-passes";

interface IProps {
	onCancel: () => void;
	onDeleted: () => void;
}

interface IFormValues {
	qrCode: number;
}

const PassDeleteForm: React.FC<IProps> = ({ onCancel, onDeleted }) => {
	const format = useFormat();
	const { addAlert } = useSnackbar();

	const {
		register,
		handleSubmit,
		formState: { isSubmitting, errors },
	} = useForm<IFormValues>({
		defaultValues: {},
	});

	const onSubmit = useCallback<SubmitHandler<IFormValues>>(
		async ({ qrCode }) => {
			try {
				await archiveAccessPassByQrCode(qrCode);

				addAlert(format("alerts:deleted"), "success");
				onDeleted();
			} catch (err) {
				addAlert((err as Error).message || format("error:default"), "danger");
			}
		},
		[onDeleted, format, addAlert],
	);

	return (
		<Form noValidate onSubmit={handleSubmit(onSubmit)}>
			<Stack gap={3}>
				<Form.Group>
					<Form.Label>{format("common:qrCode")}</Form.Label>
					<Form.Control
						isInvalid={!!errors.qrCode}
						size="lg"
						type="number"
						{...register("qrCode", { required: true, valueAsNumber: true })}
					/>
					{errors.qrCode && (
						<Form.Control.Feedback type="invalid">
							{format(`validation:${errors.qrCode.type}`, { field: format("common:qrCode") })}
						</Form.Control.Feedback>
					)}
				</Form.Group>

				<Stack direction="horizontal" gap={2} className="justify-content-end mt-1">
					<Button variant="secondary" onClick={() => onCancel()}>
						{format("common:cancel")}
					</Button>
					<Button type="submit" variant="danger" disabled={isSubmitting}>
						{format("common:delete")}
					</Button>
				</Stack>
			</Stack>
		</Form>
	);
};

export default PassDeleteForm;

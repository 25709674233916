import * as React from "react";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import LoaderButton from "../../common/loader-button";
import {
	duplicateGroupCourse,
	getReservationsExport,
	IGroupCourseDto,
} from "../../../controllers/lessons/groupCourses";
import { IReservationDto } from "../../../controllers/lessons/reservations";
import { formatDate } from "./dateTimeFormats";
import DeleteCourseModal from "./deleteCourseModal";
import DeleteReservationsModal from "./reservations/deleteReservationsModal";
import MoveReservationsModal from "./reservations/moveReservationsModal";
import ReservationList from "./reservations/reservationList";
import useFormat from "../../../hooks/useFormat";
import ConfirmModal from "../../common/ConfirmModal";
import { OSLO_TIMEZONE } from "../../../constants/timezone.constant";

enum Modals {
	MOVE_RESERVATIONS = 0,
	DELETE_RESERVATIONS = 1,
	DELETE_COURSE = 2,
}

enum Toggleables {
	SCHEDULE = 0,
	RESERVATIONS = 1,
}

interface IProps {
	archived?: boolean;
	course: IGroupCourseDto;
	refreshCourses: () => void;
	unfolded: boolean;
	updateReservations: (reservations: Array<IReservationDto>) => void;
}

export default function GroupCourseDetails(props: IProps) {
	const course = props.course;
	const format = useFormat();
	const navigate = useNavigate();

	const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
	const [showDuplicationModal, setShowDuplicationModal] = React.useState<boolean>(false);
	const [selectedReservations, setSelectedReservations] = React.useState<Array<string>>([]);
	const [modalShown, setModalShown] = React.useState<Modals>(null);
	const [toggleablesShown, setToggleablesShown] = React.useState<Array<Toggleables>>(
		props.unfolded ? [Toggleables.SCHEDULE, Toggleables.RESERVATIONS] : [],
	);
	const [isDeleted, setIsDeleted] = React.useState<boolean>(false);
	const [isExporting, setIsExporting] = React.useState<boolean>(false);
	const [exportDownloadUrl, setExportDownloadUrl] = React.useState<string | null>(null);
	const exportDownloadAnchor = React.useRef(null);

	React.useEffect(() => {
		if (!exportDownloadUrl) {
			return;
		}

		exportDownloadAnchor.current.click();
		URL.revokeObjectURL(exportDownloadUrl);
		setExportDownloadUrl(null);
	}, [exportDownloadUrl]);

	const toggleSelectedReservations = (reservationCodes: Array<string>, select: boolean) => {
		if (select) {
			setSelectedReservations(Array.from(new Set([...selectedReservations, ...reservationCodes])));
		} else {
			setSelectedReservations(selectedReservations.filter((code) => !reservationCodes.includes(code)));
		}
	};

	const isModal = (modal: Modals): boolean => modalShown === modal;

	const isToggled = (togglable: Toggleables): boolean => toggleablesShown.includes(togglable);

	const onDeletionSuccessful = async () => {
		setModalShown(null);
		setIsDeleted(true);
	};

	const duplicateCourse = async () => {
		setIsSubmitting(true);

		try {
			const gc = await duplicateGroupCourse(props.course.id);
			setShowDuplicationModal(false);
			props.refreshCourses();
			setIsSubmitting(false);
			navigate(`../edit_gc/${gc.id}`);
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	};

	const generateReservationsExport = async () => {
		setIsExporting(true);

		try {
			const reservationsData = await getReservationsExport(props.course.id, !!props.course.archivedAt);
			const exportBlob = new Blob([reservationsData]);
			setExportDownloadUrl(URL.createObjectURL(exportBlob));
		} finally {
			setIsExporting(false);
		}
	};

	if (isDeleted) {
		return (
			<div className="alert alert-success" role="alert">
				Course deleted successfully !
			</div>
		);
	}

	const spotsTaken = course.maxParticipants - course.availableSpots;
	return (
		<div className="singleGroupCourse">
			<div className="header">
				<div>
					{course.startDates && <>Upcoming: {formatDate(course.startDates[0], OSLO_TIMEZONE)}</>}
					<span className="formType">{course.formType}</span>
				</div>
				{!course.archivedAt && (
					<div className="deleteLink" onClick={() => setModalShown(Modals.DELETE_COURSE)}>
						Delete
					</div>
				)}
			</div>
			<div className="details">
				<div>
					<Link to={`/lessons/group-courses/${course.id}?archived=${!!course.archivedAt}`}>
						<h3>{course.name}</h3>
					</Link>
					{course.trainer && (
						<p className="detailsRow">
							<span className="label">Trainer:</span>
							<span className="value">{course.trainer}</span>
						</p>
					)}
					<p className="detailsRow">
						<span className="label">Participants:</span>
						<span className="value">
							{course.minParticipants} - {course.maxParticipants}
						</span>
					</p>
					<p className="detailsRow">
						<span className="label">Spots taken:</span>
						<span className="value colored">
							{spotsTaken}/{course.maxParticipants}
						</span>
					</p>
				</div>
				<div>
					<p className="detailsRow">
						<span className="label">Price:</span>
						<span className="value">{course.price / 100} NOK</span>
					</p>
					<div className="detailsRow">
						<span className="label">Levels:</span>
						<span className="value">
							{course.levels?.map((level, i) => (
								<div className="multiParam" key={i}>
									{format(`level:${level}`)}
								</div>
							))}
						</span>
					</div>
					<p className="detailsRow">
						<span className="label">Group:</span>
						<span className="value">{course.priceClass}</span>
					</p>
					{course.ageRange && (
						<p className="detailsRow">
							<span className="label">Age:</span>
							<span className="value">
								{course.ageRange.length === 1
									? course.ageRange[0]
									: `${course.ageRange[0]}-${course.ageRange[course.ageRange.length - 1]}`}
							</span>
						</p>
					)}
					<div className="detailsRow">
						<span className="label">Disciplines:</span>
						<span className="value">
							{course.disciplines?.map((discipline, i) => (
								<div className="multiParam" key={i}>
									{discipline}
								</div>
							))}
						</span>
					</div>
				</div>
				<div>
					<p className="descRow">
						<span className="label">Description:</span>
						<span className="value">{course.description}</span>
					</p>
				</div>
			</div>
			<div className="actions">
				<div>
					<Button
						variant="secondary"
						onClick={() =>
							setToggleablesShown(
								isToggled(Toggleables.SCHEDULE)
									? toggleablesShown.filter((togglable) => togglable !== Toggleables.SCHEDULE)
									: [...toggleablesShown, Toggleables.SCHEDULE],
							)
						}
					>
						{isToggled(Toggleables.SCHEDULE) ? "[ X ]Hide schedule" : "Full schedule"}
					</Button>

					{course.reservations && course.reservations.length > 0 ? (
						<>
							<Button
								variant="primary"
								onClick={() =>
									setToggleablesShown(
										isToggled(Toggleables.RESERVATIONS)
											? toggleablesShown.filter(
													(togglable) => togglable !== Toggleables.RESERVATIONS,
													// eslint-disable-next-line no-mixed-spaces-and-tabs
											  )
											: [...toggleablesShown, Toggleables.RESERVATIONS],
									)
								}
							>
								{isToggled(Toggleables.RESERVATIONS) ? "[ X ]Hide reservations" : "See reservations"}
							</Button>
						</>
					) : (
						<span>No reservations</span>
					)}

					<Link to={`../edit_gc/${course.id}?archived=${!!course.archivedAt}`}>
						<Button variant="success">Edit course</Button>
					</Link>

					<Button variant="warning" onClick={() => setShowDuplicationModal(true)} disabled={isSubmitting}>
						Duplicate
					</Button>

					{course.reservations?.length && (
						<>
							<LoaderButton
								isLoading={isExporting}
								onClick={generateReservationsExport}
								loadingLabel="Generating..."
								variant="info"
							>
								Download reservations ⬇️
							</LoaderButton>
							<a
								style={{ display: "none" }}
								download={`${props.course.name}-${formatDate(
									props.course.startDates[0],
									OSLO_TIMEZONE,
								)}.csv`}
								href={exportDownloadUrl}
								ref={exportDownloadAnchor}
							>
								Hidden export download link
							</a>
						</>
					)}
				</div>

				{isToggled(Toggleables.RESERVATIONS) && (
					<DropdownButton
						id="dropdown-variants-warning"
						variant="warning"
						title="Actions"
						size="sm"
						disabled={!selectedReservations.length}
					>
						<Dropdown.Item onClick={() => setModalShown(Modals.MOVE_RESERVATIONS)}>Move</Dropdown.Item>
						<Dropdown.Divider />
						<Dropdown.Item onClick={() => setModalShown(Modals.DELETE_RESERVATIONS)}>Delete</Dropdown.Item>
					</DropdownButton>
				)}
			</div>
			<div className="togglableContent">
				{isToggled(Toggleables.SCHEDULE) && (
					<div className="fullSchedule">
						{course.startDates?.map((date, i) => (
							<div key={date} className="oneDate">
								{formatDate(date, OSLO_TIMEZONE)}
								{course.endDates ? ` - ${formatDate(course.endDates[i], OSLO_TIMEZONE)}` : ""}
							</div>
						))}
					</div>
				)}
				{isToggled(Toggleables.RESERVATIONS) && (
					<ReservationList
						reservations={course.reservations}
						selectedReservations={selectedReservations}
						toggleSelectedReservations={toggleSelectedReservations}
						updateReservations={props.updateReservations}
					/>
				)}
			</div>
			{isModal(Modals.MOVE_RESERVATIONS) && (
				<MoveReservationsModal
					closeModal={() => setModalShown(null)}
					courseId={course.id}
					refreshCourses={props.refreshCourses}
					reservations={selectedReservations}
				/>
			)}
			{isModal(Modals.DELETE_COURSE) && (
				<DeleteCourseModal
					course={course}
					closeModal={() => setModalShown(null)}
					onMoveReservationsSelected={() => {
						setModalShown(null);
						setToggleablesShown(Array.from(new Set([...toggleablesShown, Toggleables.RESERVATIONS])));
					}}
					onDeletionSuccessful={onDeletionSuccessful}
				/>
			)}
			{isModal(Modals.DELETE_RESERVATIONS) && (
				<DeleteReservationsModal
					closeModal={() => setModalShown(null)}
					refreshCourses={props.refreshCourses}
					reservations={selectedReservations}
					courseId={course.id}
				/>
			)}
			<ConfirmModal
				show={showDuplicationModal}
				onHide={() => setShowDuplicationModal(false)}
				onConfirm={duplicateCourse}
				isLoading={isSubmitting}
			/>
		</div>
	);
}

/**
 * Util for making fetch request without dealing with the fetch api
 * and base urls.
 * Handles API keys, selects the correct environment and so on
 * Can only make requests into the admin api
 */

import * as Emitter from "./eventEmitter";
import { getLoggedUserToken } from "./storage";

// Options for the «performRequest» method
export interface IRequestOptions {
	method: string; // GET, POST, DELETE, PUT, PATCH, ..
	path: string; // The patch to the service
	body?: any; // A js object to pass as JSON
	headers?: Record<string, unknown>;
	blob?: boolean;
}

// Internal interface
interface IFetchRequestOptions {
	method: string;
	credentials: any;
	mode?: any;
	headers: any;
	body?: any;
}

/**
 * Perform a request to an Admin API endpoint
 * Pass a method, path and an optional body object
 * All environments, api keys and auth headers are
 * handled automatically
 */
export const performRequest = async (options: IRequestOptions) => {
	if (!options || !options.path || !options.method) {
		throw new Error("Invalid request input. «path» and «method» is required");
	}

	// Initial setup
	const requestOptions: IFetchRequestOptions = {
		method: options.method.toUpperCase(),
		credentials: "omit", // Don't pass any cookies (will f up cors if set to include)
		mode: "cors", // CORS mode since this client is on a separate domain from the api
		headers: {
			...options.headers,
			// Inject the required API key from env vars
			"x-api-key": process.env.REACT_APP_API_KEY,
		},
	};

	// If a token is available, inject it into headers
	const token = getLoggedUserToken();
	if (token) {
		requestOptions.headers.authorization = `Bearer ${token}`;
	}

	const isFormData = options.body instanceof FormData;

	// Set a string body for non GET methods
	if (options.body && requestOptions.method !== "GET") {
		requestOptions.body = isFormData ? options.body : JSON.stringify(options.body || {});
	}

	// Set a json content type for non GET requests
	if (options.method.toUpperCase() !== "GET" && !isFormData) {
		requestOptions.headers["content-type"] = "application/json";
	}

	// if (options.blob) {
	// 	requestOptions.headers["content-type"] = "text/csv";
	// }

	// Create the full url
	const url = `${process.env.REACT_APP_API_URL}${options.path}`;
	// const url = `http://localhost:3000/development${options.path}`;

	// Make the request
	const response = await fetch(url, requestOptions);

	if (response.ok) {
		// Success with no content, return
		if (response.status === 204) {
			return;
		}

		if (options.blob) {
			return await response.blob();
		}
		// Success, return the data
		return await response.json();
	}

	if (response.status === 403 || response.status === 401) {
		Emitter.publish(Emitter.EVENT.DELETE_USER, "Please log in again");
	}

	// Well..💩
	// Parse the error body and return it directly
	throw await response.json();
};

import * as React from "react";
import { Card, ListGroup, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

import ArrowRight from "../common/arrow";

interface IProps {
	email: string;
	name: string;
}

const GuestItem: React.FC<IProps> = ({ email, name }) => (
	<Link to={`/customers/guest?email=${email}&name=${name}`}>
		<ListGroup.Item action className="mt-3 rounded-2 position-relative">
			<Card className="item-card">
				<Card.Body>
					<Badge bg="primary" className="position-absolute top-0 end-0 m-2">
						Guest
					</Badge>
					<Card.Title>{name === email ? "No name" : name}</Card.Title>
					<Card.Subtitle>{email}</Card.Subtitle>
				</Card.Body>
			</Card>
			<ArrowRight />
		</ListGroup.Item>
	</Link>
);

export default GuestItem;

import { FC, useCallback, useMemo, useState } from "react";
import { Button, Card, ListGroup, ListGroupItem, Modal } from "react-bootstrap";
import moment from "moment";
import type stripe from "stripe";
// import { Types } from "@sno_oslo/shared-utils";
// import { IFullOrderModel } from "@sno_oslo/node-utils/dist/cartOrder/dynamodb-schemas";

import ArrowRight from "../../common/arrow";
import type { IStripeMembership } from "../../../types/memberships";
import { cancelStripeSubscription } from "../../../controllers/memberships";
import useFormat from "../../../hooks/useFormat";
import useSnackbar from "../../../hooks/useSnackbar";
import StripeInvoiceItem from "./StripeInvoiceItem";
import { PAYMENT_VENDOR_COLORS } from "../../../utils/colorUtils";
import { Types } from "@sno_oslo/shared-utils";
import MembershipActions from "../MembershipActions";

interface IStripeMembershipItemProps {
	membership: IStripeMembership;
	onUpdate: (membership: IStripeMembership) => void;
	onOpenOrderDetails: () => void;
	passes: (Types.IAccessProduct | Types.IAccessProductArchive)[];
	onUpdatePass: (pass: Types.IAccessProduct) => void;
	onFocusAccessPass: (qrCode: number) => void;
}

const subscriptionStatusToColorMap: Record<stripe.Subscription.Status, string> = {
	active: "#008000",
	canceled: "#c83e4d",
	incomplete: "#dbe3e8",
	incomplete_expired: "#dbe3e8",
	past_due: "#ffd23f",
	paused: "#ffd23f",
	trialing: "#008000",
	unpaid: "#c83e4d",
};

const StripeMembershipItem: FC<IStripeMembershipItemProps> = ({
	membership: { details, invoices },
	onUpdate,
	onOpenOrderDetails,
	passes,
	onUpdatePass,
	onFocusAccessPass,
}) => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const [showModal, setShowModal] = useState(false);
	const [action, setAction] = useState<"cancel-subscription" | "cancel-passes">(null);
	const activePass = useMemo(
		() =>
			passes.find(
				(pass) =>
					!(pass as Types.IAccessProductArchive).archivedAt &&
					(!pass.expireTime || pass.expireTime * 1000 > Date.now()),
			),
		[passes],
	);

	const subtotalAmount = useMemo(
		() => details.items.data.reduce((total, item) => total + item.plan.amount, 0),
		[details.items.data],
	);
	const discountAmount = details.discount?.coupon?.amount_off;
	const totalAmount = subtotalAmount - (discountAmount || 0);

	const cancelSubscription = useCallback(async () => {
		setAction("cancel-subscription");

		try {
			const cancelledSubscription = await cancelStripeSubscription(details.id);
			onUpdate({
				type: "stripe",
				details: cancelledSubscription,
				invoices,
			});
		} catch (err) {
			addAlert((err as Error).message || format("error:default"), "danger");
		} finally {
			setAction(null);
		}
	}, [details, invoices, onUpdate, format, addAlert]);

	// const cancelPasses = useCallback(async () => {
	// 	setAction("cancel-passes");
	// 	try {
	// 		await cancelStripeSubscriptionPasses(details.id);
	// 		setPassesCancelled(true);
	// 	} catch (err) {
	// 		addAlert((err as Error).message || format("error:default"), "danger");
	// 	} finally {
	// 		setAction(null);
	// 	}
	// }, [details.id, format, addAlert]);

	const cancelProduct = useCallback(async () => {
		if (details.status !== "canceled") {
			await cancelSubscription();
		}

		// if (!arePassesCancelled) {
		// 	cancelPasses();
		// }
	}, [
		details.status,
		// arePassesCancelled,
		cancelSubscription,
		// cancelPasses
	]);

	const handleInvoiceRefund = (invoice: stripe.Invoice) => {
		onUpdate({
			type: "stripe",
			details,
			invoices: invoices.map((i) =>
				// @ts-ignore
				i.id === invoice.id ? { ...invoice, payment_intent: { ...invoice.payment_intent } } : i,
			),
		});
	};

	return (
		<>
			<ListGroupItem onClick={() => setShowModal(true)} className="item-wrapper">
				<Card className="item-card" role="button">
					<Card.Body>
						<Card.Title>{details.id}</Card.Title>
						<Card.Subtitle className="mb-2 text-muted">
							Started: {moment(details.created * 1000).format("DD MMMM YYYY")}
						</Card.Subtitle>
						<Card.Subtitle
							className="mb-2 fw-bold"
							style={{
								color: subscriptionStatusToColorMap[details.status],
							}}
						>
							Status: {details.status.toUpperCase()}
						</Card.Subtitle>

						<ListGroup as="ol" numbered className="mb-3">
							{details.items.data.map((item, i) => (
								<ListGroup.Item key={item.id} as="li" className="d-flex">
									<div className="ms-2 me-auto">
										<div className="fw-bold">{item.plan.nickname}</div>
										<p>Plan: {item.plan.id}</p>
										<p>Amount: {item.plan.amount / 100} NOK</p>
									</div>
								</ListGroup.Item>
							))}
						</ListGroup>

						<Card.Subtitle className="mb-2">Subtotal amount: {subtotalAmount / 100} NOK</Card.Subtitle>

						{!!discountAmount && (
							<>
								<Card.Subtitle className="mb-2">Discount: {discountAmount / 100} NOK</Card.Subtitle>
								<Card.Subtitle className="mb-2">
									Total amount: {(subtotalAmount - discountAmount) / 100} NOK
								</Card.Subtitle>
							</>
						)}

						<Card.Subtitle className="mb-2 text-muted">
							Payment vendor:{" "}
							<span
								style={{
									color: "white",
									backgroundColor: PAYMENT_VENDOR_COLORS["stripe"],
									padding: "0.25rem",
									borderRadius: 7,
								}}
							>
								Stripe
							</span>
						</Card.Subtitle>
					</Card.Body>
				</Card>
				<div className="ml-3">
					<ArrowRight />
				</div>
			</ListGroupItem>

			<Modal show={showModal} onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						Subscription:{" "}
						<a
							target="_blank"
							href={`https://dashboard.stripe.com/subscriptions/${details.id}`}
							rel="noreferrer"
						>
							{details.id}
						</a>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className="fw-bold mb-1">Plans:</p>
					<ListGroup as="ol" numbered className="mb-3">
						{details.items.data.map((item, i) => (
							<ListGroup.Item key={item.id} as="li" className="d-flex">
								<div className="ms-2 me-auto">
									<div className="fw-bold">{item.plan.id}</div>
									<p className="mb-0">Nickname: {item.plan.nickname}</p>
									<p className="mb-0">Amount: {item.plan.amount / 100} NOK</p>
								</div>
							</ListGroup.Item>
						))}
					</ListGroup>

					<p className="fw-bold mb-1">Invoices:</p>
					<ListGroup as="ol" numbered>
						{invoices
							.slice()
							.reverse()
							.map((invoice, i) => (
								<ListGroup.Item key={invoice.id} as="li" className="d-flex">
									<StripeInvoiceItem
										// order={order}
										index={i}
										invoice={invoice}
										onRefunded={() => handleInvoiceRefund(invoice)}
										onCancelProduct={details.status !== "canceled" ? cancelProduct : undefined}
										onOpenOrderDetails={onOpenOrderDetails}
									/>
								</ListGroup.Item>
							))}
					</ListGroup>

					{passes.length > 0 && (
						<>
							<p className="fw-bold mt-3 mb-1">Access passes:</p>
							<ListGroup as="ol" numbered>
								{passes.map((pass) => (
									<ListGroup.Item key={pass.qrCode} as="li" className="d-flex">
										<div className="ms-2 me-auto">
											<div className="fw-bold">{pass.qrCode}</div>
											{pass.expireTime && (
												<p className="mb-0">
													Expires: {new Date(pass.expireTime * 1000).toLocaleString("no")}
												</p>
											)}
											{(pass as Types.IAccessProductArchive).archivedAt && (
												<p className="mb-0">
													Archived at:{" "}
													{new Date(
														(pass as Types.IAccessProductArchive).archivedAt,
													).toLocaleString("no")}
												</p>
											)}
											<Button
												onClick={() => onFocusAccessPass(pass.qrCode)}
												size="sm"
												className="mt-1 mb-1"
											>
												Show more
											</Button>
										</div>
									</ListGroup.Item>
								))}
							</ListGroup>
						</>
					)}

					{activePass && <MembershipActions pass={activePass} onUpdate={onUpdatePass} />}

					<Button
						onClick={() => cancelSubscription()}
						disabled={details.status === "canceled" || action === "cancel-subscription"}
						variant="danger"
						className="mt-3 w-full"
					>
						{details.status === "canceled" ? "Subscription already cancelled" : "Cancel subscription"}
					</Button>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default StripeMembershipItem;

import { Types } from "@sno_oslo/shared-utils";

export const formTypesNames = [
	{ value: Types.DetailsType.GROUP_COURSE, name: "Group course" },
	{ value: Types.DetailsType.CAMP, name: "Camp" },
];

export enum FormControlsId {
	MIN_AGE_RANGE = "formAgeRangeMin",
	MAX_AGE_RANGE = "formAgeRangeMax",
	DESCRIPTION = "formDescription",
	DESCRIPTION_NO = "formDescriptionNo",
	DIFFICULTY_MULTI = "formDifficultyMulti",
	DIFFICULTY_SINGLE = "formDifficultySingle",
	DISCIPLINE_MULTI = "formDisciplineMulti",
	DISCIPLINE_SINGLE = "formDisciplineSingle",
	MAX_NUM_PART = "formMaxPart",
	MIN_NUM_PART = "formMinPart",
	NAME = "formName",
	NAME_EN = "formNameEn",
	PRICE = "formPrice",
	PRICE_CLASS = "formPriceClass",
	TRAINER = "formTrainer",
	IS_HIDDEN = "isHidden",
	AVAILABLE_SPOTS = "currentParticipants",
}

const formTypesConfig = {
	[Types.DetailsType.GROUP_COURSE]: [
		FormControlsId.NAME,
		FormControlsId.NAME_EN,
		FormControlsId.DESCRIPTION,
		FormControlsId.DESCRIPTION_NO,
		FormControlsId.PRICE,
		FormControlsId.TRAINER,
		FormControlsId.PRICE_CLASS,
		FormControlsId.MIN_AGE_RANGE,
		FormControlsId.MAX_AGE_RANGE,
		FormControlsId.DISCIPLINE_SINGLE,
		FormControlsId.DIFFICULTY_SINGLE,
		FormControlsId.MIN_NUM_PART,
		FormControlsId.MAX_NUM_PART,
		FormControlsId.AVAILABLE_SPOTS,
	],
	[Types.DetailsType.CAMP]: [
		FormControlsId.NAME,
		FormControlsId.NAME_EN,
		FormControlsId.DESCRIPTION,
		FormControlsId.DESCRIPTION_NO,
		FormControlsId.PRICE,
		FormControlsId.PRICE_CLASS,
		FormControlsId.MIN_AGE_RANGE,
		FormControlsId.MAX_AGE_RANGE,
		FormControlsId.DISCIPLINE_MULTI,
		FormControlsId.DIFFICULTY_MULTI,
		FormControlsId.MIN_NUM_PART,
		FormControlsId.MAX_NUM_PART,
		FormControlsId.AVAILABLE_SPOTS,
	],
};

export const getShouldDisplayFunction = (formType: Types.DetailsType) => {
	const config = formTypesConfig[formType]
		? formTypesConfig[formType]
		: formTypesConfig[Types.DetailsType.GROUP_COURSE];

	return (controlId: FormControlsId): boolean => config.includes(controlId);
};

import { Types } from "@sno_oslo/shared-utils";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

import FetchErrorAlert from "../common/FetchErrorAlert";
import Loader from "../common/loader";
import { listAllAdminUsers } from "../../controllers/admin-users";
import useFormat from "../../hooks/useFormat";
import AdminUsersListItem from "./AdminUsersListItem";

interface IProps {
	loggedUserId: string;
}

const AdminUsersList: React.FC<IProps> = ({ loggedUserId }) => {
	const format = useFormat();
	const [users, setUsers] = useState<Array<Types.IAdminUser>>();
	const loggedUser = useMemo(() => users?.find(({ uid }) => uid === loggedUserId), [users, loggedUserId]);
	const [isLoading, setIsLoading] = useState(false);
	const [fetchError, setFetchError] = useState<Error | null>(null);

	const fetchUsers = useCallback(async () => {
		try {
			setFetchError(null);
			setIsLoading(true);

			setUsers(await listAllAdminUsers());
		} catch (err) {
			setFetchError(err as Error);
		} finally {
			setIsLoading(false);
		}
	}, []);

	const handleDeleted = useCallback(
		(uid: string) => {
			setUsers(users!.filter((user) => user.uid !== uid));
		},
		[users],
	);

	useEffect(() => {
		fetchUsers();
	}, [fetchUsers]);

	return (
		<Container>
			<div className="admin-users">
				<h2>{format("users:list:title")}</h2>
				{loggedUser && <p>{loggedUser.email}</p>}
				{users ? (
					<ListGroup>
						<ListGroup.Item as={Link} key="create" to="create" variant="info" action>
							{format("users:list:add")}
						</ListGroup.Item>

						{users.map((user) => (
							<AdminUsersListItem
								key={user.uid}
								user={user}
								isLoggedUser={user.uid === loggedUserId}
								onDeleted={handleDeleted}
							/>
						))}
					</ListGroup>
				) : (
					fetchError && <FetchErrorAlert message={fetchError.message} onRetry={fetchUsers} />
				)}
				{isLoading && <Loader loadingText={format("common:loading")} />}
			</div>
		</Container>
	);
};

export default AdminUsersList;

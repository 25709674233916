import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";
import { createTemplate, getProducts, updateTemplate } from "../../controllers/value-codes";

import { Types } from "@sno_oslo/shared-utils";
import { useForm } from "react-hook-form";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";

interface IProps {
	template?: Types.ITemplate;
	onSaved: (template: Types.ITemplate) => void;
	update?: boolean;
}

const VCTemplateForm: React.FC<IProps> = ({ template, onSaved, update }) => {
	const [products, setProducts] = useState([]);
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const {
		register,
		handleSubmit,
		formState: { isSubmitting, errors },
		reset,
	} = useForm({
		defaultValues: {
			...(template || {}),
		},
		mode: "onBlur",
	});

	useEffect(() => {
		const fetchProducts = async () => {
			const products = await getProducts();
			const _filteredProducts = products.filter(
				(el) =>
					el.zones ||
					// @ts-ignore
					el.zone,
			);
			setProducts(_filteredProducts);
		};
		fetchProducts();
	}, []);

	const onSubmit = useCallback(
		async (template: Types.ITemplate) => {
			try {
				const savedTemplate = update ? await updateTemplate(template) : await createTemplate(template);

				onSaved(savedTemplate);
			} catch (err) {
				addAlert((err as Error).message || format("error:default"), "danger");
			}
		},
		[template?.id, reset, onSaved, format],
	);

	return (
		<Form noValidate onSubmit={handleSubmit(onSubmit)}>
			<Stack gap={3}>
				<Row>
					<Form.Group as={Col} controlId="name">
						<Form.Label>{format("common:name")}</Form.Label>
						<Form.Control {...register("name", { required: true })} isInvalid={!!errors.name} />
						{errors.name?.type === "required" && (
							<Form.Control.Feedback type="invalid">
								{format("validation:required", { field: format("common:name") })}
							</Form.Control.Feedback>
						)}
					</Form.Group>

					<Form.Group as={Col} controlId="customer">
						<Form.Label>{format("common:customer")}</Form.Label>
						<Form.Control
							{...register("customer", { required: true })}
							type="string"
							isInvalid={!!errors.customer}
						/>
						{errors.customer?.type === "required" && (
							<Form.Control.Feedback type="invalid">
								{format("validation:required", { field: format("common:value") })}
							</Form.Control.Feedback>
						)}
					</Form.Group>
				</Row>

				<Row>
					<Form.Group as={Col} controlId="description">
						<Form.Label>{format("common:description")}</Form.Label>
						<Form.Control
							{...register("description", { required: true })}
							isInvalid={!!errors.description}
						/>
						{errors.description?.type === "required" && (
							<Form.Control.Feedback type="invalid">
								{format("validation:required", { field: format("common:description") })}
							</Form.Control.Feedback>
						)}
					</Form.Group>
				</Row>

				<Row>
					<Form.Group as={Col} controlId="accessPassDuration">
						<Form.Label>{format("common:duration")}</Form.Label>
						<Form.Control {...register("accessPassDuration", { valueAsNumber: true })} />
					</Form.Group>

					<Form.Group as={Col} controlId="ttlInDays">
						<Form.Label>{format("common:ttlInDays")}</Form.Label>
						<Form.Control
							{...register("ttlInDays", { required: true })}
							isInvalid={!!errors.ttlInDays}
							type="number"
						/>
						{errors.ttlInDays?.type === "required" && (
							<Form.Control.Feedback type="invalid">
								{format("validation:required", { field: format("common:duration") })}
							</Form.Control.Feedback>
						)}
					</Form.Group>
				</Row>

				<Row>
					<Form.Group as={Col} controlId="maxCodes">
						<Form.Label>{format("common:maxCodes")}</Form.Label>
						<Form.Control
							{...register("maxCodes", { required: true })}
							type="boolean"
							isInvalid={!!errors.maxCodes}
						/>
						{errors.maxCodes?.type === "required" && (
							<Form.Control.Feedback type="invalid">
								{format("validation:required", { field: format("common:value") })}
							</Form.Control.Feedback>
						)}
					</Form.Group>

					<Form.Group as={Col} controlId="price">
						<Form.Label>{format("common:price")}</Form.Label>
						<Form.Control
							{...register("price", { required: true })}
							type="number"
							isInvalid={!!errors.price}
						/>
						{errors.price?.type === "required" && (
							<Form.Control.Feedback type="invalid">
								{format("validation:required", { field: format("common:value") })}
							</Form.Control.Feedback>
						)}
					</Form.Group>
				</Row>

				<Row>
					{template?.productIds && products ? (
						<Form.Group controlId="productIds">
							<Form.Label>{format("common:products")}</Form.Label>
							<Form.Control as="select" {...register("productIds", { required: true })}>
								<option value="" disabled>
									Select product
								</option>
								{products.map((product) => (
									<option
										key={product.id}
										value={product.id}
										selected={product.id === template.productIds}
									>
										{product.title}
										{product.zones ? ` - ${product.zones[0]}` : ""}
									</option>
								))}
							</Form.Control>
						</Form.Group>
					) : (
						products && (
							<Form.Group controlId="productIds">
								<Form.Label>{format("common:products")}</Form.Label>
								<Form.Control as="select" {...register("productIds")}>
									<option value="" disabled>
										Select product
									</option>
									{products.map((product) => (
										<option key={product.id} value={product.id}>
											{product.title}
											{product.zones ? ` - ${product.zones[0]}` : ""}
										</option>
									))}
								</Form.Control>
							</Form.Group>
						)
					)}
				</Row>

				<Row>
					<Form.Group controlId="addons">
						<Form.Label>{format("common:addons")}</Form.Label>
						<Form.Control as="select" multiple {...register("addons")} style={{ height: 150 }}>
							{Object.values(Types.AddonType).map((type) => (
								<option key={type} value={type}>
									{Types.constructAddonProductName(type, "en")}
								</option>
							))}
						</Form.Control>
					</Form.Group>
				</Row>

				<div className="d-flex justify-content-center align-items-center mt-2">
					<Button type="submit" disabled={isSubmitting}>
						{format(isSubmitting ? "common:loading" : template ? "common:save" : "common:submit")}
					</Button>

					<Form.Group as={Col} controlId="isGift" className="ms-3 d-flex align-items-center">
						<Form.Check {...register("isGift")} type="checkbox" label={format("common:isGift")} />
					</Form.Group>
				</div>
			</Stack>
		</Form>
	);
};

export default VCTemplateForm;

import { Types } from "@sno_oslo/shared-utils";

import { performRequest } from "../request";

export interface IReservationDto extends Types.IReservation {
	email: string;
	formType: Types.DetailsType;
	archivedAt?: string;
}

export const updateReservation = async (reservation: Types.IReservation): Promise<void> => {
	await performRequest({
		path: `/reservations/${reservation.reservationCode}`,
		method: "PUT",
		body: reservation,
	});
};

export const deleteReservations = async (body: { reservations: string[]; courseId: string }): Promise<boolean> => {
	await performRequest({
		path: "/reservations",
		method: "DELETE",
		body,
	});

	return true;
};

export const searchReservations = (
	filters: Record<string, any>,
): Promise<Array<Types.IReservation | Types.IReservationArchive>> =>
	performRequest({
		path: `/reservations/search?${new URLSearchParams(filters)
			.toString()
			.replace(/\w+=&/g, "")
			.replace(/&\w+=$/, "")}`,
		method: "GET",
	});

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Types } from "@sno_oslo/shared-utils";
import * as React from "react";
import { Button, Card } from "react-bootstrap";
import {
	applyPromoCodeToAccessPass,
	freezeAccessPass,
	unapplyPromoCodeToAccessPass,
} from "../../controllers/access-passes";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";
import PromoCodeRedemptionDetailsModal from "../promo-codes/PromoCodeRedemptionDetailsModal";
import ApplyPromoCodeToSubscriptionModal from "./ApplyPromoCodeToSubscriptionModal";
import FreezeSubscriptionModal from "./FreezeSubscriptionModal";
import moment from "moment";

interface IProps {
	pass: Types.IAccessProduct;
	onUpdate: (pass: Types.IAccessProduct) => void;
}

const MembershipActions: React.FC<IProps> = ({ pass, onUpdate }) => {
	const format = useFormat();
	const { addAlert } = useSnackbar();

	const [isFreezingModalVisible, setFreezingModalVisible] = React.useState(false);
	const [isFreezing, setFreezing] = React.useState(false);
	const [isApplyPromoCodeModalVisible, setApplyPromoCodeModalVisible] = React.useState(false);
	const [isApplyingPromoCode, setApplyingPromoCode] = React.useState(false);
	const [isPromoCodeRedemptionDialogActive, setPromoCodeRedemptionModalActive] = React.useState(false);

	const isFrozen =
		pass.subscriptionFreezedAt &&
		(!pass.subscriptionUnfreezeAt || moment(pass.subscriptionUnfreezeAt).isAfter(moment()));

	const handleFreeze = React.useCallback(
		async (data?: { unfreezeAt?: string; extendLockInEndDate?: boolean; hideQRCode?: boolean }) => {
			if (isFreezingModalVisible) {
				setFreezingModalVisible(false);
			}

			setFreezing(true);
			try {
				onUpdate(await freezeAccessPass(pass.id, { freeze: !isFrozen, ...data }));
			} catch (err) {
				addAlert((err as Error).message || format("error:default"), "danger");
			} finally {
				setFreezing(false);
			}
		},
		[pass.id, isFrozen, isFreezingModalVisible, onUpdate],
	);

	const handleFreezeClick = () => {
		isFrozen ? handleFreeze() : setFreezingModalVisible(true);
	};

	const handleApplyPromoCode = React.useCallback(
		async (promoCodeName: string) => {
			setApplyPromoCodeModalVisible(false);
			setApplyingPromoCode(true);
			try {
				onUpdate(await applyPromoCodeToAccessPass(pass.id, promoCodeName));
				addAlert("Promo code applied successfully", "success");
			} catch (err) {
				addAlert((err as Error).message || format("error:default"), "danger");
			} finally {
				setApplyingPromoCode(false);
			}
		},
		[pass.id],
	);

	const handleUnapplyPromoCode = React.useCallback(async () => {
		setApplyingPromoCode(true);
		try {
			onUpdate(await unapplyPromoCodeToAccessPass(pass.id));
			addAlert("Promo code redemption deleted successfully", "success");
		} catch (err) {
			addAlert((err as Error).message || format("error:default"), "danger");
		} finally {
			setApplyingPromoCode(false);
		}
	}, [onUpdate]);

	return (
		<>
			<p className="fw-bold mt-3 mb-1">Actions:</p>
			<Card className="card--plain">
				<Card.Body>
					{isFrozen && (
						<Card.Subtitle className="mb-2">
							Freezed at: {new Date(pass.subscriptionFreezedAt).toLocaleString("no")}
							{pass.subscriptionFreezedHideQRCode ? " (pass hidden from the user)" : ""}
						</Card.Subtitle>
					)}
					<Button disabled={isFreezing} onClick={handleFreezeClick}>
						{isFrozen ? "Unfreeze" : "Freeze"} subscription
					</Button>

					<hr />

					{pass.promoCodeRedemptionId ? (
						<>
							<Card.Subtitle className="">
								Applied promo code redemption:{" "}
								<a
									href="#"
									onClick={(e) => {
										e.preventDefault();
										setPromoCodeRedemptionModalActive(true);
									}}
								>
									{pass.promoCodeRedemptionId}
								</a>
							</Card.Subtitle>

							{(pass.vippsAgreementId || pass.stripeSubscriptionId) && (
								<PromoCodeRedemptionDetailsModal
									redemptionId={pass.promoCodeRedemptionId}
									show={isPromoCodeRedemptionDialogActive}
									onHide={() => setPromoCodeRedemptionModalActive(false)}
									className="modal-background"
								>
									<Button
										className="mt-3 w-full d-block"
										disabled={isApplyingPromoCode}
										variant="danger"
										onClick={handleUnapplyPromoCode}
									>
										Deactive
									</Button>
								</PromoCodeRedemptionDetailsModal>
							)}
						</>
					) : (
						<>
							<Button disabled={isApplyingPromoCode} onClick={() => setApplyPromoCodeModalVisible(true)}>
								Apply promo code
							</Button>

							<ApplyPromoCodeToSubscriptionModal
								show={isApplyPromoCodeModalVisible}
								onHide={() => setApplyPromoCodeModalVisible(false)}
								onSubmit={handleApplyPromoCode}
							/>
						</>
					)}
				</Card.Body>
			</Card>

			<FreezeSubscriptionModal
				show={isFreezingModalVisible && !isFrozen}
				onHide={() => setFreezingModalVisible(false)}
				onContinue={handleFreeze}
			/>
		</>
	);
};

export default MembershipActions;

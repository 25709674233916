import { Types } from "@sno_oslo/shared-utils";
import React, { useState } from "react";
import useFormat from "../../hooks/useFormat";
import { Button, Modal, ModalProps, Stack } from "react-bootstrap";
import TrainerReservationsDataList from "./TrainerReservationsDataList";
import TrainerReservationDeleteModal from "./TrainerReservationDeleteModal";
import TrainerReservationRescheduleForm from "./TrainerReservationRescheduleForm";
import TrainerReservationChangeTrainerForm from "./TrainerReservationChangeTrainerForm";

interface IProps extends ModalProps {
	reservation: Types.IPersonalTrainerReservation;
	onReservationDeleted: (reservationId: Types.IPersonalTrainerReservation["id"]) => void;
	onReservationUpdated: (reservation: Types.IPersonalTrainerReservation) => void;
	showTrainerDetails?: boolean;
}

const TrainerReservationModal: React.FC<IProps> = ({
	reservation,
	onReservationDeleted,
	onReservationUpdated,
	showTrainerDetails,
	...rest
}) => {
	const format = useFormat();
	const [action, setAction] = useState<'delete' | 'change-trainer' | 'reschedule'>();

	return (
		<>
			<Modal
				show
				{...rest}
			>
				<Modal.Header closeButton>
					<Modal.Title className="text-truncate">
						{format("trainers:reservations:preview:title", { id: reservation.id })}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<TrainerReservationsDataList reservation={reservation} showTrainerDetails={showTrainerDetails} />

					<Stack direction="horizontal" gap={2}>
						<Button onClick={() => setAction('delete')} variant="danger">
							{format("common:delete")}
						</Button>
						<div className="flex-1" />

						<Button onClick={() => setAction('change-trainer')} variant="info" className="me-1">
							{format("trainers:reservations:preview:changeTrainer")}
						</Button>
						<Button onClick={() => setAction('reschedule')}>
							{format("trainers:reservations:preview:reschedule")}
						</Button>
					</Stack>
				</Modal.Body>
			</Modal>

			<TrainerReservationDeleteModal
				show={action === 'delete'}
				personalTrainerId={reservation.personalTrainerId}
				reservationId={reservation.id}
				onDeleted={(...args) => {
					onReservationDeleted(...args);
					setAction(null);
				}}
				onHide={() => setAction(null)}
				className="modal-background"
			/>

			<Modal
				show={action === 'reschedule'}
				onHide={() => setAction(null)}
				centered
				className="modal-background"
			>
				<Modal.Header closeButton>
					<Modal.Title className="text-truncate">
						{format("trainers:reservations:preview:reschedule")}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<TrainerReservationRescheduleForm
						reservation={reservation}
						onCancel={() => setAction(null)}
						onRescheduled={(...args) => {
							onReservationUpdated(...args);
							setAction(null);
						}}
					/>
				</Modal.Body>
			</Modal>

			<Modal
				show={action === 'change-trainer'}
				onHide={() => setAction(null)}
				centered
				className="modal-background"
			>
				<Modal.Header closeButton>
					<Modal.Title className="text-truncate">
						{format("trainers:reservations:preview:changeTrainer")}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<TrainerReservationChangeTrainerForm
						reservation={reservation}
						onCancel={() => setAction(null)}
						onUpdated={(...args) => {
							onReservationUpdated(...args);
							setAction(null);
						}}
					/>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default TrainerReservationModal;

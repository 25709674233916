import { FC, useState } from "react";
import { Button } from "react-bootstrap";

import useFormat from "../../../hooks/useFormat";
import useSnackbar from "../../../hooks/useSnackbar";
import { downloadReceipt } from "../../../controllers/orders";

interface IDownloadOrderReceiptButtonProps {
	orderId: string;
}

const DownloadOrderReceiptButton: FC<IDownloadOrderReceiptButtonProps> = ({ orderId }) => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const [isDownloadingReceipt, setDownloadingReceipt] = useState(false);

	const handleClick = async () => {
		setDownloadingReceipt(true);

		try {
			const { data } = await downloadReceipt(orderId);

			const a = document.createElement("a");
			a.href = data;
			a.target = "_blank";
			a.download = "receipt.pdf";
			a.click();
		} catch (err) {
			console.error(err);

			addAlert((err as Error).message || format("error:default"), "danger");
		} finally {
			setDownloadingReceipt(false);
		}
	};

	return (
		<Button
			onClick={handleClick}
			disabled={isDownloadingReceipt}
			size="sm"
			variant="outline-primary"
			className="mt-1 mb-1"
		>
			Download receipt
		</Button>
	);
};

export default DownloadOrderReceiptButton;

import { Helpers, Language, Types } from "@sno_oslo/shared-utils";
import React, { useCallback } from "react";
import { Button, Col, Form, Row, Stack, Tab, Tabs } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { createPromoCode, updatePromoCode } from "../../controllers/promo-codes";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";
import { convertToUnixSeconds, formatDatetimeLocal } from "../../utils/dateUtils";

interface IProps {
	promoCode?: Types.IPromoCode;
	onSaved: (promocode: Types.IPromoCode) => void;
}

const PromoCodeForm: React.FC<IProps> = ({ promoCode, onSaved }) => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const {
		register,
		handleSubmit,
		formState: { isSubmitting, errors },
		reset,
		watch,
		setValue,
	} = useForm<Types.IPromoCode>({
		// @ts-ignore
		defaultValues: {
			...(promoCode || {}),
			prompt: Object.values(Language.Locale).reduce(
				(schema, locale) => ({
					...schema,
					[locale]: promoCode?.prompt?.[locale] || "",
				}),
				{},
			),
			...(promoCode?.expireTime
				? {
						expireTime: formatDatetimeLocal(new Date(promoCode.expireTime * 1000)),
				  }
				: {}),
			value: promoCode?.type === Types.PromoCodeType.AMOUNT ? promoCode.value / 100 : promoCode?.value,
		},
		mode: "onBlur",
	});

	console.log("promoCode", promoCode);

	const products = watch("products");
	const includesSubscription = products?.includes(Types.CartProductType.SUBSCRIPTION);
	const includesDaypass = products?.includes(Types.CartProductType.DAY_PASS);

	const onSubmit = useCallback(
		async ({ id: _, ...values }: Types.IPromoCode) => {
			try {
				const optionalNumberProps: (keyof Types.IPromoCode)[] = [
					"subscriptionIntervalCount",
					"maxRedemptionsPerCustomer",
					"autoRedemptionProductCount",
					"accessPassTTL",
				];
				for (const prop of optionalNumberProps) {
					if (typeof values[prop] !== "number" || Number.isNaN(values[prop])) {
						values[prop] = null;
					}
				}

				if (values.accessPassTTL && !values.products.includes(Types.CartProductType.DAY_PASS)) {
					values.accessPassTTL = null;
				}

				values.expireTime = convertToUnixSeconds(new Date(values.expireTime).getTime()) || null;

				if (values.type === Types.PromoCodeType.AMOUNT) {
					values.value *= 100;
				}

				const savedPromoCode = promoCode
					? await updatePromoCode(promoCode.id, values)
					: await createPromoCode(values);

				onSaved(savedPromoCode);
			} catch (err) {
				addAlert((err as Error).message || format("error:default"), "danger");
			}
		},
		[promoCode?.id, reset, onSaved, format],
	);

	return (
		<Form noValidate onSubmit={handleSubmit(onSubmit)} className="max-w-sm">
			<Stack gap={3}>
				<Row>
					<Form.Group as={Col} controlId="name">
						<Form.Label>{format("common:name")}</Form.Label>
						<Form.Control {...register("name", { required: true })} isInvalid={!!errors.name} />
						{errors.name?.type === "required" && (
							<Form.Control.Feedback type="invalid">
								{format("validation:required", { field: format("common:name") })}
							</Form.Control.Feedback>
						)}
					</Form.Group>

					<Form.Group as={Col} controlId="value">
						<Form.Label>{format("common:value")}</Form.Label>
						<Form.Control
							{...register("value", { required: true })}
							type="number"
							isInvalid={!!errors.value}
						/>
						{errors.value?.type === "required" && (
							<Form.Control.Feedback type="invalid">
								{format("validation:required", { field: format("common:value") })}
							</Form.Control.Feedback>
						)}
					</Form.Group>
				</Row>

				<Form.Group controlId="type">
					<Form.Label>{format("common:type")}</Form.Label>
					<Form.Control
						as="select"
						{...register("type", {
							onChange: () => {
								setValue("value", 0);
							},
						})}
					>
						{Types.getPromoCodeTypes().map((type) => (
							<option key={type} value={type}>
								{Helpers.capitalize(type)}
							</option>
						))}
					</Form.Control>
				</Form.Group>

				<Form.Group controlId="type">
					<Form.Label>{"Max redemptions per customer"}</Form.Label>
					<Form.Control {...register("maxRedemptionsPerCustomer", { valueAsNumber: true })} type="number" />
				</Form.Group>

				<Form.Group controlId="product">
					<Form.Label>{format("common:products")}</Form.Label>
					<Form.Control as="select" multiple {...register("products")}>
						{Types.getPromoCodeProducts().map((product) => (
							<option key={product} value={product}>
								{Helpers.capitalize(product)}
							</option>
						))}
					</Form.Control>
				</Form.Group>

				<Form.Group controlId="type">
					<Form.Label>{"Auto redemption product count"}</Form.Label>
					<Form.Control {...register("autoRedemptionProductCount", { valueAsNumber: true })} type="number" />
				</Form.Group>

				{includesDaypass && (
					<Form.Group controlId="type">
						<Form.Label>{"Access pass TTL"}</Form.Label>
						<Form.Control {...register("accessPassTTL", { valueAsNumber: true })} type="number" />
					</Form.Group>
				)}

				{includesSubscription && (
					<>
						<Form.Group controlId="subscriptionIntervalCount">
							<Form.Label>Subscription interval count (only vipps membership campaigns)</Form.Label>
							<Form.Control
								{...register("subscriptionIntervalCount", {
									valueAsNumber: true,
									validate: (count) =>
										Number.isNaN(count) || (Number.isInteger(count) && count > 0 && count <= 12),
								})}
								type="number"
								isInvalid={!!errors.subscriptionIntervalCount}
							/>
							{errors.subscriptionIntervalCount && (
								<Form.Control.Feedback type="invalid">
									{errors.subscriptionIntervalCount.message || "Invalid count (min 1, max 12)"}
								</Form.Control.Feedback>
							)}
						</Form.Group>

						<Form.Group controlId="stripeCheckbox">
							<Form.Check
								label="Did you add the coupon code to the stripe?"
								{...register("stripeCheckbox", {
									validate: (value) =>
										(includesSubscription && value) ||
										"Stripe checkbox is required if code include subscriptions",
								})}
								isInvalid={!!errors.stripeCheckbox}
							/>
							<p style={{ color: "red" }}> {errors?.stripeCheckbox?.message} </p>
						</Form.Group>
					</>
				)}

				<Form.Group controlId="prompt">
					<Form.Label>{format("common:prompt")}</Form.Label>

					<Tabs defaultActiveKey={Language.Locale.En}>
						{Object.values(Language.Locale).map((locale) => (
							<Tab key={locale} eventKey={locale} title={format(`locales:${locale}`)}>
								<Form.Control
									{...register(`prompt.${locale}`)}
									isInvalid={!!errors.prompt?.[locale]}
									className="mt-3"
								/>
							</Tab>
						))}
					</Tabs>

					{errors.prompt &&
						Object.values(Language.Locale).map((locale) =>
							errors.prompt[locale]?.type === "required" ? (
								<p key={locale} className="mb-0 mt-2" style={{ color: "red" }}>
									{format("validation:required", { field: format("common:prompt") })} (
									{locale.toUpperCase()})
								</p>
							) : null,
						)}
				</Form.Group>

				<Form.Group controlId="expireTime">
					<Form.Label>{format("common:expiresAt")}</Form.Label>
					<Form.Control type="datetime-local" {...register("expireTime")} isInvalid={!!errors.expireTime} />
					{errors.expireTime && (
						<Form.Control.Feedback type="invalid">
							{format(`validation:${errors.expireTime.type}`, { field: format("common:expiresAt") })}
						</Form.Control.Feedback>
					)}
				</Form.Group>
				<Form.Group controlId="fixed">
					<Form.Check label={format("common:fixed")} {...register("fixed")} />
				</Form.Group>
				<div>
					<Button type="submit" disabled={isSubmitting} className="mt-2">
						{format(isSubmitting ? "common:loading" : promoCode ? "common:save" : "common:submit")}
					</Button>
				</div>
			</Stack>
		</Form>
	);
};

export default PromoCodeForm;

import { Helpers, Types } from "@sno_oslo/shared-utils";
import { FC, useEffect, useMemo, useState } from "react";
import { Button, Form, Modal, ModalProps } from "react-bootstrap";
import useFetchPromoCodes from "../../hooks/useFetchPromoCodes";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";

export interface IProps extends ModalProps {
	onSubmit: (promoCodeName: string) => void;
}

const ApplyPromoCodeToSubscriptionModal: FC<IProps> = ({ onSubmit, onHide, ...rest }) => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const { promoCodes, fetchError } = useFetchPromoCodes();
	const filteredPromocodes = useMemo(
		() => promoCodes?.filter(({ products }) => products.includes(Types.CartProductType.SUBSCRIPTION)),
		[promoCodes],
	);
	const [promoCodeName, setPromoCodeName] = useState<string>();

	useEffect(() => {
		if (filteredPromocodes) {
			if (filteredPromocodes.length === 0) {
				addAlert("Couldn't find any subscription type promo codes", "danger");
				onHide();
			} else if (!promoCodeName) {
				setPromoCodeName(filteredPromocodes[0].name);
			}
		}
	}, [filteredPromocodes]);

	useEffect(() => {
		if (fetchError) {
			addAlert(fetchError.message || format("error:default"), "danger");
			onHide();
		}
	}, [fetchError]);

	return (
		<Modal onHide={onHide} centered className="modal-background" {...rest}>
			<Modal.Header closeButton>
				<Modal.Title>Apply promo code</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form.Group>
					<Form.Label>Select promo code:</Form.Label>
					<Form.Select value={promoCodeName} required onChange={(e) => setPromoCodeName(e.target.value)}>
						{filteredPromocodes ? (
							filteredPromocodes.map(({ name, type, value, subscriptionIntervalCount }) => (
								<option key={name} value={name}>
									{name} -{" "}
									{type === Types.PromoCodeType.PERCENTAGE
										? `${value}%`
										: `${Helpers.formatPrice(value / 100)} NOK`}
									{subscriptionIntervalCount ? ` for ${subscriptionIntervalCount} month(s)` : ""}
								</option>
							))
						) : (
							<option value="" disabled>
								{format("common:loading")}
							</option>
						)}
					</Form.Select>
				</Form.Group>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => onHide()}>
					{format("confirm:close")}
				</Button>

				<Button variant="primary" disabled={!promoCodeName} onClick={() => onSubmit(promoCodeName)}>
					{format("confirm:submit")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ApplyPromoCodeToSubscriptionModal;

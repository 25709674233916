import { Types } from "@sno_oslo/shared-utils";
import * as React from "react";
import { Card, ListGroupItem } from "react-bootstrap";

import ArrowRight from "../common/arrow";
import { AllProductsType } from "../../controllers/value-codes";

interface IProps {
	pass: Types.IAccessProduct;
	onClick: (event: React.MouseEvent<HTMLElement>) => void;
	products: AllProductsType[];
}

export default function AccessPassItem({ products, pass, onClick }: IProps) {
	const purchasedDate = new Date(pass.purchasedAt);

	const title =
		products.find(
			(product) => product.id === (pass.productId ?? pass.contentfulProductId ?? pass.punchCardProductId),
		)?.title ?? pass.productName.replace("undefined", "");

	return (
		<ListGroupItem onClick={onClick} className="item-wrapper">
			<Card className="item-card" role="button">
				<Card.Body>
					<Card.Title>{title}</Card.Title>
					<Card.Subtitle className="mb-2 text-muted">
						Purchased: {purchasedDate.toLocaleString("no")}
					</Card.Subtitle>
					{pass.userNameForPass && (
						<Card.Subtitle className="mb-2 text-muted">User name: {pass.userNameForPass}</Card.Subtitle>
					)}
				</Card.Body>
			</Card>
			<ArrowRight />
		</ListGroupItem>
	);
}

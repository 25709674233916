import { Types } from "@sno_oslo/shared-utils";
import { performRequest } from "./request";

export const searchEventTicket = async (query: string): Promise<Types.IEventTicket> => {
	const eventTicket = await performRequest({
		path: `/event-tickets/${query}`,
		method: "GET",
	});

	return eventTicket
}

export const activateEventTicket = async (qrCode: number): Promise<Types.IEventTicket> => {
	const eventTicket = await performRequest({
		path: `/event-tickets/${qrCode}`,
		method: "PUT",
		body: { activated: true },
	});
	
	return eventTicket
}
/**
 * handles all service action to do with personal trainers
 */
import { Types } from "@sno_oslo/shared-utils";

import { performRequest } from "./request";

export const getAllPersonalTrainers = (): Promise<Array<Types.IPersonalTrainer>> =>
	performRequest({
		path: "/personal-trainers",
		method: "GET",
	});

export const getPersonalTrainer = (id: Types.IPersonalTrainer["id"]): Promise<Types.IPersonalTrainer> =>
	performRequest({
		path: `/personal-trainers/${id}`,
		method: "GET",
	});

export const createPersonalTrainer = (trainer: Omit<Types.IPersonalTrainer, "id">) =>
	performRequest({
		path: "/personal-trainers",
		method: "POST",
		body: trainer,
	});

export const updatePersonalTrainer = (id: Types.IPersonalTrainer["id"], trainer: Omit<Types.IPersonalTrainer, "id">) =>
	performRequest({
		path: `/personal-trainers/${id}`,
		method: "PUT",
		body: trainer,
	});

export const uploadPersonalTrainerPhoto = (id: Types.IPersonalTrainer["id"], data: FormData) =>
	performRequest({
		path: `/personal-trainers/${id}/upload-photo`,
		method: "POST",
		body: data,
	});

export const deletePersonalTrainer = (id: Types.IPersonalTrainer["id"]): Promise<{ status: string }> =>
	performRequest({
		path: `/personal-trainers/${id}`,
		method: "DELETE",
	});

export const getPersonalTrainersReservations = (query: {
	from: string;
	to: string;
}): Promise<Array<Types.IPersonalTrainerReservation>> =>
	performRequest({
		path: `/personal-trainers/reservations?${new URLSearchParams(query).toString()}`,
		method: "GET",
	});

export const getPersonalTrainersAvailabilities = (query: {
	from: string;
	to: string;
}): Promise<Array<Types.IPersonalTrainerReservation>> =>
	performRequest({
		path: `/personal-trainers/availabilities/all?${new URLSearchParams(query).toString()}`,
		method: "GET",
	});
	
export const getPersonalTrainerReservations = (
	id: Types.IPersonalTrainer["id"],
	query: { from: string; to: string },
): Promise<Array<Types.IPersonalTrainerReservation>> =>
	performRequest({
		path: `/personal-trainers/${id}/reservations?${new URLSearchParams(query).toString()}`,
		method: "GET",
	});

export const updatePersonalTrainerReservation = (
	id: Types.IPersonalTrainer["id"],
	reservationId: Types.IPersonalTrainerReservation["id"],
	body: Partial<Omit<Types.IPersonalTrainerReservation, 'id'>>,
): Promise<Types.IPersonalTrainerReservation> =>
	performRequest({
		path: `/personal-trainers/${id}/reservations/${reservationId}`,
		method: "PUT",
		body
	});

export const deletePersonalTrainerReservation = (
	id: Types.IPersonalTrainer["id"],
	reservationId: Types.IPersonalTrainerReservation["id"],
): Promise<Types.IPersonalTrainerReservation> =>
	performRequest({
		path: `/personal-trainers/${id}/reservations/${reservationId}`,
		method: "DELETE",
	});

export const reschedulePersonalTrainerReservation = (
	id: Types.IPersonalTrainer["id"],
	reservationId: Types.IPersonalTrainerReservation["id"],
	body: { start: string; message?: string },
): Promise<Types.IPersonalTrainerReservation> =>
	performRequest({
		path: `/personal-trainers/${id}/reservations/${reservationId}/reschedule`,
		method: "POST",
		body,
	});

export const getPersonalTrainerAvailabilities = (
	id: Types.IPersonalTrainer["id"],
	query: { from: string; to: string },
): Promise<Array<Types.IPersonalTrainerAvailability>> =>
	performRequest({
		path: `/personal-trainers/${id}/availabilities?${new URLSearchParams(query).toString()}`,
		method: "GET",
	});

export const addPersonalTrainerAvailability = (
	id: Types.IPersonalTrainer["id"],
	body: Pick<Types.IPersonalTrainerAvailability, "disciplines" | "start" | "end">,
): Promise<Types.IPersonalTrainerAvailability> =>
	performRequest({
		path: `/personal-trainers/${id}/availabilities`,
		method: "POST",
		body,
	});

export const updatePersonalTrainerAvailability = (
	id: Types.IPersonalTrainer["id"],
	availabilityId: Types.IPersonalTrainerAvailability["id"],
	body: Pick<Types.IPersonalTrainerAvailability, "disciplines" | "start" | "end">,
): Promise<Types.IPersonalTrainerAvailability> =>
	performRequest({
		path: `/personal-trainers/${id}/availabilities/${availabilityId}`,
		method: "PUT",
		body,
	});

export const deletePersonalTrainerAvailability = (
	id: Types.IPersonalTrainer["id"],
	availabilityId: Types.IPersonalTrainerAvailability["id"],
): Promise<void> =>
	performRequest({
		path: `/personal-trainers/${id}/availabilities/${availabilityId}`,
		method: "DELETE",
	});

export const getPersonalTrainerGroupCourses = (
	id: Types.IPersonalTrainer["id"],
	query: { from: string; to: string },
): Promise<Array<Types.IGroupCourse>> =>
	performRequest({
		path: `/personal-trainers/${id}/group-courses?${new URLSearchParams(query).toString()}`,
		method: "GET",
	});

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Types } from "@sno_oslo/shared-utils";
import { deleteGiftCard, getGiftCards } from "../../controllers/gift-cards";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";
import { Button } from "react-bootstrap";
import Loader from "../common/loader";
import GiftCardsTable from "./GiftCardsTable";
import ConfirmModal from "../common/ConfirmModal";

interface IFetchParams {
	lastKeyCode?: string;
	lastKeyCreatedAt?: string | Date;
	archived?: boolean;
}

const GiftCardsList: React.FC<{ archived?: boolean }> = ({ archived = false }: { archived?: boolean }) => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const [giftCards, setGiftCards] = useState<Array<Types.IGiftCard | Types.IGiftCardArchive>>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [total, setTotal] = useState(0);
	const [giftCardToDeleteCode, setGiftCardToDeleteCode] = useState<Types.IGiftCard["id"]>(null);
	const giftCardToDelete = useMemo(
		() =>
			giftCards && giftCardToDeleteCode
				? giftCards.find((card) => (archived ? card.id : card.code) === giftCardToDeleteCode)
				: null,
		[giftCards, giftCardToDeleteCode],
	);
	const [isDeletingGiftCard, setIsDeletingGiftCard] = useState(false);

	const fetchGiftCards = useCallback(
		async (params?: Record<string, any>) => {
			try {
				setIsLoading(true);

				if (!params) {
					params = {
						archived: archived,
					};
				}

				const res = await getGiftCards(params);

				setGiftCards(
					giftCards && params && params.lastKeyCode ? [...giftCards, ...res.giftCards] : res.giftCards,
				);
				setTotal(res.total);
			} catch (err) {
				addAlert((err as Error).message || format("error:default"), "danger");
			} finally {
				setIsLoading(false);
			}
		},
		[giftCards, archived],
	);

	const fetchMoreGiftCards = useCallback(() => {
		const lastGiftCard = giftCards ? giftCards[giftCards.length - 1] : null;
		const params: IFetchParams = lastGiftCard
			? {
					lastKeyCode: archived ? lastGiftCard.id : lastGiftCard.code,
					lastKeyCreatedAt: lastGiftCard.createdAt,
			  }
			: {};

		params.archived = archived;

		return fetchGiftCards(params);
	}, [fetchGiftCards, archived]);

	const handleGiftCardDelete = useCallback((giftCard: Types.IGiftCard) => {
		setGiftCardToDeleteCode(archived ? giftCard.id : giftCard.code);
	}, []);

	const handleGiftCardDeleteConfirm = useCallback(async () => {
		try {
			setIsDeletingGiftCard(true);
			await deleteGiftCard(giftCardToDeleteCode, archived);

			setGiftCards(giftCards!.filter((card) => (archived ? card.id : card.code) !== giftCardToDeleteCode));
			addAlert(format("alerts:deleted"), "success");
		} catch (err) {
			addAlert((err as Error).message || format("error:default"), "danger");
		} finally {
			setIsDeletingGiftCard(false);
		}
	}, [giftCards, giftCardToDeleteCode]);

	useEffect(() => {
		setGiftCards(null);
		fetchGiftCards();
	}, [archived]);

	return (
		<>
			<h1>{format(archived ? "giftCards:archived:title" : "giftCards:title")}</h1>
			{giftCards ? (
				<div className="max-w-xl mx-auto">
					<GiftCardsTable giftCards={giftCards} onDelete={handleGiftCardDelete} />

					{total > giftCards.length && (
						<Button
							variant="secondary"
							onClick={() => fetchMoreGiftCards()}
							disabled={isLoading}
							className="mx-auto d-block"
						>
							{format("common:showMore", { count: total - giftCards.length })}
						</Button>
					)}
				</div>
			) : (
				<Loader loadingText={format("common:loading")} />
			)}

			<ConfirmModal
				show={!!giftCardToDelete}
				onConfirm={handleGiftCardDeleteConfirm}
				onHide={() => setGiftCardToDeleteCode(null)}
				isLoading={isDeletingGiftCard}
				confirmVariant="danger"
			>
				{format("giftCards:delete:details")}
				<mark>#{giftCardToDeleteCode}</mark>
			</ConfirmModal>
		</>
	);
};

export default GiftCardsList;

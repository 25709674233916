import { Types } from "@sno_oslo/shared-utils";

export interface IOrderLineProductAddon {
	id: string;
	productName: string;
	productTitle?: string;
	price: number;
	taxRate?: number;
}

export const getOrderLineProductAddons = (line: Types.IOrderLine): Array<IOrderLineProductAddon> =>
	(line.productPayload.addons as Array<IOrderLineProductAddon>) ||
	(line.productPayload.eventAddons as Array<Types.IEventTicketAddon>)?.map((addon) => ({
		id: ((addon as any).id as number).toString(),
		productName: addon.name,
		price: addon.price,
	})) ||
	(line.productPayload.passes as Array<{ addons: Array<IOrderLineProductAddon> }>)
		?.map((pass) => pass.addons)
		.flat() ||
	[];

export const getOrderlinePrice = (line: Types.IOrderLine): number => {
	const addonsPrice = getOrderLineProductAddons(line).reduce((accum, value) => (accum + value.price) as number, 0);

	return (line.productPayload.price as number) - ((line.productPayload.discount as number) || 0) + addonsPrice;
};

export const getPaymentVendor = (vendor: Types.EPaymentVendor): "vipps" | "stripe" | "gift_card" =>
	vendor === Types.EPaymentVendor.VIPPS || vendor === Types.EPaymentVendor.MIXED_VIPPS_GIFT_CARD
		? "vipps"
		: vendor === Types.EPaymentVendor.STRIPE || vendor === Types.EPaymentVendor.MIXED_STRIPE_GIFT_CARD
		? "stripe"
		: "gift_card";

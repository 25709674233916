import * as React from "react";
import { Alert, Button, Modal } from "react-bootstrap";

import LoaderButton from "../../../common/loader-button";
import { deleteReservations } from "../../../../controllers/lessons/reservations";

interface IProps {
	closeModal: () => void;
	refreshCourses: () => void;
	reservations: Array<string>;
	courseId: string;
}

const DeleteReservationsModal: React.FunctionComponent<IProps> = ({
	closeModal,
	refreshCourses,
	reservations,
	courseId,
}: IProps) => {
	const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
	const [error, setError] = React.useState<string | null>(null);
	const [success, setSuccess] = React.useState<boolean>(false);

	const performDeletion = async () => {
		setIsSubmitting(true);
		try {
			await deleteReservations({ reservations, courseId });
			setSuccess(true);
		} catch (err) {
			setError(err.message);
			setIsSubmitting(false);
		}
	};

	const onClose = () => {
		closeModal();
		if (success) {
			refreshCourses();
		}
	};

	return (
		<Modal show={true} onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>Delete reservations</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{!!error && (
					<Alert variant="danger">
						An error occurred 😱
						<br />
						<p>{error}</p>
					</Alert>
				)}
				{success ? (
					<>
						<Alert variant="success">
							Reservations removed ! It may take a while to update the number of spots taken.
						</Alert>
					</>
				) : (
					<>
						<p>Are you sure you want to delete {reservations.length} reservations ?</p>
						<LoaderButton
							isLoading={isSubmitting}
							onClick={performDeletion}
							loadingLabel="Deleting..."
							variant="danger"
						>
							Yes, delete the reservations
						</LoaderButton>
					</>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={onClose}>
					{success ? "OK" : "Close"}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default DeleteReservationsModal;

import { Types } from "@sno_oslo/shared-utils";
import { useCallback, useEffect, useState } from "react";
import { getPromoCodes } from "../controllers/promo-codes";

const useFetchPromoCodes = () => {
	const [promoCodes, setPromoCodes] = useState<Array<Types.IPromoCode>>();
	const [isLoading, setIsLoading] = useState(false);
	const [fetchError, setFetchError] = useState<Error | null>(null);

	const fetchPromoCodes = useCallback(async () => {
		try {
			setFetchError(null);
			setIsLoading(true);

			setPromoCodes(await getPromoCodes());
		} catch (err) {
			setFetchError(err as Error);
		} finally {
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		fetchPromoCodes();
	}, []);

	return {
		fetchError,
		isLoading,
		promoCodes,
		setPromoCodes,
		fetchPromoCodes,
	};
};

export default useFetchPromoCodes;

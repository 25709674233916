import { FC, useMemo, useState } from "react";

import AddPassesForm, { IAddPassesFormPayload } from "./AddPassesForm";
import AddPassesProcessor from "./AddPassesProcessor";

const AddPassesTab: FC = () => {
	const [activePayload, setActivePayload] = useState<IAddPassesFormPayload | null>(null);
	const passes = useMemo(
		() =>
			activePayload
				? new Array(activePayload.amount).fill(null).map((_, i) => ({
						userId: activePayload.userId,
						daysType: activePayload.daysType,
						expireTime: activePayload.expireTime,
						type: activePayload.type,
						duration: activePayload.duration,
						zones: activePayload.zones,
						priceClass: activePayload.priceClass,
						price: activePayload.price,
				  }))
				: undefined,
		[activePayload],
	);

	return (
		<>
			<AddPassesForm onAddPasses={setActivePayload} isLoading={!!passes} />
			{passes && <AddPassesProcessor passes={passes} />}
		</>
	);
};

export default AddPassesTab;

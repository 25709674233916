import { Types } from "@sno_oslo/shared-utils";
import React, { useCallback } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { createValueCode, updateValueCode, ValueCodeBody } from "../../controllers/value-codes";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";

interface IProps {
	valueCode?: ValueCodeBody & { id: string };
	onSaved: () => void;
}

interface LocationState {
  template: Types.ITemplate
} 

const ValueCodeForm: React.FC<IProps> = ({ valueCode, onSaved }) => {
  const { template } = useLocation().state as LocationState

	const format = useFormat();
	const { addAlert } = useSnackbar();
	const {
		register,
		handleSubmit,
		formState: { isSubmitting, errors },
		reset,
	} = useForm({
		defaultValues: {
			...({...valueCode} || {}),
		},
		mode: "onBlur",
	});

	const onSubmit = useCallback(
		async (values: ValueCodeBody & { id: string }) => {
			try {
				const downloadCSV = async () => {
					const csvBlob: any = await createValueCode({ ...values, templateId: template.id, customer: template.customer })
					
					const url = window.URL.createObjectURL(new Blob([csvBlob]));
					const link = document.createElement('a');
			
					link.href = url;
					link.setAttribute(
						'download',
						`valuecodes.csv`,
					);
					document.body.appendChild(link);
					link.click();
					link.parentNode.removeChild(link);
				}

				if (valueCode) {
					await updateValueCode({ ...values, templateId: template.id, customer: template.customer })
				} else {
					await downloadCSV()
				}

				onSaved();
			} catch (err) {
				addAlert((err as Error).message || format("error:default"), "danger");
			}
		},
		[valueCode?.id, reset, onSaved, format],
	);

	return (
		<Form noValidate onSubmit={handleSubmit(onSubmit)} className="detailsFrom">
			<Form.Group controlId="description" className="pb-2">
				<Form.Label>{format("common:description")}</Form.Label>
				<Form.Control
					type="text"
					placeholder={format("common:description:placeholder")}
					size="lg"
					isInvalid={!!errors.description}
					{...register("description", { required: true })}
				/>
				{errors?.description && (
					<Form.Control.Feedback type="invalid">
						{format(`validation:${errors?.description?.type}`, { field: format("common:description") })}
					</Form.Control.Feedback>
				)}
			</Form.Group>
			<Form.Group controlId="count" className="py-2">
				<Form.Label>{format("common:count")}</Form.Label>
				<Form.Control
					type="number"
					placeholder={format("common:count:placeholder")}
					size="lg"
					isInvalid={!!errors.count}
					{...register("count", { required: true })}
				/>
				{errors?.count && (
					<Form.Control.Feedback type="invalid">
						{format(`validation:${errors?.count?.type}`, { field: format("common:count") })}
					</Form.Control.Feedback>
				)}
			</Form.Group>

			<div className="d-flex justify-content-center align-items-center mt-2">
				<Button type="submit" disabled={isSubmitting}>
					{format(isSubmitting ? "common:loading" : template ? "common:save" : "common:submit")}
				</Button>

				<Form.Group as={Col} controlId="forceExpireTime" className="ms-3 d-flex align-items-center">
					<Form.Check
						{...register("forceExpireTime")}
						type="checkbox"
						label={format("common:forceExpireTime")}
					/>
				</Form.Group>
			</div>
		</Form>
	);
};

export default ValueCodeForm;

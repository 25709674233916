import React, { useMemo, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import shallow from "zustand/shallow";
import useFormat from "../../../hooks/useFormat";
import { useCoursesStore } from "../../../stores/coursesStore";

import Loader from "../../common/loader";
import GroupCourseDetails from "./groupCourseDetails";
import GroupCoursesFilter from "./groupCoursesFilter";

const GroupCoursesList: React.FC = () => {
	const format = useFormat();
	const [params] = useSearchParams();
	const archivedParam = useMemo(() => params.get("archived"), [params]);

	const {
		fetch: fetchCourses,
		isFetching,
		items: courses,
		lastKey,
		updateItem: updateCourse,
		filterArchived,
		setFilterArchived,
		filters,
		setFilter,
		removeFilter,
	} = useCoursesStore((state) => state, shallow);

	useEffect(() => {
		const archived = archivedParam === "true";
		setFilterArchived(archived);
		if (filterArchived !== archived) {
			fetchCourses(true);
		}
	}, [archivedParam]);

	useEffect(() => {
		if (courses.length === 0) {
			fetchCourses(true);
		}
	}, []);

	useEffect(() => {
		if (!isFetching && lastKey) {
			fetchCourses();
		}
	}, [lastKey]);

	const handleFilterChange = (key: string, value: any) => {
		if (value) {
			setFilter(key, value);
		} else {
			removeFilter(key);
		}
		fetchCourses(true);
	};

	return (
		<>
			<h1>Courses at SNØ</h1>

			<GroupCoursesFilter filters={filters} onChange={handleFilterChange} />

			{courses.length > 0 ? (
				<ul className="groupCoursesList">
					{courses.map((course, index) => (
						<li key={course.id}>
							<GroupCourseDetails
								course={course}
								refreshCourses={fetchCourses}
								unfolded={false}
								updateReservations={(reservations) => updateCourse(course.id, { reservations })}
							/>
						</li>
					))}
				</ul>
			) : !isFetching ? (
				<i className="text-primary d-block text-center">{format("common:emptyState")}</i>
			) : null}

			{isFetching && <Loader loadingText="Loading..." />}
		</>
	);
};

export default GroupCoursesList;

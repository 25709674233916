import * as React from "react";
import InfiniteScroll from 'react-infinite-scroll-component';

import Loader from "../common/loader";
import { getHowManyCodesLeftFromTemplate, getValueCodesForTemplate } from "../../controllers/value-codes";
import { ValueCodesDetails } from "./valueCodesDetails";
import { useLocation } from "react-router-dom";
import { Types } from "@sno_oslo/shared-utils";
import { useState } from "react";

interface LocationState {
  template: Types.ITemplate;
}

export default function ValueCodesList() {
  const [left, setLeft] = useState<number>();
  const [fetching, setFetching] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<Array<Types.IValueCode>>([]);
  const [lastKey, setLastKey] = useState<Types.IValueCode>();
  const { template } = useLocation().state as LocationState

  const fetch = async (more = false) => {
    setFetching(true)
    const query = await getValueCodesForTemplate(template.id, template.customer, more && lastKey)
    
    setLastKey((query[0] as any)?.lastEvaluatedKey)
    setList(more ? list.concat(query) : query)
    setFetching(false)
  }

  const fetchHowManyLeft = async () => {
    setLoading(true)
    const _left = await getHowManyCodesLeftFromTemplate(template.id, template.customer)
    setLeft(_left)
    setLoading(false)
  }

  React.useEffect(() => {
    fetch()
    fetchHowManyLeft()
  }, [])

  return (
    <div>
      <div className="d-flex row">
        <h1 className="w-75">Value codes at SNØ</h1>
        <p className="d-flex align-items-center w-25 justify-content-end">{left} left</p>
      </div>

      <div style={{
        height: '90%',
        width: '100%',
        overflowY: 'auto',
        overflowX: "hidden",
      }}>
        {list.length > 0 ? (
          <ul className="groupCoursesList">
            <InfiniteScroll
              dataLength={list.length}
              next={() => fetch(true)}
              hasMore={!!lastKey}
              loader={loading && <Loader loadingText="Loading..." />}
            >
              {list.map(vc => (
                <li key={vc.id}>
                  <ValueCodesDetails
                    templateId={template.id}
                    code={vc}
                    refetch={() => fetch()}
                  />
                </li>
              ))}
            </InfiniteScroll>
          </ul>
        ) : fetching ? (
          <Loader loadingText="Loading..." />
        ) : (
          <div>No value codes</div>
        )
        }
      </div>
    </div>
  );
}

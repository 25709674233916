import * as React from "react";
const ArrowRight = () => {
	return (
		<svg className="arrow-right" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<path
				fill="#000"
				d="M12.75,23.25 C12.55,23.25 12.361,23.172 12.22,23.03 C11.928,22.738 11.928,22.262 12.22,21.969 L21.44,12.749 L0.75,12.749 C0.336,12.75 0,12.414 0,12 C0,11.586 0.336,11.25 0.75,11.25 L21.439,11.25 L12.219,2.03 C12.078,1.889 12,1.7 12,1.5 C12,1.3 12.078,1.111 12.22,0.97 C12.361,0.828 12.55,0.75 12.75,0.75 C12.95,0.75 13.139,0.828 13.28,0.97 L23.78,11.47 C23.85,11.54 23.905,11.622 23.943,11.715 C23.946,11.723 23.95,11.732 23.953,11.741 C23.984,11.822 24,11.911 24,12 C24,12.087 23.984,12.174 23.953,12.258 C23.951,12.264 23.949,12.269 23.947,12.274 C23.905,12.378 23.849,12.461 23.779,12.531 L13.28,23.03 C13.139,23.172 12.95,23.25 12.75,23.25 Z"
			/>
		</svg>
	);
};
export default ArrowRight;

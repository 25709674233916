import * as React from "react";
import { useEffect, useState } from "react";
import { Button, ButtonGroup, Card, Container, Dropdown, DropdownButton, Nav, Spinner } from "react-bootstrap";
import { getProductSettings, getPricingByIds, getAvailabilities } from "../../controllers/contentful/product-settings";
import { EntryProps } from "contentful-management";
import { Enums, Types } from "@sno_oslo/shared-utils";
import { CartProductType } from "@sno_oslo/shared-utils/dist/types";
import { PlusCircleDotted } from "react-bootstrap-icons";
import PricingModal from "./components/PricingModal";
import { EModalMode } from "../../enums/modal-mode.enum";
import AvailabilityModal from "./components/AvailabilityModal";

const PricingAndAvailability = () => {
	const [activeTab, setActiveTab] = React.useState<Enums.EPricingAndAvailabilityType>(
		Enums.EPricingAndAvailabilityType.PRICING,
	);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [modalMode, setModalMode] = useState<EModalMode>(EModalMode.CREATE);
	const [showPricingModal, setShowPricingModal] = useState<boolean>(false);
	const [showAvailabilityModal, setShowAvailabilityModal] = useState<boolean>(false);

	const [productSettings, setProductSettings] = useState<EntryProps | null>();
	const [pricing, setPricing] = useState<EntryProps[] | null>();
	const [availabilities, setAvailabilities] = useState<EntryProps[]>([]);
	const [currentPricing, setCurrentPricing] = useState<EntryProps | null>();
	const [currentAvailability, setCurrentAvailability] = useState<EntryProps | null>();

	const [productType, setProductType] = useState<Types.CartProductType>(Types.CartProductType.DAY_PASS);
	const [zone, setZone] = useState<Types.ZoneType | null>(Types.ZoneType.DOWNHILL);
	const [dayType, setDayType] = useState<Types.DaysType | null>(Types.DaysType.WEEKDAYS);
	const [priceClass, setPriceClass] = useState<Types.PriceClass | null>(Types.PriceClass.ADULT);

	const fetchProductSettings = async () => {
		setIsLoading(true);
		const res = await getProductSettings();
		setProductSettings(res);
		setIsLoading(false);
	};

	const fetchAvailabilities = async () => {
		setIsLoading(true);
		const res = await getAvailabilities(zone);

		setAvailabilities(res);
		setIsLoading(false);
	};

	useEffect(() => {
		if (activeTab === Enums.EPricingAndAvailabilityType.PRICING) {
			fetchProductSettings();
		} else {
			fetchAvailabilities();
		}
	}, [activeTab, productType]);

	useEffect(() => {
		if (productType === CartProductType.FAMILY_PASS) {
			setZone(Types.ZoneType.DOWNHILL);
			setPriceClass(null);
		} else {
			setZone(Types.ZoneType.DOWNHILL);
			setDayType(Types.DaysType.WEEKDAYS);
			setPriceClass(Types.PriceClass.ADULT);
		}
	}, [productType]);

	useEffect(() => {
		if (activeTab === Enums.EPricingAndAvailabilityType.PRICING) {
			if (productSettings?.fields?.[productType]?.nb) {
				const pricingIds = productSettings.fields[productType].nb.map(({ sys: { id } }) => id);
				const fetchPricing = async () => {
					setIsLoading(true);
					const res = await getPricingByIds({ ids: pricingIds, zone, dayType, priceClass });
					setPricing(res);
					setIsLoading(false);
				};

				fetchPricing();
			} else {
				setPricing(null);
			}
		} else {
			fetchAvailabilities();
		}
	}, [productSettings, productType, zone, dayType, priceClass]);

	return (
		<Container>
			<Nav
				justify
				variant="pills"
				activeKey={activeTab}
				onSelect={(selectedKey) => setActiveTab(selectedKey as Enums.EPricingAndAvailabilityType)}
			>
				{Object.values(Enums.EPricingAndAvailabilityType).map((key) => (
					<Nav.Item>
						<Nav.Link eventKey={key}>{key}</Nav.Link>
					</Nav.Item>
				))}
			</Nav>

			{activeTab === Enums.EPricingAndAvailabilityType.AVAILABILITY ? (
				<>
					<Nav
						className={"mb-4 mt-4"}
						justify
						variant="pills"
						activeKey={zone}
						onSelect={(selectedKey) => setZone(selectedKey as Types.ZoneType)}
					>
						{[Types.ZoneType.DOWNHILL, Types.ZoneType.CROSS_COUNTRY, Types.ZoneType.PARK].map((key) => (
							<Nav.Item>
								<Nav.Link eventKey={key}>{key}</Nav.Link>
							</Nav.Item>
						))}
					</Nav>

					{isLoading ? (
						<div className={"text-center"}>
							<Spinner />
						</div>
					) : (
						<>
							<Button
								className={"mb-3 p-4 w-full"}
								style={{ borderRadius: 4 }}
								variant={"outline-info"}
								onClick={() => {
									setCurrentAvailability(null);
									setModalMode(EModalMode.CREATE);
									setShowAvailabilityModal(true);
								}}
							>
								<PlusCircleDotted size={30} />
							</Button>

							{availabilities.map((availability) => (
								<Card
									className={"border border-info mb-2"}
									style={{ maxWidth: "none", borderRadius: 4 }}
									onClick={() => {
										setCurrentAvailability(availability);
										setModalMode(EModalMode.EDIT);
										setShowAvailabilityModal(true);
									}}
								>
									<Card.Body>
										<Card.Text>{availability.fields.name.nb}</Card.Text>
										<Card.Text>{availability.fields.openDays?.nb.join(", ")}</Card.Text>
									</Card.Body>
								</Card>
							))}
						</>
					)}
				</>
			) : (
				<>
					<ButtonGroup className={"w-full flex mt-3 mb-3"}>
						{/* Product type */}
						<DropdownButton
							as={ButtonGroup}
							title={productType}
							onSelect={(selectedKey) => setProductType(selectedKey as Types.CartProductType)}
							variant={"outline-primary"}
							className={"flex-1"}
						>
							{[
								Types.CartProductType.DAY_PASS,
								Types.CartProductType.HOUR_PASS,
								Types.CartProductType.FAMILY_PASS,
							].map((type) => (
								<Dropdown.Item title={type} eventKey={type}>
									{type}
								</Dropdown.Item>
							))}
						</DropdownButton>

						{/* Zone */}
						{productType !== Types.CartProductType.FAMILY_PASS && (
							<DropdownButton
								as={ButtonGroup}
								title={zone}
								onSelect={(selectedKey) => setZone(selectedKey as Types.ZoneType)}
								className={"flex-1"}
								variant={"outline-primary"}
							>
								{[Types.ZoneType.DOWNHILL, Types.ZoneType.CROSS_COUNTRY, Types.ZoneType.PARK].map(
									(type) => (
										<Dropdown.Item eventKey={type}>{type}</Dropdown.Item>
									),
								)}
							</DropdownButton>
						)}

						{/* Day type */}
						<DropdownButton
							as={ButtonGroup}
							title={dayType}
							onSelect={(selectedKey) => setDayType(selectedKey as Types.DaysType)}
							className={"flex-1"}
							variant={"outline-primary"}
						>
							{[Types.DaysType.WEEKDAYS, Types.DaysType.WEEKENDS].map((type) => (
								<Dropdown.Item eventKey={type}>{type}</Dropdown.Item>
							))}
						</DropdownButton>

						{/* Price class */}
						{productType !== Types.CartProductType.FAMILY_PASS && (
							<DropdownButton
								as={ButtonGroup}
								title={priceClass}
								onSelect={(selectedKey) => setPriceClass(selectedKey as Types.PriceClass)}
								className={"flex-1"}
								variant={"outline-primary"}
							>
								{[Types.PriceClass.ADULT, Types.PriceClass.YOUTH].map((type) => (
									<Dropdown.Item eventKey={type}>{type}</Dropdown.Item>
								))}
							</DropdownButton>
						)}
					</ButtonGroup>

					{isLoading ? (
						<div className={"text-center"}>
							<Spinner />
						</div>
					) : (
						<>
							{/*Add button*/}
							<Button
								className={"mb-3 p-4 w-full"}
								style={{ borderRadius: 4 }}
								variant={"outline-info"}
								onClick={() => {
									setCurrentPricing(null);
									setModalMode(EModalMode.CREATE);
									setShowPricingModal(true);
								}}
							>
								<PlusCircleDotted size={30} />
							</Button>

							{/*Pricing if exist*/}
							{pricing &&
								pricing.map((pr) => (
									<Card
										className={"border border-info mb-2"}
										style={{ maxWidth: "none", borderRadius: 4 }}
										onClick={() => {
											setCurrentPricing(pr);
											setModalMode(EModalMode.EDIT);
											setShowPricingModal(true);
										}}
									>
										<Card.Body>
											<Card.Text>{pr.fields.name.nb}</Card.Text>
											<Card.Text>Price: {pr.fields.price.nb}</Card.Text>
										</Card.Body>
									</Card>
								))}
						</>
					)}
				</>
			)}

			<PricingModal
				showModal={showPricingModal}
				setShowModal={setShowPricingModal}
				modalMode={modalMode}
				currentPricing={currentPricing}
				setCurrentPricing={setCurrentPricing}
				productType={productType}
				zone={zone}
				dayType={dayType}
				priceClass={priceClass}
				refresh={fetchProductSettings}
				pricingAndAvailability={productSettings}
				isLoading={isLoading}
				setIsLoading={setIsLoading}
			/>

			<AvailabilityModal
				showModal={showAvailabilityModal}
				setShowModal={setShowAvailabilityModal}
				modalMode={modalMode}
				currentAvailability={currentAvailability}
				setCurrentAvailability={setCurrentAvailability}
				zone={zone}
				isLoading={isLoading}
				setIsLoading={setIsLoading}
				refresh={fetchAvailabilities}
			/>
		</Container>
	);
};

export default PricingAndAvailability;

import * as React from "react";
import { Card, Form } from "react-bootstrap";

import LoaderButton from "../common/loader-button";
import { searchForValueCode } from "../../controllers/value-codes";
import ValueCodeItem from "./valueCodeItem";

interface ISearchResult {
  id: string;
  used: Date;
  usedByEmail: string;
  usedByName: string;
  found: boolean;
}

export function SearchValueCodes() {
  // Statew
  const [valueCodeId, setValueCodeId] = React.useState<string | null>(null);
  const [isSearching, setIsSearching] = React.useState<boolean>(false);
  const [result, setResult] = React.useState<ISearchResult>(null);

  const notFound = { id: valueCodeId, used: undefined, usedByName: undefined, usedByEmail: undefined, found: false };

  function changeHandler(event: any) {
    setValueCodeId(event.target.value);
  }

  function onKeyPressHandler(event?: any) {
    if (event.key === "Enter" || event.keyCode === 13) {
      searchHandler(event);
    }
  }

  async function searchHandler(event?: any) {
    event.preventDefault();
    setIsSearching(true);
    try {
      const valueCode = await searchForValueCode(valueCodeId);
      setResult({ ...valueCode, found: true });
      setIsSearching(false);
    } catch (error) {
      setResult(null);
      setIsSearching(false);

      const { code } = error;

      // Value code not found
      if (code === 404) {
        setResult(notFound);
      }
    }
  }

  return (
    <div className="customers">
      <Card className="w-75 align-items-center" style={{maxWidth: 'none'}}>
        <Card.Body className="w-50">
          <Card.Title>Search for value codes</Card.Title>
          <Form className="search-form align-items-center">
            <Form.Group controlId="formGroupEmail">
              <Form.Label>Value code</Form.Label>
              <Form.Control
                type="search"
                name="searchQuery"
                onKeyPress={onKeyPressHandler}
                onChange={changeHandler}
              />
            </Form.Group>
            <LoaderButton
              isLoading={isSearching}
              loadingLabel="Searching"
              onClick={searchHandler}
              disabled={!valueCodeId}
              className="mt-3 w-100"
            >
              Search
            </LoaderButton>
          </Form>
        </Card.Body>

        {result && (
          <ValueCodeItem
            valueCodeId={result.id}
            used={result.used}
            found={result.found}
            usedByEmail={result.usedByEmail}
            usedByName={result.usedByName}
          />
        )}
      </Card>
    </div>
  );
}

export const removeArrayDuplicates = (data: Array<any>, realValuesOnly = false) => {
	let unique = [];
	for (const d of data) {
		if (realValuesOnly && !d) {
			continue;
		}
		if (!unique.includes(d)) {
			unique.push(d);
		}
	}
	return unique;
};

import { FC } from "react";
import { Outlet } from "react-router";

import PassesSidebar from "./PassesSidebar";

const PassesPage: FC = () => {
	return (
		<>
			<PassesSidebar />
			<main className="main--with-sidebar">
				<Outlet />
			</main>
		</>
	);
};

export default PassesPage;

import * as React from "react";
import { Button, Modal } from "react-bootstrap";

import Loader from "../../../common/loader";
import LoaderButton from "../../../common/loader-button";
import {
	getSimilarGroupCourses,
	IGroupCourseDto,
	rescheduleReservations,
} from "../../../../controllers/lessons/groupCourses";
import SelectCourseList from "./selectCourseList";

interface IProps {
	closeModal: () => void;
	courseId: string;
	refreshCourses: () => void;
	reservations: Array<string>;
}

const MoveReservationsModal: React.FunctionComponent<IProps> = (props: IProps) => {
	let isSubscribed = true;
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
	const [error, setError] = React.useState<string | null>(null);
	const [selectedCourse, setSelectedCourse] = React.useState<string | null>(null);
	const [similarCourses, setSimilarCourses] = React.useState<Array<IGroupCourseDto>>([]);

	React.useEffect(() => {
		const loadSimilarCourses = async () => {
			setIsLoading(true);
			setError(null);
			try {
				const foundCourses = await getSimilarGroupCourses(props.courseId);
				const sortedCourses = foundCourses.sort((a, b) => a.startDates[0].localeCompare(b.startDates[0]));
				if (isSubscribed) {
					setSimilarCourses(sortedCourses);
				}
			} catch (err) {
				if (isSubscribed) {
					setError(err.message);
				}
			} finally {
				if (isSubscribed) {
					setIsLoading(false);
				}
			}
		};

		loadSimilarCourses();

		return () => {
			isSubscribed = false;
		};
	}, []);

	const moveReservations = async () => {
		setIsSubmitting(true);
		try {
			await rescheduleReservations(selectedCourse, props.reservations);
			props.closeModal();
			props.refreshCourses();
		} catch (err) {
			if (isSubscribed) {
				setError(err.message);
				setIsSubmitting(false);
			}
		}
	};

	return (
		<Modal show={true} onHide={props.closeModal}>
			<Modal.Header closeButton>
				<Modal.Title>Move reservations</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{isLoading && <Loader loadingText="Loading..." />}
				{!isLoading && !!error && (
					<div>
						<h6>An error occurred 😱</h6>
						<p>{error}</p>
					</div>
				)}
				{!isLoading && !error && !similarCourses.length && <span>No similar courses found</span>}
				{!isLoading && !!similarCourses.length && (
					<div>
						<h6>Select course:</h6>
						<SelectCourseList
							courses={similarCourses}
							selectCourse={setSelectedCourse}
							selectedCourse={selectedCourse}
						/>
					</div>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={props.closeModal}>
					Close
				</Button>
				<LoaderButton
					isLoading={isSubmitting}
					onClick={moveReservations}
					loadingLabel="Moving..."
					variant="primary"
					disabled={!selectedCourse}
				>
					Move
				</LoaderButton>
			</Modal.Footer>
		</Modal>
	);
};

export default MoveReservationsModal;

import { Types } from "@sno_oslo/shared-utils";

export const DISCIPLINE_COLOR_MAP = {
	[Types.Discipline.CROSS_COUNTRY_CLASSIC]: "#959a9e",
	[Types.Discipline.CROSS_COUNTRY_SKATE]: "#dbe3e8",
	[Types.Discipline.DOWNHILL]: "#6000d3",
	[Types.Discipline.FREESTYLE_SKI]: "#9d4dff",
	[Types.Discipline.ICE_CLIMBING]: "#3eebfa",
	[Types.Discipline.SNOWBOARD]: "#00ccde",
};

export const getDisciplineColor = (discipline: Types.Discipline) => DISCIPLINE_COLOR_MAP[discipline];

export const PAYMENT_VENDOR_COLORS = {
	stripe: "#5433FF",
	vipps: "#FF5B24",
	gift_card: "#ffd23f",
};

import { Types } from "@sno_oslo/shared-utils";
import type stripe from "stripe";
import { performRequest } from "./request";

export const getVippsAgreementDetails = (agreementId: string): Promise<Types.IVippsAgreementDetailsResponse> =>
	performRequest({
		path: `/memberships/vipps-agreement-details/${agreementId}`,
		method: "GET",
	});

export const getVippsAgreementCharges = (agreementId: string): Promise<Array<Types.IVippsAgreementCharge>> =>
	performRequest({
		path: `/memberships/vipps-agreement-charges/${agreementId}`,
		method: "GET",
	});

export const updateVippsAgreement = (
	agreementId: string,
	updatedAgreement: Partial<Types.IVippsUpdateAgreementPayload>,
): Promise<void> =>
	performRequest({
		path: `/memberships/vipps-agreement-update/${agreementId}`,
		method: "PATCH",
		body: { updatedAgreement },
	});

export const issueVippsChargeRefund = (
	refund: Types.IVippsAgreementChargeRefundPayload,
	agreementId: string,
	chargeId: string,
): Promise<void> =>
	performRequest({
		path: `/memberships/vipps-agreement-charge-refund/${agreementId}/${chargeId}`,
		method: "POST",
		body: { refund },
	});

export const cancelVippsAgreementPasses = (agreementId: string): Promise<void> =>
	performRequest({
		path: `/memberships/vipps-agreement/${agreementId}/cancel-passes`,
		method: "POST",
	});

export const getStripeSubscriptionDetails = (subscriptionId: string): Promise<stripe.Subscription> =>
	performRequest({
		path: `/memberships/stripe-subscription/${subscriptionId}`,
		method: "GET",
	});

export const getStripeSubscriptionInvoices = (subscriptionId: string): Promise<Array<stripe.Invoice>> =>
	performRequest({
		path: `/memberships/stripe-subscription/${subscriptionId}/invoices`,
		method: "GET",
	});

export const getStripePaymentIntentCharges = (paymentIntentId: string): Promise<Array<stripe.Charge>> =>
	performRequest({
		path: `/memberships/stripe-invoices/${paymentIntentId}/charges`,
		method: "GET",
	});

export const issueStripeInvoiceRefund = (paymentIntentId: string, amount: number): Promise<stripe.Refund> =>
	performRequest({
		path: "/memberships/stripe-invoice-refund",
		method: "POST",
		body: { paymentIntentId, amount },
	});

export const cancelStripeSubscription = (subscriptionId: string): Promise<stripe.Subscription> =>
	performRequest({
		path: `/memberships/stripe-subscription/${subscriptionId}/cancel`,
		method: "POST",
	});

export const cancelStripeSubscriptionPasses = (subscriptionId: string): Promise<void> =>
	performRequest({
		path: `/memberships/stripe-subscription/${subscriptionId}/cancel-passes`,
		method: "POST",
	});

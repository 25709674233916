export const formatDate = (date: string, timeZone?: string) => {
	const dateTimeFormatOptions: Intl.DateTimeFormatOptions = {
		hour: "numeric",
		minute: "2-digit",
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
		timeZone,
	};
	return new Date(date).toLocaleString("nb", dateTimeFormatOptions);
};

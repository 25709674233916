/**
 * handles all service action to do with admin users
 */
import { Types } from "@sno_oslo/shared-utils";

import { performRequest } from "./request";

/**
 * Returns all admin users
 */
export const listAllAdminUsers = (): Promise<Array<Types.IAdminUser>> =>
	performRequest({
		path: "/admin-users",
		method: "GET",
	});

/**
 * Returns all admin users
 * @param email
 */
export const getAdminUser = (email: string): Promise<Types.IAdminUser> =>
	performRequest({
		path: `/admin-users/${email}`,
		method: "GET",
	});

/**
 * Adds an admin user
 * @param user
 */
export const addAdminUser = (user: {
	email: string;
	phoneNumber: string;
	password: string;
	accessLevel: Types.AdminAccessLevel;
	accessTrainerId?: string;
}): Promise<Types.IAdminUser> =>
	performRequest({
		path: "/admin-users",
		method: "POST",
		body: user,
	});

/**
 * Updates an admin user
 * @param email
 * @param user
 */
export const updateAdminUser = (
	email: string,
	user: {
		phoneNumber?: string;
		accessLevel: Types.AdminAccessLevel;
		accessTrainerId?: string;
	},
): Promise<Types.IAdminUser> =>
	performRequest({
		path: `/admin-users/${email}`,
		method: "PUT",
		body: user,
	});

/**
 * Resets an admin user
 * @param email
 */
export const resetAdminUserPassword = (email: string) =>
	performRequest({
		path: `/admin-users/${email}/reset-password`,
		method: "POST",
	});

/**
 * Deletes the specified admin user
 * @param email
 */
export const deleteAdminUser = (email: string): Promise<{ status: string }> =>
	performRequest({
		path: `/admin-users/${email}`,
		method: "DELETE",
	});

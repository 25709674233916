import * as React from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";

import Loader from "../../common/loader";
import { getGroupCourse, IGroupCourseDto } from "../../../controllers/lessons/groupCourses";
import GroupCourseDetails from "./groupCourseDetails";

const GroupCoursePage: React.FunctionComponent<{}> = () => {
	const { groupCourseId } = useParams<{ groupCourseId: string }>();
	const [searchParams] = useSearchParams();
	const [groupCourse, setGroupCourse] = React.useState<IGroupCourseDto>(null);
	const [isLoading, setIsLoading] = React.useState<boolean>(true);

	const loadGroupCourse = async () => {
		setIsLoading(true);
		const course = await getGroupCourse(groupCourseId, searchParams.get('archived'));

		setGroupCourse(course);
		setIsLoading(false);
	};

	React.useEffect(() => {
		loadGroupCourse();
	}, [groupCourseId]);

	return (
		<div className="groupCoursePage">
			<div className="mb-2">
				<Link to="..">← Back to courses list</Link>
			</div>
			{isLoading ? (
				<Loader loadingText="Loading..." />
			) : (
				<GroupCourseDetails
					course={groupCourse}
					refreshCourses={loadGroupCourse}
					unfolded={true}
					updateReservations={(reservations) => setGroupCourse({ ...groupCourse, reservations })}
				/>
			)}
		</div>
	);
};

export default GroupCoursePage;

export const fetchRequest = async (endpoint: string, options: RequestInit) => {
	const response = await fetch(`${process.env.REACT_APP_API_URL_V2}${endpoint}`, {
		...options,
		headers: {
			...options.headers,
			"Content-Type": "application/json",
			"x-api-key": process.env.REACT_APP_API_KEY_V2,
		},
	});

	if (!response.ok) {
		throw new Error(`HTTP error! Status: ${response.status}`);
	}

	return await response.json();
};

import * as React from "react";
const MinusIcon = () => {
	return (
		<svg className="minus" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<path
				fill="#000"
				d="M3 12C3 11.4477 3.44772 11 4 11L20 11C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H4C3.44772 13 3 12.5523 3 12Z"
			/>
		</svg>
	);
};
export default MinusIcon;

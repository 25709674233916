import { Types } from "@sno_oslo/shared-utils";
import { FC, useState } from "react";
import { Button, ListGroup, Modal } from "react-bootstrap";

import { getOrderLineProductAddons, getOrderlinePrice } from "../../../utils/orderUtils";
import RefundForm, { IRefundFormValues } from "../refunds/RefundForm";
import { AllProductsType } from "../../../controllers/value-codes";

interface IOrderProductLineProps {
	line: Types.IOrderLine;
	giftCartToCashRate?: number;
	refunds: Types.IOrderRefund[];
	onRefund?: (refundInfo: IRefundFormValues, line?: Types.IOrderLine) => Promise<void>;
	products: AllProductsType[];
	loading: boolean;
}

const OrderProductLine: FC<IOrderProductLineProps> = ({
	line,
	giftCartToCashRate,
	refunds,
	onRefund,
	products,
	loading,
}) => {
	const [showRefundModal, setShowRefundModal] = useState(false);

	const discount = (line.productPayload.discount as number) || 0;
	const actualPrrice = (line.productPayload.price as number) - discount;
	const giftCardAmount = Math.round(giftCartToCashRate * actualPrrice);
	const cash = actualPrrice - giftCardAmount;

	const totalPrice = getOrderlinePrice(line);
	const totalGiftCardAmount = Math.round(giftCartToCashRate * totalPrice);
	const totalCash = totalPrice - totalGiftCardAmount;

	const addons = getOrderLineProductAddons(line);

	const refundedCash = refunds.reduce(
		(total, refund) =>
			total +
			Math.min(
				refund.cash,
				Math.round(
					refund.products.reduce(
						(refundTotal, product) =>
							refundTotal +
							(product.tempId === line.productPayload.tempId
								? product.cash +
								  (product.addons?.reduce((addonsCash, addon) => addonsCash + addon.cash, 0) || 0)
								: 0),
						0,
					),
				),
			),
		0,
	);
	const remainingCash = totalCash - refundedCash;

	let title =
		products.find((product) => product.id === line.productId)?.title ??
		((line.productPayload.name || line.productPayload.productName) as string);

	if (line.productPayload.isFamilyPack) {
		title = line.productPayload.productName as string;
	}

	return (
		<>
			<div className="ms-2 me-auto">
				<p className="mb-0 fw-bold">{title}</p>

				<p className="mb-0">Price: {actualPrrice / 100} NOK</p>
				{cash !== actualPrrice && <p className="mb-0">Cash: {cash / 100} NOK</p>}
				{giftCardAmount > 0 && <p className="mb-0">Gift card: {giftCardAmount / 100} NOK</p>}
				{discount > 0 && <p className="mb-0">Discount: {discount / 100} NOK</p>}

				{addons.length > 0 && (
					<>
						<hr className="my-2" />
						<div className="mb-2">
							Addons:
							<ListGroup className="mt-1" as="ol" numbered>
								{addons.map((addon, i) => (
									<ListGroup.Item key={i} as="li" variant="secondary">
										{addon.productName} - {addon.price / 100} NOK
									</ListGroup.Item>
								))}
							</ListGroup>
						</div>

						<p className="mb-0">Total price: {totalPrice / 100} NOK</p>
						{totalCash !== totalPrice && <p className="mb-0">Total cash: {totalCash / 100} NOK</p>}
						{totalGiftCardAmount > 0 && (
							<p className="mb-0">Total gift card: {totalGiftCardAmount / 100} NOK</p>
						)}
						{discount > 0 && <p className="mb-0">Total discount: {discount / 100} NOK</p>}
					</>
				)}

				{refundedCash > 0 && <p className="mb-0">Refunded: {refundedCash / 100} NOK</p>}

				{onRefund && remainingCash > 0 && (
					<Button onClick={() => setShowRefundModal(true)} size="sm" className="mt-1 mb-1" disabled={loading}>
						Refund
					</Button>
				)}
			</div>

			{onRefund && (
				<Modal
					show={showRefundModal}
					centered
					onHide={() => setShowRefundModal(false)}
					className="modal-background"
				>
					<Modal.Header closeButton>
						<Modal.Title>Refund product: {line.productPayload.name as string}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<RefundForm
							remainingAmount={remainingCash}
							onRefund={async (refundInfo) => {
								await onRefund(refundInfo, line);
								setShowRefundModal(false);
							}}
							canCancel
						/>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
};

export default OrderProductLine;

import * as React from "react";

import { IReservationDto } from "../../../../controllers/lessons/reservations";
import { formatDate } from "../dateTimeFormats";
import ReservationDetailsModal from "./reservationDetailsModal";

interface IProps {
	reservation: IReservationDto;
	reservationIndex: number;
	selected: boolean;
	toggleSelectedReservations: (reservationCodes: Array<string>, select: boolean) => void;
	updateReservation: (reservation: IReservationDto) => void;
}

const ReservationLine: React.FunctionComponent<IProps> = (props: IProps) => {
	const [showDetails, setShowDetails] = React.useState<boolean>(false);

	return (
		<>
			<div className="reservation" onClick={() => setShowDetails(true)}>
				<span>{props.reservationIndex}</span>
				<span>{props.reservation.reservationCode}</span>
				<span>{props.reservation.participantName}</span>
				<span>{props.reservation.participantAge}</span>
				<span>{props.reservation.participantPhone}</span>
				<span>{props.reservation.email}</span>
				<span>{formatDate(props.reservation.purchasedAt)}</span>
				<span>{props.reservation.confirmed ? "YES" : "NO"}</span>
				<div onClick={(event) => event.stopPropagation()}>
					<input
						type="checkbox"
						onChange={(event) =>
							props.toggleSelectedReservations([props.reservation.reservationCode], event.target.checked)
						}
						checked={props.selected}
					/>
				</div>
			</div>
			<ReservationDetailsModal
				closeModal={() => setShowDetails(false)}
				reservation={props.reservation}
				show={showDetails}
				updateReservation={props.updateReservation}
			/>
		</>
	);
};

export default ReservationLine;

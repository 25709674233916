import * as React from "react";
import { Button, Modal } from "react-bootstrap";

import LoaderButton from "../../common/loader-button";
import { deleteCourse, IGroupCourseDto } from "../../../controllers/lessons/groupCourses";

enum ReservationsOptions {
	MOVE = "MOVE",
	REFUND = "REFUND",
	DELETE = "DELETE",
}

interface IProps {
	course: IGroupCourseDto;
	closeModal: () => void;
	onMoveReservationsSelected: () => void;
	onDeletionSuccessful: () => void;
}

const DeleteCourseModal: React.FunctionComponent<IProps> = (props: IProps) => {
	const { course } = props;
	const spotsTaken = course.maxParticipants - course.availableSpots;
	const [showDeleteConfirm, setShowDeleteConfirm] = React.useState<boolean>(!spotsTaken);
	const [reservationOption, setReservationOption] = React.useState<ReservationsOptions>(null);
	const [showReservationOptions, setShowReservationOptions] = React.useState<boolean>(spotsTaken > 0);
	const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
	const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

	const onReservationOptionsContinue = () => {
		setShowReservationOptions(false);
		switch (reservationOption) {
			case ReservationsOptions.DELETE:
				setShowDeleteConfirm(true);
				break;
			case ReservationsOptions.MOVE:
				props.onMoveReservationsSelected();
				break;
			default:
				break;
		}
	};

	const performDeletion = async () => {
		setIsProcessing(true);
		try {
			const result = await deleteCourse(course.id, !!course.archivedAt);
			if (result) {
				props.onDeletionSuccessful();
			}
		} catch (error) {
			setErrorMessage(error.message);
			setIsProcessing(false);
		}
	};

	return (
		<Modal show={true} onHide={props.closeModal}>
			<Modal.Header closeButton>
				<Modal.Title className="warning">Delete course</Modal.Title>
			</Modal.Header>
			<Modal.Body className="deleteCourseModal">
				You are about to delete course <span className="purple">{course.name}</span>
				{errorMessage && <div className="errorMessage">{errorMessage}</div>}
				<br />
				<br />
				{showReservationOptions && (
					<>
						The course has <span className="purple">{spotsTaken}</span> reservations. How do you want to
						proceed ? <br />
						<div className="reservationsOptions">
							<label>
								<input
									type="radio"
									name="reservationOption"
									onClick={() => {
										setReservationOption(ReservationsOptions.MOVE);
										setShowDeleteConfirm(false)
									}}
								/>
								I will move them first
							</label>
							<label>
								<input
									type="radio"
									name="reservationOption"
									onClick={() => {
										setReservationOption(ReservationsOptions.DELETE);
										setShowDeleteConfirm(true);
									}}
								/>
								<span className="warning">Just delete them with the course</span>
							</label>
						</div>
					</>
				)}
			</Modal.Body>
			<Modal.Footer style={{ justifyContent: 'space-between' }}>
			{showDeleteConfirm ? (
				<div className="deleteConfirmation">
					<LoaderButton
						isLoading={isProcessing}
						onClick={performDeletion}
						loadingLabel="Deleting..."
						variant="danger"
					>
						Remove course {spotsTaken > 0 && `along with ${spotsTaken} reservations`}
					</LoaderButton>
				</div>
			) : (
				<Button variant="primary" disabled={!reservationOption} onClick={onReservationOptionsContinue}>
					Continue
				</Button>
			)}
				<Button variant="secondary" onClick={props.closeModal}>
					Cancel
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default DeleteCourseModal;

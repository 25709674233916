import * as React from "react";
import { ListGroup } from "react-bootstrap";

import { IGroupCourseDto } from "../../../../controllers/lessons/groupCourses";
import { formatDate } from "../dateTimeFormats";

interface IProps {
	course: IGroupCourseDto;
	selectCourse: (courseId: string) => void;
	selected: boolean;
}

const SelectCourseItem: React.FunctionComponent<IProps> = (props: IProps) => (
	<ListGroup.Item action active={props.selected} onClick={() => props.selectCourse(props.course.id)}>
		{props.course.name}
		{props.course.startDates && <> - {formatDate(props.course.startDates[0])}</>}
	</ListGroup.Item>
);

export default SelectCourseItem;

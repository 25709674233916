/* eslint-disable jsx-a11y/anchor-is-valid */
import { Types } from "@sno_oslo/shared-utils";
import * as qrcodeLib from "qrcode";
import * as React from "react";
import { useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";

import { archiveAccessPass, updatePass } from "../../controllers/access-passes";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";

import "react-datepicker/dist/react-datepicker.css";
import { AllProductsType } from "../../controllers/value-codes";
import moment from "moment-timezone";
import { OSLO_TIMEZONE } from "../../constants/timezone.constant";

interface IProps {
	pass: Types.IAccessProduct | Types.IAccessProductArchive;
	onUpdate: (pass: Types.IAccessProduct) => void;
	onDelete: (pass: Types.IAccessProduct) => void;
	products: AllProductsType[];
	refreshPass: () => void;
}

const AccessPassDetails: React.FC<IProps> = ({ pass, onUpdate, onDelete, products, refreshPass }) => {
	const format = useFormat();
	const { addAlert } = useSnackbar();
	const [date, setDate] = React.useState(pass.expireTime ? new Date(pass.expireTime * 1000) : null);
	const [day, setDay] = React.useState(pass.day ? new Date(pass.day * 1000) : null);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [dataUri, setDataUri] = React.useState<string | null>(null);
	const [showControlTicketModal, setShowControlTicketModal] = useState(false);

	const qrRef = React.useRef();

	const handlePrint = () => {
		if (qrRef && qrRef.current) {
			const { innerHTML } = qrRef.current;
			const printContent = innerHTML;
			const originalContent = document.body.innerHTML;

			document.body.innerHTML = printContent;

			window.print();

			document.body.innerHTML = originalContent;
			window.location.reload();
		}
	};

	const isArchvied =
		!!(pass as Types.IAccessProductArchive).archivedAt || (pass.expireTime && pass.expireTime * 1000 <= Date.now());

	// QR Code options
	const opts: any = {
		errorCorrectionLevel: "H",
		type: "image/png",
		quality: 0.9,
		margin: 4,
		color: {
			dark: "#000000",
			light: "#ffffffff",
		},
	};

	React.useEffect(() => {
		qrcodeLib.toDataURL(pass.qrCode!.toString(), opts, (err, url) => {
			setDataUri(url);
		});
	}, [pass.qrCode]);

	let qrCode = null;
	if (dataUri) {
		qrCode = (
			<Card className={"w-75"}>
				<div ref={qrRef}>
					<Card.Img className="qr-code" variant="top" src={dataUri} />
				</div>
				<Card.Body>
					<Button variant="primary" onClick={handlePrint}>
						Print QR Code
					</Button>
				</Card.Body>
			</Card>
		);
	}

	const qrCodeString = pass.qrCode || "N/A";
	const purchasedDate = new Date(pass.purchasedAt!) || "N/A";
	const activatedDate = pass.firstVisit ? new Date(pass.firstVisit) : "Not activated";
	const latestVisitDate = pass.lastVisit ? new Date(pass.lastVisit) : "Not activated";
	const userNameForPass = pass.userNameForPass || "N/A";
	const addons =
		pass.extendedAddons?.length > 0
			? pass.extendedAddons.map((val: any) => `${val.productName || val.type?.productName}`).join(", ")
			: "N/A";

	const updateAccessPass = async () => {
		setLoading(true);

		const body = day
			? {
					day: moment(day).tz(OSLO_TIMEZONE).unix(),
					expireTime: moment(day).tz(OSLO_TIMEZONE).endOf("day").unix(),
			  }
			: { expireTime: date.valueOf() / 1000 };

		const modifiedPass = await updatePass(pass.qrCode, body);

		setLoading(false);
		addAlert("Pass modified successfully", "success");

		return onUpdate?.(modifiedPass);
	};

	const archivePass = async () => {
		setLoading(true);

		try {
			await archiveAccessPass(pass.id);
			addAlert(format("alerts:archived"), "success");
			onDelete(pass);
		} catch (err) {
			addAlert((err as Error).message || format("error:default"), "danger");
			setLoading(false);
		}
	};

	const handleControl = async (passed: boolean) => {
		const body: Partial<Types.IAccessProduct> = {
			lastControl: moment().tz("Europe/Oslo").format(),
			...(passed ? { sneakAttempts: pass.sneakAttempts ?? 0 } : { sneakAttempts: (pass.sneakAttempts ?? 0) + 1 }),
		};
		await updatePass(pass.qrCode, body);
		setShowControlTicketModal(false);
		refreshPass();
	};

	const title =
		products.find(
			(product) => product.id === (pass.productId ?? pass.contentfulProductId ?? pass.punchCardProductId),
		)?.title ?? pass.productName;

	// weekday passes can´t be changed to a weekend day
	const filterDay = (date: Date) => {
		const isWeekend = (d: Date) => d.getDay() === 0 || d.getDay() === 6;
		return !isWeekend(day) ? !isWeekend(date) : true;
	};

	return (
		<Card>
			<Card.Body>
				{qrCode}
				<Card.Title>{title}</Card.Title>
				{pass.receiptContents && (
					<Card.Subtitle className="mb-2 text-muted">
						Order ID: {JSON.parse(pass.receiptContents).id}
					</Card.Subtitle>
				)}
				<Card.Subtitle className="mb-2 text-muted">QR code: {qrCodeString}</Card.Subtitle>
				<Card.Subtitle className="mb-2 text-muted">
					Purchased: {purchasedDate.toLocaleString("no")}
				</Card.Subtitle>
				<Card.Subtitle className="mb-2 text-muted">
					Activated: {activatedDate.toLocaleString("no")}
				</Card.Subtitle>
				<Card.Subtitle className="mb-2 text-muted">
					Last used: {latestVisitDate.toLocaleString("no")}
				</Card.Subtitle>
				{isArchvied ? (
					<Card.Subtitle className="mb-2 text-muted">
						Expired: {new Date((pass as Types.IAccessProductArchive).archivedAt).toLocaleString("no")}
					</Card.Subtitle>
				) : (
					<>
						{day ? (
							<>
								<Card.Subtitle className="mb-2 text-muted d-flex">
									Day:
									<DatePicker
										className="ps-2 ms-2"
										selected={day}
										onChange={(day) => setDay(day)}
										dateFormat="MMMM d, yyyy"
										minDate={new Date()}
										filterDate={filterDay}
										calendarStartDay={1}
									/>
								</Card.Subtitle>
								<Card.Subtitle className="mb-2 text-muted d-flex">
									Expires:
									<DatePicker
										className="ps-2 ms-2"
										selected={date}
										onChange={() => null}
										dateFormat="MMMM d, yyyy h:mm aa"
										disabled
									/>
								</Card.Subtitle>
							</>
						) : (
							<Card.Subtitle className="mb-2 text-muted d-flex">
								Expires: (
								<DatePicker
									className="ps-2 ms-2"
									selected={date}
									onChange={(date) => setDate(date)}
									showTimeSelect
									dateFormat="MMMM d, yyyy h:mm aa"
								/>
								)
							</Card.Subtitle>
						)}
					</>
				)}
				{pass.lockInEndDate && (
					<Card.Subtitle className="mb-2 text-muted">
						Lock in iate: {new Date(pass.lockInEndDate).toLocaleString("no")}
						{pass.cancelAtLockInEndDate ? " (cancel at the end date)" : ""}
					</Card.Subtitle>
				)}
				<Card.Subtitle className="mb-2 text-muted">User name for pass: {userNameForPass}</Card.Subtitle>
				{pass.club && <Card.Subtitle className="mb-2 text-muted">Club: {pass.club}</Card.Subtitle>}
				<Card.Subtitle className="mb-2 text-muted">Addons: {addons}</Card.Subtitle>
				<Card.Subtitle className="mb-2 text-muted">Payment source: {pass.paymentVendor || "old"}</Card.Subtitle>
				<Card.Subtitle className="mb-2 text-muted">
					Last control: {pass.lastControl ? new Date(pass.lastControl).toLocaleString("no") : "-"}
				</Card.Subtitle>
				<Card.Subtitle className={`mb-2 text-${pass.sneakAttempts > 0 ? "danger" : "muted"}`}>
					Sneak attempts: {pass.sneakAttempts ?? 0}
				</Card.Subtitle>

				{pass.vippsAgreementId ? (
					<>
						<Card.Subtitle className="mb-2 text-muted">
							Vipps agreement ID: {pass.vippsAgreementId}
						</Card.Subtitle>
					</>
				) : pass.stripeSubscriptionId ? (
					<>
						<Card.Subtitle className="mb-2 text-muted">
							Stripe subscription ID: {pass.stripeSubscriptionId}
						</Card.Subtitle>
					</>
				) : null}

				{!isArchvied && (
					<>
						<Button onClick={updateAccessPass} disabled={loading} className="mt-3">
							{format("common:save")}
						</Button>
						<hr />
						<Button onClick={archivePass} disabled={loading} variant="danger">
							{format("common:archive")}
						</Button>

						<Button className={"mt-3"} onClick={() => setShowControlTicketModal(true)}>
							Control ticket
						</Button>

						<Modal show={showControlTicketModal} onHide={() => setShowControlTicketModal(false)} centered>
							<Modal.Header closeButton>
								<Modal.Title>Control passed?</Modal.Title>
							</Modal.Header>
							<Modal.Footer className="d-flex justify-content-between">
								<Button className="flex-grow-1 w-50" onClick={() => handleControl(true)}>
									Yes
								</Button>
								<Button
									className={"flex-grow-1 w-50"}
									variant="danger"
									onClick={() => handleControl(false)}
								>
									No
								</Button>
							</Modal.Footer>
						</Modal>
					</>
				)}
			</Card.Body>
		</Card>
	);
};

export default AccessPassDetails;

import { Types } from "@sno_oslo/shared-utils";
import { FC, Fragment, useEffect, useMemo, useState } from "react";
import { Modal, ModalProps, Row, Spinner } from "react-bootstrap";
import { getPromoCodeRedemption } from "../../controllers/promo-codes";
import moment from "moment";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";
import { Link } from "react-router-dom";

export interface IProps extends ModalProps {
	redemptionId: string;
}

const PromoCodeRedemptionDetailsModal: FC<IProps> = ({ redemptionId, show, onHide, children, ...rest }) => {
	const { addAlert } = useSnackbar();
	const format = useFormat();
	const [redemption, setRedemption] = useState<Types.IPromoCodeRedemption | null>(null);

	const dataList = useMemo(
		() =>
			redemption && [
				{
					label: "ID",
					value: redemption.id,
				},
				{
					label: "Order ID",
					value: redemption.orderId,
				},
				{
					label: "Promo code ID",
					value: redemption.promoCodeId,
					href: `/promocodes/${redemption.promoCodeId}/edit`,
				},
				{
					label: "Redemeed at",
					value: moment(redemption.redeemedAt).format("YYYY-MM-DD LT"),
				},
			],
		[redemption],
	);

	useEffect(() => {
		const fetchRedemption = async () => {
			try {
				setRedemption(await getPromoCodeRedemption(redemptionId));
			} catch (err) {
				if ((err as { code?: number }).code === 404) {
					addAlert("Redemption not found", "danger");
				} else {
					addAlert((err as Error).message || format("error:default"), "danger");
				}

				onHide();
			}
		};

		if (show && redemptionId && redemption?.id !== redemptionId) {
			fetchRedemption();
		}
	}, [redemptionId, show]);

	return (
		<Modal centered show={show} onHide={onHide} {...rest}>
			<Modal.Header closeButton>
				<Modal.Title>Redemption details</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{redemption ? (
					<>
						<Row as="dl" className="mb-0">
							{dataList.map(({ label, value, href }, i, arr) => (
								<Fragment key={label}>
									<dt>{label}</dt>
									<dd className={i === arr.length - 1 ? "mb-0" : undefined}>
										{href ? <Link to={href}>{value}</Link> : value}
									</dd>
								</Fragment>
							))}
						</Row>
						{children}
					</>
				) : (
					<div className="my-2 text-center">
						<Spinner className="text-primary" />
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default PromoCodeRedemptionDetailsModal;

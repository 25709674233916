import { Types } from "@sno_oslo/shared-utils";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { Badge, ListGroup } from "react-bootstrap";

import useFormat from "../../hooks/useFormat";
import TrainerReservationModal from "./TrainerReservationModal";

interface IProps {
	reservations: Types.IPersonalTrainerReservation[];
	onChangeReservations: (reservations: Types.IPersonalTrainerReservation[]) => void;
}

moment.updateLocale("en", {
	calendar: {
		lastDay: "[Yesterday]",
		sameDay: "[Today]",
		nextDay: "[Tomorrow]",
		lastWeek: "[Last] dddd",
		nextWeek: "[Next] dddd",
		sameElse: "L",
	},
});

const TrainerReservationsList: React.FC<IProps> = ({ reservations, onChangeReservations }) => {
	const format = useFormat();
	const [activeReservationId, setActiveReservationId] = useState<Types.IPersonalTrainerReservation["id"] | null>(
		null,
	);
	const activeReservation = reservations.find((event) => event.id === activeReservationId);
	const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);
	const sortedReservations = useMemo(
		() => reservations.slice().sort((a, b) => new Date(b.start).getTime() - new Date(a.start).getTime()),
		[reservations],
	);

	const handleReservationUpdated = useCallback(
		(reservation: Types.IPersonalTrainerReservation) => {
			onChangeReservations(
				reservations.map((r) => r.id === reservation.id ? reservation : r),
			);
		},
		[reservations, onChangeReservations],
	);

	const handleReservationDeleted = useCallback(
		(reservationId: Types.IPersonalTrainerReservation["id"]) => {
			onChangeReservations(reservations.filter((r) => r.id !== reservationId));
			setPreviewModalOpen(false);
		},
		[reservations, onChangeReservations],
	);

	return (
		<>
			<ListGroup>
				{sortedReservations.map(({ id, start, end }) => (
					<ListGroup.Item
						key={id}
						action
						onClick={() => {
							setActiveReservationId(id);
							setPreviewModalOpen(true);
						}}
						className="d-flex justify-content-between align-items-start"
					>
						<div className="me-2 me-auto">
							<div className="fw-bold">
								({moment(start).format("DD.MM.YYYY")})
							</div>
							{format("trainers:reservations:item:timeRange", {
								from: moment(start).format("LT"),
								to: moment(end).format("LT"),
							})}
						</div>
						<Badge bg="primary" pill>
							{moment.duration(moment(end).diff(moment(start))).asHours()}h
						</Badge>
					</ListGroup.Item>
				))}
			</ListGroup>

			{activeReservation && (
				<TrainerReservationModal
					show={isPreviewModalOpen}
					onHide={() => setPreviewModalOpen(false)}
					reservation={activeReservation}
					onReservationDeleted={handleReservationDeleted}
					onReservationUpdated={handleReservationUpdated}
				/>
			)}
		</>
	);
};

export default TrainerReservationsList;

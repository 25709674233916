/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import useFormat from "../../hooks/useFormat";
import TrainersAvailabilitiesModal from "./TrainersAvailabilitiesModal";
import TrainersReservationsModal from "./TrainersReservationsModal";

const Sidebar: React.FC = () => {
	const format = useFormat();
	const [isReservationsModalOpen, setReservationsModalOpen] = useState(false);
	const [isAvailabilitiesModalOpen, setAvailabilitiesModalOpen] = useState(false);

	return (
		<>
			<nav className="sidebar">
				<h2>{format("trainers:title")}</h2>
				<ul>
					<li>
						<a href="#">{format("common:home")}</a>
					</li>
					<li>
						<NavLink to="" end className={({ isActive }) => (isActive ? "selected" : undefined)}>
							{format("trainers:title")}
						</NavLink>
					</li>
					<li>
						<NavLink to="add" end className={({ isActive }) => (isActive ? "selected" : undefined)}>
							{format("trainers:add")}
						</NavLink>
					</li>
					<li>
						<a
							href="#"
							onClick={(e) => {
								e.preventDefault();
								setReservationsModalOpen(true);
							}}
						>
							{format("trainers:reservations:all")}
						</a>
					</li>
					<li>
						<a
							href="#"
							onClick={(e) => {
								e.preventDefault();
								setAvailabilitiesModalOpen(true);
							}}
						>
							{format("trainers:availabilities:all")}
						</a>
					</li>
				</ul>
			</nav>

			<TrainersReservationsModal
				show={isReservationsModalOpen}
				onHide={() => setReservationsModalOpen(false)}
				title={format("trainers:reservations:all")}
			/>

			<TrainersAvailabilitiesModal
				show={isAvailabilitiesModalOpen}
				onHide={() => setAvailabilitiesModalOpen(false)}
				title={format("trainers:availabilities:all")}
			/>

		</>
	);
};

export default Sidebar;

import { createClient } from "contentful-management";

export const managementClient = createClient(
	{
		accessToken: process.env.REACT_APP_CONTENTFUL_MANAGEMENT_TOKEN,
	},
	{
		type: "plain",
		defaults: {
			spaceId: process.env.REACT_APP_CONTENTFUL_SPACE,
			environmentId: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
		},
	},
);

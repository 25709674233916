/**
 * This controller handles everything stored in session/local storage
 */
import { Types } from "@sno_oslo/shared-utils";
import jwtDecode from "jwt-decode";

/**
 * The structure of a decoded accesss token
 */
export interface IAccessTokenPayload {
	uid: string;
	accessLevel: Types.AdminAccessLevel;
	accessTrainerId?: string;
	exp: number;
}

// Storage keys
const ACCESS_TOKEN_KEY = "access-token";

/**
 * Returns the access token
 */
export const getLoggedUserToken = () => sessionStorage.getItem(ACCESS_TOKEN_KEY);

/**
 * Returns the access token payload of currently logged user (or null)
 */
export const getLoggedUserTokenPayload = (): IAccessTokenPayload | null => {
	const token = getLoggedUserToken();
	if (token) {
		try {
			const payload = jwtDecode(token) as IAccessTokenPayload;
			// Make sure token hasn't expired
			if (payload.exp * 1000 > Date.now()) {
				return payload;
			}
			// eslint-disable-next-line no-empty
		} catch {}
	}
	return null;
};

/**
 * Stores the access token to session storage
 * @param token
 */
export const saveLoggedUserToken = (token: string) => {
	sessionStorage.setItem(ACCESS_TOKEN_KEY, token);
};

/**
 * Removes the access token
 * Will log the user out
 */
export const removeLoggedUserToken = () => {
	sessionStorage.removeItem(ACCESS_TOKEN_KEY);
};

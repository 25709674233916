import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { Helpers, Types } from "@sno_oslo/shared-utils";
import { useNavigate, useParams } from "react-router-dom";
import { getGiftCard } from "../../controllers/gift-cards";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";
import Loader from "../common/loader";
import FetchErrorAlert from "../common/FetchErrorAlert";
import { Table } from "react-bootstrap";
import GiftCardForm from "./GiftCardForm";

interface IProps {
	archived?: boolean;
}

const PreviewGiftCard: React.FC<IProps> = ({ archived = false }) => {
	const { code, id } = useParams<{ code?: string; id?: string }>();
	const format = useFormat();
	const navigate = useNavigate();
	const { addAlert } = useSnackbar();
	const [giftCard, setGiftCard] = useState<Types.IGiftCard>();
	const [redemptions, setRedemptions] = useState<Array<Types.IGiftCardRedemption>>();
	const [fetchError, setFetchError] = useState<Error>(null);

	const fetchGiftCard = useCallback(async () => {
		try {
			setFetchError(null);

			const data = await getGiftCard(archived ? id : code, archived, id);

			setGiftCard(data.giftCard);
			setRedemptions(data.redemptions);
		} catch (err) {
			if ((err as { code?: number }).code === 404) {
				addAlert(format("giftCards:preview:notFound", { code }), "danger");
				navigate("../", { replace: true });
			} else {
				setFetchError(err as Error);
			}
		}
	}, [code, id, addAlert, navigate]);

	useEffect(() => {
		fetchGiftCard();
	}, [code, id]);

	return (
		<>
			<h1>{format("giftCards:preview:title")}</h1>
			{fetchError ? (
				<FetchErrorAlert message={fetchError.message} onRetry={fetchGiftCard} />
			) : giftCard && redemptions ? (
				<>
					<div className="panel w-100 max-w-md mx-auto">
						<GiftCardForm giftCard={giftCard} onSaved={setGiftCard} />
					</div>

					{redemptions.length > 0 && (
						<>
							<h1 className="mt-5">{format("giftCards:redemptions")}</h1>

							<div className="max-w-xl mx-auto">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>{format("common:id")}</th>
											<th>{format("common:amount")} (NOK)</th>
											<th>{format("common:productId")}</th>
											<th>{format("common:redeemedAt")}</th>
										</tr>
									</thead>
									<tbody>
										{redemptions.map((redemption) => (
											<tr key={redemption.id}>
												<td>{redemption.id}</td>
												<td>{Helpers.formatPrice(redemption.amount / 100)}</td>
												<td>{redemption.productId}</td>
												<td>{moment(redemption.redeemedAt).format("YYYY-MM-DD LT")}</td>
											</tr>
										))}
									</tbody>
								</Table>
							</div>
						</>
					)}
				</>
			) : (
				<Loader loadingText={format("common:loading")} />
			)}
		</>
	);
};

export default PreviewGiftCard;

import { Types } from "@sno_oslo/shared-utils";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Button, ListGroup, ListGroupItem, Modal, Spinner, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import FetchErrorAlert from "../common/FetchErrorAlert";
import Loader from "../common/loader";
import { getPersonalTrainer, getPersonalTrainerReservations } from "../../controllers/personal-trainers";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";
import CalendarIcon from "../icons/CalendarIcon";
import PersonalTrainerCalendar from "./TrainerCalendar";
import TrainerForm from "./TrainerForm";
import TrainerReservationsList from "./TrainerReservationsList";

interface IProps {
	id: string;
}

const TrainerProfile: React.FC<IProps> = ({ id }) => {
	const format = useFormat();
	const [trainer, setTrainer] = useState<Types.IPersonalTrainer>();
	const [fetchError, setFetchError] = useState<Error | null>(null);
	const [upcomingReservations, setUpcomingReservations] = useState<Array<Types.IPersonalTrainerReservation>>();
	const { addAlert } = useSnackbar();
	const navigate = useNavigate();
	const [isCalendarOpen, setCalendarOpen] = useState(false);

	const fetchTrainer = useCallback(async () => {
		try {
			setFetchError(null);

			setTrainer(await getPersonalTrainer(id));
		} catch (err) {
			if ((err as { code?: number }).code === 404) {
				addAlert(format("trainers:edit:notFound", { id }), "danger");
				navigate("../", { replace: true });
			} else {
				setFetchError(err as Error);
			}
		}
	}, [id, format, addAlert, navigate]);

	const fetchReservations = useCallback(async () => {
		try {
			setUpcomingReservations(
				await getPersonalTrainerReservations(id, {
					from: moment().toISOString(),
					to: moment().add(30, "day").endOf("day").toISOString(),
				})
			);
		} catch (err) {
			addAlert((err as Error).message || format("error:default"), "danger");
		}
	}, [id, format, addAlert]);

	const handleSaved = useCallback(
		(savedTrainer: Types.IPersonalTrainer) => {
			addAlert(format("alerts:saved"), "success");
			setTrainer(savedTrainer);
		},
		[format, addAlert],
	);

	useEffect(() => {
		fetchTrainer();
		fetchReservations();
	}, [id]);

	return (
		<>
			{fetchError ? (
				<FetchErrorAlert message={fetchError.message} onRetry={fetchTrainer} />
			) : trainer ? (
				<Stack gap={4} className="align-items-center">
					<div className="panel w-100 max-w-md">
						<h5>{format("trainers:section:calendar:title")}</h5>

						<div className="mb-3">
							<h6>{format("trainers:section:calendar:reservations")}</h6>
							{upcomingReservations ? (
								upcomingReservations.length > 0 ? (
									<TrainerReservationsList
										reservations={upcomingReservations}
										onChangeReservations={setUpcomingReservations}
									/>
								) : (
									<i>{format("common:emptyState")}</i>
								)
							) : (
								<ListGroup>
									<ListGroupItem className="d-flex justify-content-center py-3">
										<Spinner
											variant="primary"
											size="sm"
											animation="border"
											role="status"
											aria-hidden="true"
											className="opacity-75"
										/>
									</ListGroupItem>
								</ListGroup>
							)}
						</div>

						<Button
							onClick={() => setCalendarOpen(true)}
							className="btn-icon d-inline-flex align-items-center"
						>
							<CalendarIcon className="me-2" />
							{format("trainers:section:calendar:show")}
						</Button>
					</div>

					<Modal show={isCalendarOpen} fullscreen onHide={() => setCalendarOpen(false)}>
						<Modal.Header closeButton>
							<Modal.Title className="text-primary">
								{format("trainers:section:calendar:title")}
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<PersonalTrainerCalendar personalTrainerId={id} />
						</Modal.Body>
					</Modal>

					<div className="panel w-100 max-w-md">
						<h5>{format("common:profile")}</h5>
						<TrainerForm trainer={trainer} onSaved={handleSaved} />
					</div>
				</Stack>
			) : (
				<Loader loadingText={format("common:loading")} />
			)}
		</>
	);
};

export default TrainerProfile;

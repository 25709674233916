import { Types } from "@sno_oslo/shared-utils";

import { performRequest } from "./request";

export const getGiftCards = (
	params?: Record<string, any>,
): Promise<{ giftCards: Array<Types.IGiftCard>; total: number }> =>
	performRequest({
		path: `/gift-cards?${new URLSearchParams(params).toString()}`,
		method: "GET",
	});

export const getGiftCard = (
	code: Types.IGiftCard["code"],
	archived: boolean,
	id?: Types.IGiftCard["id"],
): Promise<{ giftCard: Types.IGiftCard; redemptions: Array<Types.IGiftCardRedemption> }> =>
	performRequest({
		path: `/gift-cards/${code}?archived=${archived}${id ? `&id=${id}` : ""}`,
		method: "GET",
	});

export const createGiftCards = (body: { value: number; quantity: number }): Promise<Array<Types.IGiftCard>> =>
	performRequest({
		path: "/gift-cards/bulk",
		method: "POST",
		body,
	});

// export const updateOrderGiftCards = (body: {
// 	orderGiftCards: Array<IOrderDetailsGiftCard>;
// }): Promise<{ updatedGiftCards: Array<Types.IGiftCard>; unrefundedAmount: number }> =>
// 	performRequest({
// 		path: "/gift-cards/order-gc-update",
// 		method: "PUT",
// 		body,
// 	});

export const updateGiftCardExpireTime = (code: Types.IGiftCard["code"], expireTime: string): Promise<Types.IGiftCard> =>
	performRequest({
		path: `/gift-cards/${code}/expire`,
		method: "PATCH",
		body: {
			expireTime,
		},
	});

export const updateGiftCardAmountLeft = (code: Types.IGiftCard["code"], amountLeft: number): Promise<Types.IGiftCard> =>
	performRequest({
		path: `/gift-cards/${code}/amount-left`,
		method: "PATCH",
		body: {
			amountLeft,
		},
	});

export const archiveGiftCard = (code: Types.IGiftCard["code"]): Promise<void> =>
	performRequest({
		path: `/gift-cards/${code}/archive`,
		method: "POST",
	});

export const deleteGiftCard = (code: Types.IGiftCard["code"], archived = false): Promise<void> =>
	performRequest({
		path: `/gift-cards/${code}?archived=${archived}`,
		method: "DELETE",
	});

export const getGiftCardRedeptionsByIntent = (redemptionIntentId: string): Promise<Array<Types.IGiftCardRedemption>> =>
	performRequest({
		path: `/gift-cards/redeemed/${redemptionIntentId}`,
		method: "GET",
	});

import { FC } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { useForm, type SubmitHandler } from "react-hook-form";
import { Helpers, Types } from "@sno_oslo/shared-utils";

// import { addAccessPass } from "../../controllers/access-passes";
// import useFormat from "../../hooks/useFormat";
// import useSnackbar from "../../hooks/useSnackbar";
import moment from "moment";

export interface IAddPassesFormValues {
	amount: number;
	userId: string;
	type: Types.ProductType | string;
	zones: Types.ZoneType[];
	priceClass: Types.PriceClass;
	price?: number;
	duration?: number;
	daysType: Types.DaysType;
	expiresAt: string;
}

export interface IAddPassesFormPayload
	extends Pick<
		IAddPassesFormValues,
		"amount" | "userId" | "type" | "duration" | "zones" | "priceClass" | "price" | "daysType"
	> {
	expireTime?: number;
}

interface IAddPassesFormProps {
	onAddPasses: (payload: IAddPassesFormPayload) => void;
	isLoading?: boolean;
}

const AddPassesForm: FC<IAddPassesFormProps> = ({ onAddPasses, isLoading }) => {
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
		watch,
	} = useForm<IAddPassesFormValues>({
		defaultValues: {
			amount: 1,
			userId: "",
			type: Types.ProductType.DAYPASS,
			zones: [Types.ZoneType.DOWNHILL, Types.ZoneType.CROSS_COUNTRY],
			priceClass: Types.PriceClass.ADULT,
		},
	});
	const type = watch("type");

	const onSubmit: SubmitHandler<IAddPassesFormValues> = async ({ price, expiresAt, ...values }) => {
		onAddPasses({
			...(typeof price === "number" && !isNaN(price) ? { price: price * 100 } : {}),
			...(expiresAt ? { expireTime: moment(expiresAt).unix() } : {}),
			...values,
		});
	};

	return (
		<Form noValidate onSubmit={handleSubmit(onSubmit)}>
			<Stack gap={4}>
				<Form.Group controlId="price">
					<Form.Label>Amount</Form.Label>
					<Form.Control
						isInvalid={!!errors.amount}
						size="lg"
						step="0"
						min="1"
						type="number"
						{...register("amount", {
							valueAsNumber: true,
							min: 1,
							validate: (value) => Number.isInteger(value),
						})}
					/>
					{errors.amount && <Form.Control.Feedback type="invalid">Invalid amount</Form.Control.Feedback>}
				</Form.Group>

				<Form.Group controlId="type">
					<Form.Label>Type</Form.Label>
					<Form.Select {...register("type", { required: true })}>
						{[Types.ProductType.DAYPASS, "custom"].map((type) => (
							<option key={type} value={type}>
								{Helpers.capitalize(type)}
							</option>
						))}
					</Form.Select>
				</Form.Group>

				{type === "custom" && (
					<Form.Group controlId="duration">
						<Form.Label>Duration (days)</Form.Label>
						<Form.Control
							size="lg"
							type="number"
							step="0"
							{...register("duration", {
								valueAsNumber: true,
								required: true,
								min: 1,
							})}
						/>
					</Form.Group>
				)}

				<Form.Group controlId="start">
					<Form.Label>Expires at</Form.Label>
					<Form.Control type="datetime-local" isInvalid={!!errors.expiresAt} {...register("expiresAt")} />
					{errors.expiresAt && <Form.Control.Feedback type="invalid">Invalid date</Form.Control.Feedback>}
				</Form.Group>

				<Form.Group controlId="zones">
					<Form.Label>Zone</Form.Label>
					<Form.Control as="select" multiple htmlSize={2} {...register("zones")}>
						{[Types.ZoneType.DOWNHILL, Types.ZoneType.CROSS_COUNTRY].map((zone) => (
							<option key={zone} value={zone}>
								{Helpers.capitalize(zone)}
							</option>
						))}
					</Form.Control>
				</Form.Group>

				<Form.Group controlId="daysType">
					<Form.Label>Days type</Form.Label>
					<Form.Select {...register("daysType", { required: true })}>
						{Object.values(Types.DaysType).map((type) => (
							<option key={type} value={type}>
								{Helpers.capitalize(type)}
							</option>
						))}
					</Form.Select>
				</Form.Group>

				<Form.Group controlId="userId">
					<Form.Label>User ID</Form.Label>
					<Form.Control
						isInvalid={!!errors.userId}
						size="lg"
						placeholder="e.g. snooslo"
						{...register("userId", {
							required: true,
							maxLength: 50,
						})}
					/>
					{errors.userId && (
						<Form.Control.Feedback type="invalid">
							{errors.userId.type === "required" ? "User ID is required" : "User ID is invalid"}
						</Form.Control.Feedback>
					)}
				</Form.Group>

				<Form.Group controlId="priceClass">
					<Form.Label>Price class</Form.Label>
					<Form.Select {...register("priceClass", { required: true })}>
						{Types.getAllPriceClasses().map((priceClass) => (
							<option key={priceClass} value={priceClass}>
								{Helpers.capitalize(priceClass)}
							</option>
						))}
					</Form.Select>
				</Form.Group>

				<Form.Group controlId="price">
					<Form.Label>Price (NOK)</Form.Label>
					<Form.Control
						size="lg"
						type="number"
						{...register("price", {
							valueAsNumber: true,
						})}
					/>
				</Form.Group>

				<Button type="submit" size="lg" disabled={isSubmitting || isLoading} className="align-self-start">
					Add
				</Button>
			</Stack>
		</Form>
	);
};

export default AddPassesForm;

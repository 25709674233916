import { Types } from '@sno_oslo/shared-utils';
import React, { useCallback, useState } from 'react';
import { Button, Form, Stack } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { createGiftCards } from '../../controllers/gift-cards';
import useFormat from '../../hooks/useFormat';
import useSnackbar from '../../hooks/useSnackbar';
import GiftCardsTable from './GiftCardsTable';

interface IFormValues {
    value: number;
    quantity: number;
}

const AddGiftCard: React.FC = () => {
    const format = useFormat();
    const { addAlert } = useSnackbar();
    const [createdGiftCards, setCreatedGiftCards] = useState<Array<Types.IGiftCard>>(null);

    const {
        register,
        handleSubmit,
        formState: { isSubmitting, errors },
    } = useForm<IFormValues>({
        defaultValues: {
            value: 1000,
            quantity: 1
        },
        mode: "onBlur",
    });

    const onSubmit = useCallback<SubmitHandler<IFormValues>>(
        async (values) => {
            try {
                setCreatedGiftCards(
                    await createGiftCards({ value: values.value * 100, quantity: values.quantity })
                );

                addAlert(format('alerts:saved'), "success");
            } catch (err) {
                addAlert((err as Error).message || format("error:default"), "danger");
            }
        },
        [addAlert, format],
    );

    return (
        <>
            <h1>{format('giftCards:add')}</h1>
            {createdGiftCards ? (
                <div className="max-w-xl mx-auto">
                    <GiftCardsTable giftCards={createdGiftCards} />
                </div>
            ) : (
                <div className='panel w-100 max-w-sm mx-auto'>
                    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Stack gap={3}>
                            <Form.Group controlId="value">
                                <Form.Label>{format("common:value")} (NOK)</Form.Label>
                                <Form.Control
                                    type="number"
                                    {...register("value", { required: true, min: 100, max: 10000, valueAsNumber: true })}
                                    isInvalid={!!errors.value}
                                />
                                {errors.value && (
                                    <Form.Control.Feedback type="invalid">
                                        {format(errors.value.type === "required" ? "validation:required" : "validation:invalid", {
                                            field: format("common:value"),
                                        })}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>

                            <Form.Group controlId="quantity">
                                <Form.Label>{format("common:quantity")}</Form.Label>
                                <Form.Control
                                    type="number"
                                    {...register("quantity", { required: true, min: 1, max: 100, valueAsNumber: true })}
                                    isInvalid={!!errors.quantity}
                                />
                                {errors.quantity && (
                                    <Form.Control.Feedback type="invalid">
                                        {format(errors.quantity.type === "required" ? "validation:required" : "validation:invalid", {
                                            field: format("common:quantity"),
                                        })}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Stack>

                        <Button type="submit" disabled={isSubmitting} className="mt-3">
                            {format(isSubmitting ? "common:loading" : "common:submit")}
                        </Button>
                    </Form>
                </div>
            )}
        </>
    );
}

export default AddGiftCard;
import jwtDecode from "jwt-decode";
import React, { useCallback, useMemo } from "react";
import { Alert, Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-dom";

import { IChangePasswordTokenPayload } from "../../controllers/auth";
import useFormat from "../../hooks/useFormat";
import ChangePasswordForm from "./ChangePasswordForm";

const ChangePasswordPage: React.FC = () => {
	const format = useFormat();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const queryToken = searchParams.get("token");
	const validToken = useMemo(() => {
		if (queryToken) {
			try {
				// Decode token and make sure it hasn't expired
				const payload = jwtDecode(queryToken) as IChangePasswordTokenPayload;
				if (payload.exp * 1000 > Date.now()) {
					return queryToken;
				}
				// eslint-disable-next-line no-empty
			} catch {}
		}
	}, [queryToken]);

	const handleSaved = useCallback(() => {
		navigate("/");
	}, [navigate]);

	return (
		<Container>
			<h2>{format("changePassword:title")}</h2>
			{validToken ? (
				<ChangePasswordForm token={validToken} onSaved={handleSaved} />
			) : (
				<Alert variant="danger">
					<Alert.Heading>{format("changePassword:expired:title")}</Alert.Heading>
					<p>{format("changePassword:expired:details")}</p>
					<hr />
					<div className="d-flex justify-content-end">
						<Link to="/">
							<Button as="span" variant="outline-danger">
								{format("common:goBack")}
							</Button>
						</Link>
					</div>
				</Alert>
			)}
		</Container>
	);
};

export default ChangePasswordPage;

/**
 * handles all service action to do with personal trainers
 */
import { Types } from "@sno_oslo/shared-utils";

import { performRequest } from "./request";

export const getPromoCodes = (): Promise<Array<Types.IPromoCode>> =>
	performRequest({
		path: "/promocodes",
		method: "GET",
	});

export const getPromoCode = (id: Types.IPromoCode["id"]): Promise<Types.IPromoCode | null> =>
	performRequest({
		path: `/promocodes/${id}`,
		method: "GET",
	});

export const createPromoCode = (promocode: Omit<Types.IPromoCode, "id">) =>
	performRequest({
		path: "/promocodes",
		method: "POST",
		body: { ...promocode, value: +promocode.value },
	});

export const updatePromoCode = (id: Types.IPromoCode["id"], promocode: Omit<Types.IPromoCode, "id">) =>
	performRequest({
		path: `/promocodes/${id}`,
		method: "PUT",
		body: { ...promocode, value: +promocode.value },
	});

export const deletePromoCode = (id: Types.IPromoCode["id"]): Promise<{ status: string }> =>
	performRequest({
		path: `/promocodes/${id}`,
		method: "DELETE",
	});

export const getPromoCodeRedemption = (
	id: Types.IPromoCodeRedemption["id"],
): Promise<Types.IPromoCodeRedemption | null> =>
	performRequest({
		path: `/promocodes/redemptions/${id}`,
		method: "GET",
	});

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Types } from "@sno_oslo/shared-utils";
import * as React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { getVC_CSV } from "../../controllers/value-codes";

interface LocationState {
  template: Types.ITemplate
}

export default function Sidebar() {
  const { template } = useLocation().state as LocationState

  const downloadCSV = async () => {
    const csvBlob = await getVC_CSV(template)
    
    const url = window.URL.createObjectURL(new Blob([csvBlob]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute(
      'download',
      `valuecodes.csv`,
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  return (
    <React.Fragment>
      <nav className="sidebar">
        <h2>Value codes</h2>
        <ul>
          <li>
            <a href="#">Home</a>
          </li>
          <li>Value Codes</li>
          <ul>
            <li>
              <NavLink
                to=""
                state={{ template }}
                end
                className={({ isActive }) => (isActive ? "selected" : undefined)}
              >
                Active
              </NavLink>
            </li>
          </ul>
          <li>
            <NavLink to="add" state={{ template }} end className={({ isActive }) => (isActive ? "selected" : undefined)}>
              Add value code
            </NavLink>
          </li>
          <li>
            <NavLink
              to="report"
              state={{ template }}
              className={({ isActive }) => (isActive ? "selected" : undefined)}
            >
              Report
            </NavLink>
          </li>
          <li>
            <div onClick={downloadCSV} style={{cursor: "pointer"}}>
              Download CSV
            </div>
          </li>
        </ul>
      </nav>
    </React.Fragment>
  );
}

import { Types } from "@sno_oslo/shared-utils";
import * as React from "react";
import { Tab, Tabs } from "react-bootstrap";

import CampParticipantDetails from "./campParticipantDetails";
import GuardianDetails from "./guardianDetails";

interface IProps {
	editMode: boolean;
	reservation: Types.IReservation;
	updateReservation: (reservation: Types.IReservation) => void;
}

const CampReservationDetails: React.FunctionComponent<IProps> = (props: IProps) => (
	<Tabs defaultActiveKey="participant">
		<Tab eventKey="participant" title="Participant">
			<CampParticipantDetails
				editMode={props.editMode}
				reservation={props.reservation}
				updateReservation={props.updateReservation}
			/>
		</Tab>
		<Tab eventKey="required-guardian" title="Required Guardian">
			<GuardianDetails
				editMode={props.editMode}
				guardianType={Types.GuardianType.REQUIRED}
				reservation={props.reservation}
				updateReservation={props.updateReservation}
			/>
		</Tab>
		<Tab eventKey="additional-guardian" title="Additional Guardian">
			<GuardianDetails
				editMode={props.editMode}
				guardianType={Types.GuardianType.ADDITIONAL}
				reservation={props.reservation}
				updateReservation={props.updateReservation}
			/>
		</Tab>
		<Tab eventKey="pickup-guardian" title="Pick up Guardian">
			<GuardianDetails
				editMode={props.editMode}
				guardianType={Types.GuardianType.PICK_UP}
				reservation={props.reservation}
				updateReservation={props.updateReservation}
			/>
		</Tab>
	</Tabs>
);

export default CampReservationDetails;

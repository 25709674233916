import { Types } from "@sno_oslo/shared-utils";
import React, { useCallback } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";

import { reschedulePersonalTrainerReservation } from "../../controllers/personal-trainers";
import useFormat from "../../hooks/useFormat";
import useSnackbar from "../../hooks/useSnackbar";
import { formatDatetimeLocal } from "../../utils/dateUtils";

interface IProps {
	reservation: Types.IPersonalTrainerReservation;
	onCancel: () => void;
	onRescheduled: (reservation: Types.IPersonalTrainerReservation) => void;
}

interface IFormValues {
	start: string;
	message?: string;
}

const TrainerReservationRescheduleForm: React.FC<IProps> = ({ reservation, onCancel, onRescheduled }) => {
	const format = useFormat();
	const { addAlert } = useSnackbar();

	const {
		register,
		handleSubmit,
		formState: { isSubmitting, errors },
	} = useForm<IFormValues>({
		defaultValues: {
			start: formatDatetimeLocal(new Date(reservation.start)),
		},
		mode: "onBlur",
	});

	const onSubmit = useCallback<SubmitHandler<IFormValues>>(
		async (values) => {
			try {
				const savedReservation = await reschedulePersonalTrainerReservation(
					reservation.personalTrainerId,
					reservation.id,
					{
						start: new Date(values.start).toISOString(),
						message: values.message,
					},
				);

				addAlert(format("trainers:reservations:preview:reschedule:success"), "success");
				onRescheduled(savedReservation);
			} catch (err) {
				addAlert((err as Error).message || format("error:default"), "danger");
			}
		},
		[reservation.id, reservation.personalTrainerId, format, addAlert],
	);

	return (
		<Form noValidate onSubmit={handleSubmit(onSubmit)}>
			<Stack gap={3}>
				<Form.Group>
					<Form.Label>{format("common:start")}</Form.Label>
					<Form.Control
						isInvalid={!!errors.start}
						type="datetime-local"
						{...register("start", { required: true })}
					/>
					{errors.start && (
						<Form.Control.Feedback type="invalid">
							{format(`validation:${errors.start.type}`, { field: format("common:start") })}
						</Form.Control.Feedback>
					)}
				</Form.Group>

				<Form.Group>
					<Form.Label>{format("common:message")}</Form.Label>
					<Form.Control isInvalid={!!errors.message} as="textarea" {...register("message")} />
				</Form.Group>

				<Stack direction="horizontal" gap={2} className="justify-content-end mt-1">
					<Button variant="secondary" onClick={() => onCancel()}>
						{format("common:cancel")}
					</Button>
					<Button type="submit" variant="primary" disabled={isSubmitting}>
						{format("trainers:reservations:preview:reschedule")}
					</Button>
				</Stack>
			</Stack>
		</Form>
	);
};

export default TrainerReservationRescheduleForm;

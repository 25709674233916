import * as React from "react";
import { Spinner } from "react-bootstrap";

interface IProps {
	className?: string;
	loadingText?: string;
}

const Loader: React.FunctionComponent<IProps> = ({ className, loadingText }) => (
	<div className={className || "loading"}>
		<Spinner animation="grow" />
		{loadingText}
	</div>
);

export default Loader;

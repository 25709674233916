import React from "react";

import useSnackbar from "../../hooks/useSnackbar";
import SnackbarAlert from "./SnackbarAlert";

const Snackbar: React.FC = () => {
	const { alerts, removeAlert } = useSnackbar();

	return (
		<div className="snackbar">
			{alerts.map((alert) => (
				<SnackbarAlert key={alert.id} alert={alert} onRemove={removeAlert} />
			))}
		</div>
	);
};

export default Snackbar;

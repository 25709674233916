import { Outlet } from "react-router-dom";

import Sidebar from "./sidebar";

const ValueCodes = () => (
	<>
		<Sidebar />
		<main className="lessons main--with-sidebar">
			<Outlet />
		</main>
	</>
);

export default ValueCodes;

import { FC } from "react";
import { Button, FloatingLabel, Form, Stack } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";

export interface IRefundFormValues {
	amount: number;
	cancelProduct: boolean;
	authorizedBy: string;
	reason: string;
}

export interface IRefundFormProps {
	remainingAmount: number;
	canCancel?: boolean;
	onRefund: (data: IRefundFormValues) => Promise<void>;
}

const RefundForm: FC<IRefundFormProps> = ({ remainingAmount, canCancel, onRefund }) => {
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm<IRefundFormValues>({
		defaultValues: {
			...(canCancel
				? {
						cancelProduct: false,
				  }
				: {}),
			authorizedBy: "",
			reason: "",
		},
	});

	const onSubmit: SubmitHandler<IRefundFormValues> = async (values) => {
		await onRefund({
			...values,
			amount: values.amount * 100,
		});
	};

	return (
		<Form noValidate onSubmit={handleSubmit(onSubmit)}>
			<Stack gap={3}>
				<Form.Group>
					<FloatingLabel label="Cash">
						<Form.Control
							isInvalid={!!errors.amount}
							type="number"
							size="lg"
							min="0.01"
							max={remainingAmount / 100}
							step="2"
							placeholder="Refund amount"
							{...register("amount", {
								required: true,
								min: 1,
								max: remainingAmount / 100,
								valueAsNumber: true,
							})}
						/>
					</FloatingLabel>
				</Form.Group>

				<Form.Group>
					<FloatingLabel label="Authorized by">
						<Form.Control
							isInvalid={!!errors.authorizedBy}
							size="lg"
							placeholder="Your name"
							{...register("authorizedBy", {
								required: true,
							})}
						/>
					</FloatingLabel>
				</Form.Group>

				<Form.Group>
					<FloatingLabel label="Reason">
						<Form.Control
							isInvalid={!!errors.reason}
							size="lg"
							placeholder="Provide reason for this refund"
							{...register("reason", {
								required: true,
							})}
						/>
					</FloatingLabel>
				</Form.Group>

				{canCancel && (
					<Form.Group controlId="cancel-products">
						<Form.Check label="Cancel product(s)" {...register("cancelProduct")} />
					</Form.Group>
				)}

				<Button type="submit" size="lg" disabled={isSubmitting}>
					Refund
				</Button>
			</Stack>
		</Form>
	);
};

export default RefundForm;

import { FC, useEffect, useState } from "react";
import { Alert, Button, Spinner } from "react-bootstrap";
import { jsPDF } from "jspdf";
import JSZip from "jszip";
import QRCode from "qrcode";
import { Types } from "@sno_oslo/shared-utils";

import { addAccessPass } from "../../controllers/access-passes";
import useSnackbar from "../../hooks/useSnackbar";

interface IAddPassesProcessor {
	passes: Array<Parameters<typeof addAccessPass>[0]>;
}

const saveBlob = (blob: Blob, filename: string) => {
	const url = window.URL.createObjectURL(new Blob([blob]));
	const link = document.createElement("a");

	link.href = url;
	link.setAttribute("download", filename);
	document.body.appendChild(link);
	link.click();
	link.remove();

	window.URL.revokeObjectURL(url);
};

const AddPassesProcessor: FC<IAddPassesProcessor> = ({ passes }) => {
	const { addAlert } = useSnackbar();
	const [addedPasses, setAddedPasses] = useState<Array<Types.IAccessProduct>>([]);
	const isFinished = addedPasses.length === passes.length;

	const downloadPasses = () => {
		const csvData = [
			"ID,QR Code,Type,Duration,User ID,Expire time,Price class,Price,Days type,Zones",
			...addedPasses.map((pass) =>
				[
					pass.id,
					pass.qrCode,
					pass.type,
					pass.duration,
					pass.userId,
					pass.expireTime,
					pass.priceClass,
					pass.price,
					pass.daysType,
					pass.zones?.join(";"),
				].join(","),
			),
		].join("\n");

		saveBlob(new Blob([csvData]), "passes.csv");
	};

	const downloadPDFZip = async () => {
		try {
			const zipPassCount = 100;

			for (let i = 0; i < Math.floor(addedPasses.length / zipPassCount) + 1; i++) {
				const zip = new JSZip();

				for (const pass of addedPasses.slice(i * zipPassCount, i * zipPassCount + zipPassCount)) {
					const pdf = new jsPDF();

					const pageWidth = pdf.internal.pageSize.getWidth();
					const pageHeight = pdf.internal.pageSize.getHeight();

					const qrCodeWidth = pageWidth * 0.9;
					const qrCodeHeight = qrCodeWidth;

					const qrDataURL = await QRCode.toDataURL(pass.qrCode.toString(), { width: qrCodeWidth * 2 });

					const xPosition = (pageWidth - qrCodeWidth) / 2;
					const yPosition = (pageHeight - qrCodeHeight) / 2;

					pdf.addImage(qrDataURL, "JPEG", xPosition, yPosition, qrCodeWidth, qrCodeHeight);

					zip.file(`${pass.qrCode}.pdf`, pdf.output("blob"));
				}

				const zipBlob = await zip.generateAsync({ type: "blob" });
				saveBlob(zipBlob, "passes.zip");
			}
		} catch {}
	};

	useEffect(() => {
		(async () => {
			if (addedPasses.length < passes.length) {
				try {
					const addedPass = await addAccessPass(passes[addedPasses.length]);
					setAddedPasses([...addedPasses, addedPass]);
				} catch (err) {
					addAlert(`Error adding pass: ${addedPasses.length}, ${err}`, "danger");
				}
			}
		})();
	}, [addedPasses, passes]);

	return (
		<>
			<hr />

			<Alert variant={isFinished ? "success" : "info"}>
				<Alert.Heading>
					{isFinished ? "Done" : `${Math.floor((addedPasses.length / passes.length) * 100)}%`}
				</Alert.Heading>
				<p>
					{isFinished ? (
						`Processed all ${passes.length} passes`
					) : (
						<>
							Processing pass QR Code: <b>{addedPasses.length}</b> {!isFinished && <Spinner size="sm" />}
						</>
					)}
				</p>
				<hr />
				{isFinished ? (
					<>
						<Button onClick={() => downloadPasses()}>Download CSV</Button>
						<Button className="d-block mt-2" onClick={() => downloadPDFZip()}>
							Download PDF(s)
						</Button>
					</>
				) : (
					<p className="mb-0">Please do not close browser tab.</p>
				)}
			</Alert>
		</>
	);
};

export default AddPassesProcessor;

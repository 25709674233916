import { Types } from "@sno_oslo/shared-utils";
import * as React from "react";

interface IProps {
	editMode: boolean;
	reservation: Types.IReservation;
	updateReservation: (reservation: Types.IReservation) => void;
}

const CampParticipantDetails: React.FunctionComponent<IProps> = (props: IProps) => {
	const { details } = props.reservation;

	const updateReservation = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		let value: Types.Difficulty | Types.Discipline | string;
		switch (e.target.name) {
			case "difficulty":
				value = Types.getDifficultyFromString(e.target.value);
				break;
			case "discipline":
				value = Types.getDisciplineFromString(e.target.value);
				break;
			case "participantName":
			default:
				value = e.target.value;
				break;
		}

		props.updateReservation({ ...props.reservation, [e.target.name]: value });
	};

	const updateReservationDetails = (
		e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
	) => {
		let value: string | number | boolean;
		switch (e.target.type) {
			case "number":
				value = parseInt(e.target.value, 10);
				break;
			case "checkbox":
				value = (e as React.ChangeEvent<HTMLInputElement>).target.checked;
				break;
			default:
				value = e.target.value;
				break;
		}

		const reservationDetails = { ...details, [e.target.name]: value };
		props.updateReservation({ ...props.reservation, details: reservationDetails });
	};

	return (
		<div className="reservation-details">
			{props.editMode ? (
				<input
					type="text"
					name="participantName"
					value={props.reservation.participantName || ""}
					onChange={updateReservation}
					placeholder="Kari Nordmann"
				/>
			) : (
				<h5>{props.reservation.participantName}</h5>
			)}
			<div className="reservation-details--participant">
				<label>
					Year of birth
					{props.editMode ? (
						<input
							type="number"
							name="yearOfBirth"
							value={props.reservation.yearOfBirth || ""}
							onChange={updateReservationDetails}
							placeholder="2015"
						/>
					) : (
						<p>{props.reservation?.yearOfBirth}</p>
					)}
				</label>
				<label>
					Height
					{props.editMode ? (
						<input
							type="number"
							name="height"
							value={props.reservation?.height || ""}
							onChange={updateReservationDetails}
							placeholder="120"
						/>
					) : (
						<p>{props.reservation?.height}</p>
					)}
				</label>
				<label>
					Gender
					{props.editMode ? (
						<select value={props.reservation?.gender || ""} name="gender" onChange={updateReservationDetails}>
							{[Types.Gender.FEMALE, Types.Gender.MALE, Types.Gender.OTHER].map((genderType) => (
								<option key={genderType} value={genderType}>
									{genderType}
								</option>
							))}
						</select>
					) : (
						<p>{props.reservation?.gender}</p>
					)}
				</label>
				<label>
					Extra comments
					{props.editMode ? (
						<textarea
							name="comments"
							value={props.reservation?.comment || ""}
							onChange={updateReservationDetails}
							placeholder="Info about allergies, etc."
						></textarea>
					) : (
						<p>{props.reservation?.comment}</p>
					)}
				</label>
				<label>
					Discipline
					{props.editMode ? (
						<select
							value={props.reservation.discipline || ""}
							name="discipline"
							onChange={updateReservation}
						>
							{Types.getAllDisciplines().map((discipline) => (
								<option key={discipline} value={discipline}>
									{discipline}
								</option>
							))}
						</select>
					) : (
						<p>{props.reservation.discipline}</p>
					)}
				</label>
				<label>
					Level
					{props.editMode ? (
						<select
							value={props.reservation.difficulty || ""}
							name="difficulty"
							onChange={updateReservation}
						>
							{Types.getAllDifficulties().map((difficulty) => (
								<option key={difficulty} value={difficulty}>
									{difficulty}
								</option>
							))}
						</select>
					) : (
						<p>{props.reservation.difficulty}</p>
					)}
				</label>
				<label htmlFor="imageConsent">
					Image consent
					<input
						type="checkbox"
						id="imageConsent"
						name="imageConsent"
						checked={props.reservation?.imageConsent === 'true'}
						onChange={updateReservationDetails}
						disabled={!props.editMode}
					/>
				</label>
			</div>
		</div>
	);
};

export default CampParticipantDetails;

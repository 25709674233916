import { Types } from "@sno_oslo/shared-utils";
import create from "zustand";
import { getGroupCourses, IGroupCourseDto } from "../controllers/lessons/groupCourses";

export interface ICoursesStore {
	items: Array<IGroupCourseDto>;
	isFetching: boolean;
	filterArchived: boolean;
	fetchTime?: number;
	lastKey?: Types.ILastKey;
	filters: Record<string, any>;
	setFilter: (key: string, value: any) => void;
	removeFilter: (key: string) => void;
	updateItem: (id: IGroupCourseDto["id"], course: Partial<Omit<IGroupCourseDto, "id">>) => void;
	fetch: (reset?: boolean) => Promise<void>;
	setFilterArchived: (filterArchived: boolean) => void;
}

export const useCoursesStore = create<ICoursesStore>((set, get) => ({
	items: [],
	filters: {},
	isFetching: false,
	filterArchived: false,
	updateItem: (id, item) => {
		const { items } = get();
		set({
			items: items.map((course) => (course.id === id ? { ...course, ...item } : course)),
		});
	},
	fetch: async (reset) => {
		const fetchTime = Date.now();
		set({ isFetching: true, fetchTime, ...(reset ? { items: [] } : {}) });

		const { filters, lastKey, filterArchived } = get();
		const res = await getGroupCourses({
			filters: {
				...filters,
				...(filters.from ? { from: filters.from.toISOString() } : {}),
				...(filters.to ? { to: filters.to.toISOString() } : {}),
			},
			lastKey: reset ? undefined : lastKey,
			archived: filterArchived,
		});

		const { items, fetchTime: oldFetchTime } = get();
		if (fetchTime !== oldFetchTime) {
			return;
		}

		const newItems = (
			reset
				? res.groupCourses
				: [...items, ...res.groupCourses.filter((course) => !items.some((item) => item.id === course.id))]
		).sort((a, b) => (a.earliestStartDate - b.earliestStartDate) * (filterArchived ? -1 : 1));

		set({
			isFetching: false,
			items: newItems,
			lastKey: res.lastKey,
		});
	},
	setFilter: (key, value) => set({ filters: { ...get().filters, [key]: value } }),
	removeFilter: (key) => {
		const { filters } = get();
		set({
			filters: Object.keys(filters)
				.filter((k) => k !== key)
				.reduce((f, k) => ({ ...f, [k]: filters[k] }), {}),
		});
	},
	setFilterArchived: (filterArchived) => set({ filterArchived }),
}));
